import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const createMassImportInstance = generateService(async () => {
  const { data } = await Api.post('contracts/mass-import/create/')

  return data
})

export const uploadCsv = async (massImportId, tempFileId) => {
  const { data } = await Api.put(`contracts/mass-import/${massImportId}/upload-csv/`, {
    csv_file: tempFileId,
  })

  return data
}

export const stateUpdate = async (massImportId, state) => {
  const { data } = await Api.put(`contracts/mass-import/${massImportId}/state-update/`, { state })

  return data
}

export const retrieveImportedContractors = generateService(async (massImportId) => {
  const { data } = await Api.get(`contracts/mass-import/${massImportId}/retrieve/`)

  return data
})

export const updateContractor = async ({ massImportId, arrayIndex, body }) => {
  const { data } = await Api.put(`contracts/mass-import/${massImportId}/validated-data/${arrayIndex}/update/`, body)

  return data
}

export const deleteContractor = async ({ massImportId, arrayIndex }) => {
  const { data } = await Api.delete(`contracts/mass-import/${massImportId}/raw-data/${arrayIndex}/remove/`)

  return data
}

export const createContractors = async (massImportId, body) => {
  const { data } = await Api.get(`contracts/mass-import/${massImportId}/create-contractors/`, body)

  return data
}

export const uploadAgreements = async (massImportId, body) => {
  const { data } = await Api.put(`contracts/mass-import/${massImportId}/upload-employment-agreement/`, body)

  return data
}
