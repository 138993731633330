import { Api, ApiNoResInterceptor } from '@api/Api'
import { generateService } from '@core/utils'

export const getContracts = generateService(async (params) => {
  const { data } = await Api.get(`/contracts/`, { params })

  return data
})

export const deactivateContract = async (id) => Api.delete(`contracts/${id}/`)

export const deleteContract = async (id) => Api.delete(`contracts/${id}/deactivate/`)

export const reactivateContract = async (id, payload) => {
  const { data } = await Api.put(`contracts/${id}/reactivate/`, payload)
  return data
}

export const mutationUploadAvatar = async (id, file) => {
  const fd = new FormData()
  fd.append('file', file)

  return Api.post(`/contracts/${id}/avatar/`, fd, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })
}

export const getContractsShort = async (params) => {
  const { data } = await Api.get(`contracts-short-list/`, {
    params,
  })
  return data
}

export const getContractsPaidLeaves = async (id) => {
  const { data } = await Api.get(`contracts/${id}/paid-leaves/`)
  return data
}

export const getContractsAvailableCurrency = async (id) => {
  const { data } = await Api.get(`countries/${id}/contractor-available-currencies/`)
  return data
}

export const getContractsChecklist = generateService(async (id) => {
  const { data } = await Api.get(`contracts/${id}/check-list/`)
  return data
})

export const getContractorPaymentsExportCsv = generateService(async () => {
  const { data } = await Api.get(`contractor-payments/export-paid-invoices-info/`, {
    responseType: 'blob',
  })

  return data
})

export const postContractsPaidLeaves = async (id, body) => {
  const { data } = await Api.post(`/contracts/${id}/paid-leaves/update/`, body)

  return data
}

export const checkAnnualSalary = async (body) => {
  const { data } = await ApiNoResInterceptor.post(`/contracts/annual-salary-check/`, body)
  return data
}

export const updateSwiftFeePayRoles = async (body) => {
  const { data } = await Api.post(`/contracts/swift-fee-pay-role/update/`, body)

  return data
}

export const updateSubState = async (id, payload) => {
  const { data } = await Api.put(`/contracts/${id}/sub-state/update/`, payload)
  return data
}

export const createSubStateChangeRequest = async (id, payload) => {
  const { data } = await Api.post(`/contracts/${id}/sub-state-change-request/create/`, payload)
  return data
}

export const getJobDetailsList = generateService(async () => {
  const { data } = await Api.get(`contracts/job-details/?limit=2000`)
  return data
})

export const getDirectManagers = generateService(async () => {
  const { data } = await Api.get(`/contracts/direct-managers/`)
  return data
})

export const getMilestones = generateService(async (id, params) => {
  const { data } = await Api.get(`/contracts/${id}/milestones/`, {
    params,
  })
  return data
})

export const deleteMilestoneById = async (contractorId, milestoneId) => {
  const { data } = await Api.delete(`/contracts/${contractorId}/milestone/${milestoneId}/`)
  return data
}

export const addMilestone = async (id, payload) => {
  const { data } = await Api.post(`/contracts/${id}/milestones/`, payload)
  return data
}

export const updateMilestone = async (id, milestoneId, payload) => {
  const { data } = await Api.put(`/contracts/${id}/milestone/${milestoneId}/`, payload)
  return data
}

export const updateMilestoneStatus = generateService(async (id, milestone_id, payload) => {
  const { data } = await Api.patch(`/contracts/${id}/milestone/${milestone_id}/`, payload)
  return data
})

export const getContractDocumentHandlers = generateService(async (params) => {
  const { page, limit } = params
  const offset = (page - 1) * limit
  const { data } = await Api.get(`contract-documents-handlers/`, {
    params: {
      ...params,
      offset,
    },
  })

  return data
})

export const createEmployeeNote = async (id, payload) => {
  const { data } = await Api.post(`/contracts/${id}/notes/create/`, payload)
  return data
}

export const updateEmployeeNote = async (id, employeeId, payload) => {
  const { data } = await Api.put(`/contracts/${employeeId}/notes/${id}/update/`, payload)
  return data
}

export const getContractDepositInvoice = async (id) => {
  const { data } = await Api.get(`/deposit-invoices/contracts/${id}/retrieve/`)
  return data
}
