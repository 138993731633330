import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { fieldNameMap } from '@core/constants'
import { useApp } from '@core/context'
import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  .modal-header {
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    position: relative;
  }
`

const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

export const CompanyInfoEditForm = ({ title, data, onSubmit, onClose, isLoading }) => {
  const { countries, currencies } = useApp()
  const formRef = useRef(null)
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    reset(data)
  }, [data])

  const triggerFormSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Input
              data-testid="company-info-edit-form.component-0C7398"
              {...register('companyName', {
                required: `${fieldNameMap.companyName} is required`,
              })}
              type="text"
              label={fieldNameMap.companyName}
            />
            {errors?.companyName && (
              <Typography className="text_regular__14 color_red">{errors?.companyName.message}</Typography>
            )}
          </FormControl>
          <FormControl>
            <Controller
              control={control}
              name="country"
              render={({ field }) => {
                return (
                  <Select
                    data-testid="company-info-edit-form.component-886E8D"
                    {...field}
                    label={fieldNameMap.country}
                    placeholder="Select country"
                    options={countries.map((country) => ({
                      value: country.id,
                      label: country.name,
                    }))}
                    isDisabled
                  />
                )
              }}
            />
            {errors.country && <Typography className="text_regular__14 color_red">{errors.country.message}</Typography>}
          </FormControl>
          <FormControl>
            <Controller
              control={control}
              name="currency"
              rules={{ required: `${fieldNameMap.currency} is required` }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="company-info-edit-form.component-41F62E"
                    {...field}
                    label={fieldNameMap.currency}
                    placeholder="Select currency"
                    options={currencies.map((currency) => ({
                      value: currency.id,
                      label: currency.name,
                    }))}
                    isDisabled
                  />
                )
              }}
            />
            {errors.currency && (
              <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
            )}
          </FormControl>
          <FormControl>
            <Input
              data-testid="company-info-edit-form.component-AF34AA"
              {...register('headquarters', {
                required: `${fieldNameMap.headquarters} is required`,
              })}
              type="text"
              label={fieldNameMap.headquarters}
              isRequired
            />
            {errors?.headquarters && (
              <Typography className="text_regular__14 color_red">{errors?.headquarters.message}</Typography>
            )}
          </FormControl>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="company-info-edit-form.component-BFADA9"
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="company-info-edit-form.component-B1D920"
          priority="primary"
          size="small"
          type="button"
          onClick={triggerFormSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
