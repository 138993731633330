import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'

export const TimeOffPolicyModal = ({ onClose }) => {
  return (
    <Modal show onHide={onClose} centered animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20">Paid time off policy change requested</Typography>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <p>
          Our team has received your change request and will verify if it complies with local regulation and if it has
          impact on your employment agreements.
        </p>
        <br />
        <p>We will get in touch if needed.</p>
      </Modal.Body>
      <Modal.Footer>
        <Button data-testid="time-off-policy-modal.component-button" size="medium" onClick={onClose}>
          Understood
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
