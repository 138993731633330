/* eslint-disable jsx-a11y/interactive-supports-focus */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { attachFile, deleteIcon } from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'
import { ProgressBar } from 'react-bootstrap'

import styles from './upload.module.scss'

const Label = ({ label, addText, id, isRequired }) => {
  return (
    <>
      <label className={classNames(['text_medium__14', !addText && 'remo-form-label'])} htmlFor={id || ''}>
        {label} {isRequired && <span className="text-danger ml-1">*</span>}
      </label>
      {addText && (
        <Typography className="text_light__12 color_text_300 mt-1" style={{ marginBottom: 12 }}>
          {addText}
        </Typography>
      )}
    </>
  )
}

export default React.forwardRef(
  (
    {
      id,
      onChange,
      onRemove,
      label,
      addText,
      progress,
      isLoading,
      isRequired,
      isSuccess,
      isDisabled = false,
      accept = '',
    },
    ref
  ) => {
    const [file, setFile] = React.useState()

    const handleRemove = () => {
      setFile(null)
      if (onRemove) onRemove()
    }

    if (isLoading) {
      return (
        <>
          <Label label={label} addText={addText} isRequired={isRequired} />

          <div className={styles.dropzone}>
            <ProgressBar now={progress} label={<Typography>{progress}%</Typography>} />
          </div>
        </>
      )
    }

    if (isSuccess && file) {
      return (
        <>
          <Label label={label} addText={addText} isRequired={isRequired} />
          <div className={styles.delete}>
            <Typography className={styles.fileName}>{file.name}</Typography>
            <div data-testid="UploadButton-index-038C0A" role="button" className={styles.btn} onClick={handleRemove}>
              <Icon icon={deleteIcon} className="mr-2" />
              Delete
            </div>
          </div>
        </>
      )
    }

    const handleFileInput = (e) => {
      const selectedFile = e.target.files[0]
      onChange(selectedFile)
      setFile(selectedFile)
    }

    return (
      <>
        <Label label={label} addText={addText} isRequired={isRequired} />
        <label htmlFor={`file-input_${id}`} className={styles.label}>
          <Icon icon={attachFile} className={styles.attachIcon} />
          Select file
          <input
            data-testid="UploadButton-index-296FA5"
            ref={ref}
            type="file"
            id={`file-input_${id}`}
            hidden
            accept={accept}
            onChange={handleFileInput}
          />
        </label>
      </>
    )
  }
)
