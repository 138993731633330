import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { CANCEL_ONBOARDING_REASONS_MAP } from '@core/constants'
import { Controller, useForm } from 'react-hook-form'

export const CancelOnboardingModal = ({ visible, loading, onClose, onSubmit }) => {
  const {
    control,
    formState: { errors },
    handleSubmit,
    register,
  } = useForm()

  return (
    <EditModal visible={visible} title="Cancel onboarding" footer={false} closeModal={onClose}>
      <InfoMessage
        message="You are about to cancel this onboarding process. After cancellation, the employee will be in Inactive - Cancelled status."
        type="warning"
      />
      <form onSubmit={handleSubmit(onSubmit)}>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="reason"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => {
              return (
                <Select
                  {...field}
                  label="Reason"
                  isRequired
                  options={Object.keys(CANCEL_ONBOARDING_REASONS_MAP).map((key) => ({
                    label: CANCEL_ONBOARDING_REASONS_MAP[key],
                    value: key,
                  }))}
                  onChange={({ value }) => field.onChange(value)}
                />
              )
            }}
          />
          {errors?.reason && <Typography className="text_regular__14 color_red">{errors.reason.message}</Typography>}
        </div>

        <div className="w-100 remo-form-input">
          <TextArea
            label="Additional information"
            {...register('description')}
            rows={3}
            placeholder="Provide more detailed description"
          />
        </div>

        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="CancelOnboardingModal-F069B3"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={onClose}
          >
            Cancel
          </Button>
          <Button
            data-testid="CancelOnboardingModal-A5370B"
            loading={loading}
            disabled={loading}
            priority="primary_dangerous"
            size="small"
            type="submit"
          >
            Cancel onboarding
          </Button>
        </div>
      </form>
    </EditModal>
  )
}
