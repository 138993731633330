import Button from '@atoms/Button/Button'
import { CONTRACT_TYPE } from '@core/constants'
import { memo } from 'react'

export const DeactivateButton = memo(
  ({ contractType, state, subState, onDeactivate, onOnboardingCancel, onActivate }) => {
    if (contractType === CONTRACT_TYPE.CONTRACTOR && state === 'active') {
      return (
        <Button
          data-testid="DeactivateButton-3814E5"
          onClick={onDeactivate}
          style={{ maxWidth: 'fit-content' }}
          priority="primary_black"
          size="small"
        >
          Deactivate contract
        </Button>
      )
    }
    if (contractType === CONTRACT_TYPE.CONTRACTOR && state === 'inactive') {
      return (
        <Button
          data-testid="Personal-3814W5"
          onClick={onActivate}
          style={{ maxWidth: 'fit-content' }}
          priority="primary_black"
          size="small"
        >
          Activate contract
        </Button>
      )
    }
    if (state === 'active') {
      if (subState === 'offboarding') {
        return (
          <Button data-testid="DeactivateButton-B37CDB" disabled style={{ maxWidth: 'fit-content' }} size="small">
            Offboarding in progress
          </Button>
        )
      }
      return (
        <Button
          data-testid="DeactivateButton-FF0E90"
          onClick={onDeactivate}
          style={{ maxWidth: 'fit-content' }}
          priority="primary_black"
          size="small"
        >
          Request Offboarding
        </Button>
      )
    }
    if (state === 'created' || state === 'onboarding') {
      return (
        <Button
          data-testid="DeactivateButton-088E92"
          onClick={onOnboardingCancel}
          style={{ maxWidth: 'fit-content' }}
          priority="primary_dangerous"
          size="small"
          disabled={subState === 'cancelled'}
        >
          Cancel onboarding
        </Button>
      )
    }
    return null
  }
)
