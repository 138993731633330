import Confirm from '@atoms/Confirm'
import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`

const StyledConfirm = styled(Confirm)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .modal-header {
    margin-bottom: 0;
  }
`

export { Container, Content, StyledConfirm }
