import './insurance-list.styles.scss'

import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Typography from '@atoms/Typography/Typography'
import { infoFilledIcon } from '@core/icons/icons'
import { healthInsuranceMap } from '@pages/employees/create-employee/forms/Benefit/const'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { RemohealthPackageInfo } from '../RemohealthPackageInfo'

const HealthItem = styled.div`
  background-color: white;
  padding: 20px 20px 2px 20px;
  width: 480px;
  min-height: 108px;
  border-radius: 8px;
  margin: 8px 0;
  z-index: 2;
`

const PackageWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  margin-top: 20px;
  flex-wrap: wrap;
`

const StyledInput = styled.input`
  width: 20px;
  height: 20px;
  accent-color: green;
`

const WrapperOptionIcon = styled.div`
  background: #ebfcef;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 50%;
  width: 20px;
  height: 20px;
`

const TextDescription = styled.div`
  color: #484848;
`
const GreenText = styled.span`
  color: #037b0f;
  font-size: 18px;
  font-weight: 500;
`

export const InsuranceList = ({ loading, insuranceTypes = [] }) => {
  const modalOpen = useBoolean(false)
  const [selectedInsuranceType, setSelectedInsuranceType] = useState()

  const { register, watch } = useFormContext()
  const insuranceId = +watch('insuranceId')
  const familyMemeberAmount = +watch('family_members_amount')
  const activeInsurance = insuranceTypes.find((ins) => ins.id === insuranceId)

  const onClickInfo = (type) => {
    modalOpen.setTrue()
    setSelectedInsuranceType(type)
  }

  if (loading) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center mb-5 mt-5">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      {insuranceTypes.length ? (
        <>
          {insuranceTypes.map(({ id, insurance_type: type, max_age, min_age, price }) => (
            <HealthItem
              key={id}
              style={{
                border: `1px solid ${id === insuranceId ? '#04C53A' : '#dfdfdf'}`,
              }}
            >
              <div className="d-flex justify-content-between">
                <Typography className="heading_semibold__20 d-flex align-items-center">
                  <StyledInput
                    type="radio"
                    {...register('insuranceId')}
                    id={id}
                    value={id}
                    defaultChecked={id === insuranceId}
                  />
                  <span className="ml-2">{healthInsuranceMap[type.name].title}</span>
                  <Icon
                    icon={infoFilledIcon}
                    className="ml-2"
                    style={{ cursor: 'pointer' }}
                    onClick={() => onClickInfo(type.name)}
                  />
                </Typography>
                <TextDescription>
                  from <GreenText>{price} USD </GreenText>/ month
                </TextDescription>
              </div>
              <PackageWrapper>
                {healthInsuranceMap[type.name].packages.map((p) => (
                  <div className="d-flex mb-3 align-items-center" key={p.title}>
                    <WrapperOptionIcon>
                      <Icon icon={p.icon} />
                    </WrapperOptionIcon>
                    <Typography className="text_light__12 ml-2">{p.title}</Typography>
                  </div>
                ))}
              </PackageWrapper>
            </HealthItem>
          ))}
          <div className="mt-3">
            <Typography className="heading_semibold__18 mb-3">Allow the inclusion of family members:</Typography>
            <div className="d-flex align-items-center justify-content-between mb-2">
              <div>
                <Input
                  data-testid="insurance-list.component-386930"
                  {...register('family_members_amount')}
                  type="number"
                  label="Select maximum number of dependents"
                  placeholder="0"
                  max={10}
                  styleClass="input-family"
                />
                <Typography className="text_light__12 color_text_300 mt-1" style={{ marginTop: 12 }}>
                  Max 10 people
                </Typography>
              </div>
              <div className="d-flex flex-column align-items-end">
                <TextDescription>
                  <GreenText>
                    ~
                    {familyMemeberAmount && activeInsurance?.price
                      ? (familyMemeberAmount * activeInsurance.price).toFixed(2)
                      : 0}{' '}
                    USD{' '}
                  </GreenText>
                  / month
                </TextDescription>
                <Typography className="text_light__12 color_text_300 mt-1">
                  {familyMemeberAmount || 0} * ~{activeInsurance?.price || 0} USD / month
                </Typography>
              </div>
            </div>
            <NotificationBlock
              styledClass="notification"
              render={
                <Typography className="text_light__12">
                  The exact cost will be provided after receiving family members information
                </Typography>
              }
            />
          </div>
        </>
      ) : (
        <div className="text-center mt-5">There is no data about insurance</div>
      )}
      {modalOpen.value && selectedInsuranceType && (
        <ModalSide title={`RemoHealth ${selectedInsuranceType || ''}`} onClose={modalOpen.setFalse}>
          <RemohealthPackageInfo packageType={selectedInsuranceType} />
        </ModalSide>
      )}
    </>
  )
}
