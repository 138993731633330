import Button from '@atoms/Button/Button'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { EOR_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { fetchCountryRegions } from '@services/countries.service'
import { DotWave } from '@uiball/loaders'
import React, { useEffect, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import { fetchCompanies, fetchCountries } from '../../../../services/app.service'
import { HiringInfo } from '../hiring-info/hiring-info.component'

export default ({ onNext, hasAppendix = false, draft, regionOptions, setRegionOptions }) => {
  const { profile } = useApp()

  const [hasRegions, setHasRegions] = useState(false)

  const { data, isLoading } = useQuery('companies', {
    queryFn: () => fetchCompanies(profile.id),
  })

  const { isLoading: loadingCountry, data: countries } = useQuery('fetchCountries', {
    queryFn: () => fetchCountries({ limit: 1000, offset: 0, is_allowed_by_company: true }),
  })

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
    reset,
    setValue,
    getValues,
  } = useForm({
    defaultValues: {
      working_country: data?.allowed_countries?.length === 1 ? data.allowed_countries[0].id : '',
    },
  })
  const watchWorkingCountry = watch('working_country')

  useEffect(() => {
    if (draft) {
      reset({
        working_country: draft?.working_country,
        region: draft?.region,
      })
    }
  }, [draft])

  const submit = (ddd) => {
    const workingCountryId = ddd.working_country.value || ddd.working_country
    const workingCountry = data?.allowed_countries.find((country) => country.id === workingCountryId)
    workingCountry.hasOccupations = countries?.results?.find((c) => c.id === workingCountryId)?.has_occupations
    onNext({
      workingCountryId,
      workingCountry,
      region: getValues().region,
    })
  }

  const regionsData = useQuery(
    'getCountryRegions',
    () => fetchCountryRegions(watchWorkingCountry?.value || watchWorkingCountry),
    {
      enabled: hasRegions,
      onSuccess: (regions) => {
        setRegionOptions(regions.results.map((r) => ({ value: r.id, label: r.name })))
      },
    }
  )
  useEffect(() => {
    setHasRegions(
      !!countries?.results?.find((c) => c.id === watchWorkingCountry.value || c.id === watchWorkingCountry)?.has_regions
    )
    regionsData.refetch()
    setValue('region', null)
  }, [watchWorkingCountry])

  const getCountryList = () => {
    switch (profile?.eor_type) {
      case EOR_TYPES.SAAS:
        return countries?.results.map((country) => ({
          value: country.id,
          label: country.name,
        }))
      case EOR_TYPES.TRADITIONAL:
        return data.allowed_countries
          .filter(
            ({ is_active, allowed_by_default, need_confirmation }) =>
              is_active && (allowed_by_default || need_confirmation)
          )
          .map((country) => ({
            value: country.id,
            label: country.name,
          }))
      default:
        return []
    }
  }

  if (isLoading)
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={32} speed={1} color="black" />
      </div>
    )

  return (
    <form className="d-flex flex-column h-100 employees-page__form" onSubmit={handleSubmit(submit)}>
      <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <Controller
          control={control}
          name="working_country"
          rules={{ required: 'Field is required' }}
          render={({ field }) => {
            return (
              <Select
                data-testid="HiringDetailsForm-0032C5"
                {...field}
                loading={loadingCountry}
                label="Where do you want to hire the employee?"
                options={getCountryList()}
              />
            )
          }}
        />

        {errors.working_country && (
          <Typography className="text_regular__14 color_red">{errors.working_country.message}</Typography>
        )}
        <div className="w-100">
          <InfoMessage
            message={
              <span>
                If you cannot find the country in the list, please contact{' '}
                <a href="mailto:help@remofirst.com">help@remofirst.com</a>.
              </span>
            }
            fullWidth
          />
        </div>
        {hasRegions && (
          <div className="w-100 remo-form-input mt-5">
            <Controller
              control={control}
              name="region"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="HiringDetailsForm-00323C5"
                    {...field}
                    loading={regionsData.isLoading}
                    label="Region"
                    options={regionOptions}
                  />
                )
              }}
            />

            {errors.region && <Typography className="text_regular__14 color_red">{errors.region.message}</Typography>}
          </div>
        )}
      </div>

      {hasAppendix && <HiringInfo country={watchWorkingCountry} />}

      <div className="pb-5 align-self-center">
        <Button data-testid="HiringDetailsForm-33A127" type="submit">
          Continue
        </Button>
      </div>
    </form>
  )
}
