import './style.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { rejectionIcon } from '@core/icons/icons'

export const RejectionReason = ({ reason }) => {
  return (
    <div className="p-2 mt-4 reason-container">
      <div className="d-flex align-items-center">
        <Icon icon={rejectionIcon} style={{ cursor: 'pointer' }} />
        <Typography className="text_medium__12 ml-1 color_dark_red">Reason for rejection</Typography>
      </div>
      <Typography className="text_light__12 mt-2 ml-1 color_dark_red text">{reason}</Typography>
    </div>
  )
}
