import Icon from '@atoms/Icon/Icon'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { EMPLOYEE_STATES } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { heartIcon } from '@core/icons/icons'
import { EquipmentDeliveryModal } from '@pages/employeeDetailPage/libs/Benefit/EquipmentDeliveryModal'
import { RemoHealthModal } from '@pages/employeeDetailPage/libs/Benefit/RemoHealthModal'
import { fetchContractors } from '@services/app.service'
import { attach, getList } from '@services/health-insurance.service'
import moment from 'moment'
import React, { useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { useParams } from 'react-router-dom'
import { components } from 'react-select'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const STEPS = {
  COMMON: 'common',
  HEALTH_CARE: 'health_care',
  OTHER_BENEFITS: 'other_benefits',
  WORK_EQUIPMENT: 'work_equipment',
}
const CardWrapper = styled.div`
  position: relative;
  width: 520px;
  min-height: 120px;
  overflow: hidden;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 24px;
  margin-bottom: 16px;
  cursor: pointer;
`

const MinPrice = styled.p`
  position: absolute;
  top: 50px;
  right: 35px;
  z-index: 2;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  transform: rotate(16.01deg);
`

const StyledIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 200px;
  z-index: 2;
  height: 100%;
`
const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  flex: 1;
  justify-content: center;
`

const TextDescription = styled.div`
  color: #484848;
`

const SelectContainer = styled.div`
  width: 520px;
`

const SingleValue = (props) => {
  const { getValue } = props
  const { label, contractor } = getValue()[0]

  return (
    <components.SingleValue {...props}>
      <span>{label}</span> <span style={{ color: '#878787' }}>{contractor}</span>
    </components.SingleValue>
  )
}
const Option = (props) => {
  const { data } = props
  const { label, contractor } = data

  return (
    <components.Option {...props}>
      <span>{label}</span> <span style={{ color: '#878787' }}>{contractor}</span>
    </components.Option>
  )
}

export const ModalBenefit = ({ age, employee, onClose, countryId, setEmployee, refetch }) => {
  const [step, setStep] = useState(STEPS.COMMON)
  const [selectedStep, setSelectedStep] = useState(STEPS.COMMON)
  const { successAlert } = useToast()
  const confirmOpen = useBoolean(false)
  const { id } = useParams()
  const { profile } = useApp()

  const methods = useForm({
    defaultValues: {
      provides_work_equipment: false,
      equipment_specification: employee?.benefit?.equipment_specification || '',
      provides_health_insurance: '',
      contract: null,
    },
  })
  const { control, errors, watch } = methods
  const contractWatch = watch('contract')
  const contractAge = contractWatch?.birth_date ? moment().diff(contractWatch.birth_date, 'years') : ''

  const { data: contracts, isLoading } = useQuery([fetchContractors.key], {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        contract_type: 'full_time_employee',
        state_in: `${EMPLOYEE_STATES.ACTIVE},${EMPLOYEE_STATES.ONBOARDING},${EMPLOYEE_STATES.CREATED}`,
        offset: 0,
        limit: 1000,
      }),
  })
  const { data: minPrice } = useQuery({
    queryKey: ['getHealthInsuranceList', countryId, contractWatch],
    queryFn: () =>
      getList({
        age,
        country_id: countryId || contractWatch?.countryId,
      }),
    enabled: !!countryId || !!contractWatch?.countryId,
  })
  const attachInsuranceMutation = useMutation({
    mutationFn: (payload) => attach(payload),
    onSuccess: () => {
      onClose()
      refetch()
      successAlert('Health insurance successfully attached')
    },
  })
  const submit = ({ insuranceId, family_members_amount, contract }) => {
    if (insuranceId) {
      attachInsuranceMutation.mutate({
        id: id || contract.value,
        payload: {
          insurance_price: +insuranceId,
          family_members_amount,
        },
      })
    }
  }

  const onOk = () => {
    if (step === STEPS.COMMON && selectedStep) {
      if (id) {
        setStep(selectedStep)
      } else if (contractWatch) setStep(selectedStep)
    } else {
      confirmOpen.setTrue()
    }
  }

  return (
    <FormProvider {...methods}>
      {step === STEPS.COMMON && (
        <ModalSide
          title="Add benefits"
          okText={step === STEPS.COMMON ? 'Next step' : 'Add benefits'}
          onClose={onClose}
          onOk={onOk}
        >
          <ContentWrapper>
            {!id && (
              <SelectContainer>
                <div className="w-100 remo-form-input">
                  <Controller
                    control={control}
                    name="contract"
                    rules={{ required: 'Field is required' }}
                    render={({ field }) => {
                      return (
                        <Select
                          data-testid="ModalBenefit-C15B50"
                          {...field}
                          isDisabled={isLoading}
                          isRequired
                          label={isLoading ? 'Loading...' : 'Employee'}
                          options={
                            contracts?.results.length > 0
                              ? contracts?.results?.map((contract) => ({
                                  value: contract.id,
                                  contractor: contract.contract_type === 'contractor' ? '(Contractor)' : '',
                                  label: contract.full_name,
                                  countryId: contract.working_country?.id,
                                  provides_work_equipment: contract.benefit?.provides_work_equipment,
                                  birth_date: contract.birth_date,
                                }))
                              : []
                          }
                          getOptionLabel={(option) => `${option.label}${option.contractor}`}
                          components={{ SingleValue, Option }}
                        />
                      )
                    }}
                  />
                  {errors?.contract && (
                    <Typography className="text_regular__14 color_red">{errors?.contract.message}</Typography>
                  )}
                </div>
              </SelectContainer>
            )}
            <CardWrapper
              onClick={() => setSelectedStep(STEPS.HEALTH_CARE)}
              style={{
                border: `1px solid ${selectedStep === STEPS.HEALTH_CARE ? '#04C53A' : '#dfdfdf'}`,
              }}
            >
              {minPrice?.results.length ? (
                <>
                  <StyledIconWrapper>
                    <StyledIcon icon={heartIcon} fill="none" />
                  </StyledIconWrapper>
                  <MinPrice>{Math.floor(minPrice.results[0].price)} USD</MinPrice>
                </>
              ) : null}
              <Typography className="heading_semibold__18 mb-2">RemoHealth packages</Typography>
              <TextDescription className="text_regular-normal__14">
                Check the available RemoHealth plans{' '}
                <a
                  data-testid="ModalBenefit-11F335"
                  href="https://remofirst-static.s3.amazonaws.com/remohealth/Remohealth.pdf"
                  target="_blank"
                  rel="noreferrer"
                  className="link"
                  onClick={(e) => e.stopPropagation()}
                >
                  here
                </a>
              </TextDescription>
            </CardWrapper>

            {/* //TODO: add in next sprint */}
            {/* <CardWrapper> */}
            {/*  <Typography className="heading_semibold__18 mb-2"> */}
            {/*    Allowance */}
            {/*  </Typography> */}
            {/*  <TextDescription className="text_regular-normal__14"> */}
            {/*    Would you like to offer other benefits to your employee? Add */}
            {/*    them here for review and we will provide feedback. */}
            {/*  </TextDescription> */}
            {/* </CardWrapper> */}
            {!employee?.benefit?.provides_work_equipment && !contractWatch?.provides_work_equipment && (
              <CardWrapper
                onClick={() => setSelectedStep(STEPS.WORK_EQUIPMENT)}
                style={{
                  border: `1px solid ${selectedStep === STEPS.WORK_EQUIPMENT ? '#04C53A' : '#dfdfdf'}`,
                }}
              >
                <Typography className="heading_semibold__18 mb-2">Equipment delivery</Typography>
                <TextDescription className="text_regular-normal__14">
                  We will purchase and ship the equipment to your international employee and issue the invoice
                  afterwards.
                </TextDescription>
              </CardWrapper>
            )}

            {/* //TODO: add in next sprint */}
            {/* <CardWrapper> */}
            {/*  <Typography className="heading_semibold__18 mb-2"> */}
            {/*    Other benefits */}
            {/*  </Typography> */}
            {/*  <TextDescription className="text_regular-normal__14"> */}
            {/*    Would you like to offer other benefits to your employee? Add */}
            {/*    them here for review and we will provide feedback. */}
            {/*  </TextDescription> */}
            {/* </CardWrapper> */}
          </ContentWrapper>
        </ModalSide>
      )}
      {step === STEPS.HEALTH_CARE && (
        <RemoHealthModal
          age={age || contractAge}
          countryId={countryId || contractWatch?.countryId}
          isLoading={attachInsuranceMutation.isLoading}
          onClose={onClose}
          onSubmit={submit}
          goBack={() => setStep(STEPS.COMMON)}
        />
      )}
      {step === STEPS.WORK_EQUIPMENT && (
        <EquipmentDeliveryModal onClose={onClose} setEmployee={setEmployee} goBack={() => setStep(STEPS.COMMON)} />
      )}
    </FormProvider>
  )
}
