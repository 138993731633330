/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable react/no-array-index-key */
import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import { keyboardArrowLeft, miniCalendarIcon } from '@core/icons/icons'
import moment from 'moment'
import { useNavigate } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import { ApproveTimesheetModal } from './ApproveTimesheetModal'
import { TIMESHEET_DETAILS, TIMESHEET_TABLE_FIELDS } from './mock'
import { RejectionReason } from './rejection-reason/RejectionReason'
import { RejectTimesheetModal } from './RejectTimesheetModal'
import { TimesheetsTable } from './timesheets-table/TimesheetsTable'

export const TimesheetDetailsPage = () => {
  const navigate = useNavigate()
  const showApproveTimesheetModal = useBoolean(false)
  const showRejectTimesheetModal = useBoolean(false)

  // get time sheet data by using id of contractor or timesheet id
  // console.log('ID', params.id)
  const data = TIMESHEET_DETAILS
  const { status } = data

  const displayPeriod = () => {
    return `${moment(data.start_date).format('D MMM YYYY')} - ${moment(data.end_date).format('D MMM YYYY')}`
  }

  const handleTimesheetReject = (payload) => {
    alert('rejected')
    console.log('payload', payload)
    showRejectTimesheetModal.setFalse()
  }

  const handleTimesheetApprove = () => {
    alert('approved')
    showApproveTimesheetModal.setFalse()
  }

  const displayHeader = () => {
    switch (status) {
      case 'pending':
        return (
          <>
            <Typography className="heading_semibold__24 ml-3">{`Review ${data.employee_name} timesheet ${displayPeriod()}`}</Typography>
            <div className="d-flex gap-2">
              <Button onClick={showRejectTimesheetModal.setTrue} priority="primary_dangerous" size="small">
                Reject
              </Button>
              <Button onClick={showApproveTimesheetModal.setTrue} size="small">
                Approve
              </Button>
            </div>
          </>
        )
      case 'rejected':
      case 'approved':
        return (
          <Typography className="heading_semibold__24 ml-3">{`View ${data.employee_name} timesheet ${displayPeriod()}`}</Typography>
        )
      default:
        return ''
    }
  }

  const displayState = () => {
    switch (status) {
      case 'pending':
        return 'Timesheet is ready for review, please approve or reject. (hardcoded version)'
      case 'rejected':
        return 'Timesheet has been rejected.'
      case 'approved':
        return 'Timesheet has been approved.'
      default:
        return ''
    }
  }

  return (
    <>
      <div className="d-flex align-items-center">
        <div
          className="d-flex align-items-center justify-content-center"
          style={{
            border: '1px solid #CCCCCC',
            boxSizing: 'border-box',
            borderRadius: '8px',
            height: 40,
            width: 40,
          }}
          onClick={() => navigate(-1)}
        >
          <Icon icon={keyboardArrowLeft} style={{ cursor: 'pointer', transform: 'rotate(180deg)' }} />
        </div>
        <div className="d-flex w-100 align-items-center justify-content-between">{displayHeader()}</div>
      </div>
      <Typography className="heading_semibold__18 mt-4">{displayState()}</Typography>
      {data.rejection_reason && <RejectionReason reason={data.rejection_reason} />}
      <div className="mt-4 gap-2" style={{ width: '500px' }}>
        <div className="d-flex gap-2">
          <Input
            style={{ borderRadius: 8, height: 38, margin: 0 }}
            disabled
            label="Start date"
            value={data.start_date}
            endIcon={<Icon icon={miniCalendarIcon} />}
          />
          <Input
            style={{ borderRadius: 8, height: 38, margin: 0 }}
            disabled
            label="End date"
            value={data.end_date}
            endIcon={<Icon icon={miniCalendarIcon} />}
          />
        </div>
        <Input
          style={{ borderRadius: 8, height: 38, margin: 0 }}
          styleClass="mt-2"
          label="Total hours"
          disabled
          value={data.total_hours}
        />
      </div>
      {data.days.map((item, index) => {
        return (
          <div key={index} className="mt-4 mb-4">
            <TimesheetsTable
              fields={TIMESHEET_TABLE_FIELDS}
              data={item.entries}
              day={item.date}
              totalHours={item.total_hours}
            />
          </div>
        )
      })}
      <ApproveTimesheetModal
        show={showApproveTimesheetModal.value}
        onClose={showApproveTimesheetModal.setFalse}
        onConfirm={handleTimesheetApprove}
        isLoading={false} // set loading from API
      />
      <RejectTimesheetModal
        show={showRejectTimesheetModal.value}
        onClose={showRejectTimesheetModal.setFalse}
        onConfirm={handleTimesheetReject}
        isLoading={false} // set loading from API
      />
    </>
  )
}
