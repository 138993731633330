import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'

export const Description = ({ description }) => {
  const [isMore, setIsMore] = React.useState(false)
  const onClickView = () => {
    setIsMore((prevState) => !prevState)
  }
  return description ? (
    <div className="d-flex flex-column text-left pl-3">
      <Typography className="text_regular__14">{description.slice(0, isMore ? description.length : 20)}</Typography>
      {description.length > 20 && (
        <Button
          data-testid="Description-8C22EC"
          style={{
            maxWidth: 'fit-content',
            height: 22,
            fontWeight: 400,
          }}
          className="p-0"
          priority="outlined"
          size="small"
          onClick={onClickView}
        >
          {isMore ? 'Hide' : 'View'}
        </Button>
      )}
    </div>
  ) : null
}
