import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { emptyCircle, success } from '@core/icons/icons'

export default function StepCard({
  completed,
  titleText,
  descriptionText,
  subtitle,
  setOnboardingStep,
  additional = null,
  step,
  activeStep,
}) {
  return (
    <div
      className={`step-card${activeStep === step ? '__completed' : ''}`}
      onClick={() => setOnboardingStep(step)}
      onKeyDown={() => setOnboardingStep(step)}
      role="presentation"
    >
      <Icon icon={completed ? success : emptyCircle} />
      <Typography className="semibold__16">{titleText}</Typography>
      {subtitle && <Typography className="text_medium__14">{subtitle}</Typography>}
      <Typography className="text_light__12 color_grey">{descriptionText}</Typography>
      {additional}
    </div>
  )
}
