import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import { EditModalForm } from '@atoms/EditModal/EditModalForm'
import List from '@atoms/List/List'
import Select from '@atoms/Select/Select'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { SwiftFeePayRoleTooltip } from '@components/swift-fee-pay-role-tooltip/swift-fee-pay-role-tooltip.component'
import { PaymentMethods, PaymentMethodsLabel } from '@core/constants'
import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import { SWIFT_FEE_PAY_ROLE, SwiftFeePayrollLabel } from '@pages/profilePage/mock'
import { patchContractById } from '@store/contracts'
import { DotWave } from '@uiball/loaders'
import { isObject } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { fetchContractorPaymentDetail, fetchUpdatePaymentDetails } from '../../../../services/app.service'
import { PaymentDetailsForm } from './PaymentForm'

const getNiumFields = (payment_details) => {
  if (!payment_details) {
    return []
  }

  return Object.entries(payment_details)
    .filter((f) => f[0] !== 'modified_at')
    .flatMap(([key, value]) => {
      if (isObject(value)) {
        return Object.entries(value).map(([subKey, subValue]) => ({
          label: mapEnum(capitalizeFirstLetter(subKey)),
          value: subValue,
        }))
      }
      return {
        label: mapEnum(capitalizeFirstLetter(key)),
        value,
      }
    })
}

const list = ({ account_number, holder_name, bank_name, additional_info, bic, iban, tax_id }) => [
  { label: 'Account number', value: account_number },
  { label: 'Holder name', value: holder_name },
  { label: 'Bank name', value: bank_name },
  { label: 'Additional information', value: additional_info },
  { label: 'BIC / SWIFT', value: bic },
  { label: 'IBAN', value: iban },
  { label: 'Tax ID', value: tax_id },
]
const generalData = ({
  working_country,
  compensation,
  payment_method,
  contractor_fee,
  company,
  bank_account_country,
}) => [
  { label: 'Country', value: working_country?.name },
  {
    label: 'Receiving currency',
    value: compensation?.receiving_currency?.name,
  },
  { label: 'Payment method', value: PaymentMethodsLabel[payment_method] },
  {
    hidden:
      bank_account_country?.country_code === 'US' ||
      compensation.receiving_currency_is_local ||
      payment_method?.toLowerCase() === PaymentMethods.wise.toLowerCase(),
    label: (
      <div className="d-flex align-items-center gap-2">
        International wire transfer (SWIFT) Fee
        <Tooltip id={v4()} className="w-50" content={<SwiftFeePayRoleTooltip />} />
      </div>
    ),
    // eslint-disable-next-line no-nested-ternary
    value: contractor_fee?.swift_fee_pay_role
      ? SwiftFeePayrollLabel[contractor_fee.swift_fee_pay_role]
      : company?.invoice_settings?.swift_fee_pay_role
        ? SwiftFeePayrollLabel[company.invoice_settings.swift_fee_pay_role]
        : SwiftFeePayrollLabel[SWIFT_FEE_PAY_ROLE.COMPANY],
  },
]

export const PaymentDetailsTab = ({ employee, setEmployee, refetch }) => {
  const { value, toggle } = useBoolean(false)
  const openSwiftFee = useBoolean(false)
  const [swiftFee, setSwiftFee] = useState()

  useEffect(() => {
    if (employee.contractor_fee) setSwiftFee(employee.contractor_fee?.swift_fee_pay_role)
  }, [employee])

  const { data: niumData, isLoading } = useQuery({
    queryKey: ['contractorPaymentDetail', employee],
    queryFn: () => fetchContractorPaymentDetail(employee.id),
  })

  const updateMutate = useMutation({
    mutationFn: ({ id, body }) => fetchUpdatePaymentDetails(id, body),
    onSuccess: (payment_details) => {
      setEmployee({ ...employee, payment_details })
      toggle()
    },
  })
  const updateSwiftFeeMutation = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: () => {
      refetch()
      toast.success('Successfully updated')
      openSwiftFee.setFalse()
    },
  })

  const handleSave = (body) => {
    updateMutate.mutate({
      id: employee.payment_details.id,
      body,
    })
  }

  const onSaveEdit = () => {
    updateSwiftFeeMutation.mutate({
      id: employee.id,
      data: {
        compensation: {
          contractor_wage_type: employee.compensation.contractor_wage_type,
          contractor_payment_frequency: employee.compensation.contractor_payment_frequency,
          contractor_rate: employee.compensation.contractor_rate,
          estimated_working_hours: employee.compensation.estimated_working_hours,
        },
        swift_fee_pay_role: swiftFee,
      },
    })
  }

  if (isLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <>
      <Card>
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__18 mb-4">General</Typography>
          <Button
            data-testid="PaymentDetailsTab-5F5F40"
            priority="secondary"
            size="small"
            hidden={
              employee.compensation.receiving_currency_is_local ||
              employee.company.contractors_automatic_payment_type !== 'nium'
            }
            onClick={openSwiftFee.setTrue}
          >
            Edit
          </Button>
        </div>
        <List lists={generalData(employee)} />
      </Card>

      <Card>
        <Typography className="heading_semibold__18 mb-4 mt-3">Bank details</Typography>
        <List lists={[...list(employee.payment_details), ...getNiumFields(niumData.payment_details)]} />
      </Card>
      <EditModal
        visible={openSwiftFee.value}
        title="Payment details"
        onSave={onSaveEdit}
        loading={updateSwiftFeeMutation.isLoading}
        closeModal={openSwiftFee.setFalse}
      >
        <Select
          data-testid="PaymentDetailsTab-1A3DF4"
          isRequired
          label="International wire transfer (SWIFT) Fee"
          addText="Please indicate who should be responsible for covering the SWIFT fees"
          options={Object.entries(SwiftFeePayrollLabel)
            .slice(0, 3)
            .map(([key, label]) => ({ value: key, label }))}
          value={swiftFee}
          onChange={(option) => setSwiftFee(option.value)}
        />
        <Typography className="text_light__12 color_text_300 mt-1" style={{ marginBottom: 12 }}>
          If contractor payment will be done via International wire transfer, the payment rails for contractor payments
          will utilize SWIFT wire transfers. SWIFT fee is fixed at 20 USD per transaction
        </Typography>
      </EditModal>

      {employee.payment_details && (
        <EditModalForm title="Payments Details" show={value} loading={updateMutate.isLoading} onHide={toggle}>
          <PaymentDetailsForm data={employee.payment_details} onSubmit={handleSave} />
        </EditModalForm>
      )}
    </>
  )
}

const Card = styled.div`
  width: 560px;
  margin-bottom: 12px;
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  padding: 16px;
`
