import { EditForm } from '@atoms/EditModal/EditModalForm'
import Input from '@atoms/Input/Input'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { omitBy } from 'lodash'
import React from 'react'
import { useForm } from 'react-hook-form'

export const PaymentDetailsForm = ({ data, onSubmit }) => {
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      iban: data.iban || '',
      bic: data.bic || '',
      holder_name: data.holder_name || '',
      bank_name: data.bank_name || '',
      account_number: data.account_number || '',
      additional_info: data.additional_info || '',
      tax_id: data.tax_id || '',
    },
  })

  const submit = (formValues) => {
    onSubmit(omitBy(formValues, (v) => v === ''))
  }

  return (
    <EditForm className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
      <section className="d-flex flex-grow-1 flex-column justify-content-center align-items-center">
        <div className="remo-form-input">
          <div className="remo-form-input">
            <Input
              data-testid="PaymentForm-408CCD"
              {...register('account_number', {
                required: 'Account number is required',
              })}
              type="text"
              label="Account Number"
              placeholder="Enter your account number"
              isRequired
            />
            {errors.account_number && (
              <Typography className="text_regular__14 color_red">{errors.account_number.message}</Typography>
            )}
          </div>

          <div className="remo-form-input">
            <Input
              data-testid="PaymentForm-FE72AF"
              {...register('holder_name', {
                required: 'Holder name is required',
              })}
              type="text"
              label="Account number"
              placeholder="Enter your name"
              isRequired
            />
            {errors.holder_name && (
              <Typography className="text_regular__14 color_red">{errors.holder_name.message}</Typography>
            )}
          </div>

          <div className="remo-form-input">
            <Input
              data-testid="PaymentForm-D2BEA7"
              {...register('bank_name', { required: 'Bank name is required' })}
              type="text"
              label="Bank Name"
              placeholder="Enter bank name"
              isRequired
            />
            {errors.bank_name && (
              <Typography className="text_regular__14 color_red">{errors.bank_name.message}</Typography>
            )}
          </div>

          <div className="remo-form-input">
            <TextArea
              {...register('additional_info', {
                required: 'Additional information is required',
              })}
              maxLength={4000}
              label="Additional information"
              placeholder="Enter additional information"
              isRequired
            />

            {errors.additional_info && (
              <Typography className="text_regular__14 color_red">{errors.additional_info.message}</Typography>
            )}
          </div>

          <div className="remo-form-input">
            <Input
              data-testid="PaymentForm-BD9A03"
              {...register('bic', {
                required: 'Field is required',
              })}
              type="text"
              label="BIC / SWIFT"
              placeholder="Enter your valid BIC / SWIFT Identification Code"
            />
            {errors.bic && <Typography className="text_regular__14 color_red">{errors.bic.message}</Typography>}
          </div>
          <Input
            data-testid="PaymentForm-E72991"
            {...register('iban', { required: 'Field is required' })}
            type="text"
            label="IBAN"
            placeholder="Enter your valid IBAN account number"
          />
          {errors.iban && <Typography className="text_regular__14 color_red">{errors.iban.message}</Typography>}
        </div>

        <div className="remo-form-input">
          <Input
            data-testid="PaymentForm-5AAE8C"
            {...register('tax_id', { required: 'Field is required' })}
            type="text"
            label="Tax ID"
            placeholder="Enter your Tax ID"
          />
          {errors.tax_id && <Typography className="text_regular__14 color_red">{errors.tax_id.message}</Typography>}
        </div>
      </section>
    </EditForm>
  )
}
