import styled from 'styled-components'

const Form = styled.form`
  width: 100%;
`
const Container = styled.div`
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-around;
`
const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  width: 480px;
`
const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0.5rem;
  }
`
export { Container, Content, Form, FormControl }
