import styled from 'styled-components'

export const CarryoverMaximum = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`

export const Label = styled.span`
  font-size: 14px;
`

export const SmallInput = styled.span`
  width: 100px;
`

export const Styled = {
  CarryoverMaximum,
  Label,
  SmallInput,
}
