export const EmployeeSubStatusTooltip = () => (
  <div>
    Definition of FTE sub-statuses
    <div>
      <div>
        <strong>Onboarding</strong> sub-statuses:
      </div>
      <ul>
        <li>
          <strong>On Hold</strong> - The onboarding process has been paused due to a specific reason.
        </li>
      </ul>
    </div>
    <div>
      <div>
        <strong>Active</strong> sub-status:
      </div>
      <ul>
        <li>
          <strong>Offboarding</strong> - The process of offboarding the employee has started, but they are still
          officially active until the process completes.
        </li>
      </ul>
    </div>
    <div>
      <div>
        <strong>Inactive</strong> sub-statuses:
      </div>
      <ul>
        <li>
          <strong>Offboarded</strong> - The employee has been offboarded, marking the end of their engagement with the
          company.
        </li>
        <li>
          <strong>Cancelled</strong> - The onboarding process or employee status was canceled.
        </li>
      </ul>
    </div>
  </div>
)
