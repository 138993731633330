import './Table.scss'

import Button from '@atoms/Button/Button'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import usePagination from '@core/hooks/usePagination'

export const TIMESHEET_FIELDS = [
  {
    title: 'Email',
    key: 'email',
    type: 'string',
    maxWidth: 400,
    minWidth: 300,
  },
  {
    title: 'Total hours',
    key: 'totalHours',
    type: 'string',
    maxWidth: 300,
    minWidth: 200,
  },
]

export const ValidateTimesheet = ({ payload, onNext, onReUpload }) => {
  const { timesheetFields = [] } = payload

  const { page, limit, setPage } = usePagination({ page: 1, limit: 5 })

  const timesheets = timesheetFields.slice((page - 1) * limit, page * limit)

  const handleNextClick = () => {
    onNext({
      timesheetFields,
    })
  }

  return (
    <div style={{ width: 850 }}>
      <div className="mb-5">
        <Typography classname="light_regular_normal__16">
          Please confirm your timesheet and the Email and total hours columns for input are correct.
        </Typography>
      </div>
      <CustomTable
        fields={TIMESHEET_FIELDS}
        data={timesheets}
        onPage={setPage}
        page={page}
        total={timesheetFields.length}
        pageSize={limit}
      />
      <div className="d-flex pb-5 justify-content-center gap-4 mt-5">
        <Button onClick={onReUpload} priority="secondary" className="align-self-end">
          Re-upload
        </Button>
        <Button onClick={handleNextClick} type="submit" className="align-self-end">
          Next
        </Button>
      </div>
    </div>
  )
}
