import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { REMOHEALTH_STATUSES } from '@core/constants'
import { useApp } from '@core/context'
import { addWhite, edit } from '@core/icons/icons'
import EmptyBenefit from '@pages/employeeDetailPage/libs/Benefit/EmptyBenefit'
import { RemoHealthModal } from '@pages/employeeDetailPage/libs/Benefit/RemoHealthModal'
import {
  getContractInsurances,
  updateContractInsurance,
  updateContractInsuranceStatus,
} from '@services/health-insurance.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { ModalBenefit } from '../employeeDetailPage/libs/Benefit/ModalBenefit'

const DEFAULT_PAGE_LIMIT = 10

const transformHealthInsuranceData = (data) =>
  data.map(({ contract, created_at, insurance_type, starts_at, status, ...field }) => ({
    ...field,
    id: contract.id,
    name: contract.full_name,
    created: created_at ? moment(created_at).format('DD MMM YYYY') : '-',
    country: contract.working_country,
    birth_date: contract.birth_date,
    insurance_price_id: field.insurance_price_id,
    type: (
      <span>
        RemoHealth <span style={{ color: '#6D6E85', fontSize: '12px' }}>({insurance_type})</span>
      </span>
    ),
    started: starts_at ? moment(starts_at).format('DD MMM YYYY') : '-',
    status,
  }))

export default function BenefitsPage() {
  const [editData, setEditData] = useState()
  const [page, setPage] = useState(1)
  const [tab, setTab] = useState(0)
  const { profile } = useApp()
  const addOpen = useBoolean(false)
  const terminateOpen = useBoolean(false)
  const deleteOpen = useBoolean(false)
  const editOpen = useBoolean(false)
  const [selectedBenefitDelete, setSelectedBenefitDelete] = useState(null)
  const [selectedBenefitTerminate, setSelectedBenefitTerminate] = useState(null)
  const age = editData?.birth_date ? moment().diff(editData.birth_date, 'years') : ''

  const methods = useForm()

  const { data, refetch, isFetching } = useQuery({
    queryKey: ['getContractInsurances', page],
    queryFn: () =>
      getContractInsurances({
        offset: (page - 1) * 10,
        limit: 10,
        company_id: profile.id,
      }),
  })

  const updateInsuranceMutate = useMutation({
    mutationFn: updateContractInsurance,
    onSuccess: () => {
      refetch()
      deleteOpen.setFalse()
      editOpen.setFalse()
      terminateOpen.setFalse()
      toast.success('Benefit successfully updated')
    },
  })

  const updateInsuranceStateMutate = useMutation({
    mutationFn: updateContractInsuranceStatus,
    onSuccess: () => {
      refetch()
      deleteOpen.setFalse()
      editOpen.setFalse()
      terminateOpen.setFalse()
      toast.success('Benefit successfully updated')
    },
  })

  if (isFetching) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center mb-5">
        <DotWave />
      </div>
    )
  }

  const handleTabChange = (id) => {
    setTab(id)
    setPage(1)
    switch (id) {
      case 0:
        console.log('tab 0')
        return null
      case 1:
        console.log('tab 1')
        return null
      default:
        return null
    }
  }

  const handleDelete = (row) => {
    deleteOpen.toggle()
    setSelectedBenefitDelete(row.id)
  }

  const handleTerminate = (row) => {
    terminateOpen.toggle()
    setSelectedBenefitTerminate(row.id)
  }

  const onRowClick = (row) => {
    methods.reset({
      insuranceId: row.insurance_price_id,
      family_members_amount: row.family_members_amount,
    })
    editOpen.toggle()
    setEditData(row)
  }

  const fields = [
    {
      title: 'Name',
      key: 'name',
      type: 'textMedium',
      minWidth: 350,
      width: 350,
    },
    {
      title: 'Created at',
      key: 'created',
    },
    {
      title: 'Country',
      key: 'country',
      type: 'country',
    },
    {
      title: 'Type',
      key: 'type',
    },
    {
      title: 'Start date',
      key: 'started',
    },
    {
      title: 'Status',
      key: 'status',
      type: 'status',
      minWidth: 160,
      maxWidth: 160,
    },
    {
      title: 'Action',
      key: 'custom',
      type: 'custom',
      maxWidth: 160,
      minWidth: 160,
      width: 160,
      render: (row) => (
        <div className="d-flex align-items-center justify-content-between">
          <div>
            {row.status === REMOHEALTH_STATUSES.CREATED ? (
              <Icon fill="none" className="mr-4" icon={edit} onClick={() => onRowClick(row)} />
            ) : null}
          </div>

          {[
            REMOHEALTH_STATUSES.CREATED,
            REMOHEALTH_STATUSES.MEDICAL_OUTSTANDING,
            REMOHEALTH_STATUSES.INFO_OUTSTANDING,
          ].includes(row.status) ? (
            <Button
              data-testid="benefits-page.component-902D12"
              priority="secondary"
              size="small"
              onClick={() => handleDelete(row)}
              style={{ height: 32 }}
            >
              Cancel
            </Button>
          ) : null}
          {row.status === REMOHEALTH_STATUSES.ACTIVE || row.status === REMOHEALTH_STATUSES.SUBMITTED ? (
            <Button
              data-testid="benefits-page.component-B817C3"
              priority="secondary"
              size="small"
              onClick={() => handleTerminate(row)}
              style={{ height: 32 }}
            >
              Terminate
            </Button>
          ) : null}
        </div>
      ),
    },
  ]

  const onEdit = ({ insuranceId, family_members_amount }) => {
    updateInsuranceMutate.mutate({
      id: editData.id,
      body: { insurance_price: insuranceId, family_members_amount },
    })
  }

  const onClickBenefitDelete = () => {
    updateInsuranceStateMutate.mutate({
      id: selectedBenefitDelete,
      body: { status: 'canceled' },
    })
  }
  const onClickBenefitTerminate = () => {
    updateInsuranceStateMutate.mutate({
      id: selectedBenefitTerminate,
      body: { status: 'terminated' },
    })
  }
  return (
    <div>
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle>Benefits</PageTitle>
        <div>
          <Button data-testid="benefits-page.component-97101C" priority="primary" size="small" onClick={addOpen.toggle}>
            <Icon icon={addWhite} className="mr-2" />
            Add benefit
          </Button>
        </div>
      </div>
      <div className="d-flex flex-column">
        <Tabs selectedTab={tab} onTabChange={handleTabChange}>
          <Tab tabId={0} title="All benefits">
            {data?.results ? (
              <CustomTable
                fields={fields}
                data={transformHealthInsuranceData(data?.results || [])}
                loading={isFetching}
                page={page}
                onPage={setPage}
                pageSize={DEFAULT_PAGE_LIMIT}
                total={data?.count || 0}
              />
            ) : (
              <EmptyBenefit onAddBenefit={addOpen.toggle} />
            )}
          </Tab>
          <Tab tabId={1} title="Remohealth">
            {data?.results ? (
              <CustomTable
                fields={fields}
                data={transformHealthInsuranceData(data?.results || [])}
                loading={isFetching}
                page={page}
                onPage={setPage}
                pageSize={DEFAULT_PAGE_LIMIT}
                total={data?.count || 0}
              />
            ) : (
              <EmptyBenefit onAddBenefit={addOpen.toggle} />
            )}
          </Tab>
        </Tabs>
      </div>

      {addOpen.value && <ModalBenefit onClose={addOpen.setFalse} refetch={refetch} />}
      {editOpen.value && editData && (
        <FormProvider {...methods}>
          <RemoHealthModal
            isEdit
            age={age}
            isLoading={updateInsuranceMutate.isLoading}
            countryId={editData.country.id}
            onClose={editOpen.toggle}
            goBack={editOpen.toggle}
            onSubmit={onEdit}
          />
        </FormProvider>
      )}

      <EditModal
        visible={deleteOpen.value}
        title="Cancel benefit"
        onSave={() => {}}
        footer={false}
        closeModal={deleteOpen.toggle}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to cancel this insurance application? This action cannot be undone.
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="benefits-page.component-979E0A"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={deleteOpen.toggle}
          >
            Close
          </Button>
          <Button
            data-testid="benefits-page.component-3D483F"
            priority="primary_dangerous"
            size="small"
            loading={updateInsuranceStateMutate.isLoading}
            onClick={onClickBenefitDelete}
          >
            Cancel forever
          </Button>
        </div>
      </EditModal>
      <EditModal
        visible={terminateOpen.value}
        title="Terminate benefit"
        onSave={() => {}}
        footer={false}
        closeModal={terminateOpen.setFalse}
      >
        <Typography className="heading_semi__16">Are you sure you want to terminate this benefit?</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="benefits-page.component-796C92"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={terminateOpen.setFalse}
          >
            Close
          </Button>
          <Button
            data-testid="benefits-page.component-08626C"
            priority="primary_dangerous"
            size="small"
            loading={updateInsuranceStateMutate.isLoading}
            onClick={onClickBenefitTerminate}
          >
            Terminate
          </Button>
        </div>
      </EditModal>
    </div>
  )
}
