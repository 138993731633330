import Typography from '@atoms/Typography/Typography'
import React from 'react'

/**
 * @param  {number} step required
 * @param {Function} setStep required
 */
export default ({ title, step, disabled, setStep, ...rest }) => {
  const handleClick = () => {
    setStep(Number(step))
  }
  return (
    <section className="d-flex flex-column">
      <button
        data-testid="Step-941A4C"
        style={{ border: 'none', background: 'none' }}
        type="button"
        disabled={disabled}
        onClick={handleClick}
      >
        <Typography className="heading_semibold__18">{title}</Typography>
      </button>
      <div>{rest.children}</div>
    </section>
  )
}
