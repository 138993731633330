import Button from '@atoms/Button/Button'
import { DocumentPreview } from '@components/document-preview/document-preview.component'
import { useDocumentSign } from '@core/hooks/useDocumentSign'
import { DotWave } from '@uiball/loaders'
import React from 'react'
import { useBoolean } from 'usehooks-ts'

export const SignMsaDocument = ({ title, file, signatureId, onSuccess }) => {
  const { signUrl, fetchSignUrlQuery, sign, share, download } = useDocumentSign(signatureId, file, onSuccess)

  return fetchSignUrlQuery.isFetching ? (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <DotWave />
    </div>
  ) : (
    <DocumentPreview
      title={title}
      file={file}
      footerActions={[
        <Button
          data-testid="sign-msa-document.component-63F7E6"
          key={1}
          priority="secondary"
          size="medium"
          onClick={download}
        >
          Download
        </Button>,
        <Button
          data-testid="sign-msa-document.component-BBB3D5"
          key={2}
          priority="primary"
          size="medium"
          onClick={sign}
          disabled={!signUrl}
        >
          Sign
        </Button>,
      ]}
    />
  )
}
