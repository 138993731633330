/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import './Table.scss'

import Pagination from '@atoms/Pagination/Pagination'
import Typography from '@atoms/Typography/Typography'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import moment from 'moment'
import { useSortBy, useTable } from 'react-table'

export const TimeTable = ({
  day,
  totalHours,
  page,
  total,
  columns,
  pageSize = 10,
  data,
  loading,
  onRowClick,
  onPage,
}) => {
  const { headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )

  return (
    <>
      {loading ? (
        <div className="loading-container">
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <table className="time-table mb-3">
          <thead className="time-table-header">
            <th colSpan={5} className="table-title" style={{ borderRadius: '8px 8px 0 0' }}>
              <Typography className="semibold__16 table-typography">
                {moment(day).format('D MMM YYYY')} <span className="divider">|</span>
                <span className="total-hours ml-1">Total hours: {totalHours}</span>
              </Typography>
            </th>
          </thead>
          <thead className="table-column-header">
            {headerGroups.map((headerGroup) =>
              headerGroup.headers.map((column) => (
                <th
                  className="px-2 table-column"
                  style={{
                    minWidth: column.minWidth,
                    width: column.width,
                    maxWidth: column.maxWidth,
                  }}
                >
                  <div className="d-flex align-items-center table_header">{column.render('Header')}</div>
                </th>
              ))
            )}
          </thead>
          <tbody>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classNames([
                    onRowClick && 'cursor-pointer',
                    row.original.status === 'inactive' && 'status_inactive',
                  ])}
                >
                  {row.cells.map((cell) => (
                    <td
                      className="px-2 table-cell"
                      style={{
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        maxWidth: cell.column.maxWidth,
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {page && Math.ceil(total / pageSize) > 1 && (
        <Pagination page={page} pageSize={pageSize} total={total} onGetPage={onPage} />
      )}
    </>
  )
}
