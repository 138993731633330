import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styledComponents from 'styled-components'

const StyledModal = styledComponents(Modal)`
  .modal-content {
    padding: 16px;
    border-radius: 16px;
  }
  .modal-header {
    border-bottom: none;
    align-items: flex-start;
  }
  .modal-footer {
    border-top: none;
  }
`

const editFormId = 'edit-form'

export const EditModalForm = ({ title, children, loading = false, show, onHide, ...modalProps }) => {
  return (
    <StyledModal centered size="md" animation={false} scrollable={false} show={show} onHide={onHide} {...modalProps}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="EditModalForm-C93B93"
          type="button"
          priority="secondary"
          size="small"
          className="mr-3"
          onClick={onHide}
        >
          Cancel
        </Button>
        <Button
          data-testid="EditModalForm-6734EA"
          type="submit"
          form={editFormId}
          priority="primary"
          size="small"
          loading={loading}
          disabled={loading}
        >
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export const EditForm = ({ children, ...formAttrs }) => {
  return (
    <form id={editFormId} {...formAttrs}>
      {children}
    </form>
  )
}
