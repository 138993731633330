import { UpdateContractModal } from '@components/contract-update-modal'
import { Notification } from '@components/notification'
import { useBoolean } from 'usehooks-ts'

export type NotificationActiveState = 'active' | 'inactive'
export type NotificationEntityType = 'contract_amendment'
type ContractUpdateNotificationSource = 'admin' | 'employee' | 'company_owner' | 'company_admin'
interface INotification {
  id: number
  state: NotificationActiveState
  data: {
    contract_id: number
    contract_full_name: string
    _from: ContractUpdateNotificationSource
  }
  source_app: string
  entity_type: NotificationEntityType
}
type ContractUpdateNotificationProps = {
  notification: INotification
  employee?: Record<string, any>
  variant?: 'normal' | 'simplified'
}
export const ContractUpdateNotification = ({
  notification,
  employee,
  variant = 'normal',
}: ContractUpdateNotificationProps) => {
  const showUpdateContractModal = useBoolean(false)

  const onNotificationClick = () => showUpdateContractModal.setTrue()
  // eslint-disable-next-line no-underscore-dangle
  const isFromAdmin = notification.data._from === 'admin' || notification.data._from === 'company_admin'
  const notificationTitle = isFromAdmin
    ? `Admin has requested a contract update for ${notification.data.contract_full_name}`
    : `${notification.data.contract_full_name} has proposed a contract update`
  return (
    <>
      <Notification
        title={notificationTitle}
        actionTitle="Review"
        onClick={onNotificationClick}
        variant={variant}
        description="Contractor details have been updated. Please regenerate a contract to be legally compliant"
      />
      {showUpdateContractModal.value && (
        <UpdateContractModal
          employee={employee}
          employeeId={notification.data.contract_id}
          showUpdateContractModal={showUpdateContractModal}
          withSelectCancelButton
        />
      )}
    </>
  )
}
