import { Api } from '@api/Api'

export const fetchPaymentMethods = async () => {
  const v = await Api.get('payment-methods/')
  return v.data
}

export const fetchPaymentHistory = async () => {
  const v = await Api.get('payments-history/')
  return v.data
}

export const postPaymentMethods = async () => {
  const v = await Api.post('payment-methods/create/')
  return v.data
}
export const postFirstPrimary = async () => {
  const v = await Api.post('callback-to-created-payment-method/')
  return v.data
}
export const autoPaymentMethods = async (data) => {
  const v = await Api.post('enable-automatic-pay/', data)
  return v.data
}

export const deletePaymentMethods = async (id, body) => {
  const v = await Api.post(`payment-methods/${id}/detach/`, body)
  return v.data
}
export const primaryPaymentMethods = async (id) => {
  const v = await Api.post(`payment-methods/${id}/assign-as-default/`)
  return v.data
}
