import './ProfilePage.scss'

import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import { EditModalForm } from '@atoms/EditModal/EditModalForm'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import { USER_GROUPS } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { userHasGroup } from '@core/utils'
import { PaymentTerms } from '@pages/profilePage/payment-terms/payment-terms'
import { SecurityTab } from '@pages/profilePage/security-2fa/security-2fa-tab'
import { patchCompany } from '@store/companies'
import React from 'react'
import { useMutation } from 'react-query'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { getResetPassword } from '../../services/app.service'
import { ManagersTab } from './managers-tab/managers-tab'
import { getMainList } from './mock'
import ProfileEditForm from './ProfileEditForm'
import { TimeOffPolicyTab } from './time-off-policy-tab/time-off-policy-tab.component'

export default function ProfilePage() {
  const navigate = useNavigate()
  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const { successAlert } = useToast()

  const { countries, profile, refetchProfile, currenciesAC, userProfile, userGroups } = useApp()

  const canManageCompany = userHasGroup(userGroups, USER_GROUPS.SUPERUSER) || userProfile.role === 'company_owner'

  const activeTab = queryParams.get('tab') || (canManageCompany ? '0' : '3')

  const { toggle, value } = useBoolean(false)
  const { toggle: clickReset, value: resetClick } = useBoolean(false)

  const { mutate: fetchUpdateCompany, isLoading: updateLoading } = useMutation({
    mutationFn: ({ id, payload }) => patchCompany(id, payload),
    onSuccess: () => {
      refetchProfile()
      toggle()
      console.log('success')
      successAlert('success')
    },
  })

  const resetPassword = useMutation({
    mutationFn: (payload) => getResetPassword(payload.email),
    onSuccess: () => {
      toast.success('Check your email for a link to set your password.')
    },
  })

  const onSaveEdit = (payload) => {
    fetchUpdateCompany({ id: profile.id, payload })
  }
  const onResetPassword = () => {
    clickReset()
    resetPassword.mutate({ email: userProfile.profile.email })
  }

  const handleTabChange = (tab) => {
    queryParams.set('tab', tab)
    navigate({ search: queryParams.toString() })
  }

  return (
    <div className="customers-detail-page">
      <div className="d-flex justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <Typography className="heading_semibold__24">{profile?.name}</Typography>
        </div>
      </div>
      <Tabs onTabChange={handleTabChange} selectedTab={+activeTab}>
        <Tab tabId={0} title="Managers" hidden={!canManageCompany}>
          {canManageCompany && <ManagersTab />}
        </Tab>
        <Tab tabId={1} title="Settings" hidden={!canManageCompany}>
          {profile && canManageCompany && (
            <div style={{ maxWidth: 540 }}>
              <div className="d-flex flex-column">
                <div className="d-flex align-items-center justify-content-between mb-4">
                  <Typography className="heading_semibold__20 color_black">Company details</Typography>
                  <Button data-testid="ProfilePage-F6DC49" priority="secondary" size="small" onClick={toggle}>
                    Edit
                  </Button>
                </div>
                <Avatar
                  src={profile?.user?.profile?.avatar}
                  width={100}
                  height={100}
                  className="personal-info__avatar mb-4"
                />
                <List lists={getMainList(profile)} />
              </div>
            </div>
          )}
        </Tab>
        <Tab tabId={2} title="Payment Terms" hidden={!canManageCompany}>
          {canManageCompany && <PaymentTerms loading={updateLoading} onSave={onSaveEdit} />}
        </Tab>
        <Tab tabId={3} title="Time off policy">
          <TimeOffPolicyTab />
        </Tab>
        <Tab tabId={4} title="Password & Account">
          <div>
            <Typography className="heading_semibold__20 mt-3 mb-4">Password & Account</Typography>
            <Typography className="text_medium__14">Password reset</Typography>
            <Typography className="text_light__12 color_text_300 mt-1" style={{ width: 480, fontWeight: 500 }}>
              Click the button below to reset password and send the email:
              <Typography className=" color_black">{userProfile.profile.email}</Typography>
            </Typography>
            <Button
              data-testid="ProfilePage-951CDC"
              className="mt-3"
              priority="secondary"
              size="small"
              disabled={resetClick}
              loading={resetPassword.isLoading}
              onClick={onResetPassword}
            >
              {resetClick ? 'Check your email' : 'Reset password'}
            </Button>
          </div>
        </Tab>
        <Tab tabId={5} title="Security">
          <SecurityTab />
        </Tab>
      </Tabs>
      <EditModalForm title="Main info" show={value} loading={updateLoading} onHide={toggle}>
        <ProfileEditForm countries={countries} currencies={currenciesAC} profile={profile} onSave={onSaveEdit} />
      </EditModalForm>
    </div>
  )
}
