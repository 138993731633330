import './style.scss'

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import NotFound from '@atoms/NotFound/NotFound'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import usePagination from '@core/hooks/usePagination'
import { payrollsInvoicedField } from '@pages/payrollsDetailPage/mock'
import { parserInvoicedPayrollsBasePayment, parserPayrollsBasePayment } from '@pages/payrollsDetailPage/parse'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import {
  changePayrollStatus,
  fetchViewCompanyInvoices,
  getPayrollApprovalFLowList,
  getPayrollInvoiceTotal,
  getPayrollListNeedChange,
} from '../../services/payroll.service'
import ChangesAccordion from './ChangesAccordion'
import DownloadReport from './DownloadReport'
import { PayrollToApprove } from './PayrollToApprove'
import { PayrollToGenerationg } from './PayrollToGenerationg'
import SuccessApproveModal from './SuccessApproveModal'

export const FlagPayrollDetailPage = () => {
  const { profile } = useContext(AppContext)
  const [payrollsData, setPayrollsData] = useState([])
  const isOpenDownloadCsv = useBoolean(false)
  const [comment, setComment] = useState()
  const [rejectVisible, setRejectVisible] = useState(false)
  const [currentPayroll, setCurrentPayroll] = useState()
  const [active, setActive] = useState(0)
  const [count, setCount] = useState(0)
  const [invoiceTotal, setInvoiceTotal] = useState()
  const [invoiceId, setInvoiceId] = useState()
  const [approvedPayroll, setApprovedPayroll] = useState(null)
  const { page, limit, setPage } = usePagination({
    page: 1,
    limit: 10000,
  })

  const { data, isLoading, refetch, isFetching } = useQuery(['payrolls', page, limit, active], {
    queryFn: () => getPayrollApprovalFLowList({ limit, page, status: 'invoiced' }),
    keepPreviousData: true,
  })

  const {
    data: listNeedChange,
    isLoading: changeLoading,
    refetch: refetchChnage,
  } = useQuery('payrollsListNeedChange', {
    queryFn: () => getPayrollListNeedChange(),
  })

  const payrollsChangeStatus = useMutation('payrollsChangeStatus', {
    mutationFn: ({ id, body }) => changePayrollStatus(id, body),
    onSuccess: () => {
      setComment(undefined)
      refetchChnage()
      refetch()
      setRejectVisible(false)
      setActive(1)
    },
  })
  const companyInvoicePreviewMutation = useMutation(fetchViewCompanyInvoices, {
    onSuccess: (response) => {
      const file = new Blob([response], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank')
    },
  })

  const onUploadInvoice = () => {
    companyInvoicePreviewMutation.mutate({ company: profile.id })
  }

  const onClickRequestChange = useCallback((payroll) => {
    setCurrentPayroll(payroll)
    setRejectVisible(true)
  }, [])

  const { isLoading: invoiceTotalLoading } = useQuery(['invoiceTotal', invoiceId], {
    queryFn: () => getPayrollInvoiceTotal(invoiceId),
    onSuccess: (res) => {
      setInvoiceTotal(res)
    },
    enabled: !!invoiceId,
  })

  const onSendComment = useCallback(() => {
    payrollsChangeStatus.mutate({
      id: currentPayroll.id,
      body: {
        state: 'need_changes_from_admin',
        comment,
      },
    })
  }, [currentPayroll, comment])

  const onChangeComment = useCallback((e) => {
    setComment(e.target.value)
  }, [])

  const onClickTab = useCallback((tabId) => {
    setActive(tabId)
  }, [])

  const onCollapseClick = useCallback((row) => {
    setInvoiceId(row.id)
  }, [])

  useEffect(() => {
    if (data) {
      setPayrollsData(data?.current)
      setInvoiceId(data?.current[0]?.id)
    }
  }, [data])

  const renderTable = React.useMemo(() => {
    return payrollsData.length > 0 ? (
      <div className="customer-payroll-table">
        <CustomTable
          className="customer-payroll-table"
          collapseId={invoiceId}
          type="collapse"
          fields={payrollsInvoicedField}
          onCollapseClick={onCollapseClick}
          data={parserInvoicedPayrollsBasePayment(
            payrollsData,
            invoiceTotal,
            invoiceTotalLoading,
            onClickRequestChange,
            data.month
          )}
        />
      </div>
    ) : (
      <NotFound
        illustration="/assets/img/payrollEmpty.png"
        title="No payrolls yet"
        description="They will appear here in the next billing period"
      />
    )
  }, [payrollsData, invoiceTotal, invoiceTotalLoading, onClickRequestChange])

  if (isLoading || changeLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <div className="customer-payroll">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography className="heading_semibold__24">Payroll</Typography>
        <div className="d-flex gap-2">
          <Button
            data-testid="PayrollDetailPage-5FEF0C"
            priority="secondary"
            size="small"
            onClick={isOpenDownloadCsv.setTrue}
          >
            <HiDownload className="mr-1" />
            Download report
          </Button>
          {count > 0 && (
            <Button
              data-testid="PayrollDetailPage-C584B9"
              priority="secondary"
              size="small"
              loading={companyInvoicePreviewMutation.isLoading}
              onClick={onUploadInvoice}
            >
              Preview invoice
            </Button>
          )}
        </div>
      </div>

      <Tabs selectedTab={active} onTabChange={onClickTab}>
        <Tab tabId={0} title="Pending" count={count}>
          <PayrollToApprove setCount={setCount} />
          <PayrollToGenerationg setCount={setCount} />
        </Tab>
        <Tab tabId={1} title="Invoiced">
          {isLoading ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="customer-payroll-table-box">
              <div className="customer-payroll-table-wrap">
                <AbsoluteSpinner isFetching={isFetching}>{renderTable}</AbsoluteSpinner>
              </div>
            </div>
          )}
        </Tab>
        <Tab tabId={2} title="Requested Change" count={listNeedChange?.count}>
          {changeLoading && listNeedChange?.payrolls?.length === 0 ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div>
              {listNeedChange?.payrolls?.length === 0 ? (
                <NotFound title="There are no requests to change" />
              ) : (
                <ChangesAccordion month={listNeedChange?.month} payments={listNeedChange?.payrolls} />
              )}
            </div>
          )}
        </Tab>
      </Tabs>

      <EditModal
        visible={rejectVisible}
        footer
        size="lg"
        loading={payrollsChangeStatus.isLoading}
        title="Reject Pending "
        closeModal={() => setRejectVisible(false)}
        onSave={onSendComment}
      >
        <TextArea onChange={onChangeComment} placeholder="Reject Cause" rows={5} />
      </EditModal>
      {approvedPayroll && <SuccessApproveModal payroll={approvedPayroll} onClose={setApprovedPayroll} />}
      {isOpenDownloadCsv.value && <DownloadReport onClose={isOpenDownloadCsv.setFalse} />}
    </div>
  )
}
