import Button from '@atoms/Button/Button'
import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import styled from 'styled-components'

const StyledBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  .title {
    margin: 32px 0 24px 0;
    font-family: Manrope;
    font-size: 24px;
    font-weight: 600;
    line-height: 28px;
    letter-spacing: 0em;
    text-align: center;
  }

  .description {
    width: 400px;
    margin-bottom: 32px;
    font-family: Inter;
    font-size: 14px;
    font-weight: 400;
    line-height: 23px;
    letter-spacing: 0px;
    text-align: center;
    color: #878787;
  }
`

export default ({ employee, companyName, onAdd, onBoarding }) => {
  const handleOnAdd = () => {
    onAdd(employee?.contract_type)
  }
  const onClickBoarding = () => {
    onBoarding(employee?.contract_type)
  }

  return (
    <Modal show fullscreen>
      <StyledBody>
        <img src="/assets/img/success.png" alt="success" />

        <div className="title">Great Job!</div>
        <div className="description">
          {employee?.contract_type === 'full_time_employee'
            ? `${employee?.profile.first_name} ${employee?.profile.last_name} at ${employee?.profile.email} has been saved as draft. Once our local provider signs the appendix, this employee will be invited to complete their onboarding process`
            : `We’ve contacted ${employee?.profile.first_name} ${employee?.profile.last_name} at ${employee?.profile.email} to request additional information. We will send this contractor an invitation to join the platform.`}
        </div>

        <div className="actions">
          <Button
            data-testid="success-modal-index-BD6BAB"
            className="mr-3"
            size="small"
            priority="secondary"
            type="button"
            onClick={handleOnAdd}
          >
            Add another person
          </Button>
          <Button data-testid="success-modal-index-57D0CC" size="small" type="button" onClick={onClickBoarding}>
            {employee?.contract_type === 'full_time_employee' ? 'Go to Onboarding' : 'Go to My team'}
          </Button>
        </div>
      </StyledBody>
    </Modal>
  )
}
