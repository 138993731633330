/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { downloadIcon } from '@core/icons/icons'
import { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { fetchGetInvoiceById } from 'src/services/aggregated-invoices.service'

export default function InvoiceDownload({ invoice }) {
  const { id } = invoice
  const [downloadLink, setDownloadLink] = useState(false)

  const { data, isLoading } = useQuery(['invoiceById', id], {
    queryFn: () => fetchGetInvoiceById(id),
    onSuccess: () => setDownloadLink(false),
    enabled: downloadLink,
  })
  const onClickDownload = useCallback((event) => {
    event.stopPropagation()
    setDownloadLink(true)
  }, [])

  useEffect(() => {
    if (data) {
      window.open(data.file, '_blank')
    }
  }, [data])

  return (
    <Button
      data-testid="InvoiceDownload-43456E"
      className="text_light__12 px-2 pl-0"
      priority="secondary"
      size="small"
      style={{ height: '32px' }}
      loading={isLoading}
      onClick={onClickDownload}
    >
      <Icon icon={downloadIcon} className="mr-2" />
      Download
    </Button>
  )
}
