import Button from '@atoms/Button/Button'
import { InfoMessage } from '@components/info-message/info-message.component'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { TimeOffAnnualDaysRule } from '@core/types/time-off.types'
import {
  TIME_OFF_POLICY_FORM_ID,
  TimeOffPolicyForm,
} from '@features/time-off/time-off-policy-form/time-off-policy-form.component'
import { parsePayload } from '@features/time-off/time-off-policy-form/time-off-policy-form.utils'
import ListHolidaysModal from '@pages/time-off/ListHolidaysModal'
import { getTimeOffPolicy, updateTimeOffPolicy } from '@services/company.service'
import { fetchCountryPaidLeavesById, fetchCountrySpecificPaidById } from '@services/countries.service'
import { getHolidays } from '@services/holidays.service'
import { DotWave } from '@uiball/loaders'
import { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import { TimeOffPolicyDefaultModal } from './time-off-policy-default-modal/time-off-policy-default-modal'
import { Styled } from './time-off-policy-step.styles'

const getAnnualDaysByContract = (countryPaidLeaves, rule) => {
  switch (rule) {
    case TimeOffAnnualDaysRule.Statutory:
      return countryPaidLeaves.statutory_annual_days
    case TimeOffAnnualDaysRule.Recommended:
      return countryPaidLeaves.recommendation_by_remofirst_days
    case TimeOffAnnualDaysRule.TopOfMarket:
      return countryPaidLeaves.recommendation_by_market_days
    default:
      return 0
  }
}

export const TimeOffPolicyStep = ({ workingCountryId, regionId, onNext }) => {
  const { profile } = useApp()
  const defaultModalOpenState = useBoolean(false)
  const publicHolidaysModalState = useBoolean(false)
  const { successAlert } = useToast()
  const [updatedPayload, setUpdatedPayload] = useState(null)

  const { isLoading, data, refetch } = useQuery([getTimeOffPolicy.key, profile.id], {
    queryFn: () => getTimeOffPolicy.fetch(profile.id),
  })

  const { data: countryPaidLeaves } = useQuery(['fetchCountryPaidLeavesById', workingCountryId], {
    queryFn: () => fetchCountryPaidLeavesById(workingCountryId),
    enabled: !!workingCountryId,
  })

  const { data: countrySpecificPaidLeaves } = useQuery(['fetchCountrySpecificPaidById', workingCountryId], {
    queryFn: () => fetchCountrySpecificPaidById(workingCountryId),
    enabled: !!workingCountryId,
  })

  const { data: holidaysData } = useQuery(['holidays', workingCountryId, regionId], {
    queryFn: () => {
      const params = {
        limit: 1000,
        country_id: workingCountryId,
        year: new Date().getFullYear(),
      }
      if (regionId) params.regions = regionId
      return getHolidays(params)
    },
    enabled: !!workingCountryId,
  })

  const { mutate, isLoading: isUpdating } = useMutation({
    mutationFn: (payload) => updateTimeOffPolicy.fetch(profile.id, payload),
    onSuccess: () => {
      defaultModalOpenState.setFalse()
      successAlert('Time off policy has been updated!')
      onNext({
        paid_leave: {
          ...updatedPayload,
          annual_days_by_contract: getAnnualDaysByContract(countryPaidLeaves, updatedPayload.annual_days_rule),
        },
      })
    },
  })

  const handleFormSubmit = (values, isDirty) => {
    if (isDirty && updatedPayload === null) {
      defaultModalOpenState.setTrue()
      setUpdatedPayload(parsePayload(values))
    } else {
      onNext({
        paid_leave: {
          ...parsePayload(values),
          annual_days_by_contract: getAnnualDaysByContract(countryPaidLeaves, parsePayload(values).annual_days_rule),
        },
      })
    }
  }

  const handleDefaultPolicyModalClose = () => {
    defaultModalOpenState.setFalse()
    onNext({
      paid_leave: {
        ...updatedPayload,
        annual_days_by_contract: getAnnualDaysByContract(countryPaidLeaves, updatedPayload.annual_days_rule),
      },
    })
  }

  const handleDefaultPolicyUpdate = () => {
    mutate(updatedPayload)
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-5">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex flex-column h-100 employees-page__form-container">
        <div className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
          <InfoMessage
            message="The default time off value can be set in your account setting; updated values on this page apply only to the employee you are adding.
Time off regulations can very from country to country. Our local teams will do their best to accommodate your desired Time Off policies, but when this is not possible, we will contact you to clarify what your options are. "
          />
          <TimeOffPolicyForm
            onSubmit={handleFormSubmit}
            data={data}
            countryPaidLeaves={countryPaidLeaves}
            countrySpecificPaidLeaves={countrySpecificPaidLeaves}
          />
          {!!holidaysData?.count && (
            <InfoMessage
              message={
                <span>
                  In addition to the paid time off and sick leave days specified above, the employee is also entitled to
                  {` ${holidaysData.count}`} days of regional/national public holidays depending on their region,
                  excluding weekends.{' '}
                  <Styled.LinkButton type="button" onClick={publicHolidaysModalState.setTrue}>
                    See breakdown
                  </Styled.LinkButton>
                </span>
              }
            />
          )}
        </div>
        <div className="pb-5 align-self-center">
          <Button type="submit" form={TIME_OFF_POLICY_FORM_ID}>
            Continue
          </Button>
        </div>
      </div>

      {defaultModalOpenState.value && (
        <TimeOffPolicyDefaultModal
          onClose={handleDefaultPolicyModalClose}
          onSubmit={handleDefaultPolicyUpdate}
          loading={isUpdating}
        />
      )}
      {publicHolidaysModalState.value && <ListHolidaysModal onClose={publicHolidaysModalState.setFalse} />}
    </>
  )
}
