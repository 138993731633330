import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { checkNone, keyboardArrowLeft } from '@core/icons/icons'
import React, { memo, useState } from 'react'
import styled from 'styled-components'

const Styled = styled.div`
  margin: 8px 0;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-radius: ${(p) => (p.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: #fff;
`
const Body = styled.div`
  padding: 24px;
  background: #ffffff;
  border-width: 0 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0 0 8px 8px;
  display: ${(p) => (p.open ? 'block' : 'none')};
`

const Text = styled(Typography)`
  width: 230px;
  font-weight: 500;
  font-size: 12px;
  line-height: 16px;
`

const WrapperElements = styled.div`
  columns: 2;
  -webkit-columns: 2;
  -moz-columns: 2;
`

const Item = memo(({ item, expandedId, setPaymentId }) => {
  const isOpen = expandedId === item.id

  const handleToggle = (id) => () => setPaymentId(isOpen ? null : id)
  return (
    <Styled key={item.id}>
      <Header className="d-flex align-items-center" open={isOpen}>
        <div className="row w-100 align-items-center" role="button" aria-hidden onClick={handleToggle(item.id)}>
          <div className="col-12">
            <Icon
              fill="#B2B2B2"
              style={{
                cursor: 'pointer',
                transform: expandedId === item.id ? 'rotate(90deg)' : 'rotate(0deg)',
              }}
              icon={keyboardArrowLeft}
            />
            <Typography as="span" className="ml-3 text_medium__14">
              {item.title}
            </Typography>
          </div>
        </div>
      </Header>
      {isOpen && (
        <Body open={isOpen}>
          <Typography>{item.description}</Typography>
          {item.packages.map((p) => (
            <div>
              <Typography className="color_secondary_light heading_semibold__20 my-4">{p.paid}</Typography>
              <WrapperElements>
                {p.elements.map((e) => (
                  <div className="d-flex align-items-start my-2">
                    <Icon fill="#262842" stroke="#262842" icon={checkNone} className="mr-2" />
                    <Text>{e}</Text>
                  </div>
                ))}
              </WrapperElements>
            </div>
          ))}
        </Body>
      )}
    </Styled>
  )
})

const MyAccordion = ({ data }) => {
  const [expandedItemId, setExpandedItemId] = useState(null)

  return data?.map((v) => {
    return <Item key={v.id} item={v} expandedId={expandedItemId} setPaymentId={setExpandedItemId} />
  })
}

MyAccordion.displayName = 'Accordion'

export default memo(MyAccordion)
