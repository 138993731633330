import React from 'react'

import Button from './Button/Button'

export default ({ error, resetErrorBoundary }) => {
  return (
    <div className="container">
      <div className="row mt-5">
        <div className="col-xs-12 text-center">
          <div role="alert">
            <p>Something went wrong:</p>
            <pre>{error.message}</pre>
            <Button data-testid="ErrorFallback-805F0C" priority="secondary" size="medium" onClick={resetErrorBoundary}>
              Go to home page
            </Button>
          </div>
        </div>
      </div>
    </div>
  )
}
