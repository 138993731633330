import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { deleteIcon, keyboardArrowLeft } from '@core/icons/icons'
import React from 'react'
import styled from 'styled-components'

const Styled = styled.div`
  margin: 8px 0px;
`
const Header = styled.div`
  border: 1px solid #f3f3f3;
  border-color: ${(props) => (props.isError ? 'red' : '')};
  border-radius: ${(props) => (props.open ? '8px 8px 0px 0px' : '8px')};
  padding: 16px;
  outline: none;
  background: #fff;
  cursor: pointer;
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`
const Body = styled.div`
  padding: 16px;
  background: #ffffff;
  border-width: 0px 1px 1px 1px;
  border-style: solid;
  border-color: #f3f3f3;
  border-radius: 0px 0px 8px 8px;
  border-color: ${(props) => (props.isError ? 'red' : '')};
  display: ${(props) => (props.open ? 'block' : 'none')};
  box-shadow: rgba(99, 99, 99, 0.2) 0px 2px 8px 0px;
`

export const CreateExpensePanel = ({ isError, expandedId, onOpen, formId, expenseName, children, onRemove }) => {
  const isOpen = expandedId === formId

  const handleToggle = (id) => () => onOpen(isOpen ? null : id)

  const handleRemove = (event) => {
    event.stopPropagation()
    onRemove(formId)
  }

  return (
    <Styled>
      <Header
        isError={isError}
        className="d-flex align-items-center gap-2"
        open={isOpen}
        onClick={handleToggle(formId)}
      >
        <Icon
          fill="#B2B2B2"
          style={{
            cursor: 'pointer',
            transform: isOpen ? 'rotate(90deg)' : 'rotate(0deg)',
          }}
          icon={keyboardArrowLeft}
        />
        <Typography as="span" className="text_medium__14 flex-grow-1">
          {expenseName}
        </Typography>
        <Icon
          fill="#B2B2B2"
          style={{
            cursor: 'pointer',
          }}
          icon={deleteIcon}
          onClick={handleRemove}
        />
      </Header>
      <Body open={isOpen}>{children}</Body>
    </Styled>
  )
}
