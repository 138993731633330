import { fetchStats } from '@services/stats.service'
import { useEffect } from 'react'
import { useQuery } from 'react-query'

const addUserToSegment = (segmentName) => {
  window.postMessage({
    TYPE: 'KOMPASSIFY_SEGMENTATION_ADD_USER_TO_SEGMENT',
    segmentName,
  })
}
export const useKompassifySegments = (hasKompassifyInit, clientCountry) => {
  const { data: stats } = useQuery('stats', {
    queryFn: () => fetchStats(),
  })

  // Add default segment
  useEffect(() => {
    if (!hasKompassifyInit) {
      return
    }
    addUserToSegment('Client')
  }, [hasKompassifyInit])

  // Add segment based on stats
  useEffect(() => {
    if (!hasKompassifyInit || !stats) {
      return
    }
    const hasEmployees = Boolean(stats.employees[0].count)
    const hasContractors = Boolean(stats.employees[1].count)
    let clientType
    switch (true) {
      case hasEmployees === true && hasContractors === true: {
        clientType = 'Combined'

        break
      }
      case hasEmployees === true && hasContractors === false: {
        clientType = 'FTE'

        break
      }
      case hasEmployees === false && hasContractors === true: {
        clientType = 'Contractors'

        break
      }
      default:
        break
    }
    if (clientType) {
      addUserToSegment(`Client-${clientType}`)
    }
  }, [hasKompassifyInit, stats])

  // Add segment based on country
  useEffect(() => {
    if (!hasKompassifyInit || !clientCountry) {
      return
    }
    addUserToSegment(`Client-${clientCountry}`)
  }, [hasKompassifyInit, clientCountry])
}
