import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import { Loader } from '@components/loader/loader.component'
import { ContractForm } from '@pages/employees/create-contractor/contract-form/contract-form'
import { deactivateNotification } from '@services/notifications.service'
import { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery, useQueryClient } from 'react-query'
import { getEmployeeDetails } from 'src/services/employee.service'
import { useBoolean } from 'usehooks-ts'

export const UpdateContractModal = ({ employee, employeeId, showUpdateContractModal, withSelectCancelButton }) => {
  const queryClient = useQueryClient()

  const [activeStep, setStep] = useState(1)
  const updateContractOpen = useBoolean(false)
  const { data: employeeData, loading: employeeLoading } = useQuery('detailEmployee', {
    queryFn: async () => {
      const result = await getEmployeeDetails(employeeId)
      return result
    },
    enabled: !employee && !!employeeId,
  })
  const handleUpdateContractClose = () => {
    showUpdateContractModal.setFalse()
    updateContractOpen.setFalse()
  }
  const contract = employee ?? employeeData
  const refetchNotifications = () => {
    queryClient.invalidateQueries('notifications', { refetchActive: true })
  }
  const { mutate: mutateDeactivateNotification, isLoading: deactivateMutationLoading } = useMutation({
    mutationFn: () => deactivateNotification(contract.id, { has_proposed_agreement_amendment: false }),
    onSuccess: () => {
      refetchNotifications()
    },
  })
  const onUpdateCancel = () => {
    if (!contract) {
      return
    }
    mutateDeactivateNotification()
  }
  if (!showUpdateContractModal.value) {
    return null
  }
  return (
    <Modal show onClose={handleUpdateContractClose} fullscreen>
      <Modal.Body className="p-0">
        <Stepper
          activeStep={activeStep}
          title="Updating contract"
          description={
            <>
              The current contract will be deactivated <br /> and replaced by the updated version.
            </>
          }
          sidebarStyle={{ width: 400 }}
        >
          <Step step="1" title="Update contract" setStep={setStep}>
            <StepContent title="Update contract" onClose={handleUpdateContractClose}>
              {employeeLoading ? (
                <Loader />
              ) : (
                <ContractForm
                  updateContractOpen={updateContractOpen}
                  contractor={contract}
                  onClose={handleUpdateContractClose}
                  showUpdateContractModal={showUpdateContractModal}
                  withSelectCancelButton={withSelectCancelButton}
                  onCancel={onUpdateCancel}
                  cancelButtonLabel="Do not update"
                  isCancelling={deactivateMutationLoading}
                />
              )}
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>
    </Modal>
  )
}
