import Button from '@atoms/Button/Button'
import RadioButton from '@atoms/RadioButton/RadioButton'
import ContentPlaceholder from '@components/content-placeholder/content-placeholder.component'
import FileInfo from '@components/file-info/file-info.component'
import { SEND_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import React, { useRef, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { fetchAddCompanyManager } from 'src/services/manager.service'
import { notifyManagerToSignMsa } from 'src/services/sign.service'
import { useBoolean } from 'usehooks-ts'

import { AuthorizedNoForm } from '../authorized-no-form/authorized-no-form.component'
import { AuthorizedYesForm } from '../authorized-yes-form/authorized-yes-form.component'
import { Container, Content, FormControl } from './authorized-step.styles'

export const AuthorizedStep = ({ onNext, onSave }) => {
  const { profile } = useApp()
  const [isAuthorized, setIsAuthorized] = useState('true')
  const isSent = useBoolean(false)
  const authorizedYesFormRef = useRef(null)
  const authorizedNoFormRef = useRef(null)
  const navigate = useNavigate()

  const addManagerMutation = useMutation({
    mutationFn: (payload) => fetchAddCompanyManager(payload),
    onSuccess: () => {
      console.log('success add')
    },
  })

  const notifyManagerMutation = useMutation({
    mutationFn: (payload) => notifyManagerToSignMsa(payload),
    onSuccess: () => {
      console.log('success notify')
      isSent.setTrue()
    },
  })

  const handleAuthorizedChange = (e) => setIsAuthorized(e.target.value)

  const triggerAuthorizedYesSubmit = () => {
    if (authorizedYesFormRef.current) {
      authorizedYesFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    } else {
      // Form is not rendered if there is a document, just pass this step
      onNext()
    }
  }

  const triggerAuthorizedNoSubmit = () => {
    if (authorizedNoFormRef.current) {
      authorizedNoFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const handleAuthorizedYesSubmit = (data) => {
    onSave(data)
    onNext()
  }

  const handleAuthorizedNoSubmit = async (data) => {
    const { sendType, firstName, lastName, email, manager } = data
    try {
      let newManager
      if (sendType === SEND_TYPES.INVITE) {
        newManager = await addManagerMutation.mutateAsync({
          company: profile.id,
          manager: {
            email,
            profile: {
              first_name: firstName,
              last_name: lastName,
            },
            // TODO need checkbox for select is it billing contact or not
            is_billing_contact: false,
          },
        })
      }
      const managerId = newManager?.id || manager?.value
      await notifyManagerMutation.mutateAsync({
        manager: managerId,
      })
    } catch (err) {
      console.log(err)
    }
  }

  const checkAuthorized = () => isAuthorized === 'true'

  const handleNavigate = () => {
    navigate(`/`, { replace: true })
  }

  return (
    <Container>
      {isSent.value ? (
        <ContentPlaceholder
          imgSrc="/assets/img/send-success.png"
          title="We have sent an invitation by email"
          description="Manager will get an email notification to sign the MSA through the platform"
          buttonText="Go to dashboard"
          onClick={handleNavigate}
        />
      ) : (
        <>
          <Content>
            <FormControl>
              <RadioButton
                label="Are you an authorized representative?"
                addText="If no is selected, you will need to invite that person to sign the MSA"
                options={[
                  {
                    id: 1,
                    text: 'Yes',
                    value: 'true',
                  },
                  {
                    id: 2,
                    text: 'No',
                    value: 'false',
                  },
                ]}
                value={isAuthorized}
                onChange={handleAuthorizedChange}
              />
            </FormControl>
            {checkAuthorized() && profile.legal_registration_document && (
              <FileInfo
                label="Company legal registration document"
                name={profile.legal_registration_document_name}
                file={profile.legal_registration_document}
              />
            )}
            {checkAuthorized() && !profile.legal_registration_document && (
              <AuthorizedYesForm formRef={authorizedYesFormRef} onSubmit={handleAuthorizedYesSubmit} />
            )}
            {!checkAuthorized() && (
              <AuthorizedNoForm type="MSA" formRef={authorizedNoFormRef} onSubmit={handleAuthorizedNoSubmit} />
            )}
          </Content>
          {checkAuthorized() ? (
            <Button data-testid="authorized-step.component-78FAE6" type="button" onClick={triggerAuthorizedYesSubmit}>
              Continue
            </Button>
          ) : (
            <Button
              data-testid="authorized-step.component-149ACB"
              type="button"
              onClick={triggerAuthorizedNoSubmit}
              loading={addManagerMutation.isLoading || notifyManagerMutation.isLoading}
              disabled={addManagerMutation.isLoading || notifyManagerMutation.isLoading}
            >
              Send
            </Button>
          )}
        </>
      )}
    </Container>
  )
}
