import React from 'react'

import { TimeTable } from './TimeTable'

export const TimesheetsTable = ({
  fields,
  data,
  day,
  totalHours,
  onRowClick,
  pageSize,
  loading,
  page,
  total,
  onPage,
}) => {
  const columns = React.useMemo(
    () =>
      fields.map((field) => {
        switch (field.type) {
          case 'custom':
            return {
              Header: field.title,
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
              Cell: ({ row }) => {
                return field.render(row.original, row.index)
              },
            }
          default:
            return {
              Header: field.title,
              accessor: field.key,
              maxWidth: field.maxWidth,
              minWidth: field.minWidth,
              width: field.width,
            }
        }
      }),
    [fields]
  )

  return (
    <TimeTable
      loading={loading}
      page={page}
      pageSize={pageSize}
      total={total}
      columns={columns}
      data={data}
      onRowClick={onRowClick}
      onPage={onPage}
      day={day}
      totalHours={totalHours}
    />
  )
}
