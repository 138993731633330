import styled from 'styled-components'

const Occurences = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`
const OccurencesCheckbox = styled.div`
  flex-shrink: 0;
`
const OccurencesInput = styled.div`
  /* width: 100px; */
  display: flex;
  align-items: center;
  gap: 8px;

  .input {
    width: 100px;
  }
`

export const Styled = {
  Occurences,
  OccurencesCheckbox,
  OccurencesInput,
}
