import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import RadioButtonHorizontal from '@atoms/RadioButtonHorizontal/RadioButtonHorizontal'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AppContext, useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import { employeeTableParse } from '@pages/employees/parse'
import { fetchContractors } from '@services/app.service'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

import { postCalculateLastDay } from '../../services/payroll.service'
import { monthOptions } from './utils'

const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'ALLOWANCE', label: 'Allowance' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

const recurrenceFrequency = [
  { value: 'MONTHLY', label: 'Monthly recurring' },
  { value: 'EVERY_SECOND_MONTH', label: 'Every 2 months recurring' },
  { value: 'QUARTERLY', label: 'Quarterly recurring' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`
const formId = 'payment_create_form'

export default ({ isLoading, onHide, onCreate, currency, isEdit, selectedItem, isSuccess }) => {
  const { profile } = useContext(AppContext)
  const { currencies } = useApp()
  const [contracts, setContracts] = useState([])
  const currentDate = new Date(moment(new Date()).format('YYYY-MM-DD'))
  const [minDate, setMinDate] = useState(currentDate)

  const {
    control,
    formState: { errors },
    register,
    reset,
    handleSubmit,
    watch,
    setValue,
  } = useForm({
    defaultValues: {
      contract: selectedItem ? selectedItem.contract_data.id : null,
      ap_type: selectedItem ? selectedItem.ap_type : '',
      amount: selectedItem ? selectedItem.amount_in_company_currency : '',
      name: selectedItem ? selectedItem.title : '',
      description: selectedItem ? selectedItem.description : '',
      currency: selectedItem ? selectedItem.currency.id : currency?.id,
      is_recurrent: selectedItem ? `${selectedItem.is_recurrent}` : '',
      recurrence_frequency: selectedItem ? selectedItem.recurrence_frequency : '',
      recurrence_starting_month: selectedItem ? selectedItem.recurrence_starting_month : undefined,
      recurrence_ending_month: selectedItem?.recurrence_ending_month ? selectedItem.recurrence_ending_month : undefined,
      tax: selectedItem?.tax,
    },
  })

  const isRecurrentWatch = watch('is_recurrent')
  const starts_at_watch = watch('recurrence_starting_month')
  const { isLoading: isLoadingContracts } = useQuery([fetchContractors.key, isRecurrentWatch], {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        contract_type: isRecurrentWatch === 'true' ? 'full_time_employee' : '',
        state: 'active',
        offset: 0,
        limit: 1000,
      }),
    onSuccess: ({ results }) => {
      setContracts(employeeTableParse(results))
      if (!selectedItem?.contract_data?.id) setValue('contract', null)
    },
  })

  const postCalculateLastDayMutate = useMutation('postCalculateLastDay', {
    mutationFn: (payload) => postCalculateLastDay(payload),
  })

  const submit = (formValues) => {
    let body = {
      amount: formValues.amount,
      description: formValues.description,
      name: formValues.name,
      contract: formValues?.contract.value || formValues?.contract,
      currency: formValues.currency.value || formValues.currency,
      ap_type: formValues.ap_type.value,
      is_recurrent: formValues?.is_recurrent,
    }
    if (isRecurrentWatch === 'true') {
      body = {
        ...body,
        recurrence_frequency: formValues.recurrence_frequency.value,
        recurrence_starting_month: formValues.recurrence_starting_month
          ? formValues.recurrence_starting_month.value
          : '',
        recurrence_ending_month: formValues.recurrence_ending_month ? formValues.recurrence_ending_month.value : '',
      }
    }
    if (isEdit) {
      body = {
        ...body,
        tax: formValues?.tax,
      }
    }
    if (isEdit) {
      body = {
        ...body,
        tax: formValues?.tax,
      }
    }
    onCreate(body)
  }
  useEffect(() => {
    if (isSuccess) {
      reset()
    }
  }, [isSuccess])

  useEffect(() => {
    if (profile) {
      postCalculateLastDayMutate.mutate({
        country: profile?.legal_address?.living_country?.id,
        days: profile.additional_payment_cut_off_day,
      })
    }
  }, [profile])

  useEffect(() => {
    if (
      postCalculateLastDayMutate?.data?.last_day &&
      currentDate.getTime() >= new Date(postCalculateLastDayMutate?.data?.last_day).getTime()
    ) {
      const nextDate = new Date()
      nextDate.setMonth(currentDate.getMonth() + 1)
      nextDate.setDate(1)
      setMinDate(nextDate)
    }
  }, [postCalculateLastDayMutate.data])
  return (
    <Modal show onHide={onHide} centered>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{isEdit ? 'Edit' : 'New'} Additional Payment</Typography>
      </Modal.Header>
      <Modal.Body>
        <StyledForm noValidate id={formId} className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col">
              <div className="remo-form-input">
                <RadioButtonHorizontal
                  {...register('is_recurrent')}
                  isHorizontal
                  label="One-time"
                  addText="Suitable for bonuses and
one-time rewards"
                  options={[{ text: '', value: false }]}
                />
                {errors.currency && (
                  <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
                )}
              </div>
            </div>
            <div className="col">
              <div className="remo-form-input">
                <RadioButtonHorizontal
                  {...register('is_recurrent')}
                  addText="Suitable for recurring payments such as bonuses"
                  label="Recurring"
                  options={[{ text: '', value: true }]}
                />
                {errors.currency && (
                  <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
                )}
              </div>
            </div>
          </div>
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="contract"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="CreateModal-727DC9"
                    {...field}
                    isDisabled={isLoadingContracts || isRecurrentWatch === ''}
                    isRequired
                    addText="You can choose employees from the list"
                    label={isLoadingContracts ? 'Loading...' : 'Select employee'}
                    options={contracts
                      .filter((contract) => contract.contractor_wage_type === 'monthly')
                      .map((contract) => ({
                        value: contract.id,
                        contractor: contract.contract_type === 'contractor' ? ' (Contractor)' : '',
                        label: contract.full_name,
                        countryId: contract.working_country?.id,
                      }))}
                    getOptionLabel={(option) => `${option.label}${option.contractor}`}
                  />
                )
              }}
            />
            {errors.contract && (
              <Typography className="text_regular__14 color_red">{errors.contract.message}</Typography>
            )}
          </div>
          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="ap_type"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select data-testid="CreateModal-23478B" {...field} label="Payment type" options={paymentTypes} />
                  )
                }}
              />
              {errors.ap_type && (
                <Typography className="text_regular__14 color_red">{errors.ap_type.message}</Typography>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="amount"
                rules={{
                  required: 'Field is required',
                  validate: {
                    minlength: (v) =>
                      /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
                  },
                }}
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    required
                    label="Amount"
                    placeholder="Enter the adjustment amount"
                    onChange={setCurrencyChangeEvent(field.onChange)}
                  />
                )}
              />
              {errors.amount && <Typography className="text_regular__14 color_red">{errors.amount.message}</Typography>}
            </div>
          </div>
          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="currency"
                rules={{ required: 'Currency is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="CreateModal-8F01FE"
                      {...field}
                      label="Currency"
                      options={currencies.map((country) => ({
                        value: country.id,
                        label: country.name || country.short_code,
                      }))}
                    />
                  )
                }}
              />
              {errors.currency && (
                <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
              )}
            </div>
          </div>
          <div className="row">
            <div className="col-12">
              <Input
                data-testid="CreateModal-525C5C"
                {...register('name', { required: 'Field is required' })}
                isRequired
                label="Payment name"
                placeholder="Title"
              />
              {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
            </div>
            <div className="col-12">
              <TextArea placeholder="Description..." {...register('description')} />
              {errors.description && (
                <Typography className="text_regular__14 color_red">{errors.description.message}</Typography>
              )}
            </div>
          </div>
          {isRecurrentWatch === 'true' && (
            <>
              <Typography className="heading_semibold__24">Select period</Typography>

              <div className="row">
                <div className="col-6 pr-2">
                  <div className="w-100 remo-form-input">
                    <Controller
                      control={control}
                      name="recurrence_starting_month"
                      rules={{ required: 'Month is required' }}
                      render={({ field }) => (
                        <Select
                          data-testid="CreateModal-F58771"
                          {...field}
                          label="From"
                          isRequired
                          options={monthOptions(minDate)}
                        />
                      )}
                    />
                    {errors.recurrence_starting_month && (
                      <Typography className="text_regular__14 color_red">
                        {errors.recurrence_starting_month.message}
                      </Typography>
                    )}
                  </div>
                </div>
                <div className="col-6 pl-2">
                  <div className="w-100 remo-form-input">
                    <Controller
                      control={control}
                      name="recurrence_ending_month"
                      rules={{ required: 'Month is required' }}
                      render={({ field }) => (
                        <Select
                          data-testid="CreateModal-677F3D"
                          {...field}
                          label="To"
                          isRequired
                          isDisabled={!starts_at_watch}
                          defaultValue={undefined}
                          options={monthOptions(starts_at_watch?.value || minDate)}
                        />
                      )}
                    />
                    {errors.recurrence_ending_month && (
                      <Typography className="text_regular__14 color_red">
                        {errors.recurrence_ending_month.message}
                      </Typography>
                    )}
                  </div>
                </div>
              </div>
              <div className="row">
                <div className="col">
                  <Controller
                    control={control}
                    name="recurrence_frequency"
                    rules={{ required: 'Field is required' }}
                    render={({ field }) => {
                      return (
                        <Select
                          data-testid="CreateModal-4E78AE"
                          {...field}
                          label="Frequency"
                          options={recurrenceFrequency}
                        />
                      )
                    }}
                  />
                  {errors.recurrence_frequency && (
                    <Typography className="text_regular__14 color_red">
                      {errors.recurrence_frequency.message}
                    </Typography>
                  )}
                </div>
              </div>
            </>
          )}
        </StyledForm>
      </Modal.Body>
      <Modal.Footer>
        <Button data-testid="CreateModal-6F99CB" priority="secondary" size="small" type="button" onClick={onHide}>
          Close
        </Button>
        <Button
          data-testid="CreateModal-29748D"
          type="submit"
          form={formId}
          size="small"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
