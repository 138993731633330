import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { useFormContext } from 'react-hook-form'

export const EquipmentDelivery = () => {
  const {
    register,
    formState: { errors },
  } = useFormContext()

  return (
    <div className="w-100 remo-form-input">
      <TextArea
        {...register('equipment_specification', {
          required: 'Equipment specifications are required',
        })}
        label="Equipment specifications"
        placeholder="Be as specific as possible with the required equipment specification"
      />
      <Typography className="text_light__12 color_text_300 mt-1">
        We’ll send an email to request additional information and provide further steps.
      </Typography>
      {errors.equipment_specification && (
        <Typography className="text_regular__14 color_red">{errors.equipment_specification.message}</Typography>
      )}
    </div>
  )
}
