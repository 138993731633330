import styled from 'styled-components'

const LinkButton = styled.button`
  border: 0;
  margin: 0 0 0 2px;
  padding: 0;
  color: #037b0f;
  text-decoration: underline;
`

export const Styled = {
  LinkButton,
}
