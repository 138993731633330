import { Api } from '@api/Api'

export const getExpenseManagements = async ({ state_in, page, limit, ...rest }) => {
  const offset = (page - 1) * limit
  const { data } = await Api.get(`expense-managements/`, {
    params: { state_in, limit, offset, ...rest },
  })

  return data
}

export const createExpense = async (data) => Api.post(`expense-managements/bulk-create/`, data)

export const cancelExpense = async (body) => Api.post(`expense-managements/${body.id}/status-update/`, { ...body.data })

export const updateExpense = async (body) => Api.put(`expense-managements/${body.id}/update/`, { ...body.data })

export const downloadExpenseCsv = async (params) => {
  const { data } = await Api.get('expense-managements/reports/', {
    params,
    responseType: 'blob',
  })

  return data
}

export const bulkApprove = async (body) => {
  const { data } = await Api.post('expense-managements/bulk-approve/', body)

  return data
}
