/* eslint-disable consistent-return */
/* eslint-disable default-case */
import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import { MASS_IMPORT_STATE } from '@core/constants'
import { createMassImportInstance, stateUpdate } from '@services/mass-import-contractors.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'

import { ContractorsMassImportAgreements } from './contractors-mass-import-agreements/contractors-mass-import-agreements.component'
import { ContractorsMassImportConfirm } from './contractors-mass-import-confirm/contractors-mass-import-confirm.component'
import { Styled } from './contractors-mass-import-modal.styles'
import { ContractorsMassImportUploadCsv } from './contractors-mass-import-upload-csv/contractors-mass-import-upload-csv.component'
import { ContractorsMassImportValidate } from './contractors-mass-import-validate/contractors-mass-import-validate.component'

const getActiveStep = (massImportState) => {
  if (!massImportState) {
    return 1
  }

  switch (massImportState) {
    case MASS_IMPORT_STATE.UPLOAD_CSV:
    case MASS_IMPORT_STATE.COMPLETED:
      return 1

    case MASS_IMPORT_STATE.VALIDATE_DATA:
      return 2

    case MASS_IMPORT_STATE.CONFIRM_IMPORT:
      return 3

    case MASS_IMPORT_STATE.UPLOAD_WORK_AGREEMENT:
      return 4
  }
}

export const ContractorsMassImportModal = ({ onClose }) => {
  const [step, setStep] = useState(2)

  const newMassImportInstanceQuery = useQuery([createMassImportInstance.key], {
    queryFn: createMassImportInstance.fetch,
    onSuccess: ({ state }) => {
      const activeStep = getActiveStep(state)
      setStep(activeStep)
    },
  })

  const stateUpdateMutation = useMutation({
    mutationFn: (state) => stateUpdate(newMassImportInstanceQuery.data.id, state),
    onSuccess: ({ state }) => {
      const activeStep = getActiveStep(state)
      setStep(activeStep)
    },
  })

  const handleComplete = () => {
    stateUpdateMutation.mutateAsync(MASS_IMPORT_STATE.COMPLETED).then(onClose)
  }

  const handleNavigateAndChangeState = (state) => () => {
    stateUpdateMutation.mutate(state)
  }

  if (newMassImportInstanceQuery.isLoading) {
    return (
      <Modal show fullscreen onHide={onClose} animation={false}>
        <Modal.Body>
          <div className="h-100 d-flex justify-content-center align-items-center">
            <DotWave />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Styled.Modal show fullscreen className="p-0" animation={false}>
      <Modal.Body className="p-0">
        <Stepper
          sidebarStyle={{ width: 336, paddingTop: 40, paddingLeft: 32 }}
          activeStep={step}
          title="Mass import contractor"
          description="Adding a large number of contractors on one go by uploading a CSV file."
        >
          {/* TODO remove setStep */}
          <Step step="1" title="Upload CSV" setStep={setStep}>
            <StepContent title="Upload CSV" onClose={onClose}>
              <ContractorsMassImportUploadCsv
                massImportId={newMassImportInstanceQuery.data.id}
                isLoading={stateUpdateMutation.isLoading}
                onNext={handleNavigateAndChangeState(MASS_IMPORT_STATE.VALIDATE_DATA)}
              />
            </StepContent>
          </Step>
          <Step step="2" title="Validate data">
            <StepContent
              title="Validate data"
              onClose={onClose}
              onBack={handleNavigateAndChangeState(MASS_IMPORT_STATE.UPLOAD_CSV)}
            >
              {/* to destroy */}
              {step === 2 && (
                <ContractorsMassImportValidate
                  massImportId={newMassImportInstanceQuery.data.id}
                  isLoading={stateUpdateMutation.isLoading}
                  onReUpload={handleNavigateAndChangeState(MASS_IMPORT_STATE.UPLOAD_CSV)}
                  onNext={handleNavigateAndChangeState(MASS_IMPORT_STATE.CONFIRM_IMPORT)}
                />
              )}
            </StepContent>
          </Step>
          <Step step="3" title="Confirm import">
            <StepContent
              title="Confirm import"
              onClose={onClose}
              onBack={handleNavigateAndChangeState(MASS_IMPORT_STATE.VALIDATE_DATA)}
            >
              <ContractorsMassImportConfirm
                massImportId={newMassImportInstanceQuery.data.id}
                isLoading={stateUpdateMutation.isLoading}
                onNext={handleNavigateAndChangeState(MASS_IMPORT_STATE.UPLOAD_WORK_AGREEMENT)}
              />
            </StepContent>
          </Step>
          <Step step="4" title="Work agreement (Optional)">
            <StepContent title="Work agreement (Optional)" onClose={onClose}>
              {/* to destroy */}
              {step === 4 && (
                <ContractorsMassImportAgreements
                  massImportId={newMassImportInstanceQuery.data.id}
                  onClose={handleComplete}
                />
              )}
            </StepContent>
          </Step>
        </Stepper>
      </Modal.Body>
    </Styled.Modal>
  )
}
