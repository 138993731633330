import { Api } from '@api/Api'

import { generateService } from '../core/utils'

export const deactivateCompany = async (id) => Api.delete(`companies/${id}/`)

export const getCompaniesShort = async (params) => {
  const { data } = await Api.get(`companies-staff-short-list/`, {
    params,
  })
  return data
}

export const getOnboardingChecklist = async (id) => {
  const { data } = await Api.get(`companies/${id}/onboarding/check-list/`)
  return data
}

export const addOnboardingAddress = async (payload) => {
  const { data } = await Api.post(`companies/onboarding/details/`, payload)
  return data
}

export const addOnboardingPaymentInfo = async (payload) => {
  const { data } = await Api.post(`companies/onboarding/payment-details/`, payload)
  return data
}

export const addOnboardingBillingContact = async (payload) => {
  const { data } = await Api.post(`companies/onboarding/billing-contacts/`, payload)
  return data
}

export const reportBookedACall = async (body) => {
  const { data } = await Api.post(`reports/companies/request-call/create/`, body)

  return data
}

export const getTimeOffPolicy = generateService(async (id) => {
  const { data } = await Api.get(`companies/${id}/time-off-policy/`)
  return data
})

export const updateTimeOffPolicy = generateService(async (id, body) => {
  const { data } = await Api.put(`companies/${id}/time-off-policy/update/`, body)
  return data
})
