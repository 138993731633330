import { EMPLOYEE_STATES } from '@core/constants'
import { formatDate, SplitNumber } from '@core/utils'

export const ContractorWageType = {
  monthly: 'm',
  hourly: 'h',
  daily: 'd',
  milestone: 'milestone',
}

export const employeeTableParse = (contracts, page) => {
  const displaySalary = (contract) => {
    const { contract_type, compensation, currency, contractor_rate, yearly_gross_salary } = contract
    const { contractor_wage_type, contractor_payment_frequency } = compensation

    const isContractor = contract_type === 'contractor'
    const isMilestoneBased = contractor_wage_type === 'milestone'
    const isBiweeklyFrequency = contractor_payment_frequency === 'biweekly'
    const currencySymbol = currency?.sign || currency?.short_code

    if (isMilestoneBased) {
      return 'Milestone'
    }

    if (isContractor) {
      const wageType = isBiweeklyFrequency ? 'bi-weekly' : ContractorWageType[contractor_wage_type]
      return `${currencySymbol} ${SplitNumber(contractor_rate)}/${wageType}`
    }

    return `${currencySymbol} ${SplitNumber(yearly_gross_salary)}/y`
  }

  return contracts.map((contract, index) => ({
    id: contract.id,
    number: (page - 1) * 10 + index + 1,
    full_name: contract.full_name,
    avatar: contract.avatar,
    customer: contract.company.name,
    department: contract.department,
    job_position: contract.job_position,
    type: contract.contract_type === 'contractor' ? 'Contractor' : 'FTE',
    salary: displaySalary(contract),
    receiving_currency: contract.compensation?.receiving_currency?.name || '-',
    status: contract.state,
    location: contract.living_country?.name || '',
    start_at:
      contract.state === EMPLOYEE_STATES.ACTIVE
        ? formatDate(contract.starts_at)
        : formatDate(contract.requested_starts_at),
    ...contract,
  }))
}

export const parseDaysToStart = (startDate) => {
  const oneDay = 24 * 60 * 60 * 1000 // hours*minutes*seconds*milliseconds
  const now = new Date()
  const start = new Date(startDate)

  const diffDays = Math.round(Math.abs((start - now) / oneDay))
  return diffDays
}
