import Icon from '@atoms/Icon/Icon'
import { Card } from '@components/card/card.component'
import { arrowLinkIcon } from '@core/icons/icons'
import { accessibleOnClick, filterKeyEnter } from '@core/utils'
import React from 'react'
import styled from 'styled-components'

import MapImage from '../../assets/images/map.svg'

const Container = styled.div`
  display: flex;
`
const Content = styled.div`
  flex: 1;
  padding-right: 200px;
  position: relative;
`
const StyledCard = styled(Card)`
  background-image: url(${MapImage});
  background-position: bottom right;
  background-repeat: no-repeat;
`
const Title = styled.div`
  color: #101828;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`
const Description = styled.div`
  font-size: 0.875rem;
  color: #878787;
`
const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
`

export const CountryGuideCard = () => {
  const handleNavigate = () => {
    window.open('https://www.remofirst.com/country-guide', '_blank')
  }

  return (
    <StyledCard color="light" {...accessibleOnClick(handleNavigate)(filterKeyEnter)}>
      <Container>
        <Content>
          <Title>Country guide</Title>
          <Description>
            Discover the general overview for building your global workforce with Remofirst’s Country guides.
          </Description>
          <StyledIcon icon={arrowLinkIcon} />
        </Content>
      </Container>
    </StyledCard>
  )
}
