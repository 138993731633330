import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useState } from 'react'

export default function CreateThread({ users, loading, onClose, onSave }) {
  const { profile } = useApp()
  const [topic, setTopic] = useState()
  const [member, setMember] = useState([])

  const onChangeTopic = useCallback((e) => {
    setTopic(e.target.value)
  }, [])

  const onChangeMember = useCallback((selectMember) => {
    setMember(selectMember)
  }, [])

  const onClickSaveThread = () => {
    const memberIds = member.map((user) => user.value)
    onSave({ members: memberIds, topic, company: profile.id })
  }

  return (
    <EditModal
      visible
      // scrollable should be eq to {false} because of select dropdown menu scroll
      scrollable={false}
      footer={false}
      title="New chat"
      closeModal={onClose}
      className="d-flex align-items-center flex-column"
    >
      <div className="d-flex justify-content-between w-100 mb-4">
        <div className="flex-column">
          <Typography className="text_regular__14 color_text_300">Here, you can chat with your employees</Typography>
        </div>
      </div>
      <div style={{ minHeight: 300 }}>
        <Input
          data-testid="CreateThread-C89FD7"
          onChange={onChangeTopic}
          label="Chat topic"
          type="text"
          name="topic"
          placeholder="Topic"
          styleClass="mb-3"
          value={topic}
        />
        <Select
          data-testid="CreateThread-8DF349"
          options={users.map((user) => ({
            value: user.id,
            label: user.full_name,
          }))}
          isMulti
          name="thread_member"
          id="thread_member"
          placeholder="Select members"
          onChange={onChangeMember}
          value={member}
          label="Add members"
        />
      </div>
      <div className="d-flex justify-content-end mt-5 w-100">
        <Button data-testid="CreateThread-4986E5" priority="secondary" size="small" className="mr-3" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-testid="CreateThread-AA97D0"
          priority="primary"
          size="small"
          loading={loading}
          disabled={loading}
          onClick={onClickSaveThread}
        >
          Create
        </Button>
      </div>
    </EditModal>
  )
}
