import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import DateInput from '@atoms/DateInput/DateInput'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { EMPLOYEE_STATES } from '@core/constants'
import { getCompensationInfo, getJobDetails } from '@pages/employeeDetailPage/mock'
import { parseDetailsToConfig } from '@pages/employees/create-employee/forms/probation-period.utils'
import { getJobDetailsList } from '@services/contract.service'
import { patchContractById } from '@store/contracts'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { SalaryChangeDetails } from '../JobDetails/SalaryChangeDetails'

export function JobDetailsFte({ employee, setEmployee, occupations, regions, probationPeriodDetails }) {
  const dropdownRef = useRef(null)
  const dropdownOpenState = useBoolean(false)
  const [salaryChange] = employee.salary_changes
  const [visibleEdit, setVisibleEdit] = useState(false)

  const [editState, setEditState] = useState({
    position: employee.job.position,
    position_description: employee.job.position_description,
    department: employee.department,
    is_permanent: employee.job.is_permanent,
    ends_at: employee.job.ends_at ? new Date(employee.job.ends_at) : null,
    starts_at: new Date(employee.job.starts_at),
    requested_starts_at: new Date(employee.job.requested_starts_at),
    occupation: employee?.job?.occupation?.id,
    region: employee?.region?.id,
    employment_type: employee?.job?.employment_type,
  })

  const hasOccupations = useMemo(() => !!occupations && occupations.length > 0, [occupations])
  const hasRegions = useMemo(() => !!regions && regions.length > 0, [regions])

  const jobDetailsListQuery = useQuery({
    queryKey: [getJobDetailsList.key],
    queryFn: getJobDetailsList.fetch,
  })

  const putJobDetail = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {
      setEmployee(res.data)
      toast.success('Successfully updated')
      setVisibleEdit(false)
    },
  })

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }

  const onChangeOccupation = ({ value }) => {
    setEditState((prevState) => ({
      ...prevState,
      occupation: value,
    }))
  }

  const onChangeRegion = ({ value }) => {
    setEditState((prevState) => ({
      ...prevState,
      region: value,
    }))
  }

  const onChangeManager = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      direct_manager: opt?.value || '',
    }))
  }

  const onChangeStartDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      starts_at: value,
      requested_starts_at: value,
    }))
  }

  const onChangeEndDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: value,
    }))
  }
  const onRemove = useCallback(() => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: null,
    }))
  }, [])

  const onSaveEdit = () => {
    if (!editState?.starts_at) return
    const data = {
      job: {
        is_permanent: editState.is_permanent,
        ends_at: editState.ends_at && !editState.is_permanent ? moment(editState.ends_at).format('YYYY-MM-DD') : null,
        starts_at: moment(editState.starts_at).format('YYYY-MM-DD'),
        requested_starts_at: moment(editState.requested_starts_at).format('YYYY-MM-DD'),
        position: editState.position,
        position_description: editState.position_description,
      },
      department: editState.department,
      direct_manager: editState.direct_manager,
      region: editState.region,
    }

    if (hasOccupations && editState.occupation) {
      data.job.occupation = editState.occupation
    }

    putJobDetail.mutate({ id: employee.id, data })
  }

  const handleCheckChange = (event) => {
    const { name, checked } = event.target
    setEditState((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  return (
    <div style={{ maxWidth: 540 }}>
      <div className="d-flex flex-column mb-3">
        <div className="d-flex align-items-center justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black">Job details</Typography>
          <Button
            data-testid="JobDetails-08D024"
            priority="secondary"
            size="small"
            onClick={() => setVisibleEdit(true)}
          >
            Edit
          </Button>
        </div>
        <List
          lists={[
            ...(hasRegions ? [{ value: employee?.region?.name || '-', label: 'Region' }] : []),
            ...(hasOccupations ? [{ value: employee?.job?.occupation?.name || '-', label: 'Occupation type' }] : []),
            ...getJobDetails(employee),
          ]}
        />
        {salaryChange && <SalaryChangeDetails salaryChange={salaryChange} />}
      </div>
      {employee.contract_type === 'contractor' && (
        <div className="d-flex flex-column mb-3">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <Typography className="heading_semibold__20 color_black">Compensation</Typography>
          </div>
          <List lists={getCompensationInfo(employee)} />
        </div>
      )}
      <EditModal
        visible={visibleEdit}
        title="Job details"
        onSave={onSaveEdit}
        loading={putJobDetail.isLoading}
        closeModal={() => setVisibleEdit(false)}
      >
        <div className="w-100 remo-form-input" ref={dropdownRef}>
          <Dropdown
            open={dropdownOpenState.value}
            trigger={
              <Input
                data-testid="JobDetails-7E47E5"
                onChange={onChangeEdit}
                name="position"
                label="Job title"
                type="text"
                onFocus={dropdownOpenState.setTrue}
                value={editState?.position}
                placeholder="Enter job title"
              />
            }
            menu={
              (jobDetailsListQuery?.data?.results.length &&
                (editState?.position
                  ? // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results?.filter((f) =>
                      f.name?.toLowerCase().includes(editState.position.toLowerCase())
                    )
                  : // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results || []
                ).map(({ id, name, scope_of_work }) => (
                  <button
                    data-testid="JobDetails-E0C036"
                    key={id}
                    type="button"
                    onClick={() => {
                      dropdownOpenState.setFalse()
                      setEditState((prevState) => ({
                        ...prevState,
                        position: name,
                        position_description: scope_of_work,
                      }))
                    }}
                  >
                    {name}
                  </button>
                ))) ||
              []
            }
          />
        </div>
        <div className="remo-form-input">
          <TextArea
            onChange={onChangeEdit}
            label="Scope of work/Job description"
            maxLength={2000}
            rows={10}
            type="text"
            name="position_description"
            value={editState?.position_description}
          />
        </div>
        {hasRegions && (
          <div className="remo-form-input">
            <Select
              onChange={onChangeRegion}
              name="region"
              data-testid="JobDetailsForm-91332SB1"
              label="Region"
              placeholder="Select"
              options={regions.map((o) => ({ value: o.id, label: o.name }))}
              isRequired
              value={editState?.region}
            />
          </div>
        )}
        {hasOccupations && occupations && (
          <div className="remo-form-input">
            <Select
              onChange={onChangeOccupation}
              name="occupation"
              data-testid="JobDetailsForm-9132SB1"
              label="Occupation type"
              placeholder="Select"
              options={occupations.map((o) => ({ value: o.id, label: o.name }))}
              isRequired
              value={editState?.occupation}
            />
          </div>
        )}
        <div className="remo-form-input">
          <Input
            data-testid="JobDetails-84C263"
            onChange={onChangeEdit}
            label="Department"
            type="text"
            name="department"
            value={editState?.department}
          />
        </div>
        <div className="remo-form-input">
          <Checkbox
            data-testid="JobDetails-AF674A"
            name="is_permanent"
            checked={editState.is_permanent}
            onChange={handleCheckChange}
            label="Contract is permanent"
          />
        </div>
        <div className="remo-form-input">
          <DateInput
            className="mb-3"
            disabled={
              (employee.state === EMPLOYEE_STATES.ACTIVE && employee.contract_type === 'full_time_employee') ||
              employee.state === EMPLOYEE_STATES.INACTIVE
            }
            onChange={onChangeStartDate}
            label="Start date"
            value={editState?.starts_at}
          />
          {!editState?.starts_at && (
            <Typography className="text_regular__14 color_red">Start date is required</Typography>
          )}
        </div>

        {!editState.is_permanent && (
          <div className="remo-form-input">
            <DateInput onChange={onChangeEndDate} label="End date" onRemove={onRemove} value={editState?.ends_at} />
          </div>
        )}
      </EditModal>
    </div>
  )
}
