import { v4 } from 'uuid'

import { Styled } from './dropdown.styles'

export const Dropdown = ({ open, trigger, menu }) => {
  return (
    <Styled.Root>
      {trigger}
      {open ? (
        <Styled.Menu>
          {menu.map((menuItem) => (
            <Styled.MenuItem key={v4()}>{menuItem}</Styled.MenuItem>
          ))}
        </Styled.Menu>
      ) : null}
    </Styled.Root>
  )
}
