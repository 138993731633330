import CurrencyInput from '@atoms/Input/CurrencyInput'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import EmployerBurden from '@components/employer-burden/employer-burden'
import { useApp } from '@core/context'
import { fetchCountriesIdBurden } from '@services/countries.service'
import { omit } from 'lodash'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import styles from './cost-calculator-modal.module.scss'

export const CostCalculatorModal = ({ ...rest }) => {
  const { countries, currencies, profile } = useApp()
  const { control, watch } = useForm()

  const country = watch('country')
  const currency = watch('currency')
  const yearly_salary = watch('salary')

  const { data, isFetching } = useQuery(['cost_calc', yearly_salary, country, currency], {
    enabled: !!country && !!yearly_salary && !!currency,
    keepPreviousData: true,
    queryFn: () =>
      fetchCountriesIdBurden({
        countryId: country.value,
        currency: currency.value,
        yearly_salary,
        company: profile.id,
      }),
  })

  return (
    <Modal dialogClassName={styles.modal} show centered animation={false} {...rest}>
      <Modal.Header closeButton>
        <Modal.Title>Employment cost calculator</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex flex-column gap-2">
          <Controller
            control={control}
            name="country"
            render={({ field }) => (
              <Select
                data-testid="cost-calculator-modal-AEE4EA"
                {...field}
                label="Select Country"
                placeholder="Select country"
                options={countries.map(({ id, name }) => ({
                  value: id,
                  label: name,
                }))}
              />
            )}
          />

          {data && (
            <div>
              {data.is_active ? (
                <NotificationBlock
                  text="The Employment cost calculator for employing a new team member has
          been provided by the local service provider."
                />
              ) : (
                <NotificationBlock
                  text={
                    <span>
                      <b>Please note:</b> The Employment cost calculator is designed to provide an estimate of what the
                      total cost of an employee could be. The figures serve as a guide and should not be treated as
                      guaranteed employment costs.
                    </span>
                  }
                />
              )}
            </div>
          )}

          <div className="d-flex">
            <p>Annual gross salary</p>
            <span className="text-danger ml-1">*</span>
          </div>

          <div className="d-flex justify-content-between" style={{ gap: 10 }}>
            <Controller
              control={control}
              name="salary"
              render={({ field }) => <CurrencyInput {...field} placeholder="Enter gross salary" />}
            />
            <Controller
              control={control}
              name="currency"
              render={({ field }) => (
                <Select
                  data-testid="cost-calculator-modal-EF98D9"
                  {...field}
                  placeholder="Select currency"
                  options={currencies.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                />
              )}
            />
          </div>

          <AbsoluteSpinner isFetching={isFetching}>
            <EmployerBurden currency={currency?.label} employeeBurden={omit(data, 'is_active')} />
          </AbsoluteSpinner>
        </div>
      </Modal.Body>
    </Modal>
  )
}
