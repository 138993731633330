import { useToast } from '@core/hooks/useNotification'
import { useStripe } from '@stripe/react-stripe-js'
import { DotWave } from '@uiball/loaders'
import React, { useEffect } from 'react'
import { useNavigate } from 'react-router-dom'

export const SuccessStripeModal = ({ close, setNewPayment, isLoading, successModalOpen }) => {
  const stripe = useStripe()
  const { successAlert } = useToast()
  const navigate = useNavigate()

  useEffect(() => {
    if (!stripe) {
      return
    }
    // Retrieve the "setup_intent_client_secret" query parameter appended to
    // your return_url by Stripe.js
    const clientSecret = new URLSearchParams(window.location.search).get('setup_intent_client_secret')
    if (clientSecret) {
      // Retrieve the SetupIntent
      stripe.retrieveSetupIntent(clientSecret).then(({ setupIntent: setupIntentProps }) => {
        // Inspect the SetupIntent `status` to indicate the status of the payment
        // to your customer.
        //
        // Some payment methods will [immediately succeed or fail][0] upon
        // confirmation, while others will first enter a `processing` state.
        //
        // [0]: https://stripe.com/docs/payments/payment-methods#payment-notification
        // eslint-disable-next-line default-case
        switch (setupIntentProps.status) {
          case 'succeeded':
            setNewPayment(setupIntentProps)
            // successAlert('Success! Yor payment method has been saved.')
            successModalOpen.setTrue()
            navigate({
              replace: true,
              search: '',
            })
            close()
            break

          case 'processing':
            successAlert("Processing payment details. We'll update you when processing is complete.")

            navigate({
              replace: true,
              search: '',
            })
            close()
            break

          case 'requires_action':
            // Redirect your user back to your payment page to attempt collecting
            // payment again
            window.open(setupIntentProps.next_action.verify_with_microdeposits.hosted_verification_url)
            navigate({
              replace: true,
              search: '',
            })
            close()

            break
        }
      })
    }
  }, [stripe])

  return (
    <div>
      {isLoading && (
        <div className="d-flex flex-grow-1 justify-content-center align-items-center">
          <DotWave />
        </div>
      )}
    </div>
  )
}
