/* eslint-disable react/button-has-type */
import './style.scss'

import classNames from 'classnames'
import React from 'react'

export default function Button({
  children,
  priority = 'primary',
  size = 'large',
  loading = false,
  disabled = false,
  loadingLabel = 'Loading...',
  ...rest
}) {
  return (
    <button
      data-testid="Button-C6443C"
      {...rest}
      className={classNames(['button', `button__${priority}`, `button__${size}`, rest.className])}
      disabled={loading || disabled}
    >
      <span className="label">{loading ? loadingLabel : children}</span>
    </button>
  )
}
