import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { uploadIcon } from '@core/icons/icons'
import { useState } from 'react'
import { useBoolean } from 'usehooks-ts'

import { AllTimesheets } from './all/AllTimesheets'
import { ApprovedTimesheets } from './approved/ApprovedTimesheets'
import { PendingTimesheets } from './pending/PendingTimesheets'
import { RejectedTimesheets } from './rejected/RejectedTimesheets'
import { UploadTimesheetsModal } from './uploadTimesheetsModal/UploadTimesheetsModal'

export const TimesheetsPage = () => {
  const [tab, setTab] = useState(1)
  const isUploadTimesheetsModalOpen = useBoolean(false)

  const onTabChange = (id) => {
    setTab(id)
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <PageTitle>Timesheets</PageTitle>
        <Button className="px-3" onClick={isUploadTimesheetsModalOpen.setTrue} priority="secondary" size="small">
          <Icon icon={uploadIcon} className="mr-1" />
          Upload timesheet
        </Button>
      </div>
      <Tabs className="mt-4" selectedTab={tab} onTabChange={onTabChange}>
        <Tab tabId={1} title="All">
          <AllTimesheets />
        </Tab>
        <Tab tabId={2} title="Pending">
          <PendingTimesheets />
        </Tab>
        <Tab tabId={3} title="Approved">
          <ApprovedTimesheets />
        </Tab>
        <Tab tabId={4} title="Rejected">
          <RejectedTimesheets />
        </Tab>
      </Tabs>
      {isUploadTimesheetsModalOpen.value && <UploadTimesheetsModal onClose={isUploadTimesheetsModalOpen.setFalse} />}
    </>
  )
}
