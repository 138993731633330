import { Api } from '@api/Api'
import EditAvatar from '@atoms/EditAvatar/EditAvatar'
import { EditForm } from '@atoms/EditModal/EditModalForm'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import { AppContext } from '@core/context'
import React, { useContext, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'

export default ({ profile, currencies, countries, onSave }) => {
  const { refetchProfile } = useContext(AppContext)
  const [avatarImage, setAvatarImage] = useState()

  const defaultCurrency = currencies.find((v) => v.id === profile?.default_currency?.id)

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profile.name || '',
      head_quarters: profile.head_quarters || '',
      default_currency: defaultCurrency ? defaultCurrency.id : '',
      living_country: profile.legal_address?.living_country?.id || '',
      street_address: profile.legal_address?.street_address || '',
      state: profile.legal_address?.state || '',
      city: profile.legal_address?.city || '',
      postal_code: profile.legal_address?.postal_code || '',
    },
  })

  const onSubmit = ({ name, state, city, head_quarters, street_address, postal_code, ...rest }) => {
    const living_country = rest.living_country.value || rest.living_country
    const default_currency = rest.default_currency.value || rest.default_currency

    const payload = {
      name,
      head_quarters,
      default_currency,
      legal_address: {
        living_country,
        state,
        city,
        street_address,
        postal_code,
      },
    }
    onSave(payload)
  }

  const mutationUploadAvatar = async (file) => {
    const fd = new FormData()
    fd.append('file', file)

    return Api.post('/profile/avatar/', fd, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  }

  const uploadProfileAvatar = useMutation(mutationUploadAvatar, {
    onSuccess: (_, imageFile) => {
      refetchProfile()
      setAvatarImage(URL.createObjectURL(imageFile))
      toast.success('Your profile image has been updated successfully')
    },
  })

  const onAvatarUpload = (file) => uploadProfileAvatar.mutate(file)

  return (
    <EditForm className="d-flex flex-column w-100" onSubmit={handleSubmit(onSubmit)}>
      <EditAvatar
        src={avatarImage || profile?.user?.profile?.avatar}
        isLoading={mutationUploadAvatar.isLoading}
        className="personal-info-edit-avatar mb-4"
        onUpload={onAvatarUpload}
      />
      <div className="remo-form-input">
        <Input data-testid="ProfileEditForm-95B5EA" label="Company name" {...register('name')} />
      </div>
      <div className="remo-form-input">
        <Input data-testid="ProfileEditForm-55E1F0" label="Headquarters" {...register('head_quarters')} />
      </div>
      <div className="remo-form-input">
        <Controller
          name="living_country"
          control={control}
          render={({ field }) => {
            return (
              <Select
                data-testid="ProfileEditForm-DEC805"
                id="living_country"
                placeholder="e.g United Kingdom"
                label="Country"
                options={countries.map((country) => ({
                  value: country.id,
                  label: country.name,
                }))}
                {...field}
              />
            )
          }}
        />
      </div>

      <div className="remo-form-input">
        <Input data-testid="ProfileEditForm-3343B1" label="State" {...register('state')} />
      </div>
      <div className="remo-form-input">
        <Input data-testid="ProfileEditForm-553A9B" label="City" {...register('city')} />
      </div>
      <div className="remo-form-input">
        <Input data-testid="ProfileEditForm-8FCDF7" label="Address" {...register('street_address')} />
      </div>
      <div className="remo-form-input">
        <Input data-testid="ProfileEditForm-CCF3FE" label="Postal code" {...register('postal_code')} />
      </div>
    </EditForm>
  )
}
