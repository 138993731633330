import CustomTable from '@atoms/Table/CustomTable'
import usePagination from '@core/hooks/usePagination'
import { useNavigate } from 'react-router-dom'

import { getTimesheetFields, TIMESHEETS } from '../mock'

export const RejectedTimesheets = () => {
  const navigate = useNavigate()

  const { page, limit, setPage } = usePagination({ page: 1, limit: 5 })

  const allRejectedTimesheets = TIMESHEETS.filter((item) => item.status === 'rejected')

  const timesheets = allRejectedTimesheets.slice((page - 1) * limit, page * limit)

  const handleView = (id) => {
    navigate(`${id}`)
  }

  return (
    <CustomTable
      fields={getTimesheetFields(handleView)}
      data={timesheets}
      onPage={setPage}
      page={page}
      total={allRejectedTimesheets.length}
      pageSize={limit}
    />
  )
}
