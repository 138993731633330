import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import * as icons from '@core/icons/icons'
import { EmployeeStatusTooltip } from '@features/team/employee-status-tooltip/employee-status-tooltip'
import React from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'

import { EmployeeSubStatusTooltip } from '../../features/team/employee-sub-status-tooltip/employee-sub-status-tooltip'
import { parseDaysToStart } from './parse'

export const employeeFields = [
  {
    title: '#',
    key: 'number',
    type: 'string',
    maxWidth: 48,
    minWidth: 48,
    width: 48,
  },
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 300,
    add_key: 'job_position',
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip-large">
            <EmployeeStatusTooltip />
          </Tooltip>
        }
      >
        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
          Status <Icon icon={icons.infoIcon} fill="#878787" />
        </span>
      </OverlayTrigger>
    ),
    key: 'status',
    type: 'custom',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
    render: (item) => <Status status={item.state} />,
  },
  {
    title: (
      <OverlayTrigger
        placement="bottom"
        overlay={
          <Tooltip className="tooltip-large">
            <EmployeeSubStatusTooltip />
          </Tooltip>
        }
      >
        <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
          Sub-status <Icon icon={icons.infoIcon} fill="#878787" />
        </span>
      </OverlayTrigger>
    ),
    key: 'sub_state',
    type: 'custom',
    render: (item) =>
      item.sub_state === 'dummy' ? (
        '-'
      ) : (
        <div className="status status_default">{item.sub_state.toString().replace('_', ' ')}</div>
      ),
    maxWidth: 150,
    minWidth: 150,
  },
]

export const contractorFields = [
  {
    title: '#',
    key: 'number',
    type: 'string',
    maxWidth: 48,
    minWidth: 48,
    width: 48,
  },
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 300,
    add_key: 'job_position',
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Receiving currency',
    key: 'receiving_currency',
    type: 'string',
    maxWidth: 200,
    width: 200,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Line manager',
    key: 'direct_manager.full_name',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
]

export const allFields = [
  {
    title: '#',
    key: 'number',
    type: 'string',
    maxWidth: 48,
    minWidth: 48,
    width: 48,
  },
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 300,
    add_key: 'job_position',
    minWidth: 300,
    width: 300,
  },
  {
    title: 'Type',
    key: 'type',
    type: 'string',
    maxWidth: 100,
    minWidth: 100,
    width: 100,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'string',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
  },
  {
    title: 'Sub-status',
    key: 'sub_state',
    type: 'custom',
    render: (item) =>
      item.sub_state === 'dummy' ? (
        '-'
      ) : (
        <div className="status status_default">{item.sub_state.toString().replace('_', ' ')}</div>
      ),
    maxWidth: 150,
    minWidth: 150,
  },
]

export const onboardingFields = [
  {
    title: 'Name',
    key: 'full_name',
    type: 'string',
    maxWidth: 500,
    add_key: 'job_position',
    minWidth: 500,
    width: 500,
  },
  {
    title: 'Start date',
    key: 'start_at',
    type: 'custom',
    render: (item) => (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <span>{item.start_at}</span>
        <span style={{ color: '#FFA530' }}>{`In ${parseDaysToStart(item.start_at)} days`}</span>
      </div>
    ),
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Type',
    key: 'type',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Location',
    key: 'location',
    type: 'string',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
  },
  {
    title: 'Salary',
    key: 'salary',
    type: 'string',
    maxWidth: 200,
    minWidth: 200,
    width: 200,
  },
  {
    title: 'Sub-Status',
    key: 'status',
    type: 'custom',
    render: (item) => (item.sub_state === 'dummy' ? '-' : <Status status={item.sub_state} />),
    maxWidth: 150,
    minWidth: 150,
  },
]
