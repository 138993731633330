import Button from '@atoms/Button/Button'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

export const RejectTimesheetModal = ({ show, onClose, onConfirm, isLoading }) => {
  const {
    register,
    formState: { errors },
    handleSubmit,
    reset,
  } = useForm()

  const handleConfirm = (formValues) => {
    onConfirm(formValues)
    reset()
  }

  const handleCloseModal = () => {
    onClose()
    reset()
  }

  return (
    <Modal centered show={show} onHide={handleCloseModal} animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Reason for rejection</Typography>
      </Modal.Header>
      <Modal.Body>
        <form onSubmit={handleSubmit(handleConfirm)}>
          <TextArea
            {...register('rejection_reason', {
              required: 'Rejection reason is required',
            })}
            isRequired
            label="Please provide reason rejecting contractor’s timesheet"
            placeholder="Enter"
            rows={2}
          />
          {errors.rejection_reason && (
            <Typography className="text_regular__14 color_red">{errors.rejection_reason.message}</Typography>
          )}
          <div className="d-flex justify-content-end mt-5">
            <Button priority="secondary" size="small" className="mr-3" onClick={handleCloseModal}>
              Cancel
            </Button>
            <Button disabled={isLoading} priority="primary_dangerous" size="small" type="submit">
              Reject timesheet
            </Button>
          </div>
        </form>
      </Modal.Body>
    </Modal>
  )
}
