import './Table.scss'

import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import Papa from 'papaparse'
import { useState } from 'react'

/* eslint-disable react/no-array-index-key */
export const IdentifyColumnTable = ({ csvColumns, csvData, csvFile, onNext, onReUpload }) => {
  const [emailColumn, setEmailColumn] = useState(null)
  const [totalHoursColumn, setTotalHoursColumn] = useState(null)
  const [errorMessage, setErrorMessage] = useState('')

  const handleEmailSelection = (index) => {
    setEmailColumn(index)
    if (totalHoursColumn !== null) {
      setErrorMessage('')
    }
  }

  const handleTotalHoursSelection = (index) => {
    setTotalHoursColumn(index)
    if (emailColumn !== null) {
      setErrorMessage('')
    }
  }

  const handleNextClick = () => {
    if (emailColumn === null || totalHoursColumn === null) {
      setErrorMessage('Please select both Email and Total hours columns.')
      return
    }
    setErrorMessage('')

    Papa.parse(csvFile, {
      header: true,
      complete: (result) => {
        const emails = result.data.map((row) => row[csvColumns[emailColumn]] || 'N/A')
        const totalHours = result.data.map((row) => row[csvColumns[totalHoursColumn]] || 'N/A')

        const timesheetFields = emails.map((email, index) => ({
          email,
          totalHours: totalHours[index],
        }))

        onNext({
          timesheetFields,
        })
      },
    })
  }

  return (
    <div>
      <Typography className="text_medium__16 color_gray mb-2">
        Identify email and total hours column <span style={{ color: 'red' }}>*</span>
      </Typography>
      {errorMessage && <Typography className="text_regular__14 color_red">{errorMessage}</Typography>}
      <table className="mb-5">
        <thead>
          <tr style={{ height: 40 }}>
            <th>Data columns</th>
            <th>Value</th>
            <th style={{ textAlign: 'center' }}>Email</th>
            <th style={{ textAlign: 'center' }}>Total hours</th>
          </tr>
        </thead>
        <tbody>
          {csvColumns.map((columnName, index) => (
            <tr key={index}>
              <td>{columnName}</td>
              <td>{csvData[0] ? csvData[0][columnName] : '-'}</td>
              <td className="radio-btn-cell">
                <input
                  type="radio"
                  name="emailColumn"
                  checked={emailColumn === index}
                  onChange={() => handleEmailSelection(index)}
                  className="radio-btn"
                />
              </td>
              <td className="radio-btn-cell">
                <input
                  type="radio"
                  name="totalHoursColumn"
                  checked={totalHoursColumn === index}
                  onChange={() => handleTotalHoursSelection(index)}
                  className="radio-btn"
                />
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      <div className="d-flex pb-5 justify-content-center gap-4">
        <Button onClick={onReUpload} priority="secondary" className="align-self-end">
          Re-upload
        </Button>
        <Button onClick={handleNextClick} type="submit" className="align-self-end">
          Next
        </Button>
      </div>
    </div>
  )
}
