import { Api } from '@api/Api'

export const getThreads = async ({ state, search, limit, offset }) => {
  const queries = new URLSearchParams({ state, offset, limit })
  if (search) {
    queries.set('search', search)
  }
  const { data } = await Api.get(`threads/?${queries}`)
  return data
}

export const updateThread = async (id, body) => {
  const { data } = await Api.patch(`threads/${id}/update/`, body)
  return data
}

export const fetchCreateNewThread = async (body) => {
  const { data } = await Api.post(`threads/create/`, body)
  return data
}

export const fetchThreadMessages = async (threadId, limit, offset) => {
  const { data } = await Api.get(`threads/${threadId}/messages/?limit=${limit}&offset=${offset}`)
  return data
}

export const fetchPostMessage = async (threadId, body) => {
  const { data } = await Api.post(`threads/${threadId}/messages/create/`, body)

  return data
}

export const fetchSendDocument = async (threadId, file) => {
  const fd = new FormData()
  fd.append('document_meta', file)

  const { data } = await Api.post(`threads/${threadId}/documents/create/`, fd)
  return data
}

export const fetchThreadUsers = async () => {
  const { data } = await Api.get(`threads/users/`)
  return data
}

export const fetchActiveAndClosedthreads = async () => {
  const [activeResult, resolvedResults] = await Promise.all([
    getThreads({
      state: 'ACTIVE',
      limit: 500,
      offset: 0,
    }),
    getThreads({
      state: 'RESOLVED',
      limit: 500,
      offset: 0,
    }),
  ])

  return activeResult.total_unread_messages_count + resolvedResults.total_unread_messages_count || null
}
