import Typography from '@atoms/Typography/Typography'
import React from 'react'
import styled from 'styled-components'

export const RecoveryKeys = ({ recoveryCodes }) => {
  return (
    <HorizontalWrapper>
      <div className="mr-3">
        {recoveryCodes.map((key, index) => (
          <Typography className="heading_semibold__16" key={`${index + 1}`}>
            {key}
          </Typography>
        ))}
      </div>
    </HorizontalWrapper>
  )
}

const HorizontalWrapper = styled.div`
  display: flex;
  column-gap: 12px;
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 25px 50px 25px 50px;
  align-items: center;
  justify-content: center;
  .heading_semibold__16 {
    line-height: 32px;
  }
`
