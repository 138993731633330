import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { CardStepper } from '@components/card-stepper/card-stepper.component'
import { CardStepperStep } from '@components/card-stepper/card-stepper-step.component'
import { COMPANY_STATES } from '@core/constants'
import { useApp } from '@core/context'
import * as icons from '@core/icons/icons'
import { SignMsa } from '@features/sign-msa/sign-msa.component'
import CreateEmployeeModal from '@pages/employees/create-employee/CreateEmployeeModal'
import { fetchCreateContractor } from '@store/contracts'
import React, { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { AboutDepositModal } from './about-deposit-modal'
import { CreateSuccessModal } from './create-success-modal/create-success-modal.component'
import { MsaInfoModal } from './msa-info-modal/msa-info-modal.component'

export const DashboardFte = () => {
  const { profile } = useApp()
  const signMsaOpen = useBoolean(false)
  const msaInfoModalOpen = useBoolean(false)
  const addEmployeeOpen = useBoolean(false)
  const depositOpen = useBoolean(false)

  const fetchProfileQuery = useQueryClient()
  const [employeeResponse, setEmployeeResponse] = useState(null)

  const createEmployeeMutation = useMutation({
    mutationFn: (payload) => fetchCreateContractor(payload),
    onSuccess: (data) => {
      addEmployeeOpen.setFalse()
      fetchProfileQuery.refetchQueries('fetchProfile')
      setEmployeeResponse(data)
    },
  })

  const handleCreateEmployee = (payload) => {
    createEmployeeMutation.mutate({
      ...payload,
      company: profile.id,
    })
  }

  return (
    <>
      <CardStepper>
        <CardStepperStep
          title="Master service agreement"
          completed={profile?.msa_agreement_document_signed}
          description={
            <p>
              Sign the MSA agreement. After signing, you can start adding your first FTE on the platform. Learn more
              about the{' '}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                className="color_primary text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={msaInfoModalOpen.setTrue}
              >
                MSA
              </span>
            </p>
          }
          actionsPlaceholderText={profile?.msa_agreement_document_signed && 'Signed'}
          actions={[
            <Button
              data-testid="dashboard-fte.component-C5B61E"
              key={1}
              type="button"
              size="small"
              priority="secondary"
              onClick={signMsaOpen.setTrue}
            >
              Sign
            </Button>,
          ]}
        />
        <CardStepperStep
          title="Add your first Full-time employee (FTE)"
          completed={profile?.has_employee}
          description={
            <p>Invite your first FTE to the platform and sign the appendix with local EOR Service provider.</p>
          }
          disabled={!profile?.has_employee && profile?.state !== COMPANY_STATES.MSA_SIGNED}
          disabledText="This step will be available once you finish signing the MSA"
          actionsPlaceholderText={profile?.has_employee && 'Added'}
          actions={[
            <Button
              data-testid="dashboard-fte.component-9742DD"
              key={1}
              type="button"
              size="small"
              priority="secondary"
              onClick={addEmployeeOpen.setTrue}
            >
              <Icon icon={icons.add} className="mr-2" />
              Add employee
            </Button>,
          ]}
        />
        <CardStepperStep
          title="Pay a deposit"
          disabled={!profile?.has_employee}
          description={
            <p>
              Once you add an FTE, you will receive a deposit invoice in 1-3 days. Learn more about the{' '}
              {/* eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */}
              <span
                className="color_primary text-decoration-underline"
                style={{ cursor: 'pointer' }}
                onClick={depositOpen.setTrue}
              >
                deposit
              </span>
            </p>
          }
          completed={profile?.state === COMPANY_STATES.ACTIVE}
        />
      </CardStepper>
      {signMsaOpen.value && <SignMsa onClose={signMsaOpen.setFalse} />}
      {addEmployeeOpen.value && (
        <CreateEmployeeModal
          loading={createEmployeeMutation.isLoading}
          onClose={addEmployeeOpen.setFalse}
          onSave={handleCreateEmployee}
        />
      )}

      {depositOpen.value && <AboutDepositModal onHide={depositOpen.setFalse} />}
      {msaInfoModalOpen.value && <MsaInfoModal modalState={msaInfoModalOpen} />}
      {employeeResponse && <CreateSuccessModal employee={employeeResponse} />}
    </>
  )
}
