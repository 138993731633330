import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import DateInput from '@atoms/DateInput/DateInput'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { getCompensationInfo, getJobDetails } from '@pages/employeeDetailPage/mock'
import { parseDetailsToConfig } from '@pages/employees/create-employee/forms/probation-period.utils'
import ProbationPeriodInfo from '@pages/employees/create-employee/forms/ProbationPeriodInfo'
import { getJobDetailsList } from '@services/contract.service'
import { patchContractById } from '@store/contracts'
import moment from 'moment'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { SalaryChangeDetails } from '../JobDetails/SalaryChangeDetails'

export function JobDetailsFte({ employee, setEmployee, occupations, regions, probationPeriodDetails }) {
  const dropdownRef = useRef(null)
  const dropdownOpenState = useBoolean(false)
  const [visibleEdit, setVisibleEdit] = useState(false)

  const [editState, setEditState] = useState({
    position: employee.job.position,
    position_description: employee.job.position_description,
    department: employee.department,
    is_permanent: employee.job.is_permanent,
    ends_at: employee.job.ends_at ? new Date(employee.job.ends_at) : null,
    starts_at: new Date(employee.job.starts_at),
    requested_starts_at: new Date(employee.job.requested_starts_at),
    occupation: employee?.job?.occupation?.id,
    region: employee?.region?.id,
    probation_period: employee?.job?.probation_period,
    employment_type: employee?.job?.employment_type,
  })

  const [probationPeriodConfig, setProbationPeriodConfig] = useState(null)

  useEffect(() => {
    const newConfig = parseDetailsToConfig(
      probationPeriodDetails,
      editState?.starts_at,
      editState?.ends_at,
      editState?.is_permanent
    )
    setProbationPeriodConfig(newConfig)
  }, [editState?.is_permanent, editState?.starts_at, editState?.ends_at, probationPeriodDetails])

  const hasOccupations = useMemo(() => !!occupations && occupations.length > 0, [occupations])
  const hasRegions = useMemo(() => !!regions && regions.length > 0, [regions])

  const jobDetailsListQuery = useQuery({
    queryKey: [getJobDetailsList.key],
    queryFn: getJobDetailsList.fetch,
  })

  const putJobDetail = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {
      setEmployee(res.data)
      toast.success('Successfully updated')
      setVisibleEdit(false)
    },
  })

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }

  const onChangeOccupation = ({ value }) => {
    setEditState((prevState) => ({
      ...prevState,
      occupation: value,
    }))
  }

  const onChangeRegion = ({ value }) => {
    setEditState((prevState) => ({
      ...prevState,
      region: value,
    }))
  }

  const onChangeManager = (opt) => {
    setEditState((prevState) => ({
      ...prevState,
      direct_manager: opt?.value || '',
    }))
  }

  const onChangeStartDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      starts_at: value,
      requested_starts_at: value,
    }))
  }

  const onChangeEndDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: value,
    }))
  }
  const onChangeProbation = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      probation_period: e.target.value,
    }))
  }

  const onRemove = useCallback(() => {
    setEditState((prevState) => ({
      ...prevState,
      ends_at: null,
    }))
  }, [])

  const handleEmploymentTypeChange = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      employment_type: e.target.value,
    }))
  }

  const onSaveEdit = () => {
    if (
      !editState?.starts_at ||
      !editState?.probation_period ||
      editState.probation_period < probationPeriodConfig.min ||
      editState.probation_period > probationPeriodConfig.max
    )
      return
    const data = {
      job: {
        is_permanent: editState.is_permanent,
        ends_at: editState.ends_at && !editState.is_permanent ? moment(editState.ends_at).format('YYYY-MM-DD') : null,
        starts_at: moment(editState.starts_at).format('YYYY-MM-DD'),
        requested_starts_at: moment(editState.requested_starts_at).format('YYYY-MM-DD'),
        position: editState.position,
        position_description: editState.position_description,
        probation_period: editState?.probation_period,
      },
      department: editState.department,
      direct_manager: editState.direct_manager,
      region: editState.region,
    }

    if (hasOccupations && editState.occupation) {
      data.job.occupation = editState.occupation
    }

    if (employee.state !== 'created') {
      delete data.job.probation_period
    }

    putJobDetail.mutate({ id: employee.id, data })
  }

  const handleCheckChange = (event) => {
    const { name, checked } = event.target
    setEditState((prevState) => ({
      ...prevState,
      [name]: checked,
    }))
  }

  return (
    <div style={{ maxWidth: 540 }}>
      <div className="w-100 remo-form-input" ref={dropdownRef}>
        <Dropdown
          open={dropdownOpenState.value}
          trigger={
            <Input
              data-testid="JobDetails-7E47E5"
              onChange={onChangeEdit}
              name="position"
              label="Job title"
              type="text"
              onFocus={dropdownOpenState.setTrue}
              value={editState?.position}
              placeholder="Enter job title"
              disabled={!visibleEdit}
            />
          }
          menu={
            (jobDetailsListQuery?.data?.results.length &&
              (editState?.position
                ? // eslint-disable-next-line no-unsafe-optional-chaining
                  jobDetailsListQuery.data.results?.filter((f) =>
                    f.name?.toLowerCase().includes(editState.position.toLowerCase())
                  )
                : // eslint-disable-next-line no-unsafe-optional-chaining
                  jobDetailsListQuery.data.results || []
              ).map(({ id, name, scope_of_work }) => (
                <button
                  data-testid="JobDetails-E0C036"
                  key={id}
                  type="button"
                  onClick={() => {
                    dropdownOpenState.setFalse()
                    setEditState((prevState) => ({
                      ...prevState,
                      position: name,
                      position_description: scope_of_work,
                    }))
                  }}
                >
                  {name}
                </button>
              ))) ||
            []
          }
        />
      </div>
      <div className="remo-form-input">
        <TextArea
          onChange={onChangeEdit}
          label="Scope of work/Job description"
          maxLength={2000}
          rows={10}
          type="text"
          name="position_description"
          value={editState?.position_description}
          disabled={!visibleEdit}
        />
      </div>
      {hasRegions && (
        <div className="remo-form-input">
          <Select
            onChange={onChangeRegion}
            name="region"
            data-testid="JobDetailsForm-91332SB1"
            label="Region"
            placeholder="Select"
            options={regions.map((o) => ({ value: o.id, label: o.name }))}
            isRequired
            value={editState?.region}
            isDisabled={!visibleEdit}
          />
        </div>
      )}
      {hasOccupations && occupations && (
        <div className="remo-form-input">
          <Select
            onChange={onChangeOccupation}
            name="occupation"
            data-testid="JobDetailsForm-9132SB1"
            label="Occupation type"
            placeholder="Select"
            options={occupations.map((o) => ({ value: o.id, label: o.name }))}
            isRequired
            value={editState?.occupation}
            disabled={!visibleEdit}
          />
        </div>
      )}
      <div className="remo-form-input">
        <Input
          data-testid="JobDetails-84C263"
          onChange={onChangeEdit}
          label="Department"
          type="text"
          name="department"
          value={editState?.department}
          disabled={!visibleEdit}
        />
      </div>
      <div className="remo-form-input">
        <Checkbox
          data-testid="JobDetails-AF674A"
          name="is_permanent"
          checked={editState.is_permanent}
          onChange={handleCheckChange}
          label="Contract is permanent"
          disabled={!visibleEdit}
        />
      </div>
      <div className="remo-form-input">
        <RadioButton
          label="Employment type"
          layout="horizontal"
          options={[
            {
              id: 'full_time',
              text: 'Full-time',
              value: 'full_time',
            },
            {
              id: 'part_time',
              text: 'Part-time',
              value: 'part_time',
            },
          ]}
          value={editState.employment_type}
          onChange={handleEmploymentTypeChange}
          disabled
        />
      </div>
      <div className="remo-form-input">
        <DateInput
          className="mb-3"
          disabled={
            !visibleEdit ||
            (employee.state === 'active' && employee.contract_type === 'full_time_employee') ||
            employee.state === 'inactive'
          }
          onChange={onChangeStartDate}
          label="Start date"
          value={editState?.starts_at}
        />
        {!editState?.starts_at && (
          <Typography className="text_regular__14 color_red">Start date is required</Typography>
        )}
      </div>

      {!editState.is_permanent && (
        <div className="remo-form-input">
          <DateInput
            onChange={onChangeEndDate}
            label="End date"
            onRemove={onRemove}
            value={editState?.ends_at}
            disabled={!visibleEdit}
          />
        </div>
      )}

      {probationPeriodConfig && !probationPeriodConfig.noProbation && (
        <div className="remo-form-input">
          {probationPeriodConfig.applicable ? (
            <>
              <Input
                disabled={!visibleEdit || employee.state !== 'created'}
                value={editState?.probation_period}
                onChange={onChangeProbation}
                label="Probation period"
                type="number"
                min={probationPeriodConfig?.min || 0}
                max={probationPeriodConfig?.max || 0}
                tooltip={
                  <Tooltip
                    id={v4()}
                    clickable
                    content={
                      <ProbationPeriodInfo
                        probationDetails={probationPeriodDetails}
                        probationPeriodConfig={probationPeriodConfig}
                        isPermanent={employee.job.is_permanent}
                      />
                    }
                  />
                }
                addText={
                  probationPeriodConfig?.applicable && !Number.isNaN(probationPeriodConfig?.min)
                    ? `Per local labor laws, the minimum is ${probationPeriodConfig.min} days and the maximum is ${probationPeriodConfig.max} days.`
                    : null
                }
              />
              {(!editState.probation_period ||
                editState.probation_period < probationPeriodConfig.min ||
                editState.probation_period > probationPeriodConfig.max) && (
                <Typography className="text_regular__14 color_red">Probation period is not in range</Typography>
              )}
            </>
          ) : (
            <>
              <Typography className="text_medium__14 d-flex align-items-center gap-1 remo-form-label">
                Probation period
              </Typography>
              <NotificationBlock
                render={
                  <Typography className="text_light__12">
                    {`The probationary period does not apply. ${probationPeriodConfig.note || ''}`}
                  </Typography>
                }
              />
            </>
          )}
        </div>
      )}

      <div className="d-flex align-items-center gap-2">
        {!visibleEdit ? (
          <Button data-testid="Personal-7A0795" priority="secondary" size="small" onClick={() => setVisibleEdit(true)}>
            Edit
          </Button>
        ) : (
          <>
            <Button
              data-testid="Personal-cancel"
              priority="secondary"
              size="small"
              onClick={() => setVisibleEdit(false)}
            >
              Cancel
            </Button>
            <Button
              data-testid="Personal-7A0795"
              priority="primary"
              size="small"
              onClick={onSaveEdit}
              disabled={putJobDetail.isLoading}
            >
              Save
            </Button>
          </>
        )}
      </div>
    </div>
  )
}
