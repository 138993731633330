import Button from '@atoms/Button/Button'
import React from 'react'
import { Modal } from 'react-bootstrap'

export const CompleteOnboardingModal = ({ onClose }) => {
  return (
    <Modal show onHide={onClose} centered animation={false}>
      <Modal.Header closeButton className="mb-0" />
      <Modal.Body className="pt-0">
        <div className="d-flex align-items-center justify-content-center mb-3">
          <img src="/assets/img/document-sign.png" alt="Complete checklist" style={{ width: '100px' }} />
        </div>
        <p className="heading_semibold__20 mb-2 text-center">Please complete your account set up checklist</p>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="complete-onboarding-modal.component-F73B87"
          size="medium"
          className="w-100"
          onClick={onClose}
        >
          OK
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
