/* eslint-disable consistent-return */
/* eslint-disable default-case */
import Stepper from '@atoms/Stepper/Stepper'
import { useApp } from '@core/context'
import { PersonalProfile } from '@pages/onboarding/personal-profile'
import { fetchPatchOnboarding } from '@services/app.service'
import React from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useAuth } from 'src/AuthProvider'

import { BillingContacts } from './billing-contacts'
import css from './onboarding.module.scss'
import { SuccessOnboarding } from './success-onboarding'

const getTitle = (step = 1) => {
  switch (step) {
    case 1:
      return {
        title: <span className={css.aside__title}>Complete your registration</span>,
        description: (
          <span className={css.aside_description}>Check your account information and add more details if needed</span>
        ),
      }

    case 2:
      return {
        title: <span className={css.aside__title}>Add billing contacts</span>,
        description: (
          <span className={css.aside_description}>
            Assign the person responsible for invoices and payments in your company
          </span>
        ),
      }
  }
}

export default function OnboardingPage() {
  const { profile } = useApp()
  const [activeStep, setActiveStep] = React.useState(1)

  const form = useForm({
    defaultValues: {
      company: {
        legal_address: {
          city: profile?.legal_address?.city || '',
          street_address: profile?.legal_address?.street_address || '',
          postal_code: profile?.legal_address?.postal_code || '',
          address_line: profile?.legal_address?.address_line || '',
          state: profile?.legal_address?.state || '',
        },
        title: profile?.title || '',
        head_quarters: profile?.head_quarters || '',
        default_currency: profile?.default_currency?.id || '',
      },
      profile: {
        phone_number: profile?.user?.profile?.phone_number || '',
      },
      billing_contacts: [],
    },
  })

  const { logout } = useAuth()
  const handleNext = () => setActiveStep((prev) => prev + 1)

  const onboardingMutation = useMutation({
    mutationFn: fetchPatchOnboarding,
  })

  const handleSubmit = () => {
    onboardingMutation.mutate(form.getValues())
  }

  return (
    <FormProvider {...form}>
      <Stepper
        activeStep={activeStep}
        title={getTitle(activeStep).title}
        description={getTitle(activeStep).description}
        logout={logout}
      >
        <Stepper.Step step="1" title="Review account information" setStep={setActiveStep}>
          <Stepper.StepContent header={false}>
            {profile && (
              <PersonalProfile profile={profile} onNext={handleNext} loading={onboardingMutation.isLoading} />
            )}
          </Stepper.StepContent>
        </Stepper.Step>

        <Stepper.Step step="2" title="Add billing contacts">
          <Stepper.StepContent header={false}>
            {!onboardingMutation.isSuccess && (
              <BillingContacts loading={onboardingMutation.isLoading} onSubmit={handleSubmit} />
            )}
            {onboardingMutation.isSuccess && <SuccessOnboarding />}
          </Stepper.StepContent>
        </Stepper.Step>
      </Stepper>
    </FormProvider>
  )
}
