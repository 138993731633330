import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import UploadButton from '@atoms/UploadButton'
import { useApp } from '@core/context'
import { useUploadFile } from '@core/hooks/useUploadFile'
import React from 'react'
import { useForm } from 'react-hook-form'

import { Form, FormControl, FormGroup } from './authorized-yes-form.styles'

export const AuthorizedYesForm = ({ formRef, onSubmit }) => {
  const { userProfile, profile } = useApp()
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      isAuthorizedPerson: 'true',
    },
  })
  const { file, upload, reset } = useUploadFile()

  const transformBeforeSubmit = (data) => {
    const payload = {
      user: userProfile?.id,
      legal_registration_document: file?.file_uuid,
      legal_registration_document_name: data.documentName,
      company: profile?.id,
    }
    onSubmit(payload)
  }

  return (
    <Form ref={formRef} onSubmit={handleSubmit(transformBeforeSubmit)}>
      <FormGroup>
        <FormControl>
          <Input
            data-testid="authorized-yes-form.component-C1C2E6"
            type="text"
            label="Your full legal name"
            placeholder="John Smith"
            isRequired
            value={`${userProfile?.profile?.first_name} ${userProfile?.profile?.last_name}` || ''}
            disabled
          />
        </FormControl>
        <FormControl>
          <Input
            data-testid="authorized-yes-form.component-ADA1E8"
            {...register(`documentName`, {
              required: 'Title is required',
            })}
            type="text"
            label="Company legal registration document"
            addText="e.g. EIN, Incorporation Certificate or equivalent"
            placeholder="Enter the document name"
            isRequired
          />
          {errors?.documentName && (
            <Typography className="text_regular__14 color_red">{errors?.documentName.message}</Typography>
          )}
          <div>
            <UploadButton
              {...register('document', {
                required: 'Document is required',
              })}
              isLoading={file.isLoading}
              isSuccess={file.success}
              progress={file.progress}
              onRemove={reset}
              onChange={upload}
            />
            {errors.document && (
              <Typography className="text_regular__14 color_red">{errors.document.message}</Typography>
            )}
          </div>
        </FormControl>
      </FormGroup>
    </Form>
  )
}
