import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { MdClose } from 'react-icons/md'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    width: 500px;
    padding: 2.5rem;
  }
  .modal-header {
    align-items: center;
    justify-content: flex-end;
    margin-bottom: 0;
    padding: 0;
  }
  .modal-body {
    padding: 0;
  }
  .modal-footer {
    padding: 0;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const StyledContent = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  text-align: center;
`

const StyledImage = styled.img`
  margin-bottom: 1.5rem;
`

const StyledDescription = styled.div`
  text-align: center;
  margin-top: 0.5rem;
  margin-bottom: 1.5rem;
  font-size: 0.875rem;
  line-height: 150%;
`

const ActionModal = ({ imgSrc, title, description, buttonText, onAction, onClose, loading }) => {
  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header>
        <Button
          data-testid="action-modal.component-10611B"
          priority="secondary"
          type="button"
          size="small"
          style={{ padding: '12px 12px', minWidth: 40 }}
          onClick={onClose}
        >
          <MdClose />
        </Button>
      </Modal.Header>
      <Modal.Body>
        <StyledContent>
          <StyledImage src={imgSrc} alt="Add template" />
          <Typography as="h4" className="heading_semibold__24">
            {title}
          </Typography>
          <StyledDescription>
            <p>{description}</p>
          </StyledDescription>
        </StyledContent>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="action-modal.component-2891E6"
          size="small"
          priority="primary"
          type="button"
          onClick={onAction}
          loading={loading}
          disabled={loading}
        >
          {buttonText}
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}

export default ActionModal
