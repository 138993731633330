import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { deleteIcon, edit, noteIcon } from '@core/icons/icons'
import { formatDate } from '@core/utils'
import { OverlayTrigger, Popover } from 'react-bootstrap'

import fileIconSvg from '../../../../../assets/images/attachment.svg'

export const getMilestoneFields = (handleEdit, handleDelete, currency) => [
  {
    title: 'Milestone',
    key: 'milestone',
    type: 'string',
    maxWidth: 200,
    minWidth: 180,
  },
  {
    title: 'Description',
    key: 'description',
    type: 'custom',
    maxWidth: 200,
    minWidth: 180,
    render: ({ description }) => {
      return description ? (
        <OverlayTrigger
          placement="right"
          overlay={<Popover style={{ maxWidth: 400, width: 'auto', padding: 10 }}>{description}</Popover>}
        >
          <span>
            <Icon icon={noteIcon} />
          </span>
        </OverlayTrigger>
      ) : (
        '-'
      )
    },
  },
  {
    title: 'Deadline',
    type: 'custom',
    key: 'deadline',
    maxWidth: 200,
    minWidth: 180,
    render: ({ deadline }) => {
      return formatDate(deadline)
    },
  },
  {
    title: 'Payment amount',
    key: 'payment_amount',
    type: 'custom',
    maxWidth: 200,
    minWidth: 180,
    render: ({ payment_amount }) => {
      return `${payment_amount} ${currency}`
    },
  },
  {
    title: 'Attachment',
    key: 'attachment.file.name',
    type: 'custom',
    maxWidth: 220,
    minWidth: 200,
    render: (row) => {
      return row.attachment?.file ? (
        <div style={{ display: 'flex', gap: 8 }}>
          <img src={fileIconSvg} alt="" />
          <span style={{ color: '#4C92C7', fontWeight: 500 }}>{row.attachment.file.name}</span>
        </div>
      ) : (
        '-'
      )
    },
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'custom',
    maxWidth: 120,
    minWidth: 60,
    render: (row) => {
      return (
        <div className="d-flex align-items-center">
          <div className="d-flex gap-2 align-items-center mr-2">
            <Button
              onClick={(event) => handleEdit(event, row.milestone_id)}
              className="text_light__12 px-2 pl-0"
              priority="secondary"
              size="small"
              style={{ height: '32px' }}
            >
              <Icon icon={edit} className="mr-2" />
              Edit
            </Button>
            <Button
              onClick={(event) => handleDelete(event, row.milestone_id)}
              className="text_light__12 px-2 pl-0"
              priority="secondary"
              size="small"
              style={{ height: '32px' }}
            >
              <Icon icon={deleteIcon} width={20} />
            </Button>
          </div>
        </div>
      )
    },
  },
]
