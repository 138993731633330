import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import { AppContext } from '@core/context'
import { patchCompany } from '@store/companies'
import React, { useContext } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'

export const CompanyInformation = ({ onNext, isModal }) => {
  const { countries, profile, refetchProfile } = useContext(AppContext)

  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: profile.name,
      ...profile?.legal_address,
      living_country: profile?.legal_address?.living_country?.id,
    },
  })
  const { mutate: fetchUpdateCompany, isLoading: updateLoading } = useMutation({
    mutationFn: ({ id, payload }) => patchCompany(id, payload),
    onSuccess: () => {
      onNext()
      refetchProfile()
    },
  })

  const submit = (formValues) => {
    fetchUpdateCompany({
      id: profile.id,
      payload: {
        name: formValues.name,
        legal_address: {
          ...formValues,
          living_country: formValues.living_country?.value || formValues.living_country,
        },
      },
    })
  }

  return (
    <form className="d-flex flex-column h-100 employees-page__form" onSubmit={handleSubmit(submit)}>
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="name"
            rules={{ required: 'Registered company name is required' }}
            render={({ field }) => (
              <Input
                data-testid="company-information-ED354A"
                {...field}
                isRequired
                label="Registered company name"
                placeholder="State"
              />
            )}
          />
          {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
        </div>

        <InfoMessage message="This should be the registered company name specified in your legal documents. Using a non-compliant name will invalidate your contract." />

        {countries && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="living_country"
              rules={{ required: 'Country is required' }}
              render={({ field }) => (
                <Select
                  data-testid="company-information-99589D"
                  {...field}
                  isRequired
                  options={countries.map((country) => ({
                    value: country.id,
                    label: country.name,
                  }))}
                  placeholder="e.g United Kingdom"
                  label="Country"
                />
              )}
            />
            {errors.living_country && (
              <Typography className="text_regular__14 color_red">{errors.living_country.message}</Typography>
            )}
          </div>
        )}

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="address_line"
            rules={{ required: 'Registered Address is required' }}
            render={({ field }) => (
              <Input data-testid="company-information-B5EFD2" {...field} isRequired label="Registered Address" />
            )}
          />
          {errors.address_line && (
            <Typography className="text_regular__14 color_red">{errors.address_line.message}</Typography>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="street_address"
            render={({ field }) => <Input data-testid="company-information-555453" {...field} label="Address line 2" />}
          />
          {errors.street_address && (
            <Typography className="text_regular__14 color_red">{errors.street_address.message}</Typography>
          )}
        </div>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="city"
            rules={{ required: 'City is required' }}
            render={({ field }) => (
              <Input data-testid="company-information-1F6212" {...field} isRequired label="City" />
            )}
          />
          {errors.city && <Typography className="text_regular__14 color_red">{errors.city.message}</Typography>}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="state"
            rules={{ required: true }}
            render={({ field }) => (
              <Input data-testid="company-information-216DBD" {...field} label="State/Province" placeholder="State" />
            )}
          />
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="postal_code"
            rules={{ required: 'Postal code is required' }}
            render={({ field }) => (
              <Input data-testid="company-information-3992F5" {...field} isRequired label="Postal code" />
            )}
          />
          {errors.postal_code && (
            <Typography className="text_regular__14 color_red">{errors.postal_code.message}</Typography>
          )}
        </div>
      </section>
      {isModal ? (
        <div className="align-self-end gap-3 d-flex">
          <Button
            data-testid="company-information-0D8DE5"
            type="button"
            priority="secondary"
            size="small"
            onClick={onNext}
          >
            Close
          </Button>
          <Button
            data-testid="company-information-3B44DC"
            priority="primary"
            size="small"
            type="submit"
            loading={updateLoading}
            disabled={updateLoading}
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="pb-5 align-self-center">
          <Button
            data-testid="company-information-212133"
            type="submit"
            className="align-self-end"
            loading={updateLoading}
          >
            Confirm and continue
          </Button>
        </div>
      )}
    </form>
  )
}
