import './style.scss'

import classNames from 'classnames'
import React, { forwardRef } from 'react'
import SelectField from 'react-select'

interface Props {
  label?: string
  value?: any
  options?: any[]
  id?: string
  styleClass?: string
  loading?: boolean
  addText?: string
  isRequired?: boolean
  layout?: string
  isDisabled?: boolean
  isClearable?: boolean
  tooltip?: string
  onChange(value: any): void
}
export type Ref = any

export default forwardRef<Ref, Props>((props, ref) => {
  const {
    label,
    options,
    id,
    value,
    styleClass,
    loading,
    addText,
    isRequired,
    isDisabled,
    isClearable,
    tooltip,
    onChange,
    ...rest
  } = props

  const selectedValue = options?.find((o) => o.value === value)

  return (
    <div className={classNames(['select', styleClass])}>
      {label && (
        <label
          className={classNames('text_medium__14 d-flex align-items-center gap-1', {
            'remo-form-label': !addText,
          })}
          htmlFor={id}
        >
          <span>
            {label} {isRequired && <span className="text-danger">*</span>}
          </span>
          {tooltip}
        </label>
      )}
      {addText && (
        <p className="text_light__12 color_text_300 mt-1" style={{ marginBottom: 12 }}>
          {addText}
        </p>
      )}
      {options && (
        <div className="select">
          <SelectField
            className="w-100 react-select"
            classNamePrefix="react-select"
            ref={ref}
            minMenuHeight={200}
            maxMenuHeight={220}
            id={id}
            isLoading={loading}
            isDisabled={isDisabled}
            defaultValue={isDisabled ? options[0] : undefined}
            options={options}
            value={selectedValue || value}
            onChange={onChange}
            isClearable={isClearable}
            {...rest}
          />
        </div>
      )}
    </div>
  )
})
