import './style.scss'

import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React, { forwardRef } from 'react'

type Props = {
  placeholder?: string
  id?: string
  value?: string
  disabled?: boolean
  style?: any
  onChange?: (event: React.ChangeEvent<HTMLInputElement>) => void
  type?: string
  label?: string
  readOnly?: boolean
  styleClass?: string
  defaultValue?: string
  currency?: string
  name?: string
  endIcon?: React.ReactNode
  isRequired?: boolean
  outlined?: boolean
  addText?: string
  step?: string
  tooltip?: string
}

export type Ref = HTMLInputElement

const Input = forwardRef<Ref, Props>(
  (
    {
      placeholder,
      id,
      value,
      disabled,
      style,
      onChange,
      type,
      label,
      readOnly,
      styleClass,
      defaultValue,
      currency,
      name,
      endIcon,
      isRequired,
      outlined,
      addText,
      step,
      tooltip,
      ...rest
    },
    ref
  ) => (
    <div className={classNames(['input', styleClass])}>
      <div className="d-flex flex-column w-100">
        {label && (
          <label
            className={classNames([
              'text_medium__14 d-flex align-items-center gap-1',
              'remo-form-label',
              addText && 'add-text',
            ])}
            htmlFor={id}
            style={style}
          >
            {label} {isRequired && <span className="text-danger">*</span>}
            {tooltip}
          </label>
        )}
        {addText && (
          <Typography className="text_light__12 color_text_300 mt-1" style={{ marginBottom: 12 }}>
            {addText}
          </Typography>
        )}
      </div>
      <div className={classNames(['input-wrapper d-flex align-items-center', outlined && 'input_outlined'])}>
        {currency && <span className="currency mx-2">{currency}</span>}
        <input
          data-testid="Input-379AFB"
          style={{ ...style }}
          id={id}
          ref={ref}
          value={value}
          defaultValue={defaultValue}
          placeholder={placeholder}
          disabled={disabled}
          type={type}
          step={step}
          name={name}
          autoComplete="off"
          readOnly={readOnly}
          onChange={onChange}
          onWheel={(event) => {
            event.currentTarget.blur()
          }}
          {...rest}
        />
        <span className="input-end-icon">{endIcon}</span>
      </div>
    </div>
  )
)

export default Input
