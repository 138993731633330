import { Styled } from './data-card.styles'

type Props = {
  title: string
  value: string
  detail: string
}

export const DataCard = ({ title, value, detail }: Props) => (
  <Styled.Root>
    <Styled.Label>{title}</Styled.Label>
    <Styled.Value>{value}</Styled.Value>
    <Styled.Detail>{detail}</Styled.Detail>
  </Styled.Root>
)
