import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { deleteIcon, edit, view } from '@core/icons/icons'
import moment from 'moment'
import React from 'react'

export const payrollsField = (onClickView, onClickEdit, handleDelete, onCancel, isDisable) => [
  {
    title: 'Team member',
    key: 'name',
    type: 'string',
    maxWidth: 270,
    minWidth: 270,
    width: 270,
  },
  {
    title: 'Submitted',
    key: 'created_at',
    type: 'string',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Expected payout',
    key: 'payout_month',
    type: 'custom',
    maxWidth: 150,
    minWidth: 150,
    width: 150,
    render: (item) => {
      return (
        <div className="d-flex align-items-center">
          <Typography className="text_regular__14 mr-2">
            {item?.payout_date ? moment(item.payout_date).format('MMMM') : '-'}
          </Typography>
        </div>
      )
    },
  },
  {
    title: 'Total amount',
    key: 'total_amount',
    type: 'string',
    maxWidth: 136,
    minWidth: 136,
    width: 136,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 156,
    minWidth: 156,
    width: 156,
  },
  {
    title: 'Action',
    key: 'custom',
    type: 'custom',
    maxWidth: 250,
    minWidth: 250,
    width: 250,
    render: (row) => {
      return (
        <div className="d-flex align-items-center">
          <Button
            onClick={() => onClickView(row)}
            className="text_light__12 px-2 pl-0 ml-2"
            priority="secondary"
            size="small"
            style={{ height: '32px' }}
          >
            <Icon fill="none" icon={view} className="mr-2" />
            View
          </Button>
          {(row.status === 'created' || !(isDisable && row.status === 'AWAITING_PAYMENT')) &&
            row.status !== 'Cancelled' &&
            row.status !== 'PAID' && (
              <Button
                onClick={() => handleDelete(row.id)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon fill="none" icon={deleteIcon} className="mr-2" />
                Cancel
              </Button>
            )}
          {(row.status === 'created' || !(isDisable && row.status === 'AWAITING_PAYMENT')) &&
            row.is_recurrent &&
            row.status !== 'Cancelled' &&
            row.status !== 'PAID' && (
              <Button
                onClick={() => onCancel(row.id)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                Cancel
              </Button>
            )}
          {(row.status === 'created' || !(isDisable && row.status === 'AWAITING_PAYMENT')) &&
            row.status !== 'Cancelled' &&
            row.status !== 'PAID' && (
              <Button
                onClick={() => onClickEdit(row)}
                className="text_light__12 px-2 pl-0 ml-2"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon fill="none" icon={edit} className="mr-2" />
                Edit
              </Button>
            )}
        </div>
      )
    },
  },
]
