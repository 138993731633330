import styled from 'styled-components'

const Root = styled.div`
  border-radius: 8px;
  border: 1px solid #ebebeb;
  padding: 16px 24px;
  display: flex;
  flex-direction: column;

  table tr,
  table th {
    height: 48px;
  }
`

const TableRowCost = styled.tr`
  color: #878787;

  td {
    color: #878787;

    &:first-child {
      padding-left: 32px;
    }
  }
`

export const Styled = {
  Root,
  TableRowCost,
}
