export default function ProbationPeriodInfo({ probationDetails, probationPeriodConfig, isPermanent }) {
  const {
    calculation_type,
    country,
    fixed_contract_is_applicable,
    fixed_contract_maximum_day,
    fixed_contract_minimum_day,
    fixed_contract_note,
    fixed_contract_suggested_day,
    permanent_contract_is_applicable,
    permanent_contract_maximum_day,
    permanent_contract_minimum_day,
    permanent_contract_note,
    permanent_contract_suggested_day,
    probation_type,
    segments,
  } = probationDetails
  if ((isPermanent && !permanent_contract_is_applicable) || (!isPermanent && !fixed_contract_is_applicable)) {
    return <div>Probation info is not available</div>
  }
  if (!isPermanent && probation_type === 'specific_to_duration_of_fixed_contract' && segments) {
    const columns = ['Contract duration', 'Minimum', 'Recommended', 'Maximum']
    const rows = []
    let last = 0
    segments.forEach(({ max_period_in_months, min_period_in_months, probation_days }) => {
      rows.push([
        `${min_period_in_months}-${max_period_in_months} months`,
        `${last} days`,
        fixed_contract_suggested_day ? `${fixed_contract_suggested_day} days` : '-',
        `${probation_days} days`,
      ])
      last = probation_days
    })
    return (
      <table>
        <tr>
          {columns.map((c) => (
            <th style={{ padding: '16px', backgroundColor: '#FAFAFA', color: '#000000D9' }}>{c}</th>
          ))}
        </tr>
        {rows.map((r) => (
          <tr>
            {r.map((f) => (
              <td
                style={{
                  padding: '16px',
                  backgroundColor: '#FAFAFA',
                  color: '#000000D9',
                  borderTop: '1px solid #0000000F',
                }}
              >
                {f}
              </td>
            ))}
          </tr>
        ))}
      </table>
    )
  }
  const { min, max, suggested } = probationPeriodConfig
  const columns = ['Minimum', 'Recommended', 'Maximum']
  const row = [`${min} days`, suggested ? `${suggested} days` : '-', `${max} days`]
  return (
    <table>
      <tr>
        {columns.map((c) => (
          <th style={{ padding: '16px', backgroundColor: '#FAFAFA', color: '#000000D9' }}>{c}</th>
        ))}
      </tr>
      <tr>
        {row.map((r) => (
          <td
            style={{
              padding: '16px',
              backgroundColor: '#FAFAFA',
              color: '#000000D9',
              borderTop: '1px solid #0000000F',
            }}
          >
            {r}
          </td>
        ))}
      </tr>
    </table>
  )
}
