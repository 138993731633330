import './index.scss'

import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import { coin } from '@core/icons/icons'
import React from 'react'

import { PaymentInfo } from './PaymentInfo'
import { TransactionHistory } from './TransactionHistory'

export default function () {
  return (
    <div className="h-100 d-flex flex-column gap-3">
      <div>
        <div className="d-flex justify-content-between">
          <PageTitle styleClass="mb-4">Payment methods</PageTitle>
        </div>
      </div>
      <NotificationBlock
        text="Add your payment methods here"
        cards={[
          {
            title: 'Add payment method',
            description: 'Bind your bank account',
            icon: coin,
          },
          {
            title: 'Approve payroll',
            description: 'Approve your payrolls on a monthly basis',
            icon: coin,
          },
          {
            title: 'Make direct debit payment',
            description: 'Amount will be pulled from your bank account',
            icon: coin,
          },
        ]}
        isExpanded
      />
      <PaymentInfo />
      <TransactionHistory />
    </div>
  )
}
