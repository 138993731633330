/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import Typography from '@atoms/Typography/Typography'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { useOnClickOutside } from '@core/hooks/useOnClickOutside'
import { useRef, useState } from 'react'

const disabledStyles = { display: 'none' }

const PaymentCheckboxDropdown = ({
  isAllChecked,
  setSelectedAction,
  paymentsOnPageCount,
  selectedPaymentsOnPageCount,
  allPaymentsCount,
  loading,
}) => {
  const [isDropdownOpen, setDropdownOpen] = useState(false)
  const dropdownRef = useRef(null)
  const toggleDropdown = () => setDropdownOpen(!isDropdownOpen)
  const isPageChecked = selectedPaymentsOnPageCount > 0
  const handleSelect = (type, value) => {
    setSelectedAction([type, value])
    setDropdownOpen(false)
  }
  useOnClickOutside(dropdownRef, () => setDropdownOpen(false))
  const shouldDisablePageCheck = !isPageChecked && paymentsOnPageCount === 0
  return (
    <Dropdown
      open={isDropdownOpen}
      trigger={
        <div className="d-flex align-items-center">
          <input
            type="checkbox"
            checked={isAllChecked || isPageChecked}
            onClick={toggleDropdown}
            style={{ width: 18, height: 18, marginLeft: 10 }}
            disabled={loading}
          />
        </div>
      }
      menu={[
        <div style={{ width: 300 }} ref={dropdownRef}>
          <div
            onClick={() => {
              let value = true
              if (isPageChecked) {
                value = false
              }
              handleSelect('onPage', value)
            }}
            style={{
              padding: 16,
              cursor: 'pointer',
              borderBottom: '1px solid #ddd',
              backgroundColor: isPageChecked ? '#EBEBEB' : 'transparent',
              ...(shouldDisablePageCheck && disabledStyles),
            }}
          >
            <Typography className="text_light__14 color_black">{`${isPageChecked ? 'Deselect' : 'Select'} all payments on page (${isPageChecked ? selectedPaymentsOnPageCount : paymentsOnPageCount})`}</Typography>
          </div>
          <div
            onClick={() => {
              let value = true
              if (isAllChecked) {
                value = false
              }
              handleSelect('all', value)
            }}
            style={{
              padding: 16,
              cursor: 'pointer',
              backgroundColor: isAllChecked ? '#EBEBEB' : 'transparent',
            }}
          >
            <Typography className="text_light__14 color_black">{`${isAllChecked ? 'Deselect' : 'Select'} all payments (${allPaymentsCount})`}</Typography>
          </div>
        </div>,
      ]}
    />
  )
}

export default PaymentCheckboxDropdown
