import Button from '@atoms/Button/Button'
import { EditForm, EditModalForm } from '@atoms/EditModal/EditModalForm'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import PhoneInput from '@atoms/PhoneInput/PhoneInput'
import Typography from '@atoms/Typography/Typography'
import { getEmergencyContact } from '@pages/employeeDetailPage/mock'
import { getContractEmergencyContact, patchEmergencyContact } from '@store/contracts'
import { DotWave } from '@uiball/loaders'
import React, { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

export default function EmergencyContact({ employee }) {
  const { value, toggle } = useBoolean(false)
  const {
    register,
    handleSubmit,
    reset,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      name: '',
      relationship: '',
      email: '',
      phone_number: '',
    },
  })

  const { data, isLoading, refetch } = useQuery(['emergencyContact'], {
    queryFn: () => getContractEmergencyContact(employee.id, employee.user_id),
  })

  const putEmergencyContact = useMutation({
    mutationFn: (payload) => patchEmergencyContact(payload.id, payload.data),
    onSuccess: (res) => {
      toggle()
      refetch()
      toast.success('Successfully updated')
    },
  })

  const submit = (formValues) => {
    const emergencyData = {
      ...formValues,
      user_id: employee.user_id,
    }
    putEmergencyContact.mutate({ id: data[0].id, data: emergencyData })
  }

  useEffect(() => {
    if (data && data[0]) {
      reset({
        name: data[0]?.name,
        relationship: data[0]?.relationship,
        email: data[0]?.email,
        phone_number: data[0]?.phone_number,
      })
    }
  }, [data])

  return (
    <div style={{ maxWidth: 540 }}>
      {isLoading ? (
        <div className="edit-avatar__loader">
          <DotWave size={32} speed={1} color="black" />
        </div>
      ) : (
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center justify-content-between mb-4">
            <Typography className="heading_semibold__20 color_black">Emergency contact</Typography>
            <Button data-testid="EmergencyContact-AE0DF8" priority="secondary" size="small" onClick={toggle}>
              Edit
            </Button>
          </div>
          {data && data[0] && <List lists={getEmergencyContact(data[0])} />}
        </div>
      )}
      <EditModalForm title="Emergency contact" show={value} loading={putEmergencyContact.isLoading} onHide={toggle}>
        <EditForm className="d-flex flex-column h-100" onSubmit={handleSubmit(submit)}>
          <div className="remo-form-input">
            <Input
              data-testid="EmergencyContact-4D73C4"
              {...register('name', {
                required: 'Field is required',
              })}
              type="text"
              label="Name"
              placeholder="Enter emergency contact’s full name"
            />
            {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
          </div>
          <div className="remo-form-input">
            <Input
              data-testid="EmergencyContact-EE4BEF"
              {...register('relationship', {
                required: 'Field is required',
              })}
              type="text"
              label="Relationship"
              placeholder="E.g. mother, father, spouse"
            />
            {errors.relationship && (
              <Typography className="text_regular__14 color_red">{errors.relationship.message}</Typography>
            )}
          </div>
          <div className="remo-form-input">
            <Input
              data-testid="EmergencyContact-373678"
              {...register('email', {
                required: 'Email is required',
                validate: {
                  isEmail: (v) => {
                    return /.+@.+\..+/.test(v) || 'Please enter correct email'
                  },
                },
              })}
              type="email"
              label="Email"
              placeholder="Enter emergency contact’s email address"
            />
            {errors.email && <Typography className="text_regular__14 color_red">{errors.email.message}</Typography>}
          </div>
          <div className="remo-form-input">
            <Controller
              control={control}
              name="phone_number"
              rules={{
                required: 'Phone number is required',
              }}
              render={({ field }) => {
                return (
                  <PhoneInput
                    {...field}
                    international
                    withCountryCallingCode
                    label="Phone number"
                    placeholder="Enter your emergency contact’s phone number"
                  />
                )
              }}
            />
            {errors.phone_number && (
              <Typography className="text_regular__14 color_red">{errors.phone_number.message}</Typography>
            )}
          </div>
        </EditForm>
      </EditModalForm>
    </div>
  )
}
