import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Typography from '@atoms/Typography/Typography'
import { keyboardArrowLeft } from '@core/icons/icons'
import { EquipmentDelivery } from '@features/benefits/equiment-delivery/equpment-delivery.component'
import { patchContractById } from '@store/contracts'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useMutation } from 'react-query'
import { useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const TextDescription = styled.div`
  color: #484848;
`

export const EquipmentDeliveryModal = ({ onClose, goBack, setEmployee }) => {
  const confirmOpen = useBoolean(false)
  const { handleSubmit } = useFormContext()
  const { id } = useParams()

  const putJobDetail = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {
      if (setEmployee) setEmployee(res.data)
      toast.success('Successfully updated')
      confirmOpen.setFalse()
      onClose()
    },
  })

  const submit = ({ contract, equipment_specification }) => {
    putJobDetail.mutate({
      id: id || contract.value,
      data: {
        benefit: {
          provides_work_equipment: true,
          equipment_specification,
        },
      },
    })
  }

  return (
    <>
      <ModalSide title="Add benefits" okText="Add benefits" onClose={onClose} onOk={confirmOpen.setTrue}>
        <div className="w-100">
          <div className="d-flex align-items-center mb-5">
            <Icon
              icon={keyboardArrowLeft}
              style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
              onClick={goBack}
            />
            <Typography className="heading_semibold__20 ml-4">Benefits</Typography>
          </div>
          <Typography className="heading_semibold__20 mb-3">Equipment delivery</Typography>
          <TextDescription className="text_regular-normal__14">
            Would you like to offer work equipment delivery as a benefit to your employee? Add your request here, and
            our team will take care of the rest.
          </TextDescription>
          <div style={{ marginTop: 100 }}>
            <EquipmentDelivery />
          </div>
        </div>
      </ModalSide>
      {confirmOpen.value && (
        <Confirm
          title="Request equipment delivery?"
          yesButtonProps={{
            text: 'Approve',
            priority: 'primary',
          }}
          onYes={handleSubmit(submit)}
          onCancel={confirmOpen.setFalse}
          isLoading={putJobDetail.isLoading}
        >
          <Typography>This benefit will take effect after verification by the administrator</Typography>
        </Confirm>
      )}
    </>
  )
}
