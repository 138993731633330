/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import Pagination from '@atoms/Pagination/Pagination'
import Typography from '@atoms/Typography/Typography'
import usePagination from '@core/hooks/usePagination'
import { arrowUploadFilled, trash } from '@core/icons/icons'
import { fetchPostFile } from '@services/app.service'
import { getContracts } from '@services/contract.service'
import { uploadAgreements } from '@services/mass-import-contractors.service'
import { DotWave } from '@uiball/loaders'
import { drop, take } from 'lodash'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { Styled } from '../contractors-mass-import-modal.styles'

const Action = ({ isLoading, file_uuid, onRemove, onUpload }) => {
  if (isLoading) {
    return <DotWave size={35} />
  }

  return (
    <div>
      {file_uuid ? (
        <div role="button" aria-hidden className="d-flex align-items-center gap-2" onClick={onRemove}>
          <Icon icon={trash} />
          Remove
        </div>
      ) : (
        <label htmlFor={file_uuid}>
          <Styled.UploadButton className="d-flex align-items-center gap-2">
            <Icon icon={arrowUploadFilled} />
            Upload
          </Styled.UploadButton>
          <input
            data-testid="contractors-mass-import-agreements.component-EAF68E"
            type="file"
            id={file_uuid}
            hidden
            onChange={onUpload}
            accept=".png,.jpeg,.jpg,.pdf,.doc,.docx."
          />
        </label>
      )}
    </div>
  )
}

export const ContractorsMassImportAgreements = ({ massImportId, onClose }) => {
  const successModal = useBoolean(false)

  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })

  // for FE pagination
  const [contractorsToRender, setContractorsToRender] = useState([])
  // hashed contractors to store file_uuid
  const [allContractors, setAllContractors] = useState({})
  const [uploadedFileCount, setUploadedFileCount] = useState(0)

  const contractsQuery = useQuery([getContracts.key], {
    queryFn: () =>
      getContracts.fetch({
        contractor_mass_import: massImportId,
        limit: 1000,
        offset: 0,
      }),
    onSuccess: ({ results }) => {
      setAllContractors(
        results
          .map((r, i) => ({ ...r, full_name: r.full_name + (i + 1) }))
          .reduce(
            (acc, contractor) => ({
              ...acc,
              [contractor.id]: {
                ...contractor,
                file_uuid: null,
                file: null,
                filename: null,
                isLoading: false,
              },
            }),
            {}
          )
      )
      setContractorsToRender(take(results, limit))
    },
  })

  const handleChangePage = (page) => {
    setPage(page)

    // cut previous [limit = 10] items
    const dropped = drop(contractsQuery.data.results, (page - 1) * limit)

    //  and then take first [limit = 10] items
    setContractorsToRender(take(dropped, limit))
  }

  const uploadAgreementsMutation = useMutation({
    mutationFn: (body) => uploadAgreements(massImportId, body),
    onSuccess: successModal.setTrue,
  })

  const handleUpload =
    (contractorId) =>
    ({ target }) => {
      setAllContractors((prev) => ({
        ...prev,
        [contractorId]: { ...prev[contractorId], isLoading: true },
      }))

      fetchPostFile(target.files[0]).then(({ file_uuid, filename, file }) => {
        setAllContractors((prev) => ({
          ...prev,
          [contractorId]: {
            ...prev[contractorId],
            isLoading: false,
            file_uuid,
            filename,
            file,
          },
        }))

        setUploadedFileCount((prev) => prev + 1)
      })
    }

  const handleRemove = (contractorId) => () => {
    setAllContractors((prev) => ({
      ...prev,
      [contractorId]: {
        ...prev[contractorId],
        file_uuid: null,
        filename: null,
        file: null,
      },
    }))

    setUploadedFileCount((prev) => prev - 1)
  }

  const handleSubmit = () => {
    const body = {
      contractors_employment_agreements: Object.values(allContractors)
        .filter((contractor) => contractor.file_uuid)
        .map(({ id, file_uuid }) => ({
          contractor: id,
          employment_agreement: file_uuid,
        })),
    }

    uploadAgreementsMutation.mutate(body)
  }

  // TODO
  useEffect(() => {
    console.log(contractorsToRender)
    console.log(uploadedFileCount)
  }, [contractorsToRender])

  if (contractsQuery.isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <div className="h-100 d-flex flex-column">
        <section className="d-flex flex-grow-1 flex-column  justify-content-center gap-3">
          <Typography className="heading_semibold__20">
            Please upload signed work agreement in pdf format. <br />
            You can also skip the step now, and either do it later or leave your contractors to do it.
          </Typography>

          <Styled.Table className="mt-3">
            <thead>
              <tr>
                <th>Contractor</th>
                <th>Work agreement</th>
                <th>Action</th>
              </tr>
            </thead>
            <tbody>
              {contractorsToRender.map(({ id }) => (
                <tr key={id}>
                  <td>{allContractors[id].full_name}</td>
                  <td>
                    {allContractors[id].filename ? (
                      <a
                        data-testid="contractors-mass-import-agreements.component-78D518"
                        href={allContractors[id].file}
                        className="link"
                        target="_blank"
                        rel="noreferrer"
                      >
                        {allContractors[id].filename}
                      </a>
                    ) : (
                      '-'
                    )}
                  </td>
                  <td>
                    <Action
                      file_uuid={allContractors[id].file_uuid}
                      isLoading={allContractors[id].isLoading}
                      onUpload={handleUpload(id)}
                      onRemove={handleRemove(id)}
                    />
                  </td>
                </tr>
              ))}
            </tbody>
          </Styled.Table>
          <div className="mt-2 align-self-end">
            <Pagination page={page} pageSize={limit} total={contractsQuery.data.count} onGetPage={handleChangePage} />
          </div>
        </section>

        <div className="d-flex gap-2 align-self-center pb-5">
          <Button
            data-testid="contractors-mass-import-agreements.component-8CD977"
            size="small"
            type="button"
            priority="secondary"
            onClick={onClose}
          >
            Skip
          </Button>

          <Button
            data-testid="contractors-mass-import-agreements.component-4AD62C"
            size="small"
            type="button"
            disabled={uploadedFileCount <= 0 || uploadAgreementsMutation.isLoading}
            onClick={handleSubmit}
          >
            Submit
          </Button>
        </div>
      </div>

      {successModal.value && (
        <Confirm
          title="Work agreement"
          cancelButtonProps={{ hidden: true }}
          yesButtonProps={{
            priority: 'primary',
            text: 'Understood',
            size: 'small',
            type: 'submit',
          }}
          onYes={onClose}
        >
          ✅ You have successfully uploaded {uploadedFileCount} work agreement documents. <br />
          <br />
          {contractsQuery.data.count - uploadedFileCount} work agreement documents remain to be uploaded.
        </Confirm>
      )}
    </>
  )
}
