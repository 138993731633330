import Typography from '@atoms/Typography/Typography'
import { useMemo } from 'react'
import { Button, CloseButton, Stack } from 'react-bootstrap'
import styled from 'styled-components'

const StyledNotification = styled('div')`
  max-width: 560px;
  border-radius: 6px;
  border: 1px solid #4c92c7;
  background-color: #ebf5fc;
  padding: 12px 16px;
  display: flex;
  justify-content: space-between;
  align-items: center;
`

type NotificationProps = {
  title: string
  actionTitle: string
  onClick: () => void
  onClose?: () => void
  description?: string
  variant?: 'normal' | 'simplified'
}
export const Notification = ({
  title,
  actionTitle,
  onClick,
  onClose,
  description,
  variant = 'normal',
}: NotificationProps) => {
  const reviewButton = useMemo(
    () => (
      <Button variant="link" className="p-0" onClick={onClick}>
        {actionTitle}
      </Button>
    ),
    [onClick, actionTitle]
  )
  return (
    <StyledNotification>
      {variant === 'normal' ? (
        <>
          <Stack gap={3} style={{ alignItems: 'flex-start' }}>
            <Typography className="heading_semibold__16" style={{ textAlign: 'center' }}>
              {title}
            </Typography>
            {description && <Typography className="text_regular__14">{description}</Typography>}
            {reviewButton}
          </Stack>
          {onClose && <CloseButton onClick={onClose} style={{ alignSelf: 'flex-start' }} />}
        </>
      ) : (
        <>
          <Typography className="heading_semibold__16">{title}</Typography>
          {reviewButton}
        </>
      )}
    </StyledNotification>
  )
}
