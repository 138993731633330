import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 845px;
  }
`

export default ({ onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20">Contractor added successfully</Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="color_grey">
          {' '}
          You have successfully added a contractor to your team.They will receive an email invitation to begin their
          onboarding process. You can monitor their progress via the Team area.
        </Typography>

        <Typography className="color_grey mt-4">
          To complete onboarding process for the contractor a contract needs to be added to the contractor.
        </Typography>
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="contractor-success-modal-index-4F9F4A"
          type="submit"
          size="small"
          priority="primary"
          onClick={onSave}
          className="mx-auto my-3"
        >
          Continue and add contract
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
