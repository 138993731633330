import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Input from '@atoms/Input/Input'
import PhoneInput from '@atoms/PhoneInput/PhoneInput'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Controller, useFormContext } from 'react-hook-form'

export const PersonalProfile = ({ onNext }) => {
  const [termsPrivacy, setTermsPrivacy] = React.useState({
    terms: false,
    privacy: false,
  })

  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useFormContext()

  const handleTermsPrivacyChange = (event) => {
    const { name, checked } = event.target
    setTermsPrivacy((prev) => ({ ...prev, [name]: checked }))
  }

  return (
    <form className="d-flex flex-column mt-2 mb-2 gap-3" style={{ width: 480 }} onSubmit={handleSubmit(onNext)}>
      <section className="d-flex gap-3 flex-column justify-content-center">
        <div>
          <Input
            data-testid="personal-profile-D250FB"
            {...register('company.title', {
              required: 'Job title is required',
            })}
            type="text"
            label="Job title"
            placeholder="Enter job title"
          />
          {errors.company?.title && (
            <Typography className="text_regular__14 color_red">{errors.company.title.message}</Typography>
          )}
        </div>

        <div>
          <Controller
            control={control}
            name="profile.phone_number"
            rules={{
              required: 'Phone number is required',
            }}
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  international
                  withCountryCallingCode
                  label="Contact phone number"
                  placeholder="e.g. +31 7530 283 978"
                />
              )
            }}
          />
          {errors.profile?.phone_number && (
            <Typography className="text_regular__14 color_red">{errors.profile.phone_number.message}</Typography>
          )}
        </div>

        <div>
          <Input
            data-testid="personal-profile-D5097A"
            {...register('company.legal_address.city', {
              required: 'Customer city is required',
            })}
            type="text"
            label="City"
            placeholder="e.g. Amsterdam"
          />
          {errors.company?.legal_address?.city && (
            <Typography className="text_regular__14 color_red">{errors.company.legal_address.city.message}</Typography>
          )}
        </div>

        <div>
          <Input
            data-testid="personal-profile-E730A8"
            {...register('company.legal_address.street_address', {
              required: 'Street Address is required',
            })}
            type="text"
            label="Street Address"
            placeholder="e.g. Circus Lane 120"
          />
          {errors.company?.legal_address?.street_address && (
            <Typography className="text_regular__14 color_red">
              {errors.company.legal_address.street_address.message}
            </Typography>
          )}
        </div>

        <div>
          <Input
            data-testid="personal-profile-8C066B"
            {...register('company.legal_address.address_line')}
            type="text"
            label="Address Line"
            placeholder="Address Line"
          />
        </div>

        <div>
          <Input
            data-testid="personal-profile-25B7C4"
            {...register('company.legal_address.state')}
            type="text"
            label="State"
            placeholder="State"
          />
        </div>

        <div>
          <Input
            data-testid="personal-profile-1C0C3B"
            {...register('company.legal_address.postal_code', {
              required: 'Zip / Postal code is required',
            })}
            type="text"
            label="Zip / Postal code"
            placeholder="e.g. 050000"
          />
          {errors.company?.legal_address?.postal_code && (
            <Typography className="text_regular__14 color_red">
              {errors.company.legal_address.postal_code.message}
            </Typography>
          )}
        </div>

        <div>
          <Input
            data-testid="personal-profile-CA7958"
            {...register('company.head_quarters', {
              required: 'Customer headquarter is required',
            })}
            type="text"
            label="Headquarters"
            placeholder="e.g. Amsterdam"
          />
          {errors.company?.head_quarters && (
            <Typography className="text_regular__14 color_red">{errors.company.head_quarters.message}</Typography>
          )}
        </div>
      </section>

      <div className="d-flex flex-column gap-1">
        <Checkbox
          data-testid="personal-profile-93D1D8"
          name="terms"
          checked={termsPrivacy.terms}
          label={
            <span className="text_medium__14">
              I agree to the
              <a
                data-testid="personal-profile-EB97B6"
                className="ml-1 link"
                href="https://www.remofirst.com/terms-of-service"
                target="_blank"
                rel="noreferrer"
              >
                Terms of use
              </a>
            </span>
          }
          onChange={handleTermsPrivacyChange}
        />
        <Checkbox
          data-testid="personal-profile-2D2AA2"
          name="privacy"
          checked={termsPrivacy.privacy}
          label={
            <span className="text_medium__14">
              I agree to the
              <a
                data-testid="personal-profile-3B4937"
                className="ml-1 link"
                href="https://www.remofirst.com/privacy"
                target="_blank"
                rel="noreferrer"
              >
                Privacy policy
              </a>
            </span>
          }
          onChange={handleTermsPrivacyChange}
        />
      </div>

      <Button
        data-testid="personal-profile-34C666"
        type="submit"
        size="small"
        disabled={!termsPrivacy.terms || !termsPrivacy.privacy}
      >
        Continue
      </Button>
    </form>
  )
}
