/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Input from '@atoms/Input/Input'
import ModalSide from '@atoms/ModalSide/ModalSide'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { SplitAmount } from '@core/utils'
import { employeeTableParse } from '@pages/employees/parse'
import { DotWave } from '@uiball/loaders'
import { omit } from 'lodash'
import moment from 'moment'
import React, { useContext, useState } from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import { components } from 'react-select'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { fetchContractors, fetchCurrencies } from '../../../services/app.service'
import { CreateExpensePanel } from './create-expense-accordion'
import { CreateExpenseForm } from './create-expense-form.component'

const getAmount = (amount, label) => {
  if (!amount || !label) {
    return ''
  }

  return `(${SplitAmount(amount)} ${label})`
}

const firstFormId = v4()

const SingleValue = (props) => {
  const { getValue } = props
  const { label, contractor } = getValue()[0]

  return (
    <components.SingleValue {...props}>
      <span>{label}</span> <span style={{ color: '#878787' }}>{contractor}</span>
    </components.SingleValue>
  )
}
const Option = (props) => {
  const { data } = props
  const { label, contractor } = data

  return (
    <components.Option {...props}>
      <span>{label}</span> <span style={{ color: '#878787' }}>{contractor}</span>
    </components.Option>
  )
}

const categoryTypes = [
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'COWORKING_SPACE', label: 'Coworking space' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'FOOD', label: 'Food' },
  { value: 'OFFICE_SUPPLIES', label: 'Office supplies' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'OTHER', label: 'Other' },
]

export const CreateExpenseModal = ({ onClose, onSubmit, loading }) => {
  const { profile } = useContext(AppContext)

  const [contracts, setContracts] = useState([])
  const [expandedItemId, setExpandedItemId] = useState(firstFormId)
  const closeConfirm = useBoolean(false)

  const form = useForm({
    mode: 'onChange',
    defaultValues: {
      contract: '',
      category: '',
      expense_name: '',
      description: '',
      fields: {
        [firstFormId]: {
          expense_date: '',
          amount: '',
          currency: '',
          receipt: '',
        },
      },
    },
  })

  const fields = form.watch('fields')

  const contractorsQuery = useQuery([fetchContractors.key], {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        state: 'active',
        offset: 0,
        limit: 1000,
      }),
    onSuccess: ({ results }) => {
      setContracts(employeeTableParse(results))
    },
  })

  const currenciesQuery = useQuery('fetchCurrenciesExpense', {
    queryFn: () => fetchCurrencies({ limit: 1000, offset: 0, ordering: 'short_code' }),
  })

  const handleSubmit = () => {
    form.trigger()

    const { category, contract, expense_name, description } = form.getValues()

    const body = {
      expense_managements: Object.values(fields).map(({ expense_date, currency, amount, receipt }) => ({
        contract: contract.value,
        category: category.value,
        expense_name,
        description,
        expense_date: moment(expense_date).format('YYYY-MM-DD'),
        amount,
        currency: currency.value,
        receipt,
      })),
    }
    onSubmit(body)
  }

  const handleAddNewForm = () => {
    const newFormId = v4()

    form.setValue('fields', {
      ...fields,
      [newFormId]: {
        expense_date: '',
        amount: '',
        currency: '',
        receipt: '',
      },
    })
    setExpandedItemId(newFormId)
  }

  const handleRemove = (formId) => {
    const isLastReceipt = Object.keys(fields).length === 1
    if (isLastReceipt) {
      return
    }

    form.setValue('fields', omit(fields, [formId]))
  }

  const fieldsKeysList = Object.keys(fields)

  if (currenciesQuery.isLoading || contractorsQuery.isLoading) {
    return (
      <ModalSide title="Submit expense request(s)" footer={null} className="h-100" onClose={onClose}>
        <div className="d-flex justify-content-center align-items-center h-100">
          <DotWave />
        </div>
      </ModalSide>
    )
  }

  return (
    <FormProvider {...form}>
      <ModalSide
        title="Submit expense request(s)"
        okButtonProps={{
          loading,
        }}
        okText="Create request(s)"
        onClose={form.formState.isDirty ? closeConfirm.setTrue : onClose}
        onOk={handleSubmit}
      >
        <div className="d-flex flex-column gap-2">
          <div className="w-100">
            <Controller
              control={form.control}
              name="contract"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="create-expense-modal.component-776C56"
                    {...field}
                    isRequired
                    label="Select worker"
                    addText="You can choose only active workers from the list"
                    options={contracts.map((contract) => ({
                      value: contract.id,
                      contractor: contract.contract_type === 'contractor' ? '(Contractor)' : '',
                      label: contract.full_name,
                      countryId: contract.working_country?.id,
                    }))}
                    getOptionLabel={(option) => `${option.label}${option.contractor}`}
                    components={{ SingleValue, Option }}
                  />
                )
              }}
            />
            {form.formState.errors?.contract && (
              <Typography className="text_regular__14 color_red">{form.formState.errors.contract.message}</Typography>
            )}
          </div>
          <div className="w-100">
            <Controller
              control={form.control}
              name="category"
              rules={{ required: 'Field is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="create-expense-modal.component-BEC7B0"
                    isRequired
                    {...field}
                    label="Category"
                    options={categoryTypes}
                  />
                )
              }}
            />
            {form.formState.errors?.category && (
              <Typography className="text_regular__14 color_red">{form.formState.errors.category.message}</Typography>
            )}
          </div>
          <div>
            <Input
              data-testid="create-expense-modal.component-72A544"
              {...form.register(`expense_name`, {
                required: 'Field is required',
              })}
              isRequired
              type="text"
              label="Expense name"
              placeholder="Enter expense name"
            />
            {form.formState.errors?.expense_name && (
              <Typography className="text_regular__14 color_red">
                {form.formState.errors.expense_name.message}
              </Typography>
            )}
          </div>
          <TextArea
            isRequired
            label="Description"
            placeholder="Provide more details"
            {...form.register(`description`, {
              required: 'Field is required',
            })}
          />
          {form.formState.errors?.description && (
            <Typography className="text_regular__14 color_red">{form.formState.errors.description.message}</Typography>
          )}
        </div>

        {fieldsKeysList.map((formId, index) => (
          <CreateExpensePanel
            key={formId}
            isError={!!form.formState.errors.fields?.[formId]}
            expenseName={`Receipt #${index + 1} ${getAmount(fields[formId].amount, fields[formId].currency.label)}`}
            expandedId={expandedItemId}
            formId={formId}
            onOpen={setExpandedItemId}
            onRemove={handleRemove}
          >
            <CreateExpenseForm
              contracts={contracts}
              currencies={currenciesQuery.data.results}
              formId={formId}
              onSubmit={() => {}}
            />
          </CreateExpensePanel>
        ))}
        <Button
          data-testid="create-expense-modal.component-D1A811"
          size="small"
          priority="primary_black"
          onClick={handleAddNewForm}
        >
          + Receipt
        </Button>
        <NotificationBlock text="Note that reimbursements will be made in the currency of the worker's employment agreement regardless of the currency in the receipt(s)" />
      </ModalSide>

      {closeConfirm.value && (
        <Confirm
          title="Close window?"
          onYes={onClose}
          yesButtonProps={{ priority: 'primary' }}
          onCancel={closeConfirm.setFalse}
        >
          Changes that you made might not be saved <br /> Are you sure you want to close this window?
        </Confirm>
      )}
    </FormProvider>
  )
}
