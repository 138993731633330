import { Api } from '@api/Api'

export const getDocumentsByContractId = async (id, page) => {
  const { data } = await Api.get(`documents/?contract_id=${id}&page=${page}`)

  return data
}

export const getContractDocuments = async (params) => {
  const { page = 1, limit = 10 } = params
  const offset = (page - 1) * limit
  const { data } = await Api.get(`contract-documents/`, {
    params: {
      ...params,
      offset,
      limit,
    },
  })
  return data
}

export const addDocumentsByContractId = async (data) => Api.post(`documents/`, data)
export const addContractDocuments = async (data) => Api.post(`contract-documents/create/`, data)

export const deleteDocumentsById = async (id, contractId) =>
  Api.delete(`contract-documents/${id}/delete/?contract_id=${contractId}`)
