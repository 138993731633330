/* eslint-disable jsx-a11y/label-has-associated-control */
import './style.scss'

import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { check, determine } from '@core/icons/icons'
import classNames from 'classnames'
import React, { forwardRef, useEffect, useState } from 'react'

export default forwardRef(({ label, description, ...props }, ref) => {
  const [checked, setChecked] = useState(props.checked || false)
  const handleCheck = (e) => setChecked(e.target.checked)

  useEffect(() => {
    setChecked(props.checked)
  }, [props.checked])

  return (
    <div>
      <label className="d-flex align-items-start">
        <div
          className={classNames('styledCheckbox', {
            styledCheckbox__disabled: props.disabled,
          })}
        >
          {checked && <Icon fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon={check} />}

          {!checked && props.determine && (
            <Icon fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon={determine} />
          )}
        </div>
        <div className="d-flex flex-column">
          {label && (
            <Typography className="ml-2 text_regular__14 color_black" as="p">
              {label}
            </Typography>
          )}
          {description && (
            <Typography className="ml-2 text_light__13 color_text_300" as="p">
              {description}
            </Typography>
          )}
        </div>
        <input
          data-testid="Checkbox-0C9743"
          ref={ref}
          hidden
          type="checkbox"
          {...props}
          onChange={(e) => {
            handleCheck(e)
            props.onChange(e)
          }}
        />
      </label>
    </div>
  )
})
