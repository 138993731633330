import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'

export const ReviewMilestoneConfirmModal = ({ show, onHide, onReject, onConfirm, isLoading }) => {
  return (
    <Modal size="lg" centered show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Approve milestone completion </Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="heading_semi__16 color_gray">
          Please approve this milestone so a invoice can be generated for the relevant contractor.
        </Typography>
        <Typography className="heading_semi__16 mt-2 color_gray">
          If this work has not been completed or an amend must be made before payment, please reject.
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button disabled={isLoading} priority="primary_dangerous" size="small" className="mr-3" onClick={onReject}>
            Reject
          </Button>
          <Button disabled={isLoading} priority="primary" size="small" onClick={onConfirm}>
            Approve
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
