/* eslint-disable react/jsx-props-no-spreading */
import '../../style.scss'

import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React, { useCallback } from 'react'

export default function Tab(props) {
  const { tabId, title, onClick, count, active, ...rest } = props
  const onClickTab = useCallback(() => {
    onClick(tabId)
  }, [tabId, onClick])
  return (
    // eslint-disable-next-line react/button-has-type
    <button
      data-testid="Tab-C4D980"
      className={classNames(['tab', `tab_${active ? 'active' : 'disabled'}`])}
      onClick={onClickTab}
      {...rest}
    >
      <Typography as="p">{title}</Typography>
      {count !== 0 && count && (
        <div className="tab-count">
          <Typography className="tab-count_text">{count}</Typography>
        </div>
      )}
    </button>
  )
}
