import Confirm from '@atoms/Confirm'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Typography from '@atoms/Typography/Typography'
import React from 'react'

const OnePaymentMethodContent = ({ payment }) => {
  return (
    <Typography className="color_grey">
      Are you sure you want to remove the payment method{' '}
      {`{ACH Direct Debit, Swift ending in ${payment?.us_bank_account?.last4} in USD}`}
      ?
      <br />
      <br />
      This is the only payment method available.
    </Typography>
  )
}
const TwoPaymentMethodContent = ({ payment, paymentMethodsList }) => {
  return (
    <Typography className="color_grey">
      Are you sure you want to remove the payment method{' '}
      {`{ACH Direct Debit, Swift ending in ${payment?.us_bank_account?.last4} in USD}`}
      ?
      <br />
      <br />
      {payment.metadata.default ? 'This is your default payment method.' : 'This is your non default payment method.'}
      <br />
      <br />
      {payment.metadata.default &&
        `After confirming, the payment method ACH Direct Debit, Swift ending in ${
          paymentMethodsList.find((f) => f.payment_method.id !== payment.id)?.payment_method.us_bank_account?.last4
        } in USD will be come your default payment method.`}
    </Typography>
  )
}
const MorePaymentMethodContent = ({ payment, paymentMethodsList, nextPayment, setNextPayment }) => {
  if (payment.metadata.default)
    return (
      <div>
        <Typography className="color_grey">
          Are you sure you want to remove your default payment method{' '}
          {`{ACH Direct Debit, Swift ending in ${payment?.us_bank_account?.last4} in USD}`}
          ?
          <br />
          <br />
          Please choose your new default payment method:
        </Typography>

        <RadioButton
          layout="vertical"
          options={paymentMethodsList
            .filter((f) => f.payment_method.id !== payment.id)
            .map((item) => ({
              id: item.payment_method.id,
              text: (
                <div className="mt-2 ml-2">
                  <Typography>ACH Direct Debit | USD</Typography>
                  <Typography className="color_grey">
                    {`Visa credit card ending in ${item.payment_method.us_bank_account.last4}`}
                  </Typography>
                </div>
              ),
              value: item.payment_method.id,
            }))}
          value={nextPayment}
          onChange={(e) => setNextPayment(e.target.value)}
        />
      </div>
    )
  return <TwoPaymentMethodContent payment={payment} paymentMethodsList={paymentMethodsList} />
}

export const PaymentMethodRemoveModal = ({
  onCancel,
  isLoading,
  onConfirm,
  disabled,
  paymentMethodsList,
  payment,
  nextPayment,
  setNextPayment,
}) => {
  return (
    <Confirm
      title="Remove payment method"
      isLoading={isLoading}
      onCancel={onCancel}
      onYes={onConfirm}
      yesButtonProps={{
        text: 'Remove',
        priority: 'danger',
      }}
      disabledButton={disabled}
    >
      {paymentMethodsList.length === 1 && <OnePaymentMethodContent payment={payment} />}
      {paymentMethodsList.length === 2 && (
        <TwoPaymentMethodContent payment={payment} paymentMethodsList={paymentMethodsList} />
      )}
      {paymentMethodsList.length > 2 && (
        <MorePaymentMethodContent
          payment={payment}
          paymentMethodsList={paymentMethodsList}
          nextPayment={nextPayment}
          setNextPayment={setNextPayment}
        />
      )}
    </Confirm>
  )
}
