import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import { deleteInvoicesById, getInvoicesByContractId } from '@store/invoices'
import moment from 'moment'
import React, { useCallback, useMemo, useState } from 'react'
import { useQuery } from 'react-query'

import { getDocumentFields } from './mock'

export function InvoiceDetailEmployee({ id }) {
  const [page, setPage] = useState(1)

  const { data, isLoading, isFetching, refetch } = useQuery(['invoices', id, page], {
    queryFn: () => getInvoicesByContractId(id, page),
  })
  const onClickDownload = useCallback((row) => {
    window.open(row.file, '_blank')
  }, [])

  const onClickDelete = useCallback((row) => {
    deleteInvoicesById(row.id).then(() => refetch())
  }, [])

  const getInvoicesTable = useMemo(
    () =>
      (data?.data &&
        data.data.results &&
        data.data.results
          .filter((invoice) => invoice.file)
          .map((invoice) => {
            return {
              id: invoice.id,
              file: invoice.file,
              name: invoice.filename || '',
              uploaded: moment(invoice.date).format('DD MMM YYYY'),
            }
          })) ||
      [],
    [data]
  )
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Invoices </PageTitle>
      </div>
      <CustomTable
        page={page}
        loading={isLoading || isFetching}
        pageSize={20}
        total={data?.data?.count || 0}
        fields={getDocumentFields(onClickDownload, onClickDelete)}
        data={getInvoicesTable}
        onPage={setPage}
      />
    </div>
  )
}
