import Icon from '@atoms/Icon/Icon'
import { attachmentIcon, deleteIconFilled } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import React from 'react'

import { Styled } from './attachment-file.styles'

export const AttachmentFile = ({ name, size, onDelete, error, loading = false }) => {
  return (
    <Styled.Root>
      <Styled.File error={error}>
        <Styled.FileContainer>
          {loading ? <DotWave size={20} speed={1} color="black" /> : <Icon icon={attachmentIcon} />}
          <Styled.FileContent>
            <Styled.FileName>{name}</Styled.FileName>
            <Styled.Description>({Math.round(size / 1000)} KB)</Styled.Description>
          </Styled.FileContent>
          <Styled.IconButton type="button" onClick={(e) => onDelete(e)}>
            <Icon icon={deleteIconFilled} />
          </Styled.IconButton>
        </Styled.FileContainer>
      </Styled.File>
      {!!error && <Styled.Error>{error}</Styled.Error>}
    </Styled.Root>
  )
}
