import './style.scss'

import Button from '@atoms/Button/Button'
import Status from '@atoms/Status/Status'
import { Link } from 'react-router-dom'

import StepCard from './step-card.component'

export default function OnboardingSteps({ employee, setOnboardingStep, activeStep }) {
  const getDepositDescription = () => {
    if (employee.onboarding_stage.substage.deposit_paid_by_client === 'completed') return 'We’ve received your payment'
    if (employee.onboarding_stage.substage.deposit_invoice_issued_by_cs === 'completed') return 'Invoice available'
    return 'Invoice will be generated'
  }
  return (
    <div className="onboarding-steps">
      <StepCard
        completed={employee.state === 'onboarding'}
        titleText="Add employee"
        descriptionText="We will collect and review relevant documents"
        setOnboardingStep={setOnboardingStep}
        step={0}
        activeStep={activeStep}
      />
      <StepCard
        completed={employee.onboarding_stage.substage.deposit_paid_by_client === 'completed'}
        titleText="Pay deposit"
        descriptionText={getDepositDescription()}
        additional={
          employee.onboarding_stage.substage.deposit_invoice_issued_by_cs === 'completed' ? (
            <>
              {employee.onboarding_stage.substage.deposit_paid_by_client !== 'completed' && (
                <Status status="pending_your_payment" />
              )}
              {/* <Link to="/pages/invoices">
                <Button size="small">Go to Invoices</Button>
              </Link> */}
            </>
          ) : null
        }
        setOnboardingStep={setOnboardingStep}
        step={1}
        activeStep={activeStep}
      />
      <StepCard
        completed={employee.onboarding_stage.substage.signed_ea_uploaded === 'completed'}
        titleText="Receive signed contract"
        descriptionText={
          employee.onboarding_stage.substage.signed_ea_uploaded === 'completed'
            ? 'Signed employment agreement is available'
            : 'Signed employment agreement will be uploaded asap'
        }
        setOnboardingStep={setOnboardingStep}
        step={2}
        activeStep={activeStep}
      />
    </div>
  )
}
