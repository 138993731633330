import 'bootstrap-4-grid'

// import { TestTypescript } from '@components/test-typescript/test-typescript.component'
import { USER_GROUPS } from '@core/constants'
import { AppContext } from '@core/context'
import { useKompassify } from '@core/hooks/useKompassify'
import { useKompassifySegments } from '@core/hooks/useKompassifySegments'
import { parseLinkedAccountsResponse, userHasOneOfGroupsOrSuper } from '@core/utils'
import Agreements from '@features/agreements/agreements.component'
import AdditionalPayments from '@pages/addition-payment'
import AdditionalPaymentsPage from '@pages/additional-payments/AdditionalPayments'
import BenefitsPage from '@pages/benefits/benefits-page.component'
import CalendlyPage from '@pages/Calendly'
import Chat from '@pages/chat/Chat'
import ContractorOnboardingChecklist from '@pages/contractorOnboardingChecklist/ContractorOnboardingChecklist'
import EmployeeDetailPage from '@pages/employeeDetailPage/EmployeeDetailPage'
import EmployeesPage from '@pages/employees/EmployeesPage'
import EmployeesExpense from '@pages/employeesExpense/EmployeesExpense'
import InvoicesPage from '@pages/invoices/InvoicesPage'
import { SubInvoicesPage } from '@pages/invoices/sub-invoices/SubInvoicesPage'
import OnboardingPage from '@pages/onboarding/onboarding-page'
import OnboardingEmployeeDetails from '@pages/onboardingEmployeeDetails/OnboardingEmployeeDetails'
import OverviewPage from '@pages/overview/OverviewPage'
import PayContractorsPage from '@pages/payContracts/PayContractorsPage'
import PayrollDetailEditPage from '@pages/payrollsDetailEditPage'
import { FlagPayrollDetailPage } from '@pages/payrollsDetailPage/FlagPayrollDetailPage'
import { PayrollDetailPage } from '@pages/payrollsDetailPage/PayrollDetailPage'
import ProfilePage from '@pages/profilePage/ProfilePage'
import Stripe from '@pages/stripe/stripe'
import { TimeOffPage } from '@pages/time-off/time-off.component'
import { TimesheetDetailsPage } from '@pages/timesheetDetailsPage/TimesheetDetailsPage'
import { TimesheetsPage } from '@pages/timesheets/TimesheetsPage'
import { fetchUserGroups } from '@services/iam.service'
import { getLinkedAccounts } from '@services/linked-accounts.service'
import { DotWave } from '@uiball/loaders'
import React, { Suspense, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { BrowserRouter, Navigate, Route, Routes } from 'react-router-dom'
import Pages from 'src/router'

import { ProtectedRoute } from './router/ProtectedRoute'
import {
  fetchCountries,
  fetchCurrencies,
  fetchProfile,
  fetchTermsOfServices,
  fetchUserProfile,
} from './services/app.service'

const SalaryChangePage = React.lazy(() => import('./pages/salary-change-page'))
const SalaryChangeDetailsPage = React.lazy(() => import('./pages/salary-change-details-page'))
const MergePage = React.lazy(() => import('./pages/merge/merge-page.component'))

function App() {
  const [profile, setProfile] = useState<any>()
  const {
    data: userProfile,
    isLoading: userProfileLoading,
    refetch: refetchUserProfile,
  } = useQuery('fetchUserProfile', {
    queryFn: fetchUserProfile,
  })

  const getLinkedAccountsQuery = useQuery(getLinkedAccounts.key, getLinkedAccounts.fetch)

  const userID = userProfile?.id

  const companyProfileQuery = useQuery('fetchProfile', {
    queryFn: fetchProfile,
    onSuccess: setProfile,
  })

  const hasKompassifyInit = useKompassify(profile)
  const clientCountry = profile?.legal_address?.living_country?.name

  useKompassifySegments(hasKompassifyInit, clientCountry)
  const userGroupsQuery = useQuery({
    enabled: !!userID,
    queryKey: fetchUserGroups.key,
    queryFn: () => fetchUserGroups.fetch(userID),
  })

  const { isLoading: loadingCountry, data: countries } = useQuery('fetchCountries', {
    queryFn: () => fetchCountries({ limit: 1000, offset: 0, ordering: 'name', include_all_countries: true }),
  })

  const { isLoading: loadingCountryActive, data: countriesActive } = useQuery('fetchCountriesActive', {
    queryFn: () =>
      fetchCountries({
        is_forbidden: false,
        limit: 1000,
        offset: 0,
        include_all_countries: true,
        ordering: 'name',
      }),
  })

  const { isLoading: loadingCurrency, data: currencies } = useQuery('fetchCurrencies', {
    queryFn: () => fetchCurrencies({ limit: 1000, offset: 0 }),
  })
  const { isLoading: loadingCurrencyAC, data: currenciesAC } = useQuery('fetchCurrenciesAllowedCompany', {
    queryFn: () => fetchCurrencies({ limit: 1000, offset: 0, allow_for_company: true }),
  })

  const termsMutate = useMutation({
    mutationFn: () => fetchTermsOfServices(companyProfileQuery?.data),
    onSuccess: () => {
      companyProfileQuery.refetch()
    },
  })

  if (
    companyProfileQuery.isLoading ||
    loadingCurrency ||
    loadingCountry ||
    userProfileLoading ||
    loadingCountryActive ||
    loadingCurrencyAC ||
    userGroupsQuery.isLoading ||
    getLinkedAccountsQuery.isLoading
  ) {
    return (
      <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: '100vh' }}>
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <AppContext.Provider
      // eslint-disable-next-line react/jsx-no-constructed-context-values
      value={{
        profile,
        countries: countries ? countries.results : [],
        countriesActive: countriesActive ? countriesActive.results : [],
        currencies: currencies ? currencies.results : [],
        currenciesAC: currenciesAC ? currenciesAC.results : [],
        userProfile,
        setProfile,
        refetchUserProfile,
        refetchProfile: companyProfileQuery.refetch,
        userGroups: userGroupsQuery.data,
        refetchUserGroups: userGroupsQuery.refetch,
        linkedAccounts: parseLinkedAccountsResponse(getLinkedAccountsQuery.data),
      }}
    >
      <BrowserRouter>
        <Suspense fallback={<DotWave />}>
          <Routes>
            <Route path="/">
              <Route index element={<Navigate to="pages" replace />} />
              <Route path="pages/*" element={<Pages />}>
                <Route index element={<Navigate to="dashboard" replace />} />
                <Route path="dashboard" element={<OverviewPage />} />
                <Route
                  path="chat"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <Chat />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="team"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <EmployeesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="team/:id"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <EmployeeDetailPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="team/onboarding/:id"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <OnboardingEmployeeDetails />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="team/:id/contractor-onboarding"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <ContractorOnboardingChecklist />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="timesheets"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <TimesheetsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="timesheets/:id"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <TimesheetDetailsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="team/:id/timesheets-details"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <TimesheetDetailsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="timeoff"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.PTO_MANAGER,
                        USER_GROUPS.PTO_VIEWER,
                        USER_GROUPS.HR_SPECIALIST,
                      ])}
                    >
                      <TimeOffPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="expense"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.EXPENSE_MANAGER,
                        USER_GROUPS.EXPENSE_VIEWER,
                        USER_GROUPS.HR_SPECIALIST,
                      ])}
                    >
                      <EmployeesExpense />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="agreements"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <Agreements />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="salary-revision"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <SalaryChangePage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="additional-payments"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.HR_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                      ])}
                    >
                      <AdditionalPaymentsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="salary-revision/:id"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <SalaryChangeDetailsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="invoices"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                      ])}
                    >
                      <InvoicesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="invoices/:id"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                      ])}
                    >
                      <SubInvoicesPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="stripe"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.FINANCE_AP_SPECIALIST])}
                    >
                      <Stripe />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="pay-contractors"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                      ])}
                    >
                      <PayContractorsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="pay-contractors/payments/:id"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                      ])}
                    >
                      <AdditionalPayments />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="benefits"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [USER_GROUPS.HR_SPECIALIST])}
                    >
                      <BenefitsPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="payrolls"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                      ])}
                    >
                      <PayrollDetailPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="flag_payrolls"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                      ])}
                    >
                      <FlagPayrollDetailPage />
                    </ProtectedRoute>
                  }
                />
                <Route
                  path="payrolls/edit/:id"
                  element={
                    <ProtectedRoute
                      permitted={userHasOneOfGroupsOrSuper(userGroupsQuery.data, [
                        USER_GROUPS.FINANCE_AP_SPECIALIST,
                        USER_GROUPS.FINANCE_SPECIALIST,
                      ])}
                    >
                      <PayrollDetailEditPage />
                    </ProtectedRoute>
                  }
                />
                <Route path="profile" element={<ProfilePage />} />
                <Route path="merge" element={<MergePage />} />
              </Route>
              <Route path="onboarding" element={<OnboardingPage />} />
              <Route path="calendly" element={<CalendlyPage />} />
              <Route path="*" element={<Navigate to="/" replace />} />
            </Route>
          </Routes>
        </Suspense>
      </BrowserRouter>
    </AppContext.Provider>
  )
}

export default App
