import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { EOR_TYPES, TEMPLATE_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { coin, searchIcon } from '@core/icons/icons'
import * as icons from '@core/icons/icons'
import { getAgreements } from '@services/sign.service'
import React, { useState } from 'react'
import { useQuery } from 'react-query'
import AsyncSelect from 'react-select/async'
import styled from 'styled-components'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

import { AgreementsTable } from './agreements-table/agreements-table.component'
import { CreateContract } from './create-contract/create-contract'

const DEFAULT_PAGE_LIMIT = 10

const SELECT_TYPES = [
  {
    value: TEMPLATE_TYPES.SALARY_CHANGE,
    label: 'Salary change',
  },
  {
    value: TEMPLATE_TYPES.JOB_CONTRACT,
    label: 'Employment agreement',
  },
  {
    value: TEMPLATE_TYPES.CONTRACT_TERMINATION,
    label: 'Contract termination',
  },
  {
    value: TEMPLATE_TYPES.CONTRACTOR_EMPLOYMENT,
    label: 'Contractor agreement',
  },
  {
    value: TEMPLATE_TYPES.AOR_CONTRACT,
    label: 'AOR agreement',
  },
]

const SELECT_TYPES_SAAS = [
  {
    value: TEMPLATE_TYPES.APPENDIX,
    label: 'MSA appendix',
  },
  {
    value: TEMPLATE_TYPES.MSA,
    label: 'MSA',
  },
  ...SELECT_TYPES,
]

const StyledContainer = styled.div`
  table {
    th:last-child {
      div {
        justify-content: end;
      }
    }
  }
`

const StyledTopbar = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 1.5rem;
`

const StyledToolbar = styled.div`
  display: flex;
  gap: 8px;
  margin-top: 24px;
`
const StyledInputContainer = styled.div`
  width: 248px;
`

export default function Agreements() {
  const modalOpen = useBoolean(false)
  const contractModalOpen = useBoolean(false)
  const { profile } = useApp()

  const [tab, setTab] = React.useState(0)
  const [status, setStatus] = useState('not_signed')
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [type, setType] = useState()
  const [search, setSearch] = useState()

  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(search, 500)

  const {
    isFetching,
    data: agreementsResponse,
    refetch,
  } = useQuery(['getAgreements', page, status, debouncedSearch, type], {
    queryFn: () =>
      getAgreements({
        page,
        agreement_document_type_in: type?.value,
        search: debouncedSearch,
        state_in: status,
        limit: DEFAULT_PAGE_LIMIT,
        offset: (page - 1) * DEFAULT_PAGE_LIMIT,
        ordering: '-created_at',
      }),
    onSuccess: ({ count }) => {
      setTotal(count)
    },
  })

  const onTabChange = (id) => {
    setTab(id)
    setPage(1)
    switch (id) {
      case 0:
        return setStatus('not_signed')
      case 1:
        return setStatus('canceled,declined,signed,uploaded')
      case 2:
        return setStatus('archived')
      default:
        return null
    }
  }

  const onChangeSearch = (evt) => {
    const { value } = evt.target
    setSearch(value)
    setPage(1)
  }
  const handleSelectChangeType = (option) => {
    setType(option)
  }
  const agreementsTable = (
    <AgreementsTable
      agreementsResponse={agreementsResponse}
      isFetching={isFetching}
      page={page}
      setPage={setPage}
      total={total}
      modalOpen={modalOpen}
    />
  )
  return (
    <StyledContainer>
      <StyledTopbar>
        <PageTitle>List of agreements</PageTitle>
      </StyledTopbar>
      <Tabs selectedTab={tab} onTabChange={onTabChange}>
        <Tab tabId={0} title="Pending agreements">
          <NotificationBlock
            text="All agreements will be stored here"
            cards={[
              {
                title: 'Sign Pending agreements',
                description: 'Select the “Sign” button next to the agreement and sign it on the platform.',
                icon: coin,
              },
              {
                title: 'Review your signed agreements',
                description:
                  'All signed agreements will be stored in the “Signed agreements” tab. You can download the documents from there.',
                icon: coin,
              },
            ]}
          />
          <StyledToolbar>
            <StyledInputContainer>
              <Input
                data-testid="agreements.component-A92E2A"
                onChange={onChangeSearch}
                placeholder="Search"
                type="text"
                name="search"
                value={search}
                endIcon={<Icon icon={searchIcon} />}
                styleClass="employees-page-search"
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <AsyncSelect
                defaultOptions={profile?.eor_type === EOR_TYPES.SAAS ? SELECT_TYPES_SAAS : SELECT_TYPES}
                isClearable
                placeholder="Select type"
                value={type}
                onChange={handleSelectChangeType}
              />
            </StyledInputContainer>
            <Button
              data-testid="agreements.component-E53BFC"
              className="ml-auto"
              size="small"
              onClick={contractModalOpen.setTrue}
            >
              <Icon icon={icons.add} className="mr-2" style={{ fill: 'white' }} />
              Create new contract
            </Button>
          </StyledToolbar>
          {agreementsTable}
        </Tab>
        <Tab tabId={1} title="Signed agreements">
          <NotificationBlock
            text="How onboarding works?"
            cards={[
              {
                title: 'Add your team members',
                description: 'You add your employee’s information to Remofirst',
                icon: coin,
              },
              {
                title: 'We invite your team members',
                description: 'We invite them to the platform and sign the contract with them',
                icon: coin,
              },
              {
                title: 'Notification',
                description: 'You receive notification once everything is ready',
                icon: coin,
              },
            ]}
          />
          <StyledToolbar>
            <StyledInputContainer>
              <Input
                data-testid="agreements.component-97A627"
                onChange={onChangeSearch}
                placeholder="Search"
                type="text"
                name="search"
                value={search}
                endIcon={<Icon icon={searchIcon} />}
                styleClass="employees-page-search"
              />
            </StyledInputContainer>
            <StyledInputContainer>
              <AsyncSelect
                defaultOptions={SELECT_TYPES}
                isClearable
                placeholder="Select type"
                value={type}
                onChange={handleSelectChangeType}
              />
            </StyledInputContainer>
          </StyledToolbar>
          {agreementsTable}
        </Tab>
        <Tab tabId={2} title="Archived agreements">
          <NotificationBlock text="All agreements that were amended will be stored here" />
          <StyledToolbar>
            <StyledInputContainer>
              <Input
                onChange={onChangeSearch}
                placeholder="Search by name"
                type="text"
                name="search"
                value={search}
                endIcon={<Icon icon={searchIcon} />}
                styleClass="employees-page-search"
              />
            </StyledInputContainer>
          </StyledToolbar>
          {agreementsTable}
        </Tab>
      </Tabs>
      {contractModalOpen.value && <CreateContract onClose={contractModalOpen.setFalse} refetch={refetch} />}
    </StyledContainer>
  )
}
