import { Api } from '@api/Api'
import axios from 'axios'

export const getEmployeeDetails = async (id) => {
  const { data } = await Api.get(`/contracts/${id}/`)
  return data
}

export const postUnisure = async (body) => {
  const { data } = await axios.post(`https://remoteio.unisuregroup.com/members_access`, body)
  return data
}

export const exportEmployeesXLS = async () => {
  const { data } = await Api.get('/contracts/export-fte-information/', {
    responseType: 'blob',
  })

  return data
}

export const exportContractorsXLS = async () => {
  const { data } = await Api.get('/contracts/export-contractors-information/', {
    responseType: 'blob',
  })

  return data
}
