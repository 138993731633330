import Button from '@atoms/Button/Button'
import { StringToBoolean } from '@core/utils'
import { CardRemoHealth } from '@features/benefits/CardRemoHealth'
import moment from 'moment'
import React from 'react'
import { Controller, FormProvider, useForm } from 'react-hook-form'

import CardProvideWorkEquipment from './CardProvideWorkEquipment'

export default ({ loading, onFinish, workingCountry }) => {
  const methods = useForm({
    defaultValues: {
      provides_work_equipment: false,
      equipment_specification: '',
      provides_health_insurance: '',
    },
  })
  const { control, handleSubmit } = methods

  const submit = (data) => {
    const { provides_work_equipment, equipment_specification, provides_health_insurance } = data
    const insurance = provides_health_insurance
      ? {
          insurance_price: +data.insuranceId,
          family_members_amount: +data.family_members_amount,
        }
      : null
    const benefit = {
      provides_work_equipment: StringToBoolean(provides_work_equipment),
      equipment_specification,
    }
    onFinish(benefit, insurance)
  }

  return (
    <FormProvider {...methods}>
      <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100">
        <section className=" flex-grow-1 d-flex flex-column justify-content-center align-items-center">
          {workingCountry?.allowed_remohealth && (
            <Controller
              control={control}
              name="provides_health_insurance"
              render={({ field }) => <CardRemoHealth countryId={workingCountry.id} {...field} />}
            />
          )}
          <Controller
            control={control}
            name="provides_work_equipment"
            render={({ field }) => <CardProvideWorkEquipment {...field} />}
          />
        </section>
        <div className="pb-5 align-self-center">
          <Button
            data-testid="BenefitsForm-052D0E"
            type="submit"
            className="align-self-end"
            loading={loading}
            disabled={loading}
          >
            Finish
          </Button>
        </div>
      </form>
    </FormProvider>
  )
}
