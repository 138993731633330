import { Styled } from './swift-fee-pay-role-tooltip.styles'

export const SwiftFeePayRoleTooltip = () => {
  return (
    <div className="d-flex flex-column gap-1">
      <div>{`When paying contractors internationally, SWIFT fees apply if the payment currency differs from the bank's local currency. Choose how to handle these fees:`}</div>
      <Styled.OrderedList>
        <Styled.BoldListItem>Client Covers SWIFT Fees</Styled.BoldListItem>
        <ul>
          <li>You pay the full $20 SWIFT fee for each contractor.</li>
          <li>The fee is added to your invoice.</li>
        </ul>
        <Styled.BoldListItem>Contractor Covers SWIFT Fees</Styled.BoldListItem>
        <ul>
          <li>The contractor pays the $20 SWIFT fee.</li>
          <li>The fee is deducted from their payment.</li>
        </ul>
        <Styled.BoldListItem>Shared SWIFT Fee</Styled.BoldListItem>
        <ul>
          <li>The fee is split equally: $10 each.</li>
          <li>You pay $10, added to your invoice.</li>
          <li>{`The contractor's payment is reduced by $10.`}</li>
        </ul>
      </Styled.OrderedList>
      <div>Select an option to manage SWIFT fees for international contractor payments.</div>
    </div>
  )
}
