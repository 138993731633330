import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'ALLOWANCE', label: 'Allowance' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`
const formId = 'payment_create_form'

export default ({ isLoading, onHide, onCreate, currency }) => {
  const { currencies } = useApp()

  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ap_type: '',
      amount: '',
      name: '',
      description: '',
      currency: currency?.id,
    },
  })

  const submit = (formValues) => {
    onCreate({
      ...formValues,
      currency: formValues.currency.value || formValues.currency,
      ap_type: formValues.ap_type.value,
    })
  }
  return (
    <Modal show onHide={onHide} centered>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">New Additional Payment</Typography>
      </Modal.Header>
      <Modal.Body>
        <StyledForm noValidate id={formId} className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="ap_type"
                rules={{ required: 'Field is required' }}
                render={({ field }) => {
                  return (
                    <Select data-testid="CreateModal-67577B" {...field} label="Payment type" options={paymentTypes} />
                  )
                }}
              />
              {errors.ap_type && (
                <Typography className="text_regular__14 color_red">{errors.ap_type.message}</Typography>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="amount"
                rules={{
                  required: 'Field is required',
                  validate: {
                    minlength: (v) =>
                      /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
                  },
                }}
                render={({ field }) => (
                  <CurrencyInput
                    {...field}
                    label="Amount"
                    placeholder="Enter the adjustment amount"
                    onChange={setCurrencyChangeEvent(field.onChange)}
                  />
                )}
              />
              {errors.amount && <Typography className="text_regular__14 color_red">{errors.amount.message}</Typography>}
            </div>
          </div>

          <div className="row">
            <div className="col">
              <Controller
                control={control}
                name="currency"
                rules={{ required: 'Currency is required' }}
                render={({ field }) => {
                  return (
                    <Select
                      data-testid="CreateModal-4F10DA"
                      {...field}
                      label="Currency"
                      options={currencies.map((country) => ({
                        value: country.id,
                        label: country.name || country.short_code,
                      }))}
                    />
                  )
                }}
              />
              {errors.currency && (
                <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>
              )}
            </div>
          </div>

          <div className="row">
            <div className="col-12">
              <Input
                data-testid="CreateModal-FF8B85"
                {...register('name', { required: 'Field is required' })}
                label="Reason name"
                placeholder="Title"
              />
              {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
            </div>
            <div className="col-12">
              <TextArea placeholder="Description..." {...register('description')} />
              {errors.description && (
                <Typography className="text_regular__14 color_red">{errors.description.message}</Typography>
              )}
            </div>
          </div>
        </StyledForm>
      </Modal.Body>
      <Modal.Footer>
        <Button data-testid="CreateModal-AC58F4" priority="secondary" size="small" type="button" onClick={onHide}>
          Close
        </Button>
        <Button
          data-testid="CreateModal-7AA0F2"
          type="submit"
          form={formId}
          size="small"
          disabled={isLoading}
          loading={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
