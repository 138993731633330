import { Api } from '@api/Api'

export const createDraftContract = async (body) => {
  const { data } = await Api.post(`draft-contracts/create/`, body)
  return data
}

export const getDraftContractList = async () => {
  const { data } = await Api.get(`draft-contracts/`)
  return data
}

export const getDraftContractDetails = async (id) => {
  const { data } = await Api.get(`/draft-contracts/${id}/`)
  return data
}

export const updateDraftContract = async (id, body) => {
  const { data } = await Api.put(`draft-contracts/${id}/update/`, body)
  return data
}

export const deleteDraftContract = async (id) => {
  const { data } = await Api.delete(`draft-contracts/${id}/delete/`)
  return data
}
