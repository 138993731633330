import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import usePagination from '@core/hooks/usePagination'
import { add } from '@core/icons/icons'
import { deleteMilestoneById, getMilestones, updateMilestoneStatus } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import { useCallback, useMemo, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { DeleteMilestoneConfirmModal } from './DeleteMilestoneConfirmModal'
import { MilestoneModal } from './MilestonesModal'
import { RejectMilestoneConfirmModal } from './RejectMilestoneConfirmModal'
import { ReviewMilestoneConfirmModal } from './ReviewMilestoneConfirmModal'
import { displayNotFoundMilesontes, getMilestoneFields } from './utils'

export const MilestonesTab = ({ employee }) => {
  const milestoneAddOrEditModalOpen = useBoolean(false)
  const [tab, setTab] = useState(0)
  const [selectedMilestone, setSelectedMilestone] = useState(null)
  const [isEditMode, setIsEditMode] = useState(false)
  const [selectedMilestoneDelete, setSelectedMilestoneDelete] = useState(null)
  const [selectedMilestoneIdToReview, setSelectedMilestoneIdToReview] = useState(null)

  const deleteConfirmOpen = useBoolean(false)
  const reviewConfirmOpen = useBoolean(false)
  const rejectConfirmOpen = useBoolean(false)

  const {
    page: pendingMilestonesPage,
    setPage: setPendingMilestonesPage,
    limit: pendingMilestonesPageSize,
  } = usePagination({ page: 1, limit: 10 })

  const {
    page: approvedMilestonesPage,
    setPage: setApprovedMilestonesPage,
    limit: approvedMilestonesPageSize,
  } = usePagination({ page: 1, limit: 10 })

  const {
    isFetching: isPendingMilestinesFetching,
    data: pendingMilestones,
    refetch: refetchPendingMilestones,
  } = useQuery(['pending-milestones', pendingMilestonesPage, pendingMilestonesPageSize], {
    queryFn: () => {
      return getMilestones.fetch(employee.id, {
        offset: (pendingMilestonesPage - 1) * pendingMilestonesPageSize,
        limit: pendingMilestonesPageSize,
        state_in: 'created,in_progress,to_revise,completed',
      })
    },
    enabled: tab === 0 && Boolean(employee?.id),
  })

  const {
    isFetching: isApprovedMilestonesFetching,
    data: approvedMilestones,
    refetch: refetchApprovedMilestones,
  } = useQuery(['approved-milestones', approvedMilestonesPage, approvedMilestonesPageSize], {
    queryFn: () => {
      return getMilestones.fetch(employee.id, {
        offset: (approvedMilestonesPage - 1) * approvedMilestonesPageSize,
        limit: approvedMilestonesPageSize,
        state_in: 'pending_payment_approval,paid,pending_invoice',
      })
    },
    enabled: tab === 1 && Boolean(employee?.id),
  })

  const deleteMilestone = useMutation({
    mutationFn: ({ contractorId, milestoneId }) => deleteMilestoneById(contractorId, milestoneId),
    onSuccess: () => {
      refetchPendingMilestones()
      refetchApprovedMilestones()
    },
  })

  const { mutateAsync: updateMilestoneState, isLoading } = useMutation({
    mutationFn: (payload) => updateMilestoneStatus.fetch(employee.id, selectedMilestoneIdToReview, payload),
    onSuccess: () => {
      reviewConfirmOpen.setFalse()
      rejectConfirmOpen.setFalse()
      refetchPendingMilestones()
    },
    onError: (error) => {
      console.log(error.message)
    },
  })

  const handleEdit = useCallback(
    (event, row) => {
      event.stopPropagation()
      setIsEditMode(true)
      if (tab === 0) {
        setSelectedMilestone(pendingMilestones?.results.find((item) => item.milestone_id === row.milestone_id))
      } else if (tab === 1) {
        setSelectedMilestone(approvedMilestones?.results.find((item) => item.milestone_id === row.milestone_id))
      }
      milestoneAddOrEditModalOpen.setTrue()
    },
    [approvedMilestones, milestoneAddOrEditModalOpen, pendingMilestones, tab]
  )

  const handleDelete = useCallback(
    (event, id) => {
      event.stopPropagation()
      setSelectedMilestoneDelete(id)
      deleteConfirmOpen.setTrue()
    },
    [deleteConfirmOpen]
  )

  const handleReview = useCallback(
    (event, id) => {
      event.stopPropagation()
      setSelectedMilestoneIdToReview(id)
      reviewConfirmOpen.setTrue()
    },
    [reviewConfirmOpen]
  )

  const handleInvoiceClick = useCallback((link) => {
    if (link) {
      window.open(link, '_blank')
    }
  }, [])

  const handleDeleteMilestone = async () => {
    await deleteMilestone.mutateAsync({ contractorId: employee.id, milestoneId: selectedMilestoneDelete })

    if (tab === 0) {
      if (pendingMilestones?.results.length === 1 && pendingMilestonesPage > 1) {
        setPendingMilestonesPage(pendingMilestonesPage - 1)
      }
      refetchPendingMilestones()
    }

    if (tab === 1) {
      if (approvedMilestones?.results.length === 1 && approvedMilestonesPage > 1) {
        setApprovedMilestonesPage(approvedMilestonesPage - 1)
      }
      refetchApprovedMilestones()
    }

    deleteConfirmOpen.setFalse()
  }

  const handleMilestoneConfirm = async () => {
    await updateMilestoneState({ status: 'pending_invoice' })
  }

  const handleMilestoneReject = () => {
    reviewConfirmOpen.setFalse()
    rejectConfirmOpen.setTrue()
  }

  const handleMilestoneRejectConfirm = async ({ rejection_reason }) => {
    await updateMilestoneState({ status: 'to_revise', rejection_reason })
  }

  const fields = useMemo(
    () =>
      getMilestoneFields(
        handleEdit,
        handleDelete,
        employee.compensation.currency.sign,
        handleReview,
        handleInvoiceClick
      ),
    [employee.compensation.currency.sign, handleDelete, handleEdit, handleInvoiceClick, handleReview]
  )

  const onTabChange = (id) => {
    setTab(id)
    setApprovedMilestonesPage(1)
    setPendingMilestonesPage(1)
  }

  const renderPendingMilestones = () => {
    if (pendingMilestones?.results.length) {
      return (
        <CustomTable
          fields={fields}
          page={pendingMilestonesPage}
          total={pendingMilestones?.count || 0}
          onPage={setPendingMilestonesPage}
          pageSize={pendingMilestonesPageSize}
          data={pendingMilestones?.results || []}
        />
      )
    }
    return displayNotFoundMilesontes('There are no pending milestones yet.')
  }

  const renderApprovedMilestones = () => {
    if (approvedMilestones?.results.length) {
      return (
        <CustomTable
          fields={fields}
          page={approvedMilestonesPage}
          total={approvedMilestones?.count || 0}
          onPage={setApprovedMilestonesPage}
          pageSize={approvedMilestonesPageSize}
          data={approvedMilestones?.results || []}
        />
      )
    }
    return displayNotFoundMilesontes('There are no approved milestones yet.')
  }

  return (
    <div>
      <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button onClick={milestoneAddOrEditModalOpen.setTrue} className="text_regular__14" size="small">
          <Icon icon={add} width={25} fill="#fff" />
          Add milestone
        </Button>
      </div>
      <Tabs className="mt-4" onTabChange={onTabChange} selectedTab={tab}>
        <Tab tabId={0} title="Pending">
          {isPendingMilestinesFetching ? (
            <div style={{ display: 'flex', height: 500, alignItems: 'center', justifyContent: 'center' }}>
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            renderPendingMilestones()
          )}
        </Tab>
        <Tab tabId={1} title="Approved">
          {isApprovedMilestonesFetching ? (
            <div style={{ display: 'flex', height: 500, alignItems: 'center', justifyContent: 'center' }}>
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            renderApprovedMilestones()
          )}
        </Tab>
      </Tabs>
      <MilestoneModal
        contractorId={employee.id}
        show={milestoneAddOrEditModalOpen.value}
        onClose={() => {
          milestoneAddOrEditModalOpen.setFalse()
          setSelectedMilestone(null)
        }}
        editMode={isEditMode}
        currency={employee.compensation.currency.sign}
        selectedMilestone={selectedMilestone}
        setSelectedMilestone={setSelectedMilestone}
        refetch={tab === 0 ? refetchPendingMilestones : refetchApprovedMilestones}
      />
      <DeleteMilestoneConfirmModal
        show={deleteConfirmOpen.value}
        onHide={deleteConfirmOpen.setFalse}
        onConfirm={handleDeleteMilestone}
        isLoading={deleteMilestone.isLoading}
      />
      <ReviewMilestoneConfirmModal
        show={reviewConfirmOpen.value}
        onHide={reviewConfirmOpen.setFalse}
        onConfirm={handleMilestoneConfirm}
        onReject={handleMilestoneReject}
        isLoading={isLoading}
      />
      <RejectMilestoneConfirmModal
        show={rejectConfirmOpen.value}
        onHide={rejectConfirmOpen.setFalse}
        onConfirm={handleMilestoneRejectConfirm}
        isLoading={isLoading}
      />
    </div>
  )
}
