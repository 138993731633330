import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { BENEFIT_TYPES, REMOHEALTH_STATUSES } from '@core/constants'
import { addWhite, edit } from '@core/icons/icons'
import EmptyBenefit from '@pages/employeeDetailPage/libs/Benefit/EmptyBenefit'
import { EquipmentDeliveryModal } from '@pages/employeeDetailPage/libs/Benefit/EquipmentDeliveryModal'
import { RemoHealthModal } from '@pages/employeeDetailPage/libs/Benefit/RemoHealthModal'
import { updateContractInsurance, updateContractInsuranceStatus } from '@services/health-insurance.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React, { useState } from 'react'
import { FormProvider, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { ModalBenefit } from './ModalBenefit'

const DEFAULT_PAGE_LIMIT = 10

const transformHealthInsuranceData = (employee) => {
  const result = []
  if (employee?.benefit.provides_work_equipment) {
    result.push({
      id: employee.id,
      created: moment(employee.benefit?.created_at).format('DD MMM YYYY'),
      equipment_specification: employee.benefit.equipment_specification,
      delivered_at: new Date(employee.benefit.delivered_at),
      type: (
        <span>
          Equipment delivery{' '}
          <span style={{ color: '#6D6E85', fontSize: '12px' }}>({employee.benefit.equipment_specification})</span>
        </span>
      ),
      started: employee?.benefit.delivered_at ? moment(employee?.benefit.delivered_at).format('DD MMM YYYY') : '-',
      status: '-',
      benefitType: BENEFIT_TYPES.EQUIPMENT_DELIVERY,
    })
  }
  if (employee.insurances?.length) {
    employee.insurances.forEach((insurance) => {
      result.push({
        id: employee.id,
        country: employee.working_country,
        insurance_price_id: insurance.insurance_price.id,
        family_members_amount: insurance.family_members_amount,
        created: moment(insurance.created_at).format('DD MMM YYYY'),
        type: (
          <span>
            RemoHealth{' '}
            <span style={{ color: '#6D6E85', fontSize: '12px' }}>({insurance.insurance_price?.insurance_type})</span>
          </span>
        ),
        started: insurance.starts_at ? moment(insurance.starts_at).format('DD MMM YYYY') : '-',
        status: insurance.status,
        benefitType: BENEFIT_TYPES.REMOHEALTH,
      })
    })
  }

  return result
}

export default function Benefit({ employee, setEmployee, refetch }) {
  const [page, setPage] = useState(1)
  const data = employee ? transformHealthInsuranceData(employee) : []
  const addOpen = useBoolean(false)
  const deleteOpen = useBoolean(false)
  const terminateOpen = useBoolean(false)
  const editOpen = useBoolean(false)
  const { value: editEquipmentOpen, toggle: toggleEditEquipment } = useBoolean(false)
  const [selectedBenefitDelete, setSelectedBenefitDelete] = useState(null)
  const [selectedBenefitTerminate, setSelectedBenefitTerminate] = useState(null)
  const [editData, setEditData] = useState()

  const methods = useForm()

  const age = employee?.profile?.birth_date ? moment().diff(employee?.profile?.birth_date, 'years') : ''

  const updateInsuranceMutate = useMutation({
    mutationFn: updateContractInsurance,
    onSuccess: () => {
      refetch()
      deleteOpen.setFalse()
      editOpen.setFalse()
      terminateOpen.setFalse()
      toast.success('Benefit successfully updated')
    },
  })

  const updateInsuranceStatusMutate = useMutation({
    mutationFn: updateContractInsuranceStatus,
    onSuccess: () => {
      refetch()
      deleteOpen.setFalse()
      editOpen.setFalse()
      terminateOpen.setFalse()
      toast.success('Benefit successfully updated')
    },
  })

  const onRowClick = (row) => {
    if (row.benefitType === BENEFIT_TYPES.REMOHEALTH) {
      methods.reset({
        insuranceId: row.insurance_price_id,
        family_members_amount: row.family_members_amount,
      })
      editOpen.toggle()
    }

    setEditData(row)
  }

  const onEdit = ({ insuranceId, family_members_amount }) => {
    updateInsuranceMutate.mutate({
      id: editData.id,
      body: { insurance_price: insuranceId, family_members_amount },
    })
  }

  const handleDelete = (row) => {
    deleteOpen.toggle()
    setSelectedBenefitDelete(row)
  }
  const handleTerminate = (row) => {
    terminateOpen.toggle()
    setSelectedBenefitTerminate(row)
  }
  const onClickBenefitDelete = () => {
    if (selectedBenefitDelete.benefitType === BENEFIT_TYPES.REMOHEALTH) {
      updateInsuranceStatusMutate.mutate({
        id: selectedBenefitDelete.id,
        body: { status: 'canceled' },
      })
    }
  }
  const onClickBenefitTerminate = () => {
    if (selectedBenefitTerminate.benefitType === BENEFIT_TYPES.REMOHEALTH) {
      updateInsuranceStatusMutate.mutate({
        id: selectedBenefitTerminate.id,
        body: { status: 'terminated' },
      })
    }
  }
  const fields = [
    // {
    //   title: 'Created at',
    //   key: 'created',
    //   width: 150,
    // },
    {
      title: 'Type',
      key: 'type',
      width: 650,
    },
    {
      title: 'Start date',
      key: 'started',
    },
    {
      title: 'Status',
      key: 'status',
      type: 'status',
    },
    {
      title: 'Action',
      key: 'custom',
      type: 'custom',
      maxWidth: 160,
      minWidth: 160,
      width: 160,
      render: (row) =>
        row.benefitType === BENEFIT_TYPES.REMOHEALTH ? (
          <div className="d-flex align-items-center justify-content-end">
            <div>
              {row.status === REMOHEALTH_STATUSES.CREATED ? (
                <Icon fill="none" className="mr-4" icon={edit} onClick={() => onRowClick(row)} />
              ) : null}
            </div>

            {row.status === REMOHEALTH_STATUSES.CREATED ? (
              <Button
                data-testid="Benefit-F72733"
                priority="secondary"
                size="small"
                onClick={() => handleDelete(row)}
                style={{ height: 32 }}
              >
                Cancel
              </Button>
            ) : null}
            {row.status === REMOHEALTH_STATUSES.ACTIVE || row.status === REMOHEALTH_STATUSES.SUBMITTED ? (
              <Button
                data-testid="Benefit-2CD15C"
                priority="secondary"
                size="small"
                onClick={() => handleTerminate(row)}
                style={{ height: 32 }}
              >
                Terminate
              </Button>
            ) : null}
          </div>
        ) : null,
    },
  ]

  if (!employee) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center mb-5">
        <DotWave />
      </div>
    )
  }

  return (
    <div>
      <div className="d-flex flex-column">
        {data?.length > 0 ? (
          <>
            <div className="d-flex align-items-center justify-content-end mb-2">
              <Button data-testid="Benefit-6D9E2F" priority="primary" size="small" onClick={addOpen.toggle}>
                <Icon icon={addWhite} className="mr-2" />
                Add benefit
              </Button>
            </div>
            <CustomTable
              fields={fields}
              data={data}
              loading={!employee}
              page={page}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          </>
        ) : (
          <EmptyBenefit onAddBenefit={addOpen.toggle} />
        )}
      </div>

      {editOpen.value && editData && (
        <FormProvider {...methods}>
          <RemoHealthModal
            isEdit
            age={age}
            isLoading={updateInsuranceMutate.isLoading}
            countryId={editData.country.id}
            onClose={editOpen.toggle}
            goBack={editOpen.toggle}
            onSubmit={onEdit}
          />
        </FormProvider>
      )}
      {editEquipmentOpen && editData && (
        <FormProvider {...methods}>
          <EquipmentDeliveryModal
            onClose={toggleEditEquipment}
            setEmployee={setEmployee}
            goBack={toggleEditEquipment}
          />
        </FormProvider>
      )}

      {addOpen.value && (
        <ModalBenefit
          age={age}
          refetch={refetch}
          employee={employee}
          onClose={addOpen.setFalse}
          countryId={employee?.working_country?.id}
          setEmployee={setEmployee}
        />
      )}
      <EditModal
        visible={deleteOpen.value}
        title="Cancel benefit"
        onSave={() => {}}
        footer={false}
        closeModal={deleteOpen.toggle}
      >
        <Typography className="heading_semi__16">Are you sure you want to cancel this benefit?</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="Benefit-8838F9"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={deleteOpen.toggle}
          >
            Close
          </Button>
          <Button
            data-testid="Benefit-9CD985"
            priority="primary_dangerous"
            size="small"
            loading={updateInsuranceMutate.isLoading}
            onClick={onClickBenefitDelete}
          >
            Cancel forever
          </Button>
        </div>
      </EditModal>
      <EditModal
        visible={terminateOpen.value}
        title="Terminate benefit"
        onSave={() => {}}
        footer={false}
        closeModal={terminateOpen.toggle}
      >
        <Typography className="heading_semi__16">Are you sure you want to terminate this benefit?</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="Benefit-4BDCBF"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={terminateOpen.toggle}
          >
            Close
          </Button>
          <Button
            data-testid="Benefit-961F74"
            priority="primary_dangerous"
            size="small"
            loading={updateInsuranceMutate.isLoading}
            onClick={onClickBenefitTerminate}
          >
            Terminate
          </Button>
        </div>
      </EditModal>
    </div>
  )
}
