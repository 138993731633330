import Icon from '@atoms/Icon/Icon'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import { heartIcon, infoFilledIcon } from '@core/icons/icons'
import { getList } from '@services/health-insurance.service'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'

import { InsuranceList } from './insurance-list/insurance-list.component'

const Card = styled.div`
  position: relative;
  width: 520px;
  min-height: 120px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 8px;
`
const CardContent = styled.div`
  width: 390px;
`

const StyledIcon = styled(Icon)`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
`
const StyledIconWrapper = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  overflow: hidden;
  width: 150px;
  z-index: 2;
  height: 100%;
`
const MinPrice = styled.p`
  position: absolute;
  top: 50px;
  right: 35px;
  z-index: 2;
  font-weight: 600;
  font-size: 16px;
  line-height: 100%;
  color: #ffffff;
  transform: rotate(16.01deg);
`

const TextDescription = styled.div`
  color: #484848;
`

export const CardRemoHealth = ({ countryId, ...field }) => {
  const { watch } = useFormContext()
  const health_insurance = watch('provides_health_insurance')
  const { data, isLoading } = useQuery({
    queryKey: ['getHealthInsuranceList', countryId],
    queryFn: () =>
      getList({
        country_id: countryId,
      }),
    enabled: !!countryId,
  })
  return (
    <Card>
      {data?.results.length ? (
        <>
          <StyledIconWrapper>
            <StyledIcon icon={heartIcon} fill="none" />
          </StyledIconWrapper>
          <MinPrice>{Math.floor(data.results[0].price)} USD</MinPrice>
        </>
      ) : null}

      <div className="d-flex">
        <div className="mr-3">
          <Toggle {...field} id="provides_health_insurance" />
        </div>

        <CardContent>
          <Typography className="heading_semibold__18 mb-2">RemoHealth packages</Typography>
          <TextDescription className="text_regular-normal__14">
            Check the available RemoHealth plans{' '}
            <a
              data-testid="CardRemoHealth-F7CFFE"
              href="https://remofirst-static.s3.amazonaws.com/remohealth/Remohealth.pdf"
              target="_blank"
              rel="noreferrer"
              className="link"
              onClick={(e) => e.stopPropagation()}
            >
              here
            </a>
          </TextDescription>
        </CardContent>
      </div>
      {health_insurance && (
        <div className="mt-5">
          <InsuranceList loading={isLoading} insuranceTypes={data?.results || []} />
        </div>
      )}
    </Card>
  )
}
