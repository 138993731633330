import './NotFound.scss'

import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import React from 'react'

export default function NotFound({ title, description, illustration, action, loading, onClickAction, className }) {
  return (
    <div className={classNames([className, 'not-found'])}>
      {loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 300 }}>
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div className="not-found_content">
          <img src={illustration || '/assets/img/notFound.png'} alt="" />
          <Typography className="heading_semibold__20 mt-4 mb-2">{title}</Typography>
          <Typography className="text_regular__14 color_text_300">{description}</Typography>
          {action && (
            <Button
              data-testid="NotFound-D85C7B"
              priority="secondary"
              size="small"
              className="mt-3"
              onClick={onClickAction}
            >
              {action}
            </Button>
          )}
        </div>
      )}
    </div>
  )
}
