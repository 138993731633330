import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 16px;
    border-radius: 16px;
  }
  .modal-header {
    border-bottom: none;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .modal-footer {
    border-top: none;
  }
`

export default function EditModal({
  visible,
  closeModal,
  title,
  onSave,
  okText = 'Save',
  size = 'md',
  children,
  loading = false,
  footer = true,
  scrollable = false,
}) {
  return (
    <StyledModal centered size={size} show={visible} onHide={closeModal} scrollable={scrollable} animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body>{children}</Modal.Body>
      {footer && (
        <Modal.Footer>
          <Button
            data-testid="EditModal-B91879"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={closeModal}
          >
            Cancel
          </Button>
          <Button data-testid="EditModal-48A562" priority="primary" size="small" disabled={loading} onClick={onSave}>
            {okText}
          </Button>
        </Modal.Footer>
      )}
    </StyledModal>
  )
}
