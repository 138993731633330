import { TimeOffCarryoverExpiry, TimeOffPolicyDto } from '../../../core/types/time-off.types'
import { TimeOffPolicyFormValues } from './time-off-policy-form.component'

export const parsePayload = (data: TimeOffPolicyFormValues): TimeOffPolicyDto => ({
  annual_days_by_contract: data.annualDaysByContract,
  annual_days_rule: data.annualDaysRule,
  accrual_rule: data.accrualRule,
  allow_carryover: data.allowCarryover === 'true',
  carryover_rule: data.carryoverRule,
  maximum_carryover_days_per_year: +data.maxCarryoverDays,
  carryover_expiry_amount: +data.carryoverExpiryAmount,
  carryover_expiry_unit: data.carryoverExpiryUnit,
  sick_leave_days_rule: data.sickLeaveDaysRule,
})

export const parseValues = (data: TimeOffPolicyDto): TimeOffPolicyFormValues => ({
  unlimitedTimeOff: `${data.unlimited_time_off_on}`,
  annualDaysByContract: data.annual_days_by_contract,
  accrualRule: data.accrual_rule,
  annualDaysRule: data.annual_days_rule,
  allowCarryover: `${data.allow_carryover}`,
  carryoverRule: data.carryover_rule,
  maxCarryoverDays: data.maximum_carryover_days_per_year,
  carryoverExpiry: data.carryover_expiry_amount ? TimeOffCarryoverExpiry.Custom : TimeOffCarryoverExpiry.None,
  carryoverExpiryAmount: data.carryover_expiry_amount,
  carryoverExpiryUnit: data.carryover_expiry_unit,
  sickLeaveDaysRule: data.sick_leave_days_rule,
})
