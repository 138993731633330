import React from 'react'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
`
const Container = styled.div`
  display: flex;
  gap: 10px;
  font-size: 12px;
  flex: 1;
`
const Info = styled.div`
  width: 60%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
`
const Title = styled.h4`
  font-size: 18px;
  font-family: 'Manrope', sans-serif;
  color: #121212;
  font-weight: 600;
  margin-bottom: 1.5rem;
`
const Signers = styled.div``
const SignersLabel = styled.div`
  margin-bottom: 1rem;
  font-weight: 600;
  font-size: 13px;
`
const SignerList = styled.div`
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  justify-content: space-between;
`
const Signer = styled.div`
  background-color: #f9f9f9;
  border-radius: 8px;
  padding: 0.5rem 0.75rem;
  margin-bottom: 1rem;
  width: calc(50% - 4px);
`
const SignerName = styled.div`
  font-weight: 500;
  margin-bottom: 2px;
  color: #121212;
`
const SignerRole = styled.div`
  color: #878787;
`

const MoreInfo = styled.div`
  width: 40%;
  padding: 1rem;
  border-radius: 8px;
  background-color: #fff;
`
const MoreInfoItem = styled.div`
  margin-bottom: 1rem;
`
const MoreInfoItemLabel = styled.div`
  font-weight: 600;
  font-size: 13px;
  margin-bottom: 4px;
`
const MoreInfoItemTextPrimary = styled.div`
  margin-bottom: 4px;
`
const MoreInfoItemTextSecondary = styled.div`
  margin-bottom: 4px;
  color: #878787;
`

export default function AgreementDetail({ data }) {
  return (
    <Wrapper>
      <Container>
        <Info>
          <Title>{data?.title}</Title>
          <Signers>
            <SignersLabel>Signers</SignersLabel>
            <SignerList>
              {data?.signatures?.map(({ user, signer_role }) => (
                <Signer key={user?.email}>
                  <SignerName>{user?.email}</SignerName>
                  <SignerRole>{signer_role}</SignerRole>
                </Signer>
              ))}
            </SignerList>
          </Signers>
        </Info>
        <MoreInfo>
          <Title>More info</Title>
          <MoreInfoItem>
            <MoreInfoItemLabel>Sent by</MoreInfoItemLabel>
            {data?.send_by ? (
              <>
                <MoreInfoItemTextPrimary>{data?.send_by?.full_name}</MoreInfoItemTextPrimary>
                <MoreInfoItemTextSecondary>{data?.send_by?.email}</MoreInfoItemTextSecondary>
              </>
            ) : (
              '-'
            )}
          </MoreInfoItem>
          <MoreInfoItem>
            <MoreInfoItemLabel>Agreement ID</MoreInfoItemLabel>
            <MoreInfoItemTextPrimary>{data?.id}</MoreInfoItemTextPrimary>
          </MoreInfoItem>
          <MoreInfoItem>
            <MoreInfoItemLabel>Message</MoreInfoItemLabel>
            <MoreInfoItemTextPrimary>{data?.subject}</MoreInfoItemTextPrimary>
            <MoreInfoItemTextSecondary>This will be used in email</MoreInfoItemTextSecondary>
          </MoreInfoItem>
        </MoreInfo>
      </Container>
    </Wrapper>
  )
}
