import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { CompanyInformation } from '../../../company-information/company-information'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  .modal-header {
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
    min-width: 600px;
    width: fit-content;
  }
  .modal-body {
    position: relative;
  }
`

export const CompanyInformationEditForm = ({ title, onClose }) => {
  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">{title}</Typography>
      </Modal.Header>
      <Modal.Body>
        <CompanyInformation isModal onNext={onClose} />
      </Modal.Body>
    </StyledModal>
  )
}
