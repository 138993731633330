import { useApp } from '@core/context'
import { fetchManagers } from '@services/manager.service'
import { useQuery } from 'react-query'

export const useDirectManagers = (companyId) => {
  const { profile } = useApp()
  const { user: owner } = profile

  const transformLineManagersData = (data) => {
    const lineManagers = data.map(({ user }) => ({
      id: user.id,
      name: `${user.profile.first_name} ${user.profile.last_name}`,
    }))
    const companyOwner = {
      id: owner.id,
      name: `${owner.profile.first_name} ${owner.profile.last_name}`,
    }
    return [companyOwner, ...lineManagers]
  }

  const { data: directManagers, isLoading } = useQuery(['line-managers', companyId], {
    queryFn: () => fetchManagers(companyId),
    enabled: !!companyId,
    select: (data) => {
      const response = transformLineManagersData(data)
      return response
    },
  })

  return {
    directManagers,
    isLoading,
  }
}
