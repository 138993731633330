import ContentPlaceholder from '@components/content-placeholder/content-placeholder.component'
import { InfoMessage } from '@components/info-message/info-message.component'
import { SIGNATURE_STATES, TEMPLATE_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { createAppendix } from '@services/sign.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { SignAppendixDocument } from '../sign-appendix-document/sign-appendix-document.component'

const COMPANY_ROLE = 'Client'
const MSA_APPENDIX_AGREEMENT_TITLE = 'MSA Appendix'
const MSA_APPENDIX_AGREEMENT_SUBJECT = 'Please sign MSA Appendix'

export const SignAppendix = ({ agreement, template, country, onNext }) => {
  const { profile, userProfile, countries } = useApp()
  const [createdAgreement, setCreatedAgreement] = useState(null)
  const [signed, setSigned] = useState(false)

  const { isFetching } = useQuery({
    queryKey: ['createAppendix', country],
    queryFn: () =>
      createAppendix({
        country,
        company: profile.id,
        agreement_template: template.id,
        signers: [
          {
            role_name: COMPANY_ROLE,
            name: `${userProfile.profile.first_name} ${userProfile.profile.last_name}`,
            email_address: userProfile.profile.email,
          },
        ],
        title: MSA_APPENDIX_AGREEMENT_TITLE,
        subject: MSA_APPENDIX_AGREEMENT_SUBJECT,
        agreement_document_type: TEMPLATE_TYPES.APPENDIX,
      }),
    onSuccess: (data) => {
      setCreatedAgreement(data)
    },
    enabled: !!template && !agreement,
  })

  const getCountryName = () => {
    const c = countries.find(({ id }) => id === country)
    return c?.name
  }

  if (isFetching) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  return agreement?.signature?.state === SIGNATURE_STATES.SIGNED || signed ? (
    <ContentPlaceholder
      imgSrc="/assets/img/sign-success.png"
      title={
        <>
          <div>Congratulations! </div>
          <div>You have signed the MSA appendix</div>
        </>
      }
      description={`We will send this document to our local provider for a signature. Once they sign the appendix, you will be able to hire employees in ${getCountryName()}. Meanwhile, you can continue adding details for this employee.`}
      buttonText="Continue"
      onClick={onNext}
    />
  ) : (
    <>
      <InfoMessage
        message={
          <span>
            To be able to hire in the selected country, you will need to sign the MSA Appendix with our local provider.
            Select <strong>Sign</strong> to proceed.
          </span>
        }
      />
      <SignAppendixDocument
        title={template?.title}
        file={template?.file}
        signatureId={agreement?.signature?.id || createdAgreement?.signatures?.[0]?.id}
        onSuccess={() => setSigned(true)}
        countryName={getCountryName()}
      />
    </>
  )
}
