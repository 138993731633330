/* eslint-disable no-param-reassign */
/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { add } from '@core/icons/icons'
import ThreadList from '@pages/chat/ThreadList'
import { DotWave } from '@uiball/loaders'
import React, { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { fetchCreateNewThread, fetchThreadUsers, getThreads, updateThread } from 'src/services/chat.service'
import styled from 'styled-components'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

import styles from './chat.module.scss'
import ChatList from './ChatList'
import CreateThread from './CreateThread/CreateThread'
import ThreadSearch from './ThreadSearch'

const StyledTabs = styled(Tabs)`
  .tab-content {
    overflow: auto;
  }
  .tabs-wrapper {
    display: flex;
    flex-direction: column;
    overflow: auto;
    position: relative;
    height: 100%;
  }

  .tabs {
    position: sticky;
    width: 100%;
    top: 0;
    background: white;
  }
`
const convertToHashMap = (v) => {
  return v.reduce((acc, item) => ({ ...acc, [item.id]: item }), {})
}

export default function Chat() {
  const newThreadVisible = useBoolean(false)
  const confirmVisible = useBoolean(false)

  const [openThreads, setOpenThreads] = useState({})

  const [currentThread, setCurrentThread] = useState()
  const isActive = currentThread?.state === 'ACTIVE'

  const [tab, setTab] = React.useState(0)
  const [searchValue, setSearchValue] = useState('')
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(searchValue)

  const threadUsers = useQuery('thread-users', { queryFn: fetchThreadUsers })

  // TODO SET LIMIT OFFSET
  const openThreadsQuery = useQuery(['ACTIVE', debouncedSearch], {
    keepPreviousData: true,
    queryFn: () =>
      getThreads({
        state: 'ACTIVE',
        search: debouncedSearch,
        limit: 500,
        offset: 0,
      }),
    onSuccess: ({ results }) => {
      setOpenThreads(convertToHashMap(results))
    },
  })
  const closedThreadsQuery = useQuery(['RESOLVED', debouncedSearch], {
    keepPreviousData: true,
    queryFn: () =>
      getThreads({
        state: 'RESOLVED',
        search: debouncedSearch,
        limit: 500,
        offset: 0,
      }),
  })

  useEffect(() => {
    console.log('openThreads', openThreads)
  }, [openThreads])

  const resolveThread = useMutation({
    mutationFn: (body) => updateThread(currentThread.id, body),
    onSuccess: () => {
      openThreadsQuery.refetch()
      closedThreadsQuery.refetch()
      setCurrentThread(null)
      confirmVisible.setFalse()
    },
  })
  const createThread = useMutation({
    mutationFn: (body) => fetchCreateNewThread(body),
    onSuccess: () => {
      openThreadsQuery.refetch()
      newThreadVisible.setFalse()
      setSearchValue('')
      setTab(0)
    },
  })

  const handleSyncThread = ({ content, created_at }) => {
    setOpenThreads((prev) => ({
      ...prev,
      [currentThread.id]: {
        ...currentThread,
        unread_messages_count: 0,
        last_message: {
          short_content: content,
          created_at,
        },
      },
    }))
  }

  const onClickThread = (thread) => {
    setCurrentThread(null)

    setTimeout(() => {
      setCurrentThread(thread)
    })
  }

  const onCreateThread = (threadData) => {
    createThread.mutate(threadData)
  }

  const handleResolve = () => {
    resolveThread.mutate({
      state: currentThread.state === 'ACTIVE' ? 'RESOLVED' : 'ACTIVE',
    })
  }

  const handleChangeTab = (tabId) => {
    setCurrentThread(null)
    setTab(tabId)
  }

  if (openThreadsQuery.isLoading || closedThreadsQuery.isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <div className={styles.chat}>
        <div className={styles.aside}>
          <div className="mr-2">
            <PageTitle>Employee Chat</PageTitle>
            <ThreadSearch onChange={setSearchValue} />
          </div>
          <div className={styles.aside_threads}>
            <StyledTabs selectedTab={tab} onTabChange={handleChangeTab}>
              <Tab tabId={0} title="Open" count={openThreadsQuery.data.count}>
                <ThreadList
                  type="unresolved"
                  currentThread={currentThread}
                  data={openThreadsQuery.data.results.map((r) => openThreads[r.id])}
                  onClickThread={onClickThread}
                />
              </Tab>
              <Tab tabId={1} title="Closed" count={closedThreadsQuery.data.count}>
                <ThreadList
                  type="resolved"
                  currentThread={currentThread}
                  data={closedThreadsQuery.data.results}
                  onClickThread={onClickThread}
                />
              </Tab>
            </StyledTabs>
          </div>
          <Button
            data-testid="Chat-7A15FF"
            className="mb-1 mr-2"
            priority="secondary"
            size="medium"
            loading={threadUsers.isLoading}
            disabled={threadUsers.isLoading}
            onClick={newThreadVisible.setTrue}
          >
            New chat
            <Icon icon={add} className="ml-1" />
          </Button>
        </div>
        <div className={styles.messages}>
          {currentThread && (
            <ChatList
              currentThread={currentThread}
              isOwner={currentThread.is_owner}
              onClickResolve={confirmVisible.setTrue}
              onGetNewMessage={handleSyncThread}
            />
          )}
        </div>
      </div>
      {threadUsers.isSuccess && newThreadVisible.value && (
        <CreateThread
          users={threadUsers.data}
          loading={createThread.isLoading}
          onClose={newThreadVisible.setFalse}
          onSave={onCreateThread}
        />
      )}

      {currentThread && (
        <EditModal visible={confirmVisible.value} footer={false} closeModal={confirmVisible.setFalse}>
          <div className="d-flex align-items-center flex-column">
            <img src="/assets/img/oc-handshake.png" alt="handshake" />
            <Typography className="heading_semibold__24 mt-5 mb-3 text-center">
              {isActive ? 'Ready to close this chat?' : 'Open chat?'}
            </Typography>
            <Typography className="text_regular__14 color_text_300 text-center">
              {isActive ? 'Your dialogue will be saved. You can always find it in the Closed tab.' : ''}
            </Typography>
            <div className="d-flex mt-4">
              <Button data-testid="Chat-A79BA9" priority="secondary" size="medium" onClick={confirmVisible.setFalse}>
                Cancel
              </Button>

              <Button
                data-testid="Chat-F2E9C6"
                className="ml-4"
                priority="primary_black"
                size="medium"
                disabled={resolveThread.isLoading}
                loading={resolveThread.isLoading}
                onClick={handleResolve}
              >
                {`${isActive ? 'Close' : 'Open'} chat`}
              </Button>
            </div>
          </div>
        </EditModal>
      )}
    </>
  )
}
