import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchProfile = async () => {
  const v = await Api.get('companies/my/')
  return v.data
}

export const fetchOnboarding = async () => {
  const v = await Api.get('companies/onboarding/')
  return v.data
}

export const fetchPopUpMfa = async () => {
  const v = await Api.get('popup/mfa')
  return v.data
}
export const fetchMfaRemind = async () => {
  const v = await Api.post('popup/mfa/')
  return v.data
}
export const fetchMfaCancel = async () => {
  const v = await Api.delete('popup/mfa')
  return v.data
}

export const fetchUserProfile = async () => {
  const v = await Api.get('profile/me/')
  return v.data
}

export const fetchUpdateUserProfile = async (data) => {
  const v = await Api.patch('profile/', data)
  return v.data
}

export const getResetPassword = async (email) => {
  const v = await Api.post(`password/reset-password/`, {
    email,
  })
  return v.data
}

export const postUserReport = async (data) => {
  const v = await Api.post(`reports/users/create/`, data)
  return v.data
}

export const fetchCountries = async (params) => {
  const v = await Api.get(`countries/`, {
    params,
  })
  return v.data
}

export const fetchCurrencies = async (params) => {
  const v = await Api.get(`currencies/?ordering=short_code`, { params })
  return v.data
}

export const fetchCompanies = async (id) => {
  const v = await Api.get(`companies/${id}/`)
  return v.data
}

export const fetchPostFile = async (file) => {
  const fd = new FormData()
  fd.append('file', file)
  const { data } = await Api.post(`temporary-files/`, fd)
  return data
}
export const fetchContractors = generateService(async (params) => {
  const { data } = await Api.get(`contracts/`, { params })
  return data
})

export const fetchUpdatePaymentDetails = async (id, body) => {
  const { data } = await Api.put(`payment-details/${id}/update/`, body)
  return data
}

export const fetchContractorPaymentDetail = async (id) => {
  const { data } = await Api.get(`contracts/${id}/payment-details/`)
  return data
}

export const fetchUploadFile = async (file, progressCallBack) => {
  const fd = new FormData()
  fd.append('file', file)
  const { data } = await Api.post(`temporary-files/`, fd, {
    onUploadProgress: (progressEvent) => {
      const progress = parseInt(Math.round((progressEvent.loaded * 100) / progressEvent.total), 10)
      if (progressCallBack) progressCallBack(progress)
    },
  })
  return data
}

export const fetchTermsOfServices = async (id) => {
  const { data } = await Api.post(`companies/${id}/terms-of-services-agree/`)
  return data
}

export const fetchPatchOnboarding = async (body) => {
  const { data } = await Api.post('companies/onboarding/', body)
  return data
}
