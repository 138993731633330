import CustomTable from '@atoms/Table/CustomTable'

import { AdditionalPaymentsTableActions } from './additional-payments-table-actions/additional-payments-table-actions.component'

export const AdditionalPaymentsTable = ({ data = [], onEdit, onDelete }) => {
  const fields = [
    {
      title: 'Title',
      key: 'title',
      type: 'string',
    },
    {
      title: 'Amount',
      type: 'custom',
      render: ({ amount, currency }) => `${amount} ${currency?.label ?? ''}`,
    },
    {
      title: 'Frequency',
      type: 'custom',
      render: ({ frequency }) => frequency.label || 'ONE_TIME',
    },
    {
      title: 'Contractual',
      type: 'custom',
      render: ({ isContractual }) => (isContractual ? 'Yes' : 'No'),
    },
    {
      title: 'Actions',
      key: 'actions',
      type: 'custom',
      render: (item, index) => (
        <AdditionalPaymentsTableActions onEdit={() => onEdit(index)} onDelete={() => onDelete(item.id)} />
      ),
      width: 100,
    },
  ]

  return !!data.length && <CustomTable fields={fields} data={data} />
}
