import Button from '@atoms/Button/Button'
import { DocumentPreview } from '@components/document-preview/document-preview.component'
import { useDocumentSign } from '@core/hooks/useDocumentSign'
import { DotWave } from '@uiball/loaders'
import React from 'react'
import { useBoolean } from 'usehooks-ts'

import SignConfirmModal from '../sign-confirm-modal/sign-confirm-modal.component'

export const SignAppendixDocument = ({ title, file, signatureId, onSuccess, countryName }) => {
  const confirmModalOpen = useBoolean(false)
  const { signUrl, fetchSignUrlQuery, sign, share, download } = useDocumentSign(signatureId, file, onSuccess)

  const handleConfirm = () => {
    confirmModalOpen.setTrue()
  }

  return fetchSignUrlQuery.isFetching ? (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <DotWave />
    </div>
  ) : (
    <>
      <DocumentPreview
        title={title}
        file={file}
        footerActions={[
          <Button
            data-testid="sign-appendix-document.component-9A06E4"
            key={1}
            priority="secondary"
            size="medium"
            onClick={download}
          >
            Download
          </Button>,
          <Button
            data-testid="sign-appendix-document.component-222388"
            key={2}
            priority="primary"
            size="medium"
            onClick={handleConfirm}
            disabled={!signUrl}
          >
            Sign
          </Button>,
        ]}
      />
      {confirmModalOpen.value && (
        <SignConfirmModal countryName={countryName} onConfirm={sign} onClose={confirmModalOpen.setFalse} />
      )}
    </>
  )
}
