import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { SEND_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { DotWave } from '@uiball/loaders'
import { useState } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'

import { fetchManagers } from '../../../services/manager.service'
import {
  Form,
  FormControl,
  FormGroup,
  Label,
  LabelDescription,
  RadioBox,
  RadioBoxBody,
  RadioInput,
} from './authorized-no-form.styles'

export const AuthorizedNoForm = ({ type, formRef, onSubmit, managerId }) => {
  const { profile, userProfile } = useApp()
  const [managerSelectOptions, setManagerSelectOptions] = useState([])
  const {
    register,
    handleSubmit,
    watch,
    setValue,
    control,
    formState: { errors },
  } = useForm()
  const watchSendType = watch('sendType')

  const { isFetching: managersFetching } = useQuery(['managers', profile.id], () => fetchManagers(profile.id), {
    onSuccess: (data) => {
      const options = data.map(({ id, user }) => ({
        value: id,
        email: user.email,
        label: `${user?.profile.first_name}  ${user?.profile.last_name}`,
      }))

      const companyOwner = {
        value: profile.user?.id,
        email: profile.user?.profile?.email,
        label: `${profile.user?.profile?.first_name} ${profile.user?.profile?.last_name}`,
      }
      const allManagers = [companyOwner, ...options].filter(({ value }) => value !== userProfile?.id)

      setManagerSelectOptions(allManagers)
      setValue('sendType', allManagers?.length ? SEND_TYPES.SELECT : SEND_TYPES.INVITE)
      if (managerId) setValue('manager', managerId)
    },
  })

  return (
    <Form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <Label>
        Please choose an authorized signatory below
        <LabelDescription>We will use these details to send an email notification</LabelDescription>
      </Label>
      {managersFetching ? (
        <div className="d-flex h-100 justify-content-center align-items-center">
          <DotWave />
        </div>
      ) : (
        <>
          {managerSelectOptions.length ? (
            <RadioBox active={watchSendType === SEND_TYPES.SELECT}>
              <RadioInput
                type="radio"
                value={SEND_TYPES.SELECT}
                id={SEND_TYPES.SELECT}
                disabled={!managerSelectOptions.length}
                {...register('sendType')}
              />
              <RadioBoxBody>
                <Label htmlFor={SEND_TYPES.SELECT}>
                  Select manager from your team
                  <LabelDescription>They will receive an email notification to sign the {type}</LabelDescription>
                </Label>
                {watchSendType === SEND_TYPES.SELECT && (
                  <FormGroup>
                    <FormControl>
                      <Controller
                        control={control}
                        name="manager"
                        rules={{ required: 'User is required' }}
                        render={({ field }) => {
                          return (
                            <Select
                              data-testid="authorized-no-form.component-2A896D"
                              {...field}
                              options={managerSelectOptions}
                            />
                          )
                        }}
                      />
                      {errors.manager && (
                        <Typography className="text_regular__14 color_red">{errors.manager.message}</Typography>
                      )}
                    </FormControl>
                  </FormGroup>
                )}
              </RadioBoxBody>
            </RadioBox>
          ) : null}
          <RadioBox active={watchSendType === SEND_TYPES.INVITE}>
            <RadioInput type="radio" value={SEND_TYPES.INVITE} id={SEND_TYPES.INVITE} {...register('sendType')} />
            <RadioBoxBody>
              <Label htmlFor={SEND_TYPES.INVITE}>
                Invite new manager
                <LabelDescription>
                  A new manager will be added to your team and will be asked to sign the {type}
                </LabelDescription>
              </Label>
              {watchSendType === SEND_TYPES.INVITE && (
                <FormGroup>
                  <FormControl>
                    <Input
                      data-testid="authorized-no-form.component-EC4F40"
                      {...register(`firstName`, {
                        required: 'First name is required',
                      })}
                      type="text"
                      placeholder="First name"
                      isRequired
                    />
                    {errors?.firstName && (
                      <Typography className="text_regular__14 color_red">{errors?.firstName.message}</Typography>
                    )}
                  </FormControl>
                  <FormControl>
                    <Input
                      data-testid="authorized-no-form.component-A4D0D9"
                      {...register(`lastName`, {
                        required: 'Last name is required',
                      })}
                      type="text"
                      placeholder="Last name"
                      isRequired
                    />
                    {errors?.lastName && (
                      <Typography className="text_regular__14 color_red">{errors?.lastName.message}</Typography>
                    )}
                  </FormControl>
                  <FormControl>
                    <Input
                      data-testid="authorized-no-form.component-77E741"
                      {...register('email', {
                        required: 'Email is required',
                        validate: {
                          isEmail: (v) => {
                            return /.+@.+\..+/.test(v) || 'Please enter correct email'
                          },
                        },
                      })}
                      type="text"
                      placeholder="Email"
                      isRequired
                    />
                    {errors?.email && (
                      <Typography className="text_regular__14 color_red">{errors?.email.message}</Typography>
                    )}
                  </FormControl>
                </FormGroup>
              )}
            </RadioBoxBody>
          </RadioBox>
        </>
      )}
    </Form>
  )
}
