import Button from '@atoms/Button/Button'
import { CONTRACT_TYPE, EMPLOYEE_STATES } from '@core/constants'
import { memo } from 'react'

export const DeactivateButton = memo(({ contractType, state, subState, onDeactivate, onOnboardingCancel }) => {
  if (state === EMPLOYEE_STATES.ACTIVE && contractType === CONTRACT_TYPE.FULL_TIME_EMPLOYEE) {
    if (subState === 'offboarding') {
      return (
        <Button data-testid="DeactivateButton-B37CDB" disabled style={{ maxWidth: 'fit-content' }} size="small">
          Offboarding in progress
        </Button>
      )
    }
    return (
      <Button
        data-testid="DeactivateButton-FF0E90"
        onClick={onDeactivate}
        style={{ maxWidth: 'fit-content' }}
        priority="primary_black"
        size="small"
      >
        Request Offboarding
      </Button>
    )
  }
  if (
    (state === EMPLOYEE_STATES.CREATED || state === EMPLOYEE_STATES.ONBOARDING) &&
    contractType === CONTRACT_TYPE.FULL_TIME_EMPLOYEE
  ) {
    return (
      <Button
        data-testid="DeactivateButton-088E92"
        onClick={onOnboardingCancel}
        style={{ maxWidth: 'fit-content' }}
        priority="primary_dangerous"
        size="small"
        disabled={subState === 'cancelled'}
      >
        Cancel onboarding
      </Button>
    )
  }
  return null
})
