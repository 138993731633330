import CustomTable from '@atoms/Table/CustomTable'
import usePagination from '@core/hooks/usePagination'
import { useNavigate } from 'react-router-dom'

import { getTimesheetFields, TIMESHEETS } from '../mock'

export const AllTimesheets = () => {
  const navigate = useNavigate()

  const { page, limit, setPage } = usePagination({ page: 1, limit: 5 })

  const timesheets = TIMESHEETS.slice((page - 1) * limit, page * limit)

  const handleView = (id) => {
    navigate(`${id}`)
  }

  return (
    <CustomTable
      fields={getTimesheetFields(handleView)}
      data={timesheets}
      onPage={setPage}
      page={page}
      total={TIMESHEETS.length}
      pageSize={limit}
    />
  )
}
