import NotFound from '@atoms/NotFound/NotFound'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { fetchPaymentHistory } from '@services/stripe.service'
import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { getHistoryFields } from './mock'
import { parseHistory } from './parser'

export const TransactionHistory = () => {
  const [page, setPage] = useState(1)
  const { data, isLoading } = useQuery('fetchPaymentHistory', {
    queryFn: fetchPaymentHistory,
  })

  return (
    <div className="transaction-history">
      <Typography className="heading_semibold__18 mb-3">Transaction history</Typography>
      {data?.length === 0 ? (
        <div className="history-empty">
          <NotFound
            illustration="/assets/img/payrollEmpty.png"
            title="It's still empty here"
            description="The history of your transactions and their statuses will be stored here"
          />
        </div>
      ) : (
        <div className="history-table">
          <CustomTable
            page={page}
            pageSize={20}
            total={data?.count || 0}
            loading={isLoading}
            fields={getHistoryFields()}
            data={parseHistory(data || [])}
            onPage={setPage}
          />
        </div>
      )}
    </div>
  )
}
