import Button from '@atoms/Button/Button'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { createEmployeeNote, updateEmployeeNote } from '@services/contract.service'
import { useState } from 'react'
import { useMutation, useQueryClient } from 'react-query'

export default function Notes({ employee }) {
  const [edit, setEdit] = useState()
  const [note, setNote] = useState(employee.compensation?.note?.text)
  const queryClient = useQueryClient()

  const createNoteMutation = useMutation({
    mutationFn: () => createEmployeeNote(employee.id, { text: note }),
    onSuccess: () => {
      setEdit(false)
      queryClient.invalidateQueries(['detailEmployee'])
    },
  })

  const updateNoteMutation = useMutation({
    mutationFn: () => updateEmployeeNote(employee.compensation?.note?.id, employee.id, { text: note }),
    onSuccess: () => {
      setEdit(false)
      queryClient.invalidateQueries(['detailEmployee'])
    },
  })

  const onSave = () => {
    if (employee.compensation?.note?.id) {
      updateNoteMutation.mutate()
    } else {
      createNoteMutation.mutate()
    }
  }

  const onEdit = () => {
    setEdit(true)
  }

  const handleChange = (e) => {
    setNote(e.target.value)
  }

  return (
    <>
      <Typography as="h3" className="w-100 heading_semibold__20 color_text_300 mt-4 mb-3">
        Additional notes on employment terms
      </Typography>

      <div className="w-100">
        <TextArea value={note} onChange={handleChange} disabled={!edit} label="Notes" />
      </div>
      <div className="w-100 mt-3">
        {edit ? (
          <Button onClick={onSave} size="small">
            Save
          </Button>
        ) : (
          <Button size="small" onClick={onEdit}>
            Edit
          </Button>
        )}
      </div>
    </>
  )
}
