import Typography from '@atoms/Typography/Typography'
import React from 'react'
import styled from 'styled-components'

import { REMOHEALTH_INFO } from '../../pages/employees/create-employee/forms/Benefit/const'
import HealthInfoAccordion from '../../pages/employees/create-employee/forms/Benefit/HealthInfoAccordion'

const StyledImage = styled.img`
  width: 169px;
  height: 175px;
`

const Number = styled.div`
  border-radius: 50%;
  border: 2px solid #04c53a;
  color: #04c53a;
  width: 24px;
  height: 24px;
  display: flex;
  align-items: center;
  justify-content: center;
`

export const RemohealthPackageInfo = ({ packageType }) => {
  return (
    <div>
      {/* <div className="d-flex align-items-center"> */}
      {/*  <StyledImage src="/assets/img/core-remohealth.png" alt="" /> */}
      {/*  <Typography className="heading_semibold__24 ml-5"> */}
      {/*    Need a title or description for a section, need marketing */}
      {/*  </Typography> */}
      {/* </div> */}
      <HealthInfoAccordion data={REMOHEALTH_INFO[packageType]} />

      <div className="mt-4">
        <Typography className="heading_semibold__20">Next steps</Typography>

        <div className="d-flex mt-4">
          <Number>1</Number>
          <div className="ml-3">
            <Typography className="heading_semi__16">Choose plan</Typography>
            <Typography className="text_light__12 color_text_300">
              Let Remofirst know which plans you’d like to offer and for whom on your team
            </Typography>
          </div>
        </div>

        <div className="d-flex mt-4">
          <Number>2</Number>
          <div className="ml-3">
            <Typography className="heading_semi__16">Invitation</Typography>
            <Typography className="text_light__12 color_text_300">
              Will invite your team member sign up and fill out their applications (-10 min)
            </Typography>
          </div>
        </div>

        <div className="d-flex mt-4">
          <Number>3</Number>
          <div className="ml-3">
            <Typography className="heading_semi__16">Invoice</Typography>
            <Typography className="text_light__12 color_text_300">
              Remofirst will invoice (annual bill) you for the cost of your team
            </Typography>
          </div>
        </div>

        <div className="d-flex mt-4">
          <Number>4</Number>
          <div className="ml-3" style={{ maxWidth: 500 }}>
            <Typography className="heading_semi__16">Activation</Typography>
            <Typography className="text_light__12 color_text_300">
              Unisure will let your team know when their plan is active and a dedicated Account Manager will be
              available for support.
            </Typography>
          </div>
        </div>
      </div>
    </div>
  )
}
