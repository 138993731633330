import './style.scss'

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import NotFound from '@atoms/NotFound/NotFound'
import Pagination from '@atoms/Pagination/Pagination'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import CustomTable from '@atoms/Table/CustomTable'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import usePagination from '@core/hooks/usePagination'
import { SplitNumber } from '@core/utils'
import { payrollsField } from '@pages/payrollsDetailPage/mock'
import { parserPayrollsBasePayment } from '@pages/payrollsDetailPage/parse'
import { DotWave } from '@uiball/loaders'
import React, { useCallback, useContext, useEffect, useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import {
  approvePayroll,
  changePayrollStatus,
  fetchDeletePayroll,
  fetchViewCompanyInvoices,
  getPayrollInvoiceTotal,
  getPayrollList,
  getPayrollListNeedChange,
} from '../../services/payroll.service'
import ChangesAccordion from './ChangesAccordion'
import DownloadReport from './DownloadReport'
import SuccessApproveModal from './SuccessApproveModal'

export const PayrollDetailPage = () => {
  const navigate = useNavigate()
  const { profile } = useContext(AppContext)
  const [payrollsData, setPayrollsData] = useState([])
  const isOpenDownloadCsv = useBoolean(false)
  const [comment, setComment] = useState()
  const [rejectVisible, setRejectVisible] = useState(false)
  const [currentPayroll, setCurrentPayroll] = useState()
  const [active, setActive] = useState(0)
  const [payrollCurrent, setPayrollCurrent] = useState()
  const [invoiceTotal, setInvoiceTotal] = useState()
  const [invoiceId, setInvoiceId] = useState()
  const [approvedPayroll, setApprovedPayroll] = useState(null)
  const [selectedPayrollDelete, setSelectedPayrollDelete] = useState(null)
  const { page, limit, setPage } = usePagination({
    page: 1,
    limit: 10,
  })

  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)

  const [isEmpty, setIsEmpty] = useState(null)

  const { data, isLoading, refetch, isFetching } = useQuery(['payrolls', page, limit], {
    queryFn: () => getPayrollList({ limit, page }),
    keepPreviousData: true,
    onSuccess: (v) => {
      setIsEmpty(v.current.length === 0)
    },
  })

  const {
    data: listNeedChange,
    isLoading: changeLoading,
    refetch: refetchChnage,
  } = useQuery('payrollsListNeedChange', {
    queryFn: () => getPayrollListNeedChange(),
  })

  const approvePayrollRequest = useMutation('payrollsChangeStatus', {
    mutationFn: approvePayroll,
    onSuccess: (response) => {
      setApprovedPayroll(response)
      toast.success('Successfully updated')
      refetch()
      setPayrollCurrent(undefined)
      // asd
    },
  })
  const payrollsChangeStatus = useMutation('payrollsChangeStatus', {
    mutationFn: ({ id, body }) => changePayrollStatus(id, body),
    onSuccess: () => {
      setComment(undefined)
      refetchChnage()
      refetch()
      setRejectVisible(false)
      setActive(1)
    },
  })
  const deleteMutate = useMutation({
    mutationFn: (payrollId) => fetchDeletePayroll(payrollId),
    onSuccess: () => {
      toggleDelete()
      if (page !== 1 && data.current.length === 1) {
        setPage((prev) => prev - 1)
        return
      }
      refetch()
      refetchChnage()
      toast.success('Payroll successfully deleted')
    },
  })
  const companyInvoicePreviewMutation = useMutation(fetchViewCompanyInvoices, {
    onSuccess: (response) => {
      const file = new Blob([response], { type: 'application/pdf' })
      const fileURL = URL.createObjectURL(file)
      window.open(fileURL, '_blank')
    },
  })

  const onUploadInvoice = () => {
    companyInvoicePreviewMutation.mutate({ company: profile.id })
  }

  const onClickRequestChange = useCallback((payroll) => {
    setCurrentPayroll(payroll)
    setRejectVisible(true)
  }, [])

  const { isLoading: invoiceTotalLoading } = useQuery(['invoiceTotal', invoiceId], {
    queryFn: () => getPayrollInvoiceTotal(invoiceId),
    onSuccess: (res) => {
      setInvoiceTotal(res)
    },
    enabled: !!invoiceId,
  })

  const onClickEdit = useCallback((rowData) => {
    navigate(`/pages/payrolls/edit/${rowData.id}`)
  }, [])

  const handleDelete = (row) => {
    toggleDelete()
    setSelectedPayrollDelete(row.id)
  }

  const onCollapseClick = useCallback((row) => {
    if (!row.disabled) {
      setInvoiceId(row.id)
    }
  }, [])

  const onSendComment = useCallback(() => {
    payrollsChangeStatus.mutate({
      id: currentPayroll.id,
      body: {
        state: 'need_changes_from_admin',
        comment,
      },
    })
  }, [currentPayroll, comment])

  const onChangeComment = useCallback((e) => {
    setComment(e.target.value)
  }, [])

  const onClickTab = useCallback((tabId) => {
    setActive(tabId)
  }, [])

  const onCreateInvoice = useCallback(() => {
    approvePayrollRequest.mutate()
  }, [])

  useEffect(() => {
    if (data) {
      setPayrollCurrent({
        total: data?.total,
        currency: data?.currency,
      })
      setPayrollsData(data?.current)
      setInvoiceId(data?.current[0]?.id)
    }
  }, [data])

  const renderTable = React.useMemo(() => {
    return payrollsData.length > 0 ? (
      <div className="customer-payroll-table">
        <CustomTable
          collapseId={invoiceId}
          type="collapse"
          className="customer-payroll-table"
          fields={payrollsField(onClickEdit, handleDelete)}
          onCollapseClick={onCollapseClick}
          data={parserPayrollsBasePayment(
            payrollsData,
            invoiceTotal,
            invoiceTotalLoading,
            onClickRequestChange,
            data.month
          )}
        />
        {data.count && (
          <div className="row align-items-center justify-content-end mt-3">
            <div className="col-auto">
              <Pagination total={data.count} pageSize={limit} page={page} onGetPage={setPage} />
            </div>
          </div>
        )}
      </div>
    ) : (
      <NotFound
        illustration="/assets/img/payrollEmpty.png"
        title="No payrolls yet"
        description="They will appear here in the next billing period"
      />
    )
  }, [payrollsData, invoiceTotal, invoiceTotalLoading, onClickRequestChange, onClickEdit])
  const renderTotal = () => {
    return (
      <div className="d-flex justify-content-between align-content-center w-100">
        <div>
          {data.can_approve && (
            <Typography className="heading_semibold__20">
              Total: {SplitNumber(payrollCurrent?.total)}{' '}
              {payrollCurrent?.currency?.sign || payrollCurrent?.currency?.short_code}
            </Typography>
          )}
        </div>
        <Button
          data-testid="PayrollDetailPage-D90DE4"
          className="px-5"
          priority="primary"
          size="medium"
          disabled={!data.can_approve}
          loading={approvePayrollRequest.isLoading || isLoading}
          onClick={onCreateInvoice}
        >
          Approve
        </Button>
      </div>
    )
  }

  if (isLoading || changeLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }

  return (
    <div className="customer-payroll">
      <div className="d-flex align-items-center justify-content-between mb-4">
        <Typography className="heading_semibold__24">Payroll</Typography>
        <div className="d-flex gap-2">
          <Button
            data-testid="PayrollDetailPage-5FEF0C"
            priority="secondary"
            size="small"
            onClick={isOpenDownloadCsv.setTrue}
          >
            <HiDownload className="mr-1" />
            Download report
          </Button>
          {payrollsData.length > 0 && data.can_approve && (
            <Button
              data-testid="PayrollDetailPage-C584B9"
              priority="secondary"
              size="small"
              loading={companyInvoicePreviewMutation.isLoading}
              onClick={onUploadInvoice}
            >
              Preview invoice
            </Button>
          )}
        </div>
      </div>

      {!isEmpty && (
        <Tabs selectedTab={active} onTabChange={onClickTab}>
          <Tab tabId={0} title="Currently Pending">
            {isLoading ? (
              <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                <DotWave size={48} speed={1} color="black" />
              </div>
            ) : (
              <div className="customer-payroll-table-box">
                <div className="customer-payroll-table-wrap">
                  <AbsoluteSpinner isFetching={isFetching}>{renderTable}</AbsoluteSpinner>
                  {payrollCurrent && payrollCurrent.total > 0 && (
                    <div className="payroll-bottom-wrapper">
                      <div className="payroll-bottom d-flex justify-content-center">{renderTotal()}</div>
                    </div>
                  )}
                </div>
              </div>
            )}
          </Tab>
          <Tab tabId={1} title="Requested Change" count={listNeedChange?.count}>
            {changeLoading && listNeedChange?.payrolls?.length === 0 ? (
              <div className="d-flex w-100 h-100 align-items-center justify-content-center">
                <DotWave size={48} speed={1} color="black" />
              </div>
            ) : (
              <div>
                {listNeedChange?.payrolls?.length === 0 ? (
                  <NotFound title="There are no requests to change" />
                ) : (
                  <ChangesAccordion month={listNeedChange?.month} payments={listNeedChange?.payrolls} />
                )}
              </div>
            )}
          </Tab>
        </Tabs>
      )}

      {isEmpty && (
        <div className="d-flex justify-content-center align-items-center flex-grow-1">
          <div className="text-center">
            <img src="/assets/img/notFound.png" alt="" />
            <p className="heading_semibold__20 mb-3 mt-4">No payrolls yet</p>
            <p className="text_light__14 mb-4">They will appear here in the next billing period</p>
          </div>
        </div>
      )}

      <EditModal
        visible={rejectVisible}
        footer
        size="lg"
        loading={payrollsChangeStatus.isLoading}
        title="Reject Pending "
        closeModal={() => setRejectVisible(false)}
        onSave={onSendComment}
      >
        <TextArea onChange={onChangeComment} placeholder="Reject Cause" rows={5} />
      </EditModal>
      {approvedPayroll && <SuccessApproveModal payroll={approvedPayroll} onClose={setApprovedPayroll} />}

      <EditModal
        visible={visibleDelete}
        title="Delete payroll"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to delete this payroll from your company?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="PayrollDetailPage-76255E"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            data-testid="PayrollDetailPage-006C7F"
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            disabled={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedPayrollDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>
      {isOpenDownloadCsv.value && <DownloadReport onClose={isOpenDownloadCsv.setFalse} />}
    </div>
  )
}
