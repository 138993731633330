import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Typography from '@atoms/Typography/Typography'
import { Controller, useForm } from 'react-hook-form'

export const ConfirmTimesheetUpload = ({ payload }) => {
  const {
    control,
    handleSubmit,
    formState: { errors },
  } = useForm()

  const handleConfirmUpload = () => {
    alert('Confirmed') // TEMP
  }

  return (
    <div className="d-flex flex-column gap-4">
      <Typography className="heading_semibold__20 mb-5">Confirm timesheet upload</Typography>
      <form onSubmit={handleSubmit(handleConfirmUpload)}>
        <div>
          <Controller
            control={control}
            name="confirm"
            rules={{
              required: 'Confirmation is required',
            }}
            render={({ field }) => {
              return (
                <Checkbox
                  label="I confirm that I have reviewed and approve the imported timesheet. "
                  description="Remofirst takes no responsibility for any incorrect data that may have been added during the upload process."
                  onChange={(e) => field.onChange(e.target.checked)}
                />
              )
            }}
          />
          {errors.confirm && (
            <Typography className="text_regular__14 color_red mt-2">{errors.confirm.message}</Typography>
          )}
        </div>
        <div className="d-flex pb-5 justify-content-center gap-4 mt-5">
          <Button type="submit" className="align-self-end">
            Confirm timesheet upload
          </Button>
        </div>
      </form>
    </div>
  )
}
