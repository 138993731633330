/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './style.scss'

import classNames from 'classnames'
import React, { useCallback } from 'react'

export default function Modal({ sideClose, wrapperStyle, className, isFullScreen, ...rest }) {
  const handleClick = useCallback(
    (e) => {
      if (e.currentTarget === e.target && sideClose) {
        rest.onClose()
      }
    },
    [rest, sideClose]
  )

  return (
    <div data-testid="Modal-C56AC0" className="modalWrapper" onClick={handleClick} style={wrapperStyle}>
      <div {...rest} className={classNames(['modalBox', className, isFullScreen && 'modalBox_full'])}>
        {rest.children}
      </div>
    </div>
  )
}
