import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Status from '@atoms/Status/Status'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { CONTRACT_TYPE } from '@core/constants'
import usePagination from '@core/hooks/usePagination'
import { getContractDocumentHandlers } from '@services/contract.service'
import { addContractDocuments, deleteDocumentsById, getContractDocuments } from '@store/documents'
import moment from 'moment'
import React, { useCallback, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { getDocumentFields } from './mock'

export default function DocumentDetailEmployee({ id, employee }) {
  const [requiredDocuments, setRequiredDocuments] = useState([])
  const documentsQuery = useQuery(
    [getContractDocumentHandlers.key],
    () => getContractDocumentHandlers.fetch({ contract_id: id, page: 1, limit: 100 }),
    {
      keepPreviousData: true,
      onSuccess: ({ results }) => {
        setRequiredDocuments(results)
      },
    }
  )

  return (
    <div className="onboarding-document-container">
      {requiredDocuments.map((doc) => (
        <div className="document">
          <div className="header">
            <Typography>{doc.employee_document.name}</Typography>
            <Status status={doc.state} />
          </div>
          {doc.attachments && (
            <div className="files">
              {doc.attachments.map((a) => (
                <Typography>
                  <a href={a.file} target="_blank" rel="noreferrer" style={{ paddingLeft: '28px' }}>
                    {a.filename}
                  </a>
                </Typography>
              ))}
            </div>
          )}
        </div>
      ))}
    </div>
  )
}
