import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Input from '@atoms/Input/Input'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AttachmentDropzone } from '@components/attachment/attachment-dropzone/attachment-dropzone'
import { fetchPostFile } from '@services/app.service'
import { addMilestone, updateMilestone } from '@services/contract.service'
import moment from 'moment'
import { useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useMutation } from 'react-query'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 8px;
    width: 600px;
  }
`

const fileExtensions = ['.png', '.jpeg', '.jpg', '.pdf', '.doc', '.docx', '.xls', '.xlsx', '.zip', '.csv']

export const MilestoneModal = ({
  show,
  onClose,
  editMode,
  currency,
  selectedMilestone,
  setSelectedMilestone,
  contractorId,
  refetch,
}) => {
  const {
    register,
    handleSubmit,
    control,
    reset,
    setValue,
    formState: { errors },
  } = useForm()

  const [selectedFile, setSelectedFile] = useState(null)
  const [selectedFileUuid, setSelectedFileUuid] = useState(null)

  useEffect(() => {
    if (selectedMilestone) {
      setValue('milestone', selectedMilestone.milestone)
      setValue('deadline', selectedMilestone.deadline)
      setValue('payment_amount', selectedMilestone.payment_amount)
      setValue('description', selectedMilestone.description)
      setSelectedFileUuid(selectedMilestone?.attachment?.file_uuid || null)
    } else {
      reset()
      setSelectedFileUuid(null)
      setSelectedFile(null)
    }
  }, [reset, selectedMilestone, setValue])

  const temporaryFilesMutation = useMutation({
    mutationFn: fetchPostFile,
  })

  const addMilestoneMutate = useMutation({
    mutationFn: ({ id, payload }) => addMilestone(id, payload),
    onSuccess: () => {
      refetch()
      setSelectedFile(null)
      setSelectedFileUuid(null)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const updateMilestoneMutate = useMutation({
    mutationFn: ({ id, milestoneId, payload }) => updateMilestone(id, milestoneId, payload),
    onSuccess: () => {
      refetch()
      setSelectedFile(null)
      setSelectedFileUuid(null)
    },
    onError: (error) => {
      console.log(error)
    },
  })

  const handleClose = () => {
    onClose()
    setSelectedFile(null)
    setSelectedFileUuid(null)
    setSelectedMilestone(null)
    reset()
  }

  const onFormSubmit = async (data) => {
    const paymentAmount = String(data.payment_amount).replace(/^0+(?=\d)/, '')
    if (editMode && selectedMilestone) {
      const updatedMilestone = {
        ...data,
        deadline: moment(data.deadline).format('YYYY-MM-DD'),
        payment_amount: +paymentAmount,
        attachment: selectedFileUuid,
      }
      await updateMilestoneMutate.mutateAsync({
        id: contractorId,
        milestoneId: selectedMilestone.milestone_id,
        payload: updatedMilestone,
      })
    } else {
      const newMilestone = [
        {
          ...data,
          deadline: moment(data.deadline).format('YYYY-MM-DD'),
          payment_amount: +paymentAmount,
          attachment: selectedFileUuid,
        },
      ]
      await addMilestoneMutate.mutateAsync({
        id: contractorId,
        payload: newMilestone,
      })
    }
    handleClose()
  }

  const handleDrop = ([file]) => {
    temporaryFilesMutation
      .mutateAsync(file)
      .then(({ file_uuid }) => setSelectedFileUuid(file_uuid))
      .then(() => setSelectedFile(file))
  }

  const handleRemoveFile = () => {
    setSelectedFileUuid(null)
    setSelectedFile(null)
  }

  return (
    <StyledModal show={show} onHide={handleClose} centered animation={false} size="lg">
      <Modal.Header closeButton>
        <Modal.Title>{selectedMilestone ? 'Edit milestone' : 'Add milestone'}</Modal.Title>
      </Modal.Header>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body className="pt-0">
          <div className="w-100 remo-form-input">
            <Input
              {...register('milestone', {
                required: 'Milestone is required',
                validate: (value) => value.trim() !== '' || 'Milestone is required',
              })}
              type="string"
              label="Milestone"
              placeholder="Enter"
              isRequired
            />
            {errors.milestone && (
              <Typography className="text_regular__14 color_red">{errors.milestone.message}</Typography>
            )}
          </div>
          <div className="d-flex w-100">
            <div className="remo-form-input">
              <Controller
                control={control}
                name="deadline"
                rules={{
                  required: 'Deadline is required',
                  validate: (value) => {
                    const selectedDate = moment(value)
                    const currentDate = moment().startOf('day')
                    if (selectedDate.isBefore(currentDate)) {
                      return 'Deadline can not be in the past'
                    }
                    return true
                  },
                }}
                render={({ field }) => {
                  return (
                    <DateInput
                      label="Deadline"
                      value={field.value}
                      onChange={(date) => {
                        field.onChange(date)
                      }}
                      minDate={new Date()}
                      isRequired
                    />
                  )
                }}
              />
              {errors.deadline && (
                <Typography className="text_regular__14 color_red">{errors.deadline.message}</Typography>
              )}
            </div>
            <div className="w-100 remo-form-input">
              <Input
                {...register('payment_amount', { required: 'Payment amount is required' })}
                type="number"
                step="0.01"
                label="Payment amount"
                placeholder="0.00"
                isRequired
                currency={currency}
              />
              {errors.payment_amount && (
                <Typography className="text_regular__14 color_red">{errors.payment_amount.message}</Typography>
              )}
            </div>
          </div>
          <TextArea placeholder="Enter" rows={2} type="text" label="Description" {...register('description')} />
          <Typography className="text_medium__14 mb-2 mt-4 color_black">Attachment</Typography>
          <AttachmentDropzone
            multiple={false}
            accept={fileExtensions.join(',')}
            file={selectedFile}
            isLoading={temporaryFilesMutation.isLoading}
            onDrop={handleDrop}
            onDelete={handleRemoveFile}
          >
            Permitted files .jpeg, .png., .pdf, .docx, .doc, .xls and .csv no more than 50 MB
          </AttachmentDropzone>
        </Modal.Body>
        <Modal.Footer>
          <Button style={{ width: 80 }} size="small" priority="secondary" onClick={handleClose}>
            Cancel
          </Button>
          <Button
            disabled={
              temporaryFilesMutation.isLoading || updateMilestoneMutate.isLoading || addMilestoneMutate.isLoading
            }
            style={{ width: 65 }}
            type="submit"
            size="small"
          >
            Save
          </Button>
        </Modal.Footer>
      </form>
    </StyledModal>
  )
}
