import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import ProgressStepper from '@atoms/ProgressStepper'
import Typography from '@atoms/Typography/Typography'
import { CloseIcon, keyboardArrowLeft } from '@core/icons/icons'
import { getDate } from '@core/utils'
import { getContractsChecklist } from '@services/contract.service'
import { getEmployeeDetails } from '@services/employee.service'
import { DotWave } from '@uiball/loaders'
import { useQuery } from 'react-query'
import { Link, useNavigate, useParams } from 'react-router-dom'

import styles from './ContractorOnboardingChecklist.module.scss'

export default function ContractorOnboardingChecklist() {
  const params = useParams()

  const { data: contractorCheckList, isLoading } = useQuery(getContractsChecklist.key, {
    queryFn: () => getContractsChecklist.fetch(params.id),
    enabled: !!params.id,
  })

  const { data: employee, isLoading: employeeIsLoading } = useQuery('detailEmployee', {
    queryFn: () => getEmployeeDetails(params.id),
    enabled: !!params.id,
  })

  const uploadContractDone = contractorCheckList?.check_list.uploaded_agreement.passed ?? false
  const w8benDone = contractorCheckList?.check_list.w8ben_agreement.passed ?? false
  const identityDone = contractorCheckList?.check_list.sumsub_identity.passed ?? false
  const withdrawalDone = contractorCheckList?.check_list.withdrawal_method.passed ?? false

  const steps = [
    {
      success: uploadContractDone,
      active: uploadContractDone,
      title: 'Client sends an invitation & uploads the contract',
      content: contractorCheckList?.check_list.uploaded_agreement.datetime && (
        <p className={styles.datetime}>{getDate(contractorCheckList?.check_list.uploaded_agreement.datetime)}</p>
      ),
    },
    {
      success: w8benDone,
      active: w8benDone,
      title: 'Complete tax form',
      content: contractorCheckList?.check_list.w8ben_agreement.datetime && (
        <p className={styles.datetime}>{getDate(contractorCheckList.check_list.w8ben_agreement.datetime)}</p>
      ),
    },
    {
      success: identityDone,
      active: identityDone,
      title: 'Identity verification',
      content: contractorCheckList?.check_list.sumsub_identity.datetime && (
        <p className={styles.datetime}>{getDate(contractorCheckList.check_list.sumsub_identity.datetime)}</p>
      ),
    },
    {
      success: withdrawalDone,
      active: withdrawalDone,
      title: 'Set a withdrawal method',
      content: contractorCheckList?.check_list.withdrawal_method.datetime && (
        <p className={styles.datetime}>{getDate(contractorCheckList.check_list.withdrawal_method.datetime)}</p>
      ),
    },
  ]

  if (isLoading || employeeIsLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  return (
    <div className="h-100 d-flex flex-column">
      <div className="d-flex align-items-center justify-content-between">
        <Link to="/pages/team">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              border: '1px solid #CCCCCC',
              boxSizing: 'border-box',
              borderRadius: '8px',
              height: 40,
              width: 40,
            }}
          >
            <Icon icon={keyboardArrowLeft} style={{ cursor: 'pointer', transform: 'rotate(180deg)' }} />
          </div>
        </Link>
        <Typography className="heading_semibold__24">Contractor checklist</Typography>
        <Link to="/pages/team">
          <div
            className="d-flex align-items-center justify-content-center"
            style={{
              border: '1px solid #CCCCCC',
              boxSizing: 'border-box',
              borderRadius: '8px',
              height: 40,
              width: 40,
            }}
          >
            <Icon icon={CloseIcon} style={{ cursor: 'pointer' }} />
          </div>
        </Link>
      </div>
      <div className="flex-grow-1 d-flex justify-content-center align-items-center">
        <div className={styles.card}>
          <div className="d-flex justify-content-between align-items-center">
            <UserInfo employee={employee} />
            <GoToProfileButton />
          </div>
          <p>Below are the steps a Contractor must complete to be able to receive payments.</p>
          <div>
            <ProgressStepper
              steps={Object.keys(steps).map((id) => ({
                ...steps[id],
              }))}
              progress={0}
              // TODO: do we have to send onStepClick here? is it required?
            />
          </div>
        </div>
      </div>
    </div>
  )
}

function UserInfo({ employee }) {
  return (
    <div className="d-flex">
      <Avatar width={40} height={40} className="mr-3" />
      <div className={styles.creds}>
        <Typography>
          {employee.profile.first_name} {employee.profile.last_name}
        </Typography>
        <p
          style={{
            color: '#878787',
          }}
        >
          {employee.company.name}
        </p>
      </div>
    </div>
  )
}
function GoToProfileButton() {
  const navigate = useNavigate()
  const queryParams = useParams()

  const handleNavigateToProfile = () => navigate(`/pages/team/${queryParams.id}`)

  return (
    <Button
      data-testid="ContractorOnboardingChecklist-E632E6"
      priority="primary_black"
      size="small"
      onClick={handleNavigateToProfile}
    >
      Go to employee profile
    </Button>
  )
}
