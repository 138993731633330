export const parseCustomerEmployees = (employees, profile, resetUserIds) => {
  const user = profile
    ? {
        owner_id: profile.user.id,
        is_billing_contact: profile.user.is_billing_contact,
        id: profile.id,
        email: profile.user.profile.email,
        first_name: profile.user.profile.first_name,
        last_name: profile.user.profile.last_name,
        full_name: [profile.user.profile.first_name, profile.user.profile.last_name].filter((v) => !!v).join(' '),
        groups_joined: 'Superuser',
        status: '',
        avatar: profile.user?.profile?.avatar,
        resetSendButton: resetUserIds?.includes(profile.id),
      }
    : {}
  const employeesList = employees.map((employee) => ({
    is_billing_contact: employee.user.is_billing_contact,
    id: employee.id,
    uid: employee.user.id,
    groups: employee.user.groups,
    email: employee.user.email,
    first_name: employee.user.profile.first_name,
    last_name: employee.user.profile.last_name,
    full_name: [employee.user.profile.first_name, employee.user.profile.last_name].filter((v) => !!v).join(' '),
    groups_joined: employee.user.groups.map((group) => group.name).join(', '),
    status: employee.user.state,
    avatar: employee?.user?.profile?.avatar,
    resetSendButton: resetUserIds?.includes(employee.id),
  }))

  return profile ? [user, ...employeesList] : employeesList
}
