import { Api } from '@api/Api'

export const getSalaryChangeList = async ({ page, limit, ...rest }) => {
  const offset = (page - 1) * limit

  const { data } = await Api.get(`contracts/salary-change-flow/`, {
    params: {
      offset,
      limit,
      ...rest,
    },
  })
  return data
}

export const getSalaryChangeById = async (id) => {
  const { data } = await Api.get(`contracts/salary-change-flow/${id}/`)
  return data
}

export const createSalaryChange = async (body) => {
  const { data } = await Api.post(`contracts/salary-change-flow/create/`, body)
  return data
}

export const updateSalaryChange = async (id, body) => {
  const { data } = await Api.put(`contracts/salary-change-flow/${id}/update/`, body)
  return data
}

export const cancelSalaryChange = async (id, body) => {
  const { data } = await Api.put(`contracts/salary-change-flow/${id}/cancel/`, body)
  return data
}

export const createOnboardingAP = async (body, id) => {
  const v = await Api.post(`/compensation/${id}/onboarding-additional-payments/create/`, body)
  return v.data
}

export const deleteOnboardingAP = async (id) => {
  const v = await Api.delete(`/compensation/onboarding-additional-payments/${id}/delete/`)
  return v.data
}

export const updateOnboardingAP = async (id, body) => {
  const v = await Api.patch(`/compensation/onboarding-additional-payments/${id}/update/`, body)
  return v.data
}
