/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-nested-ternary */
import './Table.scss'

import Icon from '@atoms/Icon/Icon'
import Pagination from '@atoms/Pagination/Pagination'
import { arrowDown } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import React from 'react'
import { useSortBy, useTable } from 'react-table'

export default function Table({ page, total, columns, pageSize = 10, data, loading, onRowClick, onPage }) {
  // Use the state and functions returned from useTable to build your UI
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns,
      data,
    },
    useSortBy
  )
  // Render the UI for your table
  return (
    <>
      {loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 300 }}>
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <table {...getTableProps()} cellSpacing="0" className="mb-3" cellPadding="0">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    className="px-2"
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    <div className="d-flex align-items-center table_header">
                      {column.render('Header')}
                      {!column.isSorted && <Icon fill="#878787" className="sort_icon" icon={arrowDown} />}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon fill="#878787" className="rotate" icon={arrowDown} />
                        ) : (
                          <Icon fill="#878787" icon={arrowDown} />
                        )
                      ) : (
                        <span style={{ width: 20, height: 20 }} />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  className={classNames([
                    onRowClick && 'cursor-pointer',
                    row.original.status === 'inactive' && 'status_inactive',
                  ])}
                  {...row.getRowProps()}
                  onClick={
                    onRowClick
                      ? () => {
                          onRowClick(row.original)
                        }
                      : undefined
                  }
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      className="px-2"
                      style={{
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        maxWidth: cell.column.maxWidth,
                        wordBreak: 'break-word',
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {page && Math.ceil(total / pageSize) > 1 && (
        <Pagination page={page} pageSize={pageSize} total={total} onGetPage={onPage} />
      )}
    </>
  )
}
