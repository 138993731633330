import styled from 'styled-components'

const Multiple = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

const Single = styled.div``

const Files = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
`

export const Styled = {
  Multiple,
  Single,
  Files,
}
