import './style.scss'

import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import Step from '@atoms/Stepper/Step'
import StepContent from '@atoms/Stepper/StepContent'
import Stepper from '@atoms/Stepper/Stepper'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { CONTRACT_TYPE, SUB_STATE_CHANGE_REQUEST_TYPES } from '@core/constants'
import { keyboardArrowLeft } from '@core/icons/icons'
import { ContractForm } from '@pages/employees/create-contractor/contract-form/contract-form'
import {
  fetchCountryOccupations,
  fetchCountryRegions,
  getCountryProbationPeriodDetails,
} from '@services/countries.service'
import { DotWave } from '@uiball/loaders'
import { useCallback, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'
import { getEmployeeDetails } from 'src/services/employee.service'
import { useBoolean } from 'usehooks-ts'

import { createSubStateChangeRequest } from '../../services/contract.service'
import Agreement from './libs/Agreement/Agreement'
import Benefit from './libs/Benefit/Benefit'
import Compensation from './libs/Compensation/Compensation'
import Deposit from './libs/Deposit/Deposit'
import DocumentDetailEmployee from './libs/DocumentDetailEmployee/DocumentDetailEmployee'
import EmergencyContact from './libs/EmergencyContact/EmergencyContact'
import { JobDetailsFte } from './libs/JobDetailsFte/JobDetailsFte'
import { MilestoneStatus } from './libs/MilestonesTab/MilestoneStatus'
import Notes from './libs/Notes/Notes'
import OnboardingSteps from './libs/OnboardingSteps/onboarding-steps.component'
import { CancelOnboardingModal } from './libs/Personal/CancelOnboardingModal'
import { DeactivateButton } from './libs/Personal/DeactivateButton'
import Personal from './libs/Personal/Personal'
import TimeOffEmployee from './libs/TimeOff/TimeOffEmployee'
import { UpdateAgreementConfirmModal } from './UpdateAgreementModal'

export default function OnboardingEmployeeDetails() {
  const params = useParams()
  const [employee, setEmployee] = useState()
  const [occupations, setOccupations] = useState([])
  const [regions, setRegions] = useState([])
  const [onboardingStep, setOnboardingStep] = useState(0)
  const [activeStep, setStep] = useState(1)
  const [visibleCancelOnboarding, setVisibleCancelOnboarding] = useState(false)
  const updateContractOpen = useBoolean(false)
  const showUpdateContractModal = useBoolean(false)
  const openAgreementUpdateConfirm = useBoolean(false)
  const [probationPeriodDetails, setProbationPeriodDetails] = useState(null)

  const { isLoading: detailEmployeeLoading, refetch } = useQuery('detailEmployee', {
    queryFn: () => getEmployeeDetails(params.id),
    enabled: !!params.id,
    onSuccess: (v) => {
      setEmployee(v)
    },
  })

  const occupationsData = useQuery(
    'getCountryOccupations',
    () => fetchCountryOccupations(employee.working_country.id),
    {
      enabled: !!employee?.working_country?.id,
      onSuccess: (data) => {
        setOccupations(data.results)
      },
    }
  )

  const probationPeriod = useQuery(
    [getCountryProbationPeriodDetails.key, employee?.working_country.id],
    () => getCountryProbationPeriodDetails(employee?.working_country.id),
    {
      enabled: !!employee?.working_country.id,
      onSuccess: (data) => {
        setProbationPeriodDetails(data)
      },
    }
  )

  const createSubStateChangeRequestMutation = useMutation({
    mutationFn: ({ id, payload }) => createSubStateChangeRequest(id, payload),
    onSuccess: () => {
      toast.success('Thank you. Please wait for our team to contact you about the next steps.', {
        position: 'top-right',
        theme: 'colored',
        autoClose: false,
      })
      setVisibleCancelOnboarding(false)
      refetch()
    },
  })

  const regionsData = useQuery('getCountryRegions', () => fetchCountryRegions(employee.working_country.id), {
    enabled: !!employee?.working_country?.id,
    onSuccess: (data) => {
      setRegions(data.results)
    },
  })

  const handleUpdateContractClose = () => {
    showUpdateContractModal.setFalse()
    openAgreementUpdateConfirm.setFalse()
    updateContractOpen.setFalse()
  }

  const handleCancelOnboarding = ({ reason, description }) => {
    createSubStateChangeRequestMutation.mutate({
      id: employee.id,
      payload: {
        reason,
        request_type: SUB_STATE_CHANGE_REQUEST_TYPES.CANCELLED,
        comment: description,
      },
    })
  }

  const handleCancelOnboardingOpen = useCallback(() => setVisibleCancelOnboarding(true), [])
  const handleCancelOnboardingClose = useCallback(() => setVisibleCancelOnboarding(false), [])

  return (
    <div className="employee-detail-page h-100">
      {detailEmployeeLoading ? (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      ) : (
        <>
          <div className="d-flex align-items-center mb-4">
            <Link to="/pages/team">
              <div
                className="d-flex align-items-center justify-content-center"
                style={{
                  border: '1px solid #CCCCCC',
                  boxSizing: 'border-box',
                  borderRadius: '8px',
                  height: 40,
                  width: 40,
                }}
              >
                <Icon icon={keyboardArrowLeft} style={{ cursor: 'pointer', transform: 'rotate(180deg)' }} />
              </div>
            </Link>
            <Typography className="heading_semibold__24 ml-3">
              {employee?.profile.first_name} {employee?.profile.last_name}
            </Typography>
          </div>
          <div style={{ display: 'flex', justifyContent: 'space-between' }}>
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <Typography className="heading_semibold__20 color_black mt-4 mb-3">Onboarding</Typography>
              {employee.state !== 'created' && (
                <span className="ml-3 pt-2">
                  <Status
                    status={
                      employee.state === 'onboarding' &&
                      employee.onboarding_stage.substage.deposit_paid_by_client === 'completed' &&
                      employee.onboarding_stage.substage.signed_ea_uploaded === 'completed'
                        ? 'completed'
                        : 'in_progress'
                    }
                  />
                </span>
              )}
            </div>
            <div>
              <DeactivateButton
                contractType={employee.contract_type}
                state={employee.state}
                subState={employee.sub_state}
                onOnboardingCancel={handleCancelOnboardingOpen}
              />
            </div>
          </div>
          <OnboardingSteps employee={employee} setOnboardingStep={setOnboardingStep} activeStep={onboardingStep} />
          {onboardingStep === 0 && (
            <Tabs>
              <Tab tabId={0} title="Employee">
                {employee && (
                  <Personal
                    employee={employee}
                    refetch={refetch}
                    setEmployee={setEmployee}
                    openAgreementUpdateConfirm={openAgreementUpdateConfirm}
                  />
                )}
              </Tab>
              <Tab tabId={1} title="Job details">
                {employee?.contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE && (
                  <JobDetailsFte
                    employee={employee}
                    setEmployee={setEmployee}
                    occupations={occupations}
                    regions={regions}
                    probationPeriodDetails={probationPeriodDetails}
                  />
                )}
              </Tab>
              <Tab tabId={2} title="Compensation">
                {employee && <Compensation employee={employee} />}
              </Tab>
              <Tab
                tabId={3}
                title="Time-off"
                hidden={employee && employee.compensation.contractor_wage_type !== 'monthly'}
              >
                {employee && <TimeOffEmployee employee={employee} />}
              </Tab>
              <Tab tabId={4} title="RemoPlus" hidden={employee && employee.contract_type === 'contractor'}>
                {employee && <Benefit employee={employee} setEmployee={setEmployee} refetch={refetch} />}
              </Tab>
              <Tab tabId={5} title="Documents">
                {employee && <DocumentDetailEmployee id={employee.id} employee={employee} />}
              </Tab>
              <Tab tabId={6} title="Emergency contact">
                {employee && <EmergencyContact employee={employee} />}
              </Tab>
              <Tab tabId={7} title="Notes">
                {employee && <Notes employee={employee} />}
              </Tab>
            </Tabs>
          )}
          {onboardingStep === 1 && employee && <Deposit employee={employee} />}
          {onboardingStep === 2 && employee && <Agreement employee={employee} />}
        </>
      )}
      {showUpdateContractModal.value ? (
        <Modal show onClose={handleUpdateContractClose} fullscreen>
          <Modal.Body className="p-0">
            <Stepper
              activeStep={activeStep}
              title="Updating contract"
              description={
                <>
                  The current contract will be deactivated <br /> and replaced by the updated version.
                </>
              }
              sidebarStyle={{ width: 400 }}
            >
              <Step step="1" title="Update contract" setStep={setStep}>
                <StepContent title="Update contract" onClose={handleUpdateContractClose}>
                  <ContractForm
                    updateContractOpen={updateContractOpen}
                    contractor={employee}
                    onClose={handleUpdateContractClose}
                  />
                </StepContent>
              </Step>
            </Stepper>
          </Modal.Body>
        </Modal>
      ) : null}
      <UpdateAgreementConfirmModal
        show={openAgreementUpdateConfirm.value}
        onHide={openAgreementUpdateConfirm.setFalse}
        onConfirm={showUpdateContractModal.setTrue}
      />

      <CancelOnboardingModal
        visible={visibleCancelOnboarding}
        loading={createSubStateChangeRequestMutation.isLoading}
        onClose={handleCancelOnboardingClose}
        onSubmit={handleCancelOnboarding}
      />
    </div>
  )
}
