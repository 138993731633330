import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchAdditionPayments = async (id) => {
  const { data } = await Api.get(`/contractor-payments/${id}/additional-payments/`)
  return data
}

export const fetchContractorPayments = async (profileId, page, search, limit = 10) => {
  const { data } = await Api.get(
    `contractor-payments/companies/${profileId}/?limit=${limit}&offset=${(page - 1) * limit}${
      search ? `&search=${search}` : ''
    }`
  )
  return data
}
export const paymentCreate = async (data) => {
  const v = await Api.post(`contractor-payments/create/`, data)
  return v.data
}

export const paymentInvoicePreview = async (data) => {
  const v = await Api.post(`contractor-payments/invoice-preview/`, data, {
    responseType: 'blob',
  })
  return v.data
}
export const paymentReports = generateService(async (data) => {
  const v = await Api.post(`contractor-payments/reports/`, data, {
    responseType: 'blob',
  })
  return v.data
})

export const fetchDeleteContractorPayments = async (contractorId) => {
  const { data } = await Api.delete(`contractor-payments/${contractorId}/delete/`)
  return data
}

export const fetchDeleteAdditionPayment = async (id) => {
  const { data } = await Api.delete(`/additional-payments/${id}/delete/`)
  return data
}
export const fetchDeleteAdditionPaymentWithReccuring = async ({ id, body }) => {
  const { data } = await Api.delete(`/additional-payments/${id}/delete/?close_recurrence=${body.close_recurrence}`)
  return data
}

export const fetchUpdateAdditionPayment = async ({ id, body }) => {
  const { data } = await Api.put(`/additional-payments/${id}/update/`, body)
  return data
}

export const fetchCreateAdditionPayment = async (body) => {
  const { data } = await Api.post(`/additional-payments/create/`, body)
  return data
}

export const downloadAdditionalPaymentCsv = async (params) => {
  const { data } = await Api.get('/additional-payments/reports/', {
    params,
    responseType: 'blob',
  })

  return data
}
