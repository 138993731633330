/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable jsx-a11y/click-events-have-key-events */
import './AddPaymentInfoModal.scss'

import Button from '@atoms/Button/Button'
import moment from 'moment/moment'
import React from 'react'

export default function AddPaymentInfoModal({ addPayment, onCloseClick }) {
  return (
    <div className="invoice-modal">
      <div data-testid="AddPaymentInfoModal-323BA4" className="invoice-modal-bg" onClick={onCloseClick} />
      <div className="invoice-modal-content-wrap">
        <div className="invoice-modal-header">
          <div className="invoice-modal-title">Additional payment: {addPayment?.contract_data?.full_name}</div>
          <div className="invoice-modal-subtitle">Information</div>
        </div>
        <div className="invoice-modal-divider" />
        <div className="invoice-modal-info-section">
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Created by</div>
            <div className="invoice-modal-row-subtitle">{addPayment?.created_by?.full_name}</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Created time</div>
            <div className="invoice-modal-row-subtitle">{addPayment?.created_at}</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Type request</div>
            <div className="invoice-modal-row-subtitle">Additional payment</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Type</div>
            <div className="invoice-modal-row-subtitle">{addPayment?.get_ap_type_display}</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Title</div>
            <div className="invoice-modal-row-subtitle">{addPayment?.title}</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Description</div>
            <div className="invoice-modal-row-subtitle">{addPayment?.description}</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Gross amount</div>
            <div className="invoice-modal-row-subtitle">{addPayment?.amount}</div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Tax</div>
            <div className="invoice-modal-row-subtitle">
              {addPayment?.tax} {addPayment?.currency?.short_code}
            </div>
          </div>
          <div className="invoice-modal-info-row">
            <div className="invoice-modal-row-title">Payroll month</div>
            <div className="invoice-modal-row-subtitle">
              {addPayment?.payout_date ? moment(addPayment.payout_date).format('MMMM') : '-'}
            </div>
          </div>
        </div>
        <div className="invoice-modal-buttons-section">
          <Button
            data-testid="AddPaymentInfoModal-EB1FDB"
            priority="secondary"
            size="small"
            className="w-100"
            onClick={onCloseClick}
            style={{ height: 48 }}
          >
            Close
          </Button>
        </div>
      </div>
    </div>
  )
}
