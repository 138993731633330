import Button from '@atoms/Button/Button'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { setCurrencyChangeEvent } from '@core/utils'
import React, { memo } from 'react'
import { Controller, useForm } from 'react-hook-form'
import styled from 'styled-components'

const paymentTypes = [
  { value: 'BONUS', label: 'Bonus' },
  { value: 'COMMISSION', label: 'Commission' },
  { value: 'RETRO', label: 'Retro' },
  { value: 'OVERTIME', label: 'Overtime' },
  { value: 'DEDUCTION', label: 'Deduction' },
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'OTHER', label: 'Other' },
]

const expenseTypes = [
  { value: 'WORK_EQUIPMENT', label: 'Work Equipment' },
  { value: 'COWORKING_SPACE', label: 'Coworking space' },
  { value: 'EDUCATION', label: 'Education' },
  { value: 'FOOD', label: 'Food' },
  { value: 'OFFICE_SUPPLIES', label: 'Office supplies' },
  { value: 'TRAVEL', label: 'Travel' },
  { value: 'OTHER', label: 'Other' },
]

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`

const Form = ({ data, isLoading, onClose, onSave }) => {
  const { currencies } = useApp()
  const {
    control,
    formState: { errors },
    register,
    handleSubmit,
  } = useForm({
    defaultValues: {
      ap_type: data.ap_type || '',
      amount: data.amount || '',
      name: data.name || '',
      currency: data.currency.id || '',
      description: data.description || '',
      tax: data?.tax,
    },
  })

  const submit = (formValues) => {
    onSave({
      ...formValues,
      currency: formValues.currency.value,
      ap_type: formValues.ap_type.value,
    })
  }

  return (
    <StyledForm noValidate className="d-flex flex-column" onSubmit={handleSubmit(submit)}>
      <div className="row">
        <div className="col-6">
          <Controller
            control={control}
            name="ap_type"
            rules={{ required: 'Field is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-41949C"
                  {...field}
                  label="Payment type"
                  options={data?.source === 'EXPENSE_MANAGEMENT' ? expenseTypes : paymentTypes}
                />
              )
            }}
          />
          {errors.ap_type && <Typography className="text_regular__14 color_red">{errors.ap_type.message}</Typography>}
        </div>
        <div className="col-6">
          <Controller
            control={control}
            name="amount"
            rules={{
              required: 'Field is required',
              validate: {
                minlength: (v) =>
                  /^(?=(?:\d\.?){0,16}$)\d+(?:\.\d{1,2})?$/.test(v) || 'Only 2 digits allowed after decimal point',
              },
            }}
            render={({ field }) => (
              <CurrencyInput
                {...field}
                label="Amount"
                placeholder="Enter the adjustment amount"
                onChange={setCurrencyChangeEvent(field.onChange)}
              />
            )}
          />
          {errors.amount && <Typography className="text_regular__14 color_red">{errors.amount.message}</Typography>}
        </div>
      </div>

      <div className="row">
        <div className="col">
          <Controller
            control={control}
            name="currency"
            rules={{ required: 'Currency is required' }}
            render={({ field }) => {
              return (
                <Select
                  data-testid="Form-F78DBC"
                  {...field}
                  label="Currency"
                  options={currencies.map((country) => ({
                    value: country.id,
                    label: country.name || country.short_code,
                  }))}
                />
              )
            }}
          />
          {errors.currency && <Typography className="text_regular__14 color_red">{errors.currency.message}</Typography>}
        </div>
      </div>

      <div className="row">
        <div className="col-12">
          <Input
            data-testid="Form-E95C04"
            {...register('name', { required: 'Field is required' })}
            label="Reason name"
            placeholder="Title"
          />
          {errors.name && <Typography className="text_regular__14 color_red">{errors.name.message}</Typography>}
        </div>
        <div className="col-12">
          <TextArea placeholder="Description..." {...register('description')} />
          {errors.description && (
            <Typography className="text_regular__14 color_red">{errors.description.message}</Typography>
          )}
        </div>
      </div>

      <div className="align-self-end mb-0">
        <Button
          data-testid="Form-7AA813"
          className="mr-3"
          priority="secondary"
          size="small"
          type="button"
          onClick={onClose}
        >
          Close
        </Button>
        <Button data-testid="Form-2D0372" size="small" type="submit" disabled={isLoading} loading={isLoading}>
          Save
        </Button>
      </div>
    </StyledForm>
  )
}

export default memo(Form)
