import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import DateInput from '@atoms/DateInput/DateInput'
import CurrencyInput from '@atoms/Input/CurrencyInput'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { fetchContractors } from '@services/app.service'
import { getTaskProcessing } from '@store/taskProcessing'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import styled from 'styled-components'
import { v4 } from 'uuid'

import { paymentCreate } from '../../services/payments.service'

const PaymentType = {
  CurrentRate: 0,
  AnyAmount: 1,
}

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 570px;
    border-radius: 0 !important;
  }
`

export default ({ close, refetch }) => {
  const { profile, currencies } = useContext(AppContext)
  const [taskId, setTaskId] = useState()
  const [tabId, setTabId] = useState(PaymentType.CurrentRate)
  const [loading, setLoading] = useState(false)
  const {
    handleSubmit,
    control,
    watch,
    reset,
    setValue,
    register,
    formState: { errors },
  } = useForm()

  const createPaymentRequest = useMutation(paymentCreate, {
    onSuccess: (res) => {
      setTaskId(res.task_id)
    },
  })
  const forAll = watch('for_all')
  const contractsWatch = watch('contracts')
  const startDate = watch('start_date')
  const onCreatePayment = (values) => {
    createPaymentRequest.mutate({
      ...values,
      start_date: moment(values.start_date).format('YYYY-MM-DD'),
      end_date: moment(values.end_date).format('YYYY-MM-DD'),
      company: profile.id,
      amount: tabId === 1 ? values.amount : undefined,
      contracts: forAll ? [] : values.contracts.map((contract) => contract.value),
      currency: values.currency?.value,
    })
  }
  const contractors = useQuery([fetchContractors.key], {
    queryFn: () =>
      fetchContractors.fetch({
        company_id: profile.id,
        contract_type: 'contractor',
        state: 'active',
        offset: 0,
        limit: 1000,
      }),
  })
  const taskProcessing = useQuery(['task_processing', taskId], {
    queryFn: () => getTaskProcessing(taskId),
    enabled: !!taskId,
  })
  useEffect(() => {
    if (forAll) {
      setValue('contracts', [])
    }
  }, [forAll])
  useEffect(() => {
    if (taskId) {
      const interval = setInterval(() => {
        taskProcessing.refetch()
      }, 2000)
      setLoading(true)
      if (
        taskProcessing.data?.state === 'SUCCESS' ||
        taskProcessing.data?.state === 'FAILURE' ||
        taskProcessing.data?.state === 'RETRY'
      ) {
        if (taskProcessing.data?.state === 'SUCCESS') {
          refetch()
          toast.success('Successfully created!')
        }
        clearInterval(interval)
        close()
        refetch()
        reset()
        setLoading(false)
      }
      return () => {
        clearInterval(interval)
      }
    }
    return () => {}
  }, [taskId, taskProcessing])
  const renderTab = (_type) => (
    <form id="timeOff-form" key={tabId} onSubmit={handleSubmit(onCreatePayment)}>
      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="contracts"
          rules={{ required: !forAll }}
          render={({ field }) => {
            return (
              <Select
                data-testid="NewPaymentModal-705610"
                {...field}
                onChange={(e) => field.onChange(e)}
                isDisabled={forAll}
                isMulti
                selectedValue={contractsWatch || []}
                loading={contractors.isLoading}
                options={contractors?.data?.results.map((contract) => ({
                  value: contract.id,
                  label: contract.full_name,
                }))}
              />
            )
          }}
        />
        {errors.contracts && <Typography className="text_regular__14 color_red">{errors.contracts.message}</Typography>}
      </div>
      {((contractsWatch && contractsWatch.length > 1) || forAll) && (
        <NotificationBlock
          styledClass="notification"
          text="When you create payments for multiple contractors, the amount specified below will be used for every contractor. You can update individual payments’ rate and hours afterwards."
        />
      )}
      <div className="w-100 remo-form-input">
        <Checkbox data-testid="NewPaymentModal-7E918B" {...register('for_all')} label="Payment for the whole team" />
        {errors.for_all && <Typography className="text_regular__14 color_red">{errors.for_all.message}</Typography>}
      </div>
      {_type === PaymentType.AnyAmount && (
        <>
          <div className="w-100 remo-form-input">
            <CurrencyInput
              {...register('amount', {
                required: `Amount is required`,
              })}
              type="text"
              label="Enter any amount"
              required
            />
            {errors?.amount && <Typography className="text_regular__14 color_red">{errors?.amount.message}</Typography>}
          </div>
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              rules={{ required: `Currency is required` }}
              name="currency"
              render={({ field }) => (
                <Select
                  data-testid="NewPaymentModal-3FB8AC"
                  {...field}
                  placeholder="Select currency"
                  options={currencies.map(({ id, name }) => ({
                    value: id,
                    label: name,
                  }))}
                />
              )}
            />
            {errors?.currency && (
              <Typography className="text_regular__14 color_red">{errors?.currency.message}</Typography>
            )}
          </div>
        </>
      )}
      <Typography className="heading_semibold__16 my-2">Period</Typography>
      <div className="row">
        <div className="col-6 pr-2">
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="start_date"
              rules={{ required: 'Start date is required' }}
              render={({ field }) => {
                return <DateInput {...field} label="Start date" />
              }}
            />
            {errors.start_date && (
              <Typography className="text_regular__14 color_red">{errors.start_date.message}</Typography>
            )}
          </div>
        </div>
        <div className="col-6 pl-2">
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="end_date"
              rules={{ required: 'End date is required' }}
              render={({ field }) => {
                return <DateInput {...field} label="End date" minDate={startDate} />
              }}
            />
            {errors.end_date && (
              <Typography className="text_regular__14 color_red">{errors.end_date.message}</Typography>
            )}
          </div>
        </div>
      </div>
    </form>
  )

  const onTabChange = (tab) => {
    setTabId(tab)
    reset()
  }

  return (
    <StyledModal show onHide={close} centered className="p-0">
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">New payment</Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4">
        {contractors.isLoading ? (
          <div className="edit-avatar__loader">
            <DotWave size={32} speed={1} color="black" />
          </div>
        ) : (
          <Tabs selectedTab={tabId} onTabChange={onTabChange}>
            <Tab
              tabId={PaymentType.CurrentRate}
              title={
                <div className="d-flex">
                  <p className="mr-2">Pay current rate</p>
                  <Tooltip
                    id={v4()}
                    clickable
                    style={{ width: '540px' }}
                    content="Create off-cycle payments for your contractors based on their current payment rate."
                  />
                </div>
              }
            >
              {renderTab(tabId)}
            </Tab>
            <Tab
              tabId={PaymentType.AnyAmount}
              title={
                <div className="d-flex">
                  <p className="mr-2">Pay any amount</p>
                  <Tooltip
                    id={v4()}
                    clickable
                    style={{ width: '470px' }}
                    content="Create off-cycle payments for your contractors for any specified amount."
                  />
                </div>
              }
            >
              {renderTab(tabId)}
            </Tab>
          </Tabs>
        )}
      </Modal.Body>
      <Modal.Footer>
        <Button data-testid="NewPaymentModal-3067C2" type="button" size="small" priority="secondary" onClick={close}>
          Cancel
        </Button>
        <Button
          data-testid="NewPaymentModal-12A418"
          form="timeOff-form"
          type="submit"
          size="small"
          loading={createPaymentRequest.isLoading || taskProcessing.isLoading || loading}
          priority="primary"
        >
          Generate
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
