/* eslint-disable no-shadow */
import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Pagination from '@atoms/Pagination/Pagination'
import Typography from '@atoms/Typography/Typography'
import usePagination from '@core/hooks/usePagination'
import {
  deleteContractor,
  retrieveImportedContractors,
  updateContractor,
} from '@services/mass-import-contractors.service'
import { DotWave } from '@uiball/loaders'
import { drop, isEmpty, take } from 'lodash'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { Styled } from '../contractors-mass-import-modal.styles'
import { ContractorsMassImportValidateModal } from './contractors-mass-import-validate-modal.component'
import { SuccessMassImport } from './success-mass-import.component'

export const ContractorsMassImportValidate = ({ massImportId, isLoading, onReUpload, onNext }) => {
  const [{ contractor, arrayIndex }, setContractorAndIndex] = useState({})
  const deleteModalOpen = useBoolean(false)

  const { page, setPage, limit } = usePagination({ page: 1, limit: 10 })

  // for FE pagination
  const [contractorsToRender, setContractorsToRender] = useState([])

  const retrieveQuery = useQuery([retrieveImportedContractors.key, massImportId], {
    enabled: !!massImportId,
    keepPreviousData: true,
    queryFn: () => retrieveImportedContractors.fetch(massImportId),
    onSuccess: ({ raw_data }) => {
      setContractorsToRender(take(raw_data, limit))
    },
  })

  const handleChangePage = (page) => {
    setPage(page)

    // cut previous [limit = 10] items
    const dropped = drop(retrieveQuery.data.raw_data, (page - 1) * limit)

    //  and then take first [limit = 10] items
    setContractorsToRender(take(dropped, limit))
  }

  const updateContractorMutation = useMutation({
    mutationFn: updateContractor,
    onSuccess: (contractor) => {
      retrieveQuery.refetch()

      setContractorAndIndex((prev) => (contractor.errors.length === 0 ? {} : { ...prev, contractor }))
    },
  })

  const deleteContractorMutation = useMutation({
    mutationFn: deleteContractor,
    onSuccess: () => {
      retrieveQuery.refetch()
      deleteModalOpen.setFalse()
      setContractorAndIndex({})
    },
  })

  const handleSave = (body) => {
    updateContractorMutation.mutate({
      massImportId,
      arrayIndex,
      body,
    })
  }

  if (retrieveQuery.isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  return (
    <>
      <div className="h-100 d-flex flex-column">
        <section className="d-flex flex-column justify-content-center gap-3 flex-grow-1">
          {isEmpty(contractorsToRender) ? (
            <div className="d-flex flex-column align-items-center">
              <SuccessMassImport />
              <Typography>Data validated, all good!</Typography>
            </div>
          ) : (
            <div className="d-flex flex-column gap-3">
              <Typography className="heading_semibold__20">
                We have found the errors below in your data file. <br /> You can either provide the new value here or
                correct the errors in your CSV and then{' '}
                <span role="button" aria-hidden style={{ color: '#04c53a' }} disabled={isLoading} onClick={onReUpload}>
                  re-upload your file.
                </span>
              </Typography>
              <Styled.Table className="mt-3">
                <thead>
                  <tr>
                    <th>Contractor</th>
                    <th>No. of errors</th>
                    <th>Action</th>
                  </tr>
                </thead>
                <tbody>
                  {contractorsToRender.map((contractor, arrayIndex) => (
                    <tr key={v4()}>
                      <td>{`${contractor.first_name} ${contractor.last_name}`}</td>
                      <td>{contractor.errors.length}</td>
                      <td>
                        <Button
                          data-testid="contractors-mass-import-validate.component-253F21"
                          size="small"
                          color="primary"
                          onClick={() => {
                            setContractorAndIndex({ contractor, arrayIndex })
                          }}
                        >
                          Correct error
                        </Button>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </Styled.Table>
              <div className="mt-2 align-self-end">
                <Pagination
                  page={page}
                  pageSize={limit}
                  total={retrieveQuery.data.raw_data.length - 1}
                  onGetPage={handleChangePage}
                />
              </div>
            </div>
          )}
        </section>
        <div className="align-self-center pb-5">
          <Button
            data-testid="contractors-mass-import-validate.component-AC0E65"
            size="small"
            disabled={!isEmpty(retrieveQuery.data.raw_data) || isLoading}
            onClick={onNext}
          >
            Next
          </Button>
        </div>
      </div>

      {contractor && (
        <ContractorsMassImportValidateModal
          contractor={contractor}
          isLoading={updateContractorMutation.isLoading}
          onClose={() => setContractorAndIndex({})}
          onSave={handleSave}
          onDelete={deleteModalOpen.setTrue}
        />
      )}

      {deleteModalOpen.value && contractor && (
        <Confirm
          title="Remove contractor"
          onCancel={deleteModalOpen.setFalse}
          disabledButton={deleteContractorMutation.isLoading}
          yesButtonProps={{
            type: 'button',
            text: 'Yes, remove',
          }}
          onYes={() => {
            deleteContractorMutation.mutate({ massImportId, arrayIndex })
          }}
        >
          Are you sure you want to remove <b>{`${contractor.first_name} ${contractor.last_name}`}</b> from this import?
          All info of <b>{`${contractor.first_name} ${contractor.last_name}`}</b> will be discarded.
        </Confirm>
      )}
    </>
  )
}
