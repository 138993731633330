import { Api } from '@api/Api'
import { patchContractById } from '@store/contracts'

export const getNotifications = async ({ type, contractId }) => {
  const { data } = await Api.get(`/platform-notifications/`, {
    params: {
      source_app_in: 'company',
      state_in: 'active',
      contract_id: contractId,
      entity_type: type,
    },
  })

  return data
}

export const deactivateNotification = (id, data) => patchContractById(id, data)
