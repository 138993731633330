import styled from 'styled-components'

const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const Employee = styled.div`
  background: #f6f6f6;
  padding: 0.75rem;
  border-radius: 8px;
  display: flex;
  align-items: center;
  gap: 1.5rem;
`
const EmployeeInfo = styled.div``

const Actions = styled.div`
  text-align: right;
`
const Filters = styled.div`
  display: flex;
  align-items: center;
  gap: 0.5rem;
`

const DaysCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

const ColoredNumber = styled.span`
  color: ${({ value }) => {
    if (value < 0) return '#E0613A'
    if (value > 0) return '#037B0F'
    return 'inherit'
  }};
`
const IconButton = styled.button`
  padding: 0;
  margin: 0;
  border: 0;
  background: none;
  line-height: 1;
`

const ActionCell = styled.div`
  display: flex;
  flex-direction: column;
  gap: 2px;
`

export const Styled = {
  Container,
  Employee,
  EmployeeInfo,
  Actions,
  Filters,
  DaysCell,
  ColoredNumber,
  IconButton,
  ActionCell,
}
