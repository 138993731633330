import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import SignConfirm from '../sign-confirm/sign-confirm.component'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .modal-header {
    margin-bottom: 0;
    padding: 1.5rem;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    position: relative;
    padding: 0 1.5rem 1.5rem;
  }
  .modal-footer {
    padding: 1.5rem;
  }
`
const Container = styled.div``

export default function SignConfirmModal({ countryName, onConfirm, onClose }) {
  const [checked, setChecked] = useState(false)

  const handleSubmit = () => {
    onConfirm()
    onClose()
  }

  const handleCheck = (e) => {
    setChecked(e.target.checked)
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">
          Please confirm that you are an authorized representative
        </Typography>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <SignConfirm countryName={countryName} onCheck={handleCheck} checked={checked} />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="sign-confirm-modal.component-F36902"
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="sign-confirm-modal.component-9A97E5"
          form="employeeDocumentsForm"
          priority="primary"
          size="small"
          disabled={!checked}
          onClick={handleSubmit}
        >
          Continue
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
