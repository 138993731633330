import 'rc-pagination/assets/index.css'

import Arrow from '@atoms/Pagination/libs/Arrow/Arrow'
import Pagination from 'rc-pagination'
import styled from 'styled-components'

const StyledPagination = styled(Pagination)`
  .rc-pagination-item {
    border: none;
    border-radius: 8px;
    width: 40px;
    height: 40px;

    a {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100%;
    }

    &:hover {
      border: 1px solid #f5f5f5;
      transition: none;
    }
  }
  .rc-pagination-item-active {
    background: #f5f5f5;
    a {
      color: none;
    }
  }
`

export default ({ total, onGetPage, pageSize: propsPageSize = 10, page }) => {
  const handleChange = (current) => {
    onGetPage(current)
  }

  return (
    <div className="d-flex align-items-center justify-content-end">
      <StyledPagination
        className="ant-pagination"
        total={total}
        current={page}
        pageSize={propsPageSize}
        prevIcon={<Arrow direction="left" />}
        nextIcon={<Arrow direction="right" />}
        onChange={handleChange}
        style={{ alignItems: 'center' }}
      />
    </div>
  )
}
