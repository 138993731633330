import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Input from '@atoms/Input/Input'
import List from '@atoms/List/List'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

import { mapGroupToList } from './assign-groups-modal'

const editManagerFormId = 'editManagerFormId'

export const EditManagerModal = ({ loading, selectedManager, onSubmit, onOpenGroupsModal, ...rest }) => {
  const {
    control,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: { ...selectedManager },
  })

  const submit = ({ email, first_name, last_name, is_billing_contact }) => {
    const payload = {
      manager: {
        email,
        profile: {
          first_name,
          last_name,
        },
        is_billing_contact,
      },
    }

    onSubmit(payload)
  }

  return (
    <Modal {...rest} centered show>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>{`${selectedManager.first_name} ${selectedManager.last_name}`}</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id={editManagerFormId} className="d-flex flex-column gap-3" onSubmit={handleSubmit(submit)}>
          <div className="d-flex gap-3">
            <div className="w-100">
              <Input
                data-testid="edit-manager-form-866567"
                autoFocus
                placeholder="First name"
                label="First name"
                autoComplete="false"
                isRequired
                {...register('first_name', {
                  required: 'First name is required',
                })}
              />
              {errors.first_name && (
                <Typography className="text_regular__14 color_red">{errors.first_name.message}</Typography>
              )}
            </div>

            <div className="w-100">
              <Input
                data-testid="edit-manager-form-A5315E"
                autoComplete="false"
                placeholder="Last name"
                label="Last name"
                isRequired
                {...register('last_name', {
                  required: 'Last name is required',
                })}
              />
              {errors.last_name && (
                <Typography className="text_regular__14 color_red">{errors.last_name.message}</Typography>
              )}
            </div>
          </div>
          <Input
            data-testid="edit-manager-form-B5723A"
            type="email"
            placeholder="Enter the email"
            label="Email"
            disabled
            isRequired
            {...register('email', {
              required: 'Please fill the email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && <Typography className="text_regular__14 color_red">{errors.email.message}</Typography>}

          <Controller
            control={control}
            name="is_billing_contact"
            render={({ field }) => (
              <Checkbox
                data-testid="edit-manager-form-B6D130"
                {...field}
                checked={field.value}
                label="Is a Billing contact"
              />
            )}
          />
        </form>

        <div className="d-flex flex-column gap-3 mt-3">
          <div className="d-flex justify-content-between">
            <Typography className="text_medium__14">Groups</Typography>
            <Button
              data-testid="edit-manager-form-24B3A0"
              priority="primary"
              size="small"
              className="align-self-end"
              hidden={selectedManager.owner_id}
              onClick={() => onOpenGroupsModal()}
            >
              <Typography>Assign groups</Typography>
            </Button>
          </div>
          {selectedManager.groups && <List lists={selectedManager.groups.map((group) => mapGroupToList(group))} />}
        </div>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="edit-manager-form-DE1A59"
          type="button"
          priority="secondary"
          size="small"
          onClick={rest.onHide}
        >
          Cancel
        </Button>
        <Button
          data-testid="edit-manager-form-986937"
          type="submit"
          form={editManagerFormId}
          priority="primary"
          size="small"
          disabled={loading}
        >
          <Typography>Save</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
