import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchAllGroups = generateService(async () => {
  const { data } = await Api.get(`roles/groups`)
  return data
})

export const fetchUserGroups = generateService(async (id) => {
  const { data } = await Api.get(`roles/${id}`)
  return data
})

export const assignGroups = async (payload) => {
  const { data } = await Api.post(`roles/users/manage/`, payload)
  return data
}
