import Button from '@atoms/Button/Button'
import { useApp } from '@core/context'
import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import React, { useState } from 'react'

export const SetupForm = ({ onClose }) => {
  const { userProfile } = useApp()
  const stripe = useStripe()
  const elements = useElements()
  const returnUrl = window.location.origin
  const [errorMessage, setErrorMessage] = useState(null)
  const handleSubmit = async (event) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()
    if (!stripe || !elements) {
      // Stripe.js has not yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }
    const { error } = await stripe.confirmSetup({
      // `Elements` instance that was used to create the Payment Element
      elements,
      confirmParams: {
        return_url: `${returnUrl}/pages/stripe`,
        payment_method_data: {
          billing_details: {
            name: `${userProfile.profile.first_name} ${userProfile.profile.last_name}`,
            email: `${userProfile.profile.email}`,
          },
        },
      },
    })

    if (error) {
      // This point will only be reached if there is an immediate error when
      // confirming the payment. Show error to your customer (for example, payment
      // details incomplete)
      setErrorMessage(error.message)
    } else {
      // Your customer will be redirected to your `return_url`. For some payment
      // methods like iDEAL, your customer will be redirected to an intermediate
      // site first to authorize the payment, then redirected to the `return_url`.
    }
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement
        options={{
          fields: {
            billingDetails: {
              name: 'never',
              email: 'never',
            },
          },
        }}
      />
      {/* eslint-disable-next-line react/button-has-type */}
      <div className="d-flex mt-2">
        <Button data-testid="SetupForm-9802E1" type="submit" size="small" priority="primary" disabled={!stripe}>
          Submit
        </Button>
        <Button
          data-testid="SetupForm-9B4895"
          className="ml-2"
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Close
        </Button>
      </div>
      {/* Show error message to your customers */}
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}
