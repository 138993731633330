import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const fetchCountriesIdBurden = async ({ countryId, yearly_salary, currency, company }) => {
  const { data } = await Api.post(`countries/${countryId}/calculate-burden/`, {
    yearly_salary,
    currency,
    company,
  })
  return data
}

export const fetchCountryDetailById = async (id) => {
  const { data } = await Api.get(`countries/${id}/`)
  return data
}

export const fetchCountryPaidLeavesById = async (id) => {
  const { data } = await Api.get(`country/${id}/paid-leaves/`)
  return data
}

export const fetchCountrySpecificPaidById = async (id) => {
  const { data } = await Api.get(`country/${id}/specific-paid-leaves/`)
  return data
}

export const fetchCountryOccupations = async (id) => {
  const { data } = await Api.get(`occupations/?country_id=${id}&limit=1000000&offset=${0}`)
  return data
}

export const fetchCountryRegions = async (id) => {
  const { data } = await Api.get(`regions/?country=${id}&limit=1000000&offset=${0}`)
  return data
}

export const getCountryPartners = generateService(async (params) => {
  const { data } = await Api.get(`/country-partners/`, {
    params,
  })
  return data
})

export const getCountryProbationPeriodDetails = async (id) => {
  const { data } = await Api.get(`/countries/${id}/probation-period-detail/`)
  return data
}
