import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Input from '@atoms/Input/Input'
import PhoneInput from '@atoms/PhoneInput/PhoneInput'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Typography from '@atoms/Typography/Typography'
import { RESIDENCY_TYPES } from '@core/constants'
import moment from 'moment'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'

import { RemoCheckFormSection } from '../../remoCheck-form'

export default ({ onNext, draft }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    watch,
    reset,
  } = useForm({
    defaultValues: {
      first_name: '',
      last_name: '',
      email: '',
      phone_number: '',
      needs_work_permit: false,
      use_remo_check: false,
      remo_check_plan: '',
    },
  })

  const watchResidencyType = watch('residency_type')

  useEffect(() => {
    if (draft?.profile) {
      reset({
        ...draft.profile,
        residency_type: draft.residency_type,
      })
    }
  }, [draft])

  const submit = ({
    email,
    first_name,
    last_name,
    middle_name,
    birth_date,
    phone_number,
    needs_work_permit,
    residency_type,
    use_remo_check,
    remo_check_plan,
  }) => {
    onNext({
      profile: {
        email,
        first_name,
        middle_name: middle_name ?? '',
        last_name,
        birth_date: moment(birth_date).format('YYYY-MM-DD'),
        phone_number,
        needs_work_permit: residency_type === RESIDENCY_TYPES.NON_RESIDENT && needs_work_permit === 'true',
      },
      remo_check: {
        use_remo_check: use_remo_check === 'true',
        remo_check_plan: use_remo_check === 'true' ? remo_check_plan : '',
      },
      residency_type,
    })
  }

  return (
    <form className="d-flex flex-column h-100 employees-page__form" onSubmit={handleSubmit(submit)}>
      <section className=" flex-grow-1 d-flex flex-column  justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <Input
            data-testid="PersonalInformationForm-0D5258"
            {...register('first_name', { required: 'First name is required' })}
            type="text"
            label="First name"
            placeholder="As on Legal ID"
          />
          {errors.first_name && (
            <Typography className="text_regular__14 color_red">{errors.first_name.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="PersonalInformationForm-MiddleName"
            {...register('middle_name')}
            type="text"
            label="Middle name"
            placeholder="As on Legal ID"
          />
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="PersonalInformationForm-40F075"
            {...register('last_name', { required: 'Last name is required' })}
            type="text"
            label="Last name"
            placeholder="As on Legal ID"
          />
          {errors.last_name && (
            <Typography className="text_regular__14 color_red">{errors.last_name.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Input
            data-testid="PersonalInformationForm-D29126"
            {...register('email', {
              required: 'Email is required',
              validate: {
                isEmail: (v) => {
                  return /.+@.+\..+/.test(v) || 'Please enter correct email'
                },
              },
            })}
            addText="We will use this email to send an invite"
            type="email"
            label="Email"
            placeholder="Personal email"
          />
          {errors.email && <Typography className="text_regular__14 color_red">{errors.email.message}</Typography>}
        </div>

        <div className="w-100 remo-form-input ">
          <Controller
            control={control}
            name="birth_date"
            rules={{ required: 'Date of birth is required' }}
            render={({ field }) => {
              return (
                <DateInput {...field} label="Date of birth" className="datepicker_full-width" maxDate={new Date()} />
              )
            }}
          />
          {errors.birth_date && (
            <Typography className="text_regular__14 color_red">{errors.birth_date.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="phone_number"
            rules={{
              required: 'Phone number is required',
            }}
            render={({ field }) => {
              return (
                <PhoneInput
                  {...field}
                  international
                  withCountryCallingCode
                  label="Phone number"
                  placeholder="Enter phone number"
                />
              )
            }}
          />
          {errors.phone_number && (
            <Typography className="text_regular__14 color_red">{errors.phone_number.message}</Typography>
          )}
        </div>

        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="residency_type"
            rules={{ required: 'Residency Status is required' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Is the employee a resident or non-resident of this country?"
                layout="vertical"
                options={[
                  {
                    id: 'resident',
                    value: RESIDENCY_TYPES.RESIDENT,
                    text: 'Resident',
                  },
                  {
                    id: 'non-resident',
                    value: RESIDENCY_TYPES.NON_RESIDENT,
                    text: 'Non-Resident',
                  },
                ]}
              />
            )}
          />
          {errors.residency_type && (
            <Typography className="text_regular__14 color_red">{errors.residency_type.message}</Typography>
          )}
        </div>

        {watchResidencyType === RESIDENCY_TYPES.NON_RESIDENT && (
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="needs_work_permit"
              rules={{ required: 'Work permit is required' }}
              render={({ field }) => (
                <RadioButton
                  {...field}
                  label="Does the employee have a visa/work permit to work in this country?"
                  layout="vertical"
                  options={[
                    {
                      id: 'needs_work_permit_true',
                      value: true,
                      text: 'Yes, the employee currently has valid visa/permit ',
                    },
                    {
                      id: 'needs_work_permit_false',
                      value: false,
                      text: 'No, the employee doesn’t have and needs to apply for visa/work permit',
                    },
                  ]}
                />
              )}
            />
            {errors.needs_work_permit && (
              <Typography className="text_regular__14 color_red">{errors.needs_work_permit.message}</Typography>
            )}
          </div>
        )}
        <RemoCheckFormSection control={control} />
      </section>

      <div className="align-self-center pb-5">
        <Button data-testid="PersonalInformationForm-3D56FE" type="submit" className="align-self-end">
          Continue
        </Button>
      </div>
    </form>
  )
}
