import './style.scss'

import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import React from 'react'
import { NumericFormat } from 'react-number-format'

export default React.forwardRef(
  ({ id, style, onChange, name, label, styleClass, currency, endIcon, outlined, addText, required, ...props }, ref) => {
    const handleChange = ({ value }) => {
      onChange({ target: { value, name } })
    }
    return (
      <div className={classNames(['input', styleClass])}>
        <div className="d-flex flex-column w-100">
          {label && (
            <label
              className={classNames(['text_medium__14', !addText && 'remo-form-label'])}
              htmlFor={id}
              style={style}
            >
              {label} {required && <span className="text-danger">*</span>}
            </label>
          )}
          {addText && (
            <Typography className="text_light__12 color_text_300 mt-1" style={{ marginBottom: 12 }}>
              {addText}
            </Typography>
          )}
        </div>
        <div className={classNames(['input-wrapper d-flex align-items-center', outlined && 'input_outlined'])}>
          {currency && <span className="currency mx-2">{currency}</span>}
          <NumericFormat
            {...props}
            ref={ref}
            allowNegative={false}
            decimalScale={2}
            thousandSeparator=" "
            fixedDecimalScale
            onValueChange={handleChange}
          />
          <span className="input-end-icon">{endIcon}</span>
        </div>
      </div>
    )
  }
)
