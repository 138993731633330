import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import {
  CountryPaidLeaves,
  CountrySpecificPaidLeaves,
  TimeOffAccrualRule,
  TimeOffAnnualDaysRule,
  TimeOffCarryoverExpiry,
  TimeOffCarryoverExpiryUnit,
  TimeOffCarryoverRule,
  TimeOffPolicyDto,
  TimeOffSickLeaveDaysRule,
} from '@core/types/time-off.types'
import { useEffect } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { Link } from 'react-router-dom'

import { Styled } from './time-off-policy-form.styles'
import { parseValues } from './time-off-policy-form.utils'

export type TimeOffPolicyFormValues = {
  unlimitedTimeOff?: string
  annualDaysByContract: string
  accrualRule: TimeOffAccrualRule
  annualDaysRule: TimeOffAnnualDaysRule
  allowCarryover: string
  carryoverRule: TimeOffCarryoverRule
  maxCarryoverDays: number
  carryoverExpiry: TimeOffCarryoverExpiry
  carryoverExpiryAmount: number
  carryoverExpiryUnit: TimeOffCarryoverExpiryUnit
  sickLeaveDaysRule: TimeOffSickLeaveDaysRule
}

export const TIME_OFF_POLICY_FORM_ID = 'TIME_OFF_POLICY_FORM_ID'

type Props = {
  onSubmit(data: TimeOffPolicyFormValues, isDirty: boolean): void
  data?: TimeOffPolicyDto
  countryPaidLeaves: CountryPaidLeaves
  countrySpecificPaidLeaves?: CountrySpecificPaidLeaves | null
}

export const TimeOffPolicyForm = ({ onSubmit, data, countryPaidLeaves, countrySpecificPaidLeaves }: Props) => {
  const {
    control,
    formState: { isDirty, errors },
    handleSubmit,
    watch,
    register,
    reset,
  } = useForm<TimeOffPolicyFormValues>()

  const watchAllowCarryover = watch('allowCarryover')
  const watchCarryoverRule = watch('carryoverRule')
  const watchCarryoverExpiry = watch('carryoverExpiry')

  useEffect(() => {
    if (data) {
      reset(parseValues(data))
    }
  }, [data])

  const onBeforeSubmit = (values: TimeOffPolicyFormValues) => {
    onSubmit(values, isDirty)
  }

  return (
    <form onSubmit={handleSubmit(onBeforeSubmit)} id={TIME_OFF_POLICY_FORM_ID}>
      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="annualDaysRule"
          rules={{ required: 'Choose one option' }}
          render={({ field }) => {
            return (
              <Select
                isRequired
                {...field}
                label="Annual paid time off days"
                options={[
                  {
                    value: TimeOffAnnualDaysRule.Statutory,
                    label: countryPaidLeaves ? `Statutory - ${countryPaidLeaves?.statutory_annual_days}` : `Statutory`,
                  },
                  {
                    value: TimeOffAnnualDaysRule.Recommended,
                    label: countryPaidLeaves
                      ? `Recommended - ${countryPaidLeaves?.recommendation_by_remofirst_days}`
                      : 'Recommended',
                  },
                  {
                    value: TimeOffAnnualDaysRule.TopOfMarket,
                    label: countryPaidLeaves
                      ? `Top of market - ${countryPaidLeaves?.recommendation_by_market_days}`
                      : `Top of market`,
                  },
                ]}
                onChange={({ value }) => field.onChange(value)}
              />
            )
          }}
        />
        {errors?.annualDaysRule && (
          <Typography className="text_regular__14 color_red">{errors.annualDaysRule.message}</Typography>
        )}
      </div>

      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="sickLeaveDaysRule"
          rules={{ required: 'Choose one option' }}
          render={({ field }) => {
            return (
              <Select
                isRequired
                {...field}
                label="Sick leave days"
                options={[
                  {
                    value: TimeOffSickLeaveDaysRule.Statutory,
                    label: countrySpecificPaidLeaves
                      ? `Statutory - ${countrySpecificPaidLeaves?.sick_days}`
                      : `Statutory`,
                  },
                  {
                    value: TimeOffSickLeaveDaysRule.Unlimited,
                    label: 'Unlimited',
                  },
                ]}
                onChange={({ value }) => field.onChange(value)}
              />
            )
          }}
        />
        {errors?.sickLeaveDaysRule && (
          <Typography className="text_regular__14 color_red">{errors.sickLeaveDaysRule.message}</Typography>
        )}
      </div>

      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="accrualRule"
          rules={{ required: 'Choose one option' }}
          render={({ field }) => (
            <RadioButton
              {...field}
              label="Paid time off accrual rule"
              layout="vertical"
              options={[
                {
                  id: TimeOffAccrualRule.AllAtOnce,
                  text: 'All at once: employee will receive all their Annual Days balance immediately',
                  value: TimeOffAccrualRule.AllAtOnce,
                },
                {
                  id: TimeOffAccrualRule.Accrued,
                  text: 'Accrued: employee will accumulate their annual days off over time',
                  value: TimeOffAccrualRule.Accrued,
                },
              ]}
            />
          )}
        />
        {errors.accrualRule && (
          <Typography className="text_regular__14 color_red">{errors.accrualRule.message}</Typography>
        )}
      </div>

      <div className="w-100 remo-form-input">
        <Controller
          control={control}
          name="allowCarryover"
          rules={{ required: 'Choose one option' }}
          render={({ field }) => (
            <RadioButton
              {...field}
              label="Allow carryover"
              layout="vertical"
              options={[
                {
                  id: 'true',
                  text: 'Yes',
                  value: 'true',
                },
                {
                  id: 'false',
                  text: 'No',
                  value: 'false',
                },
              ]}
            />
          )}
        />
        {errors.allowCarryover && (
          <Typography className="text_regular__14 color_red">{errors.allowCarryover.message}</Typography>
        )}
      </div>

      {watchAllowCarryover === 'true' && (
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="carryoverRule"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Carryover options"
                layout="vertical"
                options={[
                  {
                    id: 'unlimited',
                    text: 'Unlimited',
                    value: TimeOffCarryoverRule.Unlimited,
                  },
                  {
                    id: 'limited',
                    text: 'Limited',
                    value: TimeOffCarryoverRule.Limited,
                  },
                ]}
              />
            )}
          />
          {errors.carryoverRule && (
            <Typography className="text_regular__14 color_red">{errors.carryoverRule.message}</Typography>
          )}
          {watchCarryoverRule === TimeOffCarryoverRule.Limited && (
            <>
              <Styled.CarryoverMaximum>
                <Styled.Label>Please specify maximum carryover per year:</Styled.Label>
                <Styled.SmallInput>
                  <Input {...register('maxCarryoverDays', { required: 'Set carryover days' })} type="number" />
                </Styled.SmallInput>
              </Styled.CarryoverMaximum>
              {errors.maxCarryoverDays && (
                <Typography className="text_regular__14 color_red">{errors.maxCarryoverDays.message}</Typography>
              )}
            </>
          )}
        </div>
      )}

      {watchAllowCarryover === 'true' && (
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="carryoverExpiry"
            rules={{ required: 'Choose one option' }}
            render={({ field }) => (
              <RadioButton
                {...field}
                label="Carryover expiry"
                layout="vertical"
                options={[
                  {
                    id: 'none',
                    text: 'None: carried over days will not expire, and can be used at any point in the future',
                    value: TimeOffCarryoverExpiry.None,
                  },
                  {
                    id: 'custom',
                    text: 'Set expiry',
                    value: TimeOffCarryoverExpiry.Custom,
                  },
                ]}
              />
            )}
          />
          {errors.carryoverExpiry && (
            <Typography className="text_regular__14 color_red">{errors.carryoverExpiry.message}</Typography>
          )}
          {watchCarryoverExpiry === TimeOffCarryoverExpiry.Custom && (
            <>
              <Styled.CarryoverMaximum>
                <Styled.Label>Please specify maximum carryover per year:</Styled.Label>
                <Styled.SmallInput>
                  <Input {...register('carryoverExpiryAmount', { required: 'Set expiry amount' })} type="number" />
                </Styled.SmallInput>
                <Controller
                  control={control}
                  name="carryoverExpiryUnit"
                  rules={{ required: 'Select expiry unit' }}
                  render={({ field }) => {
                    return (
                      <Styled.SmallInput>
                        <Select
                          isRequired
                          {...field}
                          options={[
                            {
                              value: TimeOffCarryoverExpiryUnit.Days,
                              label: 'days',
                            },
                            {
                              value: TimeOffCarryoverExpiryUnit.Weeks,
                              label: 'weeks',
                            },
                            {
                              value: TimeOffCarryoverExpiryUnit.Months,
                              label: 'months',
                            },
                            {
                              value: TimeOffCarryoverExpiryUnit.Years,
                              label: 'years',
                            },
                          ]}
                          onChange={({ value }) => field.onChange(value)}
                        />
                      </Styled.SmallInput>
                    )
                  }}
                />
              </Styled.CarryoverMaximum>
              {errors?.carryoverExpiryUnit && (
                <Typography className="text_regular__14 color_red">{errors.carryoverExpiryUnit.message}</Typography>
              )}
              {errors.carryoverExpiryAmount && (
                <Typography className="text_regular__14 color_red">{errors.carryoverExpiryAmount.message}</Typography>
              )}
            </>
          )}
        </div>
      )}
    </form>
  )
}
