import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Typography from '@atoms/Typography/Typography'
import { createContractors, retrieveImportedContractors } from '@services/mass-import-contractors.service'
import { DotWave } from '@uiball/loaders'
import React from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

export const ContractorsMassImportConfirm = ({ massImportId, isLoading, onNext }) => {
  const isDisabled = useBoolean(true)

  const retrieveQuery = useQuery([retrieveImportedContractors.key, massImportId], {
    enabled: !!massImportId,
    keepPreviousData: true,
    queryFn: () => retrieveImportedContractors.fetch(massImportId),
  })

  const createContractorsMutation = useMutation({
    mutationFn: createContractors,
    onSuccess: onNext,
  })

  const handleConfirm = () => {
    createContractorsMutation.mutate(massImportId, retrieveQuery.data)
  }

  const handleChangeCheckbox = ({ target }) => {
    isDisabled.setValue(!target.checked)
  }

  if (retrieveQuery.isLoading || isLoading) {
    return (
      <div className="h-100 d-flex justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  if (createContractorsMutation.isLoading) {
    return (
      <div className="h-100 flex-column gap-5 d-flex justify-content-center align-items-center">
        <DotWave />
        <Typography className="heading_semibold__20">Working hard to get your contractors imported...</Typography>
      </div>
    )
  }

  return (
    <div className="h-100 d-flex flex-column">
      <section style={{ marginTop: 112 }} className="d-flex flex-column align-items-start gap-5">
        <Typography className="heading_semibold__20">Confirm data import</Typography>

        <Checkbox
          data-testid="contractors-mass-import-confirm.component-CDCE1A"
          label="I confirm that I have reviewed and approve the imported data."
          description="Remofirst takes no responsibility for any incorrect data that may have been added during the upload proces."
          onChange={handleChangeCheckbox}
        />
      </section>

      <div style={{ marginTop: 112 }} className="align-self-center pb-5">
        <Button
          data-testid="contractors-mass-import-confirm.component-1C1888"
          size="small"
          disabled={isDisabled.value}
          onClick={handleConfirm}
        >
          Confirm import
        </Button>
      </div>
    </div>
  )
}
