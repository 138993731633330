/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import Typography from '@atoms/Typography/Typography'
import { getDate } from '@core/utils'
import NoContent from '@pages/chat/NoContent/NoContent'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import React from 'react'

import styles from './chat.module.scss'

export default function ThreadList({ loading, data, onClickThread, currentThread, type }) {
  if (loading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={32} speed={1} color="black" />
      </div>
    )
  }

  if (data.length === 0) {
    return <NoContent type={type} />
  }

  return (
    <div id="chat_threads">
      {data.map((thread) => {
        return (
          <div
            key={thread.id}
            onClick={() => onClickThread(thread)}
            className={classNames({
              [styles.thread]: true,
              [styles.thread_selected]: currentThread && thread.id === currentThread.id,
            })}
          >
            <div className="d-flex justify-content-between">
              <Typography className="text_light__12 mb-1">{getDate(thread?.created_at)}</Typography>
              {thread.unread_messages_count > 0 && (
                <Typography className={classNames([styles.chat_thread_count, 'text_light__12'])}>
                  {thread.unread_messages_count}
                </Typography>
              )}
            </div>
            <Typography className="text_medium__14 mb-1">{thread.topic}</Typography>
            <Typography className="text_regular__14">{thread?.last_message?.short_content}</Typography>
          </div>
        )
      })}
    </div>
  )
}
