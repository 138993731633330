import CustomTable from '@atoms/Table/CustomTable'
import ContentPlaceholder from '@components/content-placeholder/content-placeholder.component'
import { getAgreement } from '@services/sign.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useQuery } from 'react-query'

import { AgreementActions } from '../agreement-actions/agreement-actions.component'
import { transformAgreements } from '../agreements.utils'

const DEFAULT_PAGE_LIMIT = 10

const fields = (refetch) => [
  {
    title: 'Name',
    key: 'name',
    type: 'textMedium',
    minWidth: 320,
  },
  {
    title: 'Type',
    key: 'agreement_document_type',
    type: 'textMedium',
  },
  {
    title: 'Sent',
    key: 'created',
    type: 'textMedium',
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'custom',
    minWidth: 320,
    render: (item) => <AgreementActions item={item} refetch={refetch} />,
  },
]

export const AgreementsTable = ({ isFetching, agreementsResponse, page, setPage, total, modalOpen }) => {
  const [agreementId, setAgreementId] = useState()
  const [agreementDetail, setAgreementDetail] = useState()

  const { isLoading, refetch } = useQuery(['getAgreement', agreementId], {
    queryFn: () => getAgreement(agreementId),
    onSuccess: (data) => {
      setAgreementDetail(data)
    },
    enabled: !!agreementId,
  })

  const handleCollapse = (row) => {
    if (row.id === agreementId) return
    setAgreementId(row.id)
  }

  const handleTemplateAdd = () => {
    modalOpen.setTrue()
  }

  return (
    <div>
      {isFetching ? (
        <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 200 }}>
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {agreementsResponse?.results?.length > 0 ? (
            <CustomTable
              type="collapse"
              fields={fields(refetch)}
              data={transformAgreements(agreementsResponse, agreementDetail, isLoading)}
              onCollapseClick={handleCollapse}
              page={page}
              total={total}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: 200 }}>
              <ContentPlaceholder
                imgSrc="/assets/img/quiet.png"
                title={`It's quiet in here ...`}
                onClick={handleTemplateAdd}
              />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
