import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Dropdown } from '@components/dropdown/dropdown.component'
import { useApp } from '@core/context'
import { useDirectManagers } from '@core/hooks/useDirectManagers'
import { getJobDetailsList } from '@services/contract.service'
import { useEffect, useRef } from 'react'
import { Controller, useForm } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const HintText = styled.span`
  font-size: 12px;
  color: #8a96a1;
  margin-top: 4px;
`

export default ({ initValue, onNext }) => {
  const dropdownRef = useRef(null)
  const { profile } = useApp()
  const {
    watch,
    register,
    reset,
    setValue,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({
    defaultValues: {
      position: '',
      department: '',
      position_description: '',
      line_manager: '',
    },
  })
  const jobTitle = watch('position')
  const dropdownOpenState = useBoolean(false)

  const jobDetailsListQuery = useQuery({
    queryKey: [getJobDetailsList.key],
    queryFn: getJobDetailsList.fetch,
  })

  const { directManagers, isLoading } = useDirectManagers(profile.id)

  const handleClickOutside = (event) => {
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      dropdownOpenState.setFalse()
    }
  }

  useEffect(() => {
    document.addEventListener('click', handleClickOutside)

    return () => {
      document.removeEventListener('click', handleClickOutside)
    }
  }, [])

  useEffect(() => {
    if (initValue)
      reset({
        position: initValue.job?.position,
        position_description: initValue.job?.position_description,
        department: initValue?.department,
      })
  }, [initValue])

  const submit = ({ position, position_description, department, line_manager }) => {
    onNext({
      job: {
        position: position?.label || position,
        position_description,
      },
      department,
      direct_manager: line_manager?.value || '',
    })
  }

  return (
    <form onSubmit={handleSubmit(submit)} className="d-flex flex-column h-100 employees-page__form">
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input" ref={dropdownRef}>
          <Dropdown
            open={dropdownOpenState.value}
            trigger={
              <Input
                data-testid="JobDetailsForm-1EA106"
                {...register('position', {
                  required: 'Job title is required',
                  maxLength: {
                    value: 128,
                    message: 'Job title cannot exceed 128 characters.',
                  },
                  validate: (value) => value.trim() !== '' || 'Job title cannot be empty or just spaces',
                })}
                label="Job title"
                type="text"
                isRequired
                onFocus={dropdownOpenState.setTrue}
                placeholder="Enter job title"
              />
            }
            menu={
              (jobDetailsListQuery?.data?.results.length &&
                (jobTitle
                  ? // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results?.filter((f) =>
                      f.name?.toLowerCase().includes(jobTitle.toLowerCase())
                    )
                  : // eslint-disable-next-line no-unsafe-optional-chaining
                    jobDetailsListQuery.data.results || []
                ).map(({ id, name, scope_of_work }) => (
                  <button
                    data-testid="JobDetailsForm-D82E90"
                    key={id}
                    type="button"
                    onClick={() => {
                      dropdownOpenState.setFalse()
                      setValue('position', name)
                      setValue('position_description', scope_of_work)
                    }}
                  >
                    {name}
                  </button>
                ))) ||
              []
            }
          />
          {errors.position && <Typography className="text_regular__14 color_red">{errors.position.message}</Typography>}
        </div>
        <div className="w-100 remo-form-input">
          <TextArea
            {...register('position_description')}
            type="text"
            rows={10}
            label="Description of work"
            maxLength={2000}
            placeholder="This will be pre-filled with a Remofirst template of the core duties and responsibilities of the role based on the job title provided. "
          />
        </div>
        <div className="w-100 remo-form-input">
          <Input
            data-testid="JobDetailsForm-F2986B"
            {...register('department')}
            type="text"
            label="Department"
            placeholder="Enter department"
          />
        </div>
        <div className="w-100 remo-form-input">
          <Controller
            control={control}
            name="line_manager"
            render={({ field }) => {
              return (
                <>
                  <Select
                    {...field}
                    label="Line manager"
                    placeholder="Select"
                    options={
                      directManagers?.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) || []
                    }
                    loading={isLoading}
                    isClearable
                  />
                  <HintText>
                    The line manager will receive all notifications related to this Contractor. This can be added later
                    from the contractor’s profile.
                  </HintText>
                </>
              )
            }}
          />
          {errors.line_manager && (
            <Typography className="text_regular__14 color_red">{errors.line_manager.message}</Typography>
          )}
        </div>
      </section>
      <div className="pb-5 align-self-center">
        <Button data-testid="JobDetailsForm-29DE62" type="submit" className="align-self-end">
          Save and continue
        </Button>
      </div>
    </form>
  )
}
