import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { AttachmentDropzone } from '@components/attachment/attachment-dropzone/attachment-dropzone'
import * as icons from '@core/icons/icons'
import { fetchPostFile } from '@services/app.service'
import { uploadCsv } from '@services/mass-import-contractors.service'
import { useState } from 'react'
import { useMutation } from 'react-query'

import { Styled } from '../contractors-mass-import-modal.styles'

export const ContractorsMassImportUploadCsv = ({ massImportId, isLoading, onNext }) => {
  const [selectedFile, setSelectedFile] = useState(null)

  const uploadCsvByTempIdMutation = useMutation({
    mutationFn: (tempFileId) => uploadCsv(massImportId, tempFileId),
  })

  const temporaryFilesMutation = useMutation({
    mutationFn: fetchPostFile,
  })

  const handleDrop = ([file]) => {
    temporaryFilesMutation
      .mutateAsync(file)
      .then(({ file_uuid }) => uploadCsvByTempIdMutation.mutateAsync(file_uuid))
      .then(() => setSelectedFile(file))
  }

  const handleRemoveFile = () => setSelectedFile(null)

  return (
    <div className="h-100 d-flex flex-column">
      <section className="d-flex flex-column align-items-start justify-content-center gap-3 flex-grow-1">
        <Typography>Prepare your mass import csv file</Typography>
        <Styled.Card className="d-flex flex-column align-items-start gap-2">
          Download a sample file to understand what the expected import csv file looks like.
          <Styled.Link href="https://remofirst-static.s3.amazonaws.com/remofirst_sample_file.xlsx">
            <Button
              data-testid="contractors-mass-import-upload-csv.component-38C52C"
              size="small"
              className="d-flex justify-content-between gap-2"
            >
              <Icon icon={icons.whiteDownLoadIcon} />
              Download sample file
            </Button>
          </Styled.Link>
        </Styled.Card>

        <Styled.Card className="d-flex flex-column align-items-start gap-2">
          Download an empty mass import csv template and use it to prepare your csv file for upload.
          <Styled.Link href="https://remofirst-static.s3.amazonaws.com/empty_template.csv">
            <Button
              data-testid="contractors-mass-import-upload-csv.component-694834"
              size="small"
              className="d-flex justify-content-between gap-2"
            >
              <Icon icon={icons.whiteDownLoadIcon} />
              Download empty template
            </Button>
          </Styled.Link>
        </Styled.Card>

        <div style={{ width: 464, paddingTop: 32 }}>
          <Typography>Upload your mass import csv file</Typography>
          <AttachmentDropzone
            multiple={false}
            accept={{ 'text/csv': ['.csv'] }}
            file={selectedFile}
            isLoading={temporaryFilesMutation.isLoading || uploadCsvByTempIdMutation.isLoading}
            onDrop={handleDrop}
            onDelete={handleRemoveFile}
          >
            Supported file formats: .csv; file size up to 50 MB
          </AttachmentDropzone>
        </div>
      </section>

      <div className="align-self-center pb-5">
        <Button
          data-testid="contractors-mass-import-upload-csv.component-C61ED7"
          size="small"
          disabled={!selectedFile || isLoading}
          onClick={onNext}
        >
          Next
        </Button>
      </div>
    </div>
  )
}
