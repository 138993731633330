import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;

  .modal-header {
    margin-bottom: 0;
    padding: 1.5rem;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    position: relative;
    padding: 0 1.5rem 1.5rem;
  }
  .modal-footer {
    padding: 1.5rem;
  }
`
const Container = styled.div`
  display: flex;
  flex-direction: column;
  gap: 24px;
`

export const PaymentModal = ({ onClose, checked, onChange }) => {
  return (
    <StyledModal show centered>
      <Modal.Header>
        <Typography className="heading_semibold__24">Invoice & Payment</Typography>
      </Modal.Header>
      <Modal.Body>
        <Container>
          <p>
            Please treat the payroll timelines with the same importance as you would treat your own internal payroll due
            dates.
          </p>
          <Checkbox
            data-testid="payment-modal.component-69E976"
            checked={checked}
            label="I have read and understood the above notice."
            onChange={onChange}
          />
        </Container>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="payment-modal.component-5AFD76"
          priority="primary"
          size="small"
          disabled={!checked}
          onClick={onClose}
          style={{ width: '100%' }}
        >
          Continue
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
