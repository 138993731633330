import { getAgreements } from '@services/sign.service'
import { DotWave } from '@uiball/loaders'
import { useQuery } from 'react-query'

export default function Agreement({ employee }) {
  const { isFetching, data: agreementsResponse } = useQuery(['getAgreements'], {
    queryFn: () =>
      getAgreements({
        contract_id: employee.id,
        limit: 5000,
        offset: 0,
      }),
  })
  return (
    <div>
      {!isFetching && agreementsResponse.results.length > 0 ? (
        <embed
          src={`${agreementsResponse.results[0].signed_agreement_document}#toolbar=0`}
          type="application/pdf"
          height="1000px"
          width="750px"
        />
      ) : (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      )}
    </div>
  )
}
