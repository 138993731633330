import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { deleteIcon, edit } from '@core/icons/icons'

export const AdditionalPaymentsTableActions = ({ onEdit, onDelete }) => {
  return (
    <div className="d-flex align-items-center">
      <Button
        type="button"
        className="text_light__12 px-2 mr-1"
        data-testid="allowance-table.component-0D9DD0"
        priority="secondary"
        size="small"
        style={{ height: '32px' }}
        onClick={onEdit}
      >
        <Icon fill="none" icon={edit} />
      </Button>
      <Button
        type="button"
        data-testid="allowance-table.component-60876A"
        className="text_light__12 px-2"
        priority="danger_outlined"
        size="small"
        style={{ height: '32px' }}
        onClick={onDelete}
      >
        <Icon fill="none" icon={deleteIcon} />
      </Button>
    </div>
  )
}
