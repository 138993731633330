/* eslint-disable jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions */
import { useAxiosCall } from '@api/Api'
import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import * as icons from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import moment from 'moment'
import React, { useCallback, useEffect } from 'react'
import { toast } from 'react-toastify'

import ThreadMemberList from '../ThreadMemberList/ThreadMemberList'

export default function ThreadEditModal({ closeModal, thread, onClickResolve, isOwner = false }) {
  const activeChat = thread?.state === 'ACTIVE'

  const [topic, setTopic] = React.useState()
  const [{ data: documentsData, loading: documentsLoading }, getThreadDocuments] = useAxiosCall(
    {
      url: `threads/${thread.id}/documents/`,
    },
    {
      manual: true,
    }
  )
  const [, patchThreadUpdate] = useAxiosCall(
    {
      method: 'patch',
      url: `threads/${thread?.id}/update/`,
    },
    {
      manual: true,
    }
  )

  const onClickDownload = useCallback(
    (document) => () => {
      window.open(document.file_url, '_blank')
    },
    []
  )

  const onChangeTopic = useCallback((e) => {
    setTopic(e.target.value)
  })

  const onClickSave = useCallback(() => {
    patchThreadUpdate({ data: { topic } }).then(() => toast.success('Topic changed'))
  }, [topic])

  useEffect(() => {
    if (thread) {
      getThreadDocuments()
      setTopic(thread.topic)
    }
  }, [thread])

  return (
    <EditModal
      footer={false}
      visible
      title={thread.topic}
      closeModal={closeModal}
      className="d-flex align-items-center flex-column"
    >
      <div className="w-100" style={{ minHeight: 350, maxHeight: 350 }}>
        <Tabs>
          <Tab tabId={0} title="About">
            <div key={1}>
              <div className="d-flex align-content-start">
                <Input
                  data-testid="ThreadEditModal-AE79FC"
                  outlined
                  value={topic}
                  label="Topic"
                  onChange={onChangeTopic}
                />
                <Button data-testid="ThreadEditModal-A950B2" priority="outlined" size="small" onClick={onClickSave}>
                  Save
                </Button>
              </div>
              <div>
                <Typography className="text_regular-normal__14 color_text_300">Created by</Typography>
                <Typography className="text_regular-normal__14 mt-2">
                  {thread.owner.full_name} on {moment(thread?.created_at).format('DD MMM YYYY')}
                </Typography>
              </div>
              {isOwner && (
                <Button
                  data-testid="ThreadEditModal-90DD7C"
                  className="mt-5"
                  priority="secondary"
                  size="medium"
                  onClick={onClickResolve}
                >
                  {`${activeChat ? 'Close' : 'Open'} chat`}
                </Button>
              )}
            </div>
          </Tab>
          <Tab tabId={1} title="Members">
            <ThreadMemberList threadId={thread.id} />
          </Tab>
          <Tab tabId={2} title="Documents">
            {documentsLoading ? (
              <div
                key={0}
                className="d-flex w-100 loader align-items-center justify-content-center"
                style={{ height: 80 }}
              >
                <DotWave size={32} speed={1} color="black" />
              </div>
            ) : (
              <div style={{ height: 300, overflow: 'auto' }}>
                {documentsData?.map((document) => (
                  <div
                    key={document.filename}
                    style={{ cursor: 'pointer' }}
                    className="d-flex align-items-center"
                    onClick={onClickDownload(document)}
                  >
                    <Icon icon={icons.description} />
                    <Typography className={classNames(['text_medium__14', 'ml-2'])}>{document.filename}</Typography>
                  </div>
                ))}
              </div>
            )}
          </Tab>
        </Tabs>
      </div>
    </EditModal>
  )
}
