const PAYMENT_FREQUENCIES = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  semi_monthly: 'Semi monthly',
  one_time_payment: 'One time payment',
  biweekly: 'Bi weekly',
}

export const formatFieldValue = (value) => {
  switch (value) {
    case 'semi_monthly':
    case 'monthly':
    case 'weekly':
    case 'one_time_payment':
    case 'biweekly':
      return PAYMENT_FREQUENCIES[value]
    default:
      return value
  }
}
