/* eslint-disable react/jsx-no-constructed-context-values */
import 'bootstrap/dist/css/bootstrap.min.css'
import 'react-phone-number-input/style.css'
import 'flag-icons/sass/flag-icons.scss'
import './index.scss'
import 'react-toastify/dist/ReactToastify.css'
import 'react-loading-skeleton/dist/skeleton.css'
import 'react-tooltip/dist/react-tooltip.css'

import React from 'react'
import { createRoot } from 'react-dom/client'
import { IconContext } from 'react-icons'
import { QueryClient, QueryClientProvider } from 'react-query'
import { ToastContainer } from 'react-toastify'

import App from './App'
import { AuthProvider } from './AuthProvider'
import reportWebVitals from './reportWebVitals'

const queryClient = new QueryClient({
  defaultOptions: {
    queries: {
      refetchOnWindowFocus: false,
      retry: false,
      cacheTime: 0,
    },
  },
})

const container = document.getElementById('root') as HTMLElement
const root = createRoot(container) // createRoot(container!) if you use TypeScript
root.render(
  <QueryClientProvider client={queryClient}>
    <AuthProvider
      loginUrl={`${process.env.REACT_APP_BFF_API}/auth/login`}
      logoutUrl={`${process.env.REACT_APP_BFF_API}/auth/logout`}
    >
      <IconContext.Provider value={{ size: '20' }}>
        <ToastContainer autoClose={5000} />
        <App />
      </IconContext.Provider>
    </AuthProvider>
  </QueryClientProvider>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()

console.log(new Date())
