import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { timeIcon } from '@core/icons/icons'

export const TIMESHEET_DETAILS = {
  employee_name: 'Gregory Jones',
  start_date: '2024-10-07',
  end_date: '2024-10-11',
  total_hours: 40,
  status: 'pending',
  // rejection_reason:
  //   'This timesheet has a number of wrong dates, it was understood contractor was not working during this timeframe.',
  days: [
    {
      date: '2024-10-07',
      total_hours: '8h 0m',
      entries: [
        {
          start_time: '08:00',
          finish_time: '10:00',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '10:00',
          finish_time: '10:30',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '10:30',
          finish_time: '12:30',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '12:30',
          finish_time: '13:00',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '13:00',
          finish_time: '16:00',
          task: 'Working hours',
          hours_worked: '3h 0m',
        },
      ],
    },
    {
      date: '2024-10-08',
      total_hours: '8h 0m',
      entries: [
        {
          start_time: '08:00',
          finish_time: '10:00',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '10:00',
          finish_time: '10:30',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '10:30',
          finish_time: '12:30',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '12:30',
          finish_time: '13:00',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '13:00',
          finish_time: '16:00',
          task: 'Working hours',
          hours_worked: '3h 0m',
        },
      ],
    },
    {
      date: '2024-10-09',
      total_hours: '8h 0m',
      entries: [
        {
          start_time: '08:00',
          finish_time: '10:00',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '10:00',
          finish_time: '10:30',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '10:30',
          finish_time: '12:30',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '12:30',
          finish_time: '13:00',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '13:00',
          finish_time: '16:00',
          task: 'Working hours',
          hours_worked: '3h 0m',
        },
      ],
    },
    {
      date: '2024-10-10',
      total_hours: '8h 0m',
      entries: [
        {
          start_time: '08:00',
          finish_time: '10:00',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '10:00',
          finish_time: '10:30',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '10:30',
          finish_time: '12:30',
          task: 'Working hours',
          hours_worked: '2h 0m',
        },
        {
          start_time: '12:30',
          finish_time: '13:00',
          task: 'Break',
          hours_worked: '0h 30m',
        },
        {
          start_time: '13:00',
          finish_time: '16:00',
          task: 'Working hours',
          hours_worked: '3h 0m',
        },
      ],
    },
  ],
}

export const TIMESHEET_TABLE_FIELDS = [
  {
    title: 'Start time',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return (
        <Input
          style={{ borderRadius: 8, height: 38, margin: 0 }}
          disabled
          value={item.start_time}
          endIcon={<Icon icon={timeIcon} />}
        />
      )
    },
  },
  {
    title: 'Finish time',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return (
        <Input
          style={{ borderRadius: 8, height: 38, margin: 0 }}
          disabled
          value={item.finish_time}
          endIcon={<Icon icon={timeIcon} />}
        />
      )
    },
  },
  {
    title: 'Task',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return <Select isDisabled value={item.task} options={[{ label: item.task, value: item.task }]} />
    },
  },
  {
    title: 'Hours worked',
    type: 'custom',
    maxWidth: 400,
    minWidth: 300,
    render: (item) => {
      return <Typography>{item.hours_worked}</Typography>
    },
  },
]
