import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import PageTitle from '@atoms/PageTitle/PageTitle'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { CONTRACT_TYPE } from '@core/constants'
import usePagination from '@core/hooks/usePagination'
import { addContractDocuments, deleteDocumentsById, getContractDocuments } from '@store/documents'
import moment from 'moment'
import React, { useCallback, useRef, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { getDocumentFields } from './mock'

export default function DocumentDetailEmployee({ id, employee }) {
  const fileInputRef = useRef(null)
  const confirmDelete = useBoolean(false)
  const { page, limit, setPage } = usePagination({ page: 1, limit: 10 })
  const [documentId, setDocumentId] = useState()

  const isContractor = employee.contract_type === CONTRACT_TYPE.CONTRACTOR

  const { data, isFetching, isLoading, refetch } = useQuery(['documents', page, id], {
    queryFn: () =>
      getContractDocuments({
        page,
        limit,
        contract_id: id,
        document_type_is_not_in: 'payslip,agreement',
      }),
  })

  const addDocument = useMutation({
    mutationFn: (payload) => addContractDocuments(payload),
    onSuccess: () => {
      refetch()
    },
  })

  const deleteDocument = useMutation({
    mutationFn: (payload) => deleteDocumentsById(payload.id, payload.contractId),
    onSuccess: () => {
      refetch()
    },
  })

  const onChangeFile = (event) => {
    const fd = new FormData()
    const file = event.target.files[0]
    fd.append('file', file)
    fd.append('contract', id)

    addDocument.mutate(fd)
  }

  const onClickAddDocument = useCallback(() => {
    fileInputRef.current?.click()
  }, [])

  const onClickDownload = useCallback((row) => {
    window.open(row.file, '_blank')
  }, [])

  const onClickDelete = useCallback(
    (row) => {
      setDocumentId(row.id)
      confirmDelete.setTrue()
    },
    [confirmDelete]
  )

  const onDeleteFile = useCallback(() => {
    deleteDocument.mutate({ id: documentId, contractId: id })
    confirmDelete.setFalse()
  }, [confirmDelete, documentId])

  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle> Document </PageTitle>
        <Button
          data-testid="DocumentDetailEmployee-3F2C1D"
          priority="secondary"
          size="small"
          onClick={onClickAddDocument}
        >
          Add document
        </Button>
        <input
          data-testid="DocumentDetailEmployee-00FF14"
          type="file"
          className="d-none"
          ref={fileInputRef}
          multiple
          onChange={onChangeFile}
        />
      </div>
      <CustomTable
        page={page}
        pageSize={10}
        total={data?.count || 0}
        loading={isFetching || isLoading || deleteDocument.isLoading || addDocument.isLoading}
        fields={getDocumentFields(onClickDownload, onClickDelete, isContractor)}
        data={
          data?.results.map((document) => ({
            id: document.id,
            file: document.file,
            name: document.name,
            uploaded: moment(document.created_at).format('DD MMM YYYY'),
          })) || []
        }
        onPage={setPage}
      />

      {confirmDelete.value && (
        <Confirm
          title="Are you sure you want to delete this document?"
          yesButtonProps={{
            text: 'Delete',
          }}
          cancelButtonProps={{
            text: 'Cancel',
          }}
          isLoading={deleteDocument.isLoading}
          onCancel={confirmDelete.setFalse}
          onYes={onDeleteFile}
        >
          <Typography>This file will be deleted from the platform</Typography>
        </Confirm>
      )}
    </div>
  )
}
