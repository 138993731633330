import styled from 'styled-components'

const Container = styled.div`
  height: 100%;
  padding: 2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
`

export { Container, Content }
