import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import ModalSide from '@atoms/ModalSide/ModalSide'
import Typography from '@atoms/Typography/Typography'
import { keyboardArrowLeft } from '@core/icons/icons'
import { InsuranceList } from '@features/benefits/insurance-list/insurance-list.component'
import { getList } from '@services/health-insurance.service'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import { useQuery } from 'react-query'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

const TextDescription = styled.div`
  color: #484848;
`

export const RemoHealthModal = ({ isEdit, age, isLoading, countryId, onClose, goBack, onSubmit }) => {
  const confirmOpen = useBoolean(false)
  const { handleSubmit } = useFormContext()

  const {
    data,
    isLoading: loading,
    isFetching,
  } = useQuery({
    queryKey: ['getHealthInsuranceList', countryId],
    queryFn: () =>
      getList({
        country_id: countryId,
        age,
      }),
    enabled: !!countryId,
  })

  return (
    <>
      <ModalSide
        title={`${isEdit ? 'Edit' : 'Add'} benefits`}
        okText={data?.results.length && (isEdit ? 'Update benefit' : 'Add benefits')}
        onClose={onClose}
        onOk={confirmOpen.setTrue}
      >
        <div className="w-100">
          <div className="d-flex align-items-center mb-5">
            <Icon
              icon={keyboardArrowLeft}
              style={{ transform: 'rotate(180deg)', cursor: 'pointer' }}
              onClick={goBack}
            />
            <Typography className="heading_semibold__20 ml-4">Benefits</Typography>
          </div>
          <Typography className="heading_semibold__20 mb-3">RemoHealth packages</Typography>
          {data?.results.length ? (
            <TextDescription className="text_regular-normal__14">
              Choose a package that suits your employee
            </TextDescription>
          ) : null}
          <InsuranceList
            loading={isLoading || loading || isFetching}
            insuranceTypes={data?.results || []}
            countryId={countryId}
          />
        </div>
      </ModalSide>
      {confirmOpen.value && (
        <Confirm
          title={!isEdit ? 'Add Remohealth?' : 'Update Remohealth'}
          yesButtonProps={{
            text: 'Approve',
            priority: 'primary',
          }}
          onYes={handleSubmit(onSubmit)}
          onCancel={confirmOpen.setFalse}
          isLoading={isLoading}
        >
          <Typography>Remofirst will invoice you for the cost of the Remohealth package for 3 months.</Typography>
        </Confirm>
      )}
    </>
  )
}
