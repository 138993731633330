import Button from '@atoms/Button/Button'
import { useApp } from '@core/context'
import { useRouteQuery } from '@core/hooks/useRouteQuery'
import { reportBookedACall } from '@services/company.service'
import React, { useEffect } from 'react'
import { MdChevronLeft } from 'react-icons/md'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'

const calendly_url = process.env.REACT_APP_CALENDLY_URL

const CalendlyPage = () => {
  const { userProfile, profile } = useApp()

  const reportMutation = useMutation({ mutationFn: reportBookedACall })

  useEffect(() => {
    const script = document.createElement('script')
    script.src = 'https://static.hsappstatic.net/MeetingsEmbed/ex/MeetingsEmbedCode.js'
    script.async = true
    document.body.appendChild(script)

    return () => {
      document.body.removeChild(script)
    }
  }, [])

  const navigate = useNavigate()
  const query = useRouteQuery()

  const handleBack = () => {
    const page = query.get('page')
    if (page) {
      navigate(`/pages/${query.get('page')}`)
    } else {
      navigate(`/`)
    }
  }

  return (
    <div className="h-100 d-flex flex-column justify-content-center">
      <div className="d-flex align-items-center pl-5 pt-5">
        <Button
          data-testid="Calendly-index-F498D3"
          priority="secondary"
          type="button"
          size="small"
          style={{ padding: '12px 12px', minWidth: 40 }}
          onClick={handleBack}
        >
          <MdChevronLeft />
        </Button>
        <p className="heading_semibold__20 ml-2">Back</p>
      </div>
      <div className="meetings-iframe-container" data-src={calendly_url}></div>
    </div>
  )
}

export default CalendlyPage
