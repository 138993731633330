import { Api } from '@api/Api'

export const fetchCreateContractor = async (body) => {
  const { data } = await Api.post(`contracts/`, body)
  return data
}

export const getContractByCompanyId = async (id) => Api.get(`contracts/?company_id=${id}`)

export const getContractById = async (id) => Api.get(`contracts/${id}/`)

export const patchContractById = async (id, data) => Api.patch(`contracts/${id}/`, data)

export const getContractEmergencyContact = async (id, user_id) => {
  const { data } = await Api.get(`user/emergency-contacts/?user_id=${user_id}`)

  return data
}

export const patchEmergencyContact = async (id, data) => Api.put(`user/emergency-contacts/${id}/update/`, data)
