/* eslint-disable */
import '../Table.scss'

import Icon from '@atoms/Icon/Icon'
import Pagination from '@atoms/Pagination/Pagination'
import PaymentCheckboxDropdown from '@atoms/Table/ContractorPaymentsTable/PaymentCheckboxDropdown'
import TableCheckbox from '@atoms/Table/libs/TableCheckbox/TableCheckbox'
import { EMPLOYEE_STATES } from '@core/constants'
import { arrowDown } from '@core/icons/icons'
import * as icons from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useRowSelect, useSortBy, useTable } from 'react-table'

export default function ContractorPaymentsTable({
  page,
  total,
  pageSize = 10,
  onPage,
  columns: columnsData,
  data,
  loading,
  selectedIds,
  setSelectedIds,
  activePayments,
  allPayments,
}) {
  const [isAllChecked, setIsAllChecked] = useState(false)
  const [selectedAction, setSelectedAction] = useState([])
  const paymentsOnPage = data.filter((i) => i.contract_onboarding_status !== EMPLOYEE_STATES.ONBOARDING)
  const allActivePayments = allPayments.filter(
    (payment) => payment.contract_onboarding_status !== EMPLOYEE_STATES.ONBOARDING
  )
  const handleRowSelect = (id, isChecked) => {
    setSelectedIds((ids) => {
      if (isChecked) {
        return [...ids, id]
      }
      return ids.filter((oldId) => oldId !== id)
    })
  }

  useEffect(() => {
    if (!selectedAction.length) {
      return
    }

    setSelectedIds((oldIds) => {
      switch (`${selectedAction[0]}-${selectedAction[1]}`) {
        case 'all-true': {
          setIsAllChecked(true)
          return allActivePayments.map((payment) => payment.id)
        }
        case 'all-false': {
          setIsAllChecked(false)

          return []
        }
        case 'onPage-true': {
          const pageIds = paymentsOnPage.map((payment) => payment.id)
          return [...oldIds, ...pageIds].filter((value, index, self) => {
            return self.indexOf(value) === index
          })
        }
        case 'onPage-false': {
          const pageIds = paymentsOnPage.map((payment) => payment.id)

          return oldIds.filter((oldId) => !pageIds.includes(oldId))
        }
        default:
          return oldIds
      }
    })
  }, [selectedAction])

  const paymentsOnPageCount = paymentsOnPage.length
  const allPaymentsCount = isAllChecked ? selectedIds.length : activePayments.length
  const selectedPaymentsOnPageCount = paymentsOnPage.reduce((acc, curr) => {
    if (selectedIds.includes(curr.id)) {
      return acc + 1
    }
    return acc
  }, 0)
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow } = useTable(
    {
      columns: columnsData,
      data,
    },
    useSortBy,
    useRowSelect,
    (hooks) => {
      hooks.visibleColumns.push((columns) => [
        {
          id: 'selection',
          width: 40,
          minWidth: 60,
          maxWidth: 60,
          Header: () => (
            <PaymentCheckboxDropdown
              isAllChecked={isAllChecked}
              setSelectedAction={setSelectedAction}
              paymentsOnPageCount={paymentsOnPageCount}
              selectedPaymentsOnPageCount={selectedPaymentsOnPageCount}
              allPaymentsCount={allPaymentsCount}
              loading={loading}
            />
          ),
          Cell: ({ row }) => {
            return row?.original?.isDisabled && row?.original?.tooltipText ? (
              <OverlayTrigger placement="bottom" overlay={<Tooltip>{row?.original?.tooltipText}</Tooltip>}>
                <span style={{ verticalAlign: 'top', marginLeft: 10 }}>
                  <Icon icon={icons.infoIcon} fill="orange" />
                </span>
              </OverlayTrigger>
            ) : (
              <TableCheckbox
                checked={selectedIds.includes(row.original.id) || false}
                onChange={(e) => handleRowSelect(row.original.id, e.target.checked)}
                style={{ width: 18, height: 18, marginLeft: 10 }}
              />
            )
          },
        },
        ...columns,
      ])
    }
  )

  return (
    <>
      {loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 300 }}>
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <table {...getTableProps()} cellSpacing="0" cellPadding="0">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    <div className="d-flex align-items-center table_header">
                      {column.render('Header')}
                      {!column.isSorted && <Icon fill="#878787" className="sort_icon" icon={arrowDown} />}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon fill="#878787" className="rotate" icon={arrowDown} />
                        ) : (
                          <Icon fill="#878787" icon={arrowDown} />
                        )
                      ) : (
                        <span style={{ width: 20, height: 20 }} />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr key={i} {...row.getRowProps()}>
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        maxWidth: cell.column.maxWidth,
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {page && Math.ceil(total / pageSize) > 1 && !loading && (
        <div className="mt-3">
          <Pagination page={page} pageSize={pageSize} total={total} onGetPage={onPage} />
        </div>
      )}
    </>
  )
}
