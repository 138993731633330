/* eslint-disable react/jsx-props-no-spreading */
import './style.scss'

import { forwardRef } from 'react'

export default forwardRef((props, ref) => {
  const { id, style, label, isRequired, rows = 5, ...rest } = props

  return (
    <div className="textArea">
      <label className="text_medium__14 remo-form-label" htmlFor={id} style={style}>
        {label} {isRequired && <span className="text-danger ml-1">*</span>}
      </label>
      <textarea ref={ref} rows={rows || 5} style={{ ...style }} {...rest} />
    </div>
  )
})
