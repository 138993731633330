import Button from '@atoms/Button/Button'
import ChooseMeetingTime from '@atoms/ChooseMeetingTimeModal'
import Icon from '@atoms/Icon/Icon'
import { KompassifyNotificationsWrapper } from '@components/kompassify-notification-wrapper'
import { dots, notificationsIcon, support, user } from '@core/icons/icons'
import classNames from 'classnames'
import { useEffect, useRef, useState } from 'react'
import { NavLink, useMatch, useNavigate } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { AuthModal } from './auth-modal/auth-modal.component'
import { KompassifyNotificationBadge } from './KompassifyNotificationBadge'
import styles from './sidebar.module.scss'
import { UserAccounts } from './user-accounts/user-accounts.component'

export const Sidebar = ({
  firstName,
  lastName,
  items,
  bookMeeting,
  showChat,
  profilePagePath,
  linkedAccounts,
  sessions,
  onLogout,
}) => {
  const isProfileRoute = useMatch({ path: profilePagePath })
  const menuRef = useRef()
  const menu = useBoolean(false)
  const bookMeetModalState = useBoolean(false)
  const navigate = useNavigate()
  const signInModalOpen = useBoolean(false)
  const [prefillEmail, setPrefillEmail] = useState(undefined)

  const handleAddAcount = () => {
    setPrefillEmail(undefined)
    signInModalOpen.setTrue()
  }

  const handleClickSignIn = (email) => {
    setPrefillEmail(email)
    signInModalOpen.setTrue()
  }

  useEffect(() => {
    const handler = (e) => {
      if (menuRef.current && menuRef.current.contains(e.target)) {
        return
      }
      menu.setFalse()
    }

    window.addEventListener('click', handler)

    return () => {
      window.removeEventListener('close', handler)
    }
  }, [menu])

  return (
    <>
      <div className={styles.root}>
        <div className={styles.links}>
          <div className={styles.logo}>
            <img src="/assets/icons/logo_2.0.svg" alt="logo" />
          </div>

          {items
            .filter(({ hidden }) => !hidden)
            .map(({ title, children }) => (
              <div key={v4()} className={styles.section}>
                {title && <div className={styles.section__title}>{title}</div>}

                {children
                  .filter(({ hidden }) => !hidden)
                  .map(({ url, icon, count, name }) => (
                    <NavLink
                      key={v4()}
                      to={`/pages/${url}`}
                      className={({ isActive }) =>
                        `${styles.section__item} ${isActive ? styles.section__item_active : ''}`
                      }
                    >
                      <div>
                        <Icon icon={icon} />
                      </div>

                      <div title={name}>{name}</div>
                      {!!count && (
                        <div className="flex-grow-1 d-flex justify-content-end">
                          <div className={styles['section__item-badge']}>{count}</div>
                        </div>
                      )}
                    </NavLink>
                  ))}
              </div>
            ))}

          {bookMeeting && (
            <div className={styles['book-section']}>
              <div className={styles['book-section__title']}>
                Book a meeting with the Remofirst team to start hiring.
              </div>
              <Button
                disabled={process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'stage'}
                data-testid="sidebar-292A46"
                size="small"
                priority="primary_black"
                className="w-100"
                onClick={bookMeetModalState.setTrue}
              >
                Book a meeting
              </Button>
            </div>
          )}
          <div className={classNames([styles.section, styles['footer-section']])}>
            <KompassifyNotificationsWrapper>
              <div className={styles.section__item}>
                <Icon icon={notificationsIcon} />
                <div>Notifications</div>
                <KompassifyNotificationBadge />
              </div>
            </KompassifyNotificationsWrapper>
            <a
              data-testid="sidebar-9FCA57"
              href="https://knowledgebase.remofirst.com/guides-for-clients"
              target="_blank"
              className={styles.section__item}
              rel="noreferrer"
            >
              <div>
                <Icon icon={support} />
              </div>
              <div>Go to Help Center</div>
            </a>
          </div>
        </div>

        <div className={styles['profile-section']}>
          <div className="d-flex align-items-center" ref={menuRef} role="button" aria-hidden onClick={menu.toggle}>
            <div className={classNames(styles['profile-section__item'])}>
              <div
                className={classNames({
                  [styles['profile-section__item_active']]: isProfileRoute,
                })}
              >
                <Icon icon={user} />
              </div>
              <div
                className={classNames([styles['profile-section__item-label']], {
                  [styles['profile-section__item_active']]: isProfileRoute,
                })}
              >{`${firstName} ${lastName}`}</div>
            </div>

            <div style={{ paddingRight: 12 }}>
              <Icon icon={dots} />
            </div>
          </div>
          <div
            className={classNames([styles['sidebar-menu']], {
              'd-none': !menu.value,
            })}
          >
            <UserAccounts onClickSignIn={handleClickSignIn} linkedAccounts={linkedAccounts} sessions={sessions} />
            <div
              className={classNames([styles['sidebar-menu__item']])}
              role="button"
              aria-hidden
              onClick={handleAddAcount}
            >
              Add account
            </div>
            <div className={classNames([styles['sidebar-menu__item']])} role="button" aria-hidden onClick={onLogout}>
              Sign out of all accounts
            </div>
          </div>
        </div>

        {bookMeetModalState.value && (
          <ChooseMeetingTime
            onClose={bookMeetModalState.setFalse}
            onMeet={() => navigate('/calendly?page=dashboard')}
          />
        )}
      </div>

      <AuthModal prefillEmail={prefillEmail} isOpen={signInModalOpen.value} onClose={signInModalOpen.setFalse} />
    </>
  )
}
