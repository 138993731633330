import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { CONTRACT_TYPE, EMPLOYEE_STATES } from '@core/constants'
import { keyboardArrowLeft } from '@core/icons/icons'
import { deactivateContract, deleteContract, reactivateContract } from '@services/contract.service'
import { useMutation } from 'react-query'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

export const ProfileHeader = ({ employee, refetch }) => {
  const navigate = useNavigate()
  const contractType = employee?.contract_type
  const employeeState = employee?.state
  const isDeactivateContractorModalOpen = useBoolean(false)
  const isActivateContractorModalOpen = useBoolean(false)
  const isDeleteContractorModalOpen = useBoolean(false)

  const deactivateEmployee = useMutation({
    mutationFn: (id) => deactivateContract(id),
    onSuccess: () => {
      isDeactivateContractorModalOpen.setFalse()
      refetch()
    },
  })

  const deleteContractor = useMutation({
    mutationFn: (id) => deleteContract(id),
    onSuccess: () => {
      isDeleteContractorModalOpen.setFalse()
      toast.success('Contractor deleted succesfully')
      navigate('/pages/team')
    },
  })

  const activateContract = useMutation({
    mutationFn: ({ id, payload }) => reactivateContract(id, payload),
    onSuccess: () => {
      isActivateContractorModalOpen.setFalse()
      refetch()
    },
  })

  const onClickActivateContractor = async () => {
    activateContract.mutateAsync({
      id: employee?.id,
      payload: {
        state: 'active',
      },
    })
  }

  const onClickDeactivateContractor = () => {
    deactivateEmployee.mutate(employee?.id)
  }

  const onClickDeleteContractor = () => {
    deleteContractor.mutate(employee?.id)
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between mb-4">
        <div className="d-flex align-items-center">
          <Link to="/pages/team">
            <div
              className="d-flex align-items-center justify-content-center"
              style={{
                border: '1px solid #CCCCCC',
                boxSizing: 'border-box',
                borderRadius: '8px',
                height: 40,
                width: 40,
              }}
            >
              <Icon icon={keyboardArrowLeft} style={{ cursor: 'pointer', transform: 'rotate(180deg)' }} />
            </div>
          </Link>
          <Typography className="heading_semibold__24 ml-3">
            {employee?.profile.first_name} {employee?.profile.last_name}
          </Typography>
        </div>
        <div className="d-flex gap-2">
          {contractType === CONTRACT_TYPE.CONTRACTOR &&
            (employeeState === EMPLOYEE_STATES.ACTIVE || employeeState === EMPLOYEE_STATES.ONBOARDING) && (
              <Button
                onClick={isDeactivateContractorModalOpen.setTrue}
                style={{ maxWidth: 'fit-content' }}
                priority="primary_dangerous"
                size="small"
                disabled={deactivateEmployee.isLoading}
              >
                Deactivate contractor
              </Button>
            )}
          {contractType === CONTRACT_TYPE.CONTRACTOR && employeeState === EMPLOYEE_STATES.INACTIVE && (
            <Button
              onClick={isActivateContractorModalOpen.setTrue}
              style={{ maxWidth: 'fit-content' }}
              priority="primary_black"
              size="small"
            >
              Activate contractor
            </Button>
          )}
          {contractType === CONTRACT_TYPE.CONTRACTOR &&
            (employeeState === EMPLOYEE_STATES.INVITED || employeeState === EMPLOYEE_STATES.ONBOARDING) && (
              <Button
                onClick={isDeleteContractorModalOpen.setTrue}
                style={{ maxWidth: 'fit-content' }}
                priority="primary_dangerous"
                size="small"
                loading={deleteContractor.isLoading}
                disabled={deleteContractor.isLoading}
              >
                Delete contractor
              </Button>
            )}
        </div>
      </div>
      <EditModal
        visible={isDeactivateContractorModalOpen.value}
        title="Deactivate contractor"
        footer={false}
        closeModal={isDeactivateContractorModalOpen.setFalse}
      >
        <Typography className="heading_semi__16">This contractor will be deactivated immediately.</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button priority="secondary" size="small" className="mr-3" onClick={isDeactivateContractorModalOpen.setFalse}>
            Cancel
          </Button>
          <Button
            priority="primary_dangerous"
            size="small"
            onClick={onClickDeactivateContractor}
            loading={deactivateEmployee.isLoading}
            disabled={deactivateEmployee.isLoading}
          >
            Confirm deactivation
          </Button>
        </div>
      </EditModal>
      <EditModal
        visible={isActivateContractorModalOpen.value}
        title="Activate contract"
        footer={false}
        closeModal={isActivateContractorModalOpen.setFalse}
      >
        <Typography className="heading_semi__16">Are you sure you want to activate this contractor?</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="Personal-87EE54"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={isActivateContractorModalOpen.setFalse}
          >
            Cancel
          </Button>
          <Button
            data-testid="Personal-E18R4B"
            loading={activateContract.isLoading}
            disabled={activateContract.isLoading}
            priority="primary"
            size="small"
            onClick={onClickActivateContractor}
          >
            Activate
          </Button>
        </div>
      </EditModal>
      <EditModal
        visible={isDeleteContractorModalOpen.value}
        title="Delete contractor"
        footer={false}
        closeModal={isDeleteContractorModalOpen.setFalse}
      >
        <Typography className="heading_semi__16">Are you sure? This contractor will be deleted immediately.</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={isDeleteContractorModalOpen.setFalse}
            disabled={deleteContractor.isLoading}
          >
            Cancel
          </Button>
          <Button
            priority="primary_dangerous"
            size="small"
            onClick={onClickDeleteContractor}
            loading={deleteContractor.isLoading}
            disabled={deleteContractor.isLoading}
          >
            Delete
          </Button>
        </div>
      </EditModal>
    </>
  )
}
