import '../ProfilePage.scss'

import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'
import { USER_ROLES_MAP } from '@core/constants'
import { useApp } from '@core/context'
import { parseCustomerEmployees } from '@pages/profilePage/parse'
import React, { useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { getResetPassword } from '../../../services/app.service'
import {
  fetchAddCompanyManager,
  fetchDeleteCompanyManager,
  fetchManagers,
  fetchUpdateCompanyManager,
  updateCompanyOwnerProfile,
} from '../../../services/manager.service'
import { fields } from '../mock'
import { AssignGroupsModal } from './assign-groups-modal'
import { EditManagerModal } from './edit-manager-form'
import { AddManagerModal } from './new-manager-modal'

export const ManagersTab = () => {
  const { profile, refetchProfile } = useApp()
  const [selectedManager, setSelectedManager] = useState(null)
  const [resentUserIds, setResentUserIds] = useState([])
  const [selectedManagerDeactivate, setSelectedManagerDeactivate] = useState(null)
  const [groupsModalOpen, setGroupsModalOpen] = useState(false)
  const [editModalOpen, setEditModalOpen] = useState(false)

  const newManagerOpen = useBoolean(false)
  const { value: visibleDeactivate, toggle: toggleDeactivate } = useBoolean(false)

  const managersQuery = useQuery('managers', {
    queryFn: () => fetchManagers(profile.id),
  })

  const updateManagerMutation = useMutation({
    mutationFn: ({ managerId, body }) => fetchUpdateCompanyManager(managerId, body),
    onSuccess: () => {
      setSelectedManager(null)
      managersQuery.refetch()
    },
  })

  const updateCompanyOwnerMutation = useMutation({
    mutationFn: ({ id, body }) => updateCompanyOwnerProfile(id, body),
    onSuccess: () => {
      setSelectedManager(null)
      refetchProfile()
    },
  })

  const addMutate = useMutation({
    mutationFn: (body) => fetchAddCompanyManager(body),
    onSuccess: () => {
      toast.success('Manager successfully created')
      managersQuery.refetch()
      newManagerOpen.setFalse()
    },
  })

  const deleteMutate = useMutation({
    mutationFn: (managerId) => fetchDeleteCompanyManager(managerId),
    onSuccess: () => {
      managersQuery.refetch()
      toggleDeactivate()
      toast.success('Manager successfully deactivated')
    },
  })

  const resetPassword = useMutation({
    mutationFn: (payload) => getResetPassword(payload.email),
    onSuccess: () => {
      toast.success('Check email for a link to set your password.')
    },
  })

  const handleEdit = (row) => {
    setSelectedManager({
      ...row,
      id: row.id,
      company: profile.id,
      first_name: row.first_name || '',
      last_name: row.last_name || '',
      email: row.email || '',
    })
    setEditModalOpen(true)
  }

  const handleSave = (payload) => {
    const isManager = selectedManager.role === USER_ROLES_MAP.company_admin

    if (!isManager) {
      updateCompanyOwnerMutation.mutate({
        id: selectedManager.owner_id,
        body: {
          first_name: payload.manager.profile.first_name,
          last_name: payload.manager.profile.last_name,
          is_billing_contact: payload.manager.is_billing_contact,
        },
      })
    } else {
      updateManagerMutation.mutate({
        managerId: selectedManager.id,
        body: {
          ...payload,
          company: selectedManager.company,
        },
      })
    }
  }

  const handleAddNewManager = (payload) => {
    addMutate.mutate({
      company: profile.id,
      ...payload,
    })
  }

  const handleDelete = (row) => {
    toggleDeactivate()
    setSelectedManagerDeactivate(row.id)
  }
  const handleReset = (row) => {
    setResentUserIds([...resentUserIds, row.id])
    resetPassword.mutate({ email: row.email })
  }

  return (
    <>
      <div className="d-flex justify-content-between">
        <strong>Managers</strong>
        <Button data-testid="managers-tab-F6D2B5" priority="secondary " size="small" onClick={newManagerOpen.setTrue}>
          Add manager
        </Button>
      </div>

      <div className="team-tab-table">
        <CustomTable
          loading={managersQuery.isLoading}
          fields={fields({ handleDelete, handleEdit, handleReset })}
          data={parseCustomerEmployees(managersQuery.data || [], profile, resentUserIds)}
        />
      </div>

      {editModalOpen && (
        <EditManagerModal
          selectedManager={selectedManager}
          loading={updateCompanyOwnerMutation.isLoading || updateManagerMutation.isLoading}
          onHide={() => {
            setEditModalOpen(false)
            setSelectedManager(null)
          }}
          onSubmit={handleSave}
          onOpenGroupsModal={() => {
            setGroupsModalOpen(true)
            setEditModalOpen(false)
          }}
        />
      )}

      {groupsModalOpen && (
        <AssignGroupsModal
          selectedManager={selectedManager}
          onHide={() => {
            setGroupsModalOpen(false)
          }}
          refetchManagers={managersQuery.refetch}
        />
      )}

      {newManagerOpen.value && (
        <AddManagerModal
          loading={addMutate.isLoading}
          onHide={newManagerOpen.setFalse}
          onSubmit={handleAddNewManager}
        />
      )}

      <EditModal
        visible={visibleDeactivate}
        title="Deactivate manager"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDeactivate}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to deactivate this manager from your company?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="managers-tab-488673"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDeactivate}
          >
            Cancel
          </Button>
          <Button
            data-testid="managers-tab-1F9490"
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedManagerDeactivate || '')}
          >
            Deactivate
          </Button>
        </div>
      </EditModal>
    </>
  )
}
