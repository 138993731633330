import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Info } from '@components/info/info.component'
import { useApp } from '@core/context'
import { patchCompany } from '@store/companies'
import React, { useMemo } from 'react'
import { useMutation, useQuery } from 'react-query'
import { fetchUserProfile } from 'src/services/app.service'
import { useBoolean } from 'usehooks-ts'

import { CompanyInfoEditForm } from '../company-info-edit-form/company-info-edit-form.component'
import { LegalAddressEditForm } from '../legal-address-edit-form/legal-address-edit-form.component'
import { PersonalInfoEditForm } from '../personal-info-edit-form/personal-info-edit-form.component'
import { Container, Content, StyledConfirm } from './review-step.styles'

const personalInfoTitle = 'Personal information'
const companyInfoTitle = 'About company'
const legalAddressTitle = 'Legal address'

export const ReviewStep = ({ onNext }) => {
  const { profile, refetchProfile } = useApp()
  const personalInfoEditOpen = useBoolean(false)
  const companyInfoEditOpen = useBoolean(false)
  const legalAddressEditOpen = useBoolean(false)
  const confirmOpen = useBoolean(false)

  const { data: userProfile, refetch: refetchUserProfile } = useQuery('fetchUserProfileReview', {
    queryFn: fetchUserProfile,
  })

  const { mutate, isLoading } = useMutation({
    mutationFn: ({ id, payload }) => patchCompany(id, payload),
    onSuccess: () => {
      refetchProfile()
      refetchUserProfile()
      personalInfoEditOpen.setFalse()
      companyInfoEditOpen.setFalse()
      legalAddressEditOpen.setFalse()
    },
  })

  const personalInfo = useMemo(
    () => ({
      firstName: userProfile?.profile?.first_name,
      lastName: userProfile?.profile?.last_name,
      email: userProfile?.profile?.email,
      jobTitle: profile?.title,
      phoneNumber: userProfile?.profile?.phone_number,
    }),
    [profile, userProfile]
  )

  const companyInfo = useMemo(
    () => ({
      companyName: profile?.name,
      country: profile?.legal_address?.living_country?.id,
      currency: profile?.default_currency?.id,
      headquarters: profile?.head_quarters,
    }),
    [profile]
  )

  const legalAddress = useMemo(
    () => ({
      state: profile?.legal_address?.state,
      city: profile?.legal_address?.city,
      streetAddress: profile?.legal_address?.street_address,
      addressLine: profile?.legal_address?.address_line,
      postalCode: profile?.legal_address?.postal_code,
    }),
    [profile]
  )

  const handlePersonalInfoSubmit = ({ firstName, lastName, email, jobTitle, phoneNumber }) => {
    mutate({
      id: profile.id,
      payload: {
        profile: {
          first_name: firstName,
          last_name: lastName,
          phone_number: phoneNumber,
          email,
        },
        title: jobTitle,
      },
    })
  }

  const handleCompanyInfoSubmit = ({ companyName, country, currency, headquarters }) => {
    mutate({
      id: profile.id,
      payload: {
        name: companyName,
        legal_address: {
          living_country: country.value || country,
        },
        default_currency: currency.value || currency,
        head_quarters: headquarters,
      },
    })
  }

  const handleLegalAddressSubmit = ({ state, city, streetAddress, addressLine, postalCode }) => {
    mutate({
      id: profile.id,
      payload: {
        legal_address: {
          state,
          city,
          street_address: streetAddress,
          address_line: addressLine,
          postal_code: postalCode,
        },
      },
    })
  }

  const handleStepSubmit = () => {
    confirmOpen.setFalse()
    onNext()
  }

  return (
    <>
      <Container>
        <Content>
          <Info
            title={personalInfoTitle}
            data={personalInfo}
            actions={[
              <Button
                data-testid="review-step.component-B9F72A"
                key={1}
                type="button"
                size="small"
                priority="secondary"
                onClick={personalInfoEditOpen.setTrue}
              >
                Edit
              </Button>,
            ]}
          />
          <Info
            title={companyInfoTitle}
            data={{
              ...companyInfo,
              country: profile?.legal_address?.living_country?.name,
              currency: profile?.default_currency?.short_code,
            }}
            actions={[
              <Button
                data-testid="review-step.component-ED5107"
                key={1}
                type="button"
                size="small"
                priority="secondary"
                onClick={companyInfoEditOpen.setTrue}
              >
                Edit
              </Button>,
            ]}
          />
          <Info
            title={legalAddressTitle}
            data={{
              ...legalAddress,
            }}
            actions={[
              <Button
                data-testid="review-step.component-1EAB76"
                key={1}
                type="button"
                size="small"
                priority="secondary"
                onClick={legalAddressEditOpen.setTrue}
              >
                Edit
              </Button>,
            ]}
          />
        </Content>
        <Button data-testid="review-step.component-AC228E" type="button" onClick={confirmOpen.setTrue}>
          Continue
        </Button>
      </Container>
      {personalInfoEditOpen.value && (
        <PersonalInfoEditForm
          title={personalInfoTitle}
          data={personalInfo}
          onSubmit={handlePersonalInfoSubmit}
          onClose={personalInfoEditOpen.setFalse}
          isLoading={isLoading}
        />
      )}
      {companyInfoEditOpen.value && (
        <CompanyInfoEditForm
          title={companyInfoTitle}
          data={companyInfo}
          onSubmit={handleCompanyInfoSubmit}
          onClose={companyInfoEditOpen.setFalse}
          isLoading={isLoading}
        />
      )}
      {legalAddressEditOpen.value && (
        <LegalAddressEditForm
          title={legalAddressTitle}
          data={legalAddress}
          onSubmit={handleLegalAddressSubmit}
          onClose={legalAddressEditOpen.setFalse}
          isLoading={isLoading}
        />
      )}
      {confirmOpen.value && (
        <StyledConfirm
          title="Are you sure you want to confirm?"
          yesButtonProps={{
            text: 'Confirm',
            priority: 'primary',
          }}
          onYes={handleStepSubmit}
          onCancel={confirmOpen.setFalse}
          // isLoading={cancelTimeOffRequest.isLoading}
        >
          <Typography>Once confirmed, this information will be used in the MSA that you will need to sign</Typography>
        </StyledConfirm>
      )}
    </>
  )
}
