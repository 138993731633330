import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'

const newManagerFormId = 'newManagerFormId'

export const AddManagerModal = ({ loading, onSubmit, ...rest }) => {
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
  } = useForm({ defaultValues: { is_billing_contact: false } })

  const submit = ({ email, first_name, last_name, is_billing_contact }) => {
    const payload = {
      manager: {
        email,
        profile: {
          first_name,
          last_name,
        },
        is_billing_contact,
      },
    }

    onSubmit(payload)
  }

  return (
    <Modal {...rest} centered show>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>New Manager</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id={newManagerFormId} className="d-flex flex-column gap-3" onSubmit={handleSubmit(submit)}>
          <div className="d-flex gap-3">
            <div className="w-100">
              <Input
                data-testid="new-manager-modal-E7933A"
                autoFocus
                placeholder="First name"
                label="First name"
                isRequired
                {...register('first_name', {
                  required: 'First name is required',
                })}
              />
              {errors.first_name && (
                <Typography className="text_regular__14 color_red">{errors.first_name.message}</Typography>
              )}
            </div>

            <div className="w-100">
              <Input
                data-testid="new-manager-modal-452BEA"
                placeholder="Last name"
                label="Last name"
                isRequired
                {...register('last_name', {
                  required: 'Last name is required',
                })}
              />
              {errors.last_name && (
                <Typography className="text_regular__14 color_red">{errors.last_name.message}</Typography>
              )}
            </div>
          </div>
          <Input
            data-testid="new-manager-modal-86AD42"
            type="email"
            placeholder="Enter the email"
            label="Email"
            isRequired
            {...register('email', {
              required: 'Please fill the email',
              pattern: {
                value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
                message: 'Invalid email address',
              },
            })}
          />
          {errors.email && <Typography className="text_regular__14 color_red">{errors.email.message}</Typography>}

          <Controller
            control={control}
            name="is_billing_contact"
            render={({ field }) => (
              <Checkbox
                data-testid="new-manager-modal-9E6D0B"
                {...field}
                checked={field.value}
                label="Is a Billing contact"
              />
            )}
          />
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="new-manager-modal-8EC7B4"
          type="button"
          priority="secondary"
          size="small"
          onClick={rest.onHide}
        >
          Cancel
        </Button>
        <Button
          data-testid="new-manager-modal-517531"
          type="submit"
          form={newManagerFormId}
          priority="primary"
          size="small"
          disabled={loading}
        >
          <Typography>Add</Typography>
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
