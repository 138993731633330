/* eslint-disable no-shadow */
import Typography from '@atoms/Typography/Typography'
import { SetupForm } from '@pages/stripe/SetupForm'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    padding: 0;
    width: 570px;
    border-radius: 0 !important;
  }
`

export default ({ onClose }) => {
  return (
    <StyledModal show onHide={onClose} centered className="p-0">
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Set up auto pay</Typography>
      </Modal.Header>
      <Modal.Body className="px-4 pb-4">
        <SetupForm onClose={onClose} />
      </Modal.Body>
    </StyledModal>
  )
}
