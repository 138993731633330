import Button from '@atoms/Button/Button'
import Checkbox from '@atoms/Checkbox/Checkbox'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import { useApp } from '@core/context'
import { capitalizeFirstLetter, mapEnum } from '@core/utils'
import { SWIFT_FEE_PAY_ROLE } from '@pages/profilePage/mock'
import { fetchContractors } from '@services/app.service'
import { DotWave } from '@uiball/loaders'
import { isEmpty } from 'lodash'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { v4 } from 'uuid'

import { Styled } from './contractors-list-modal.styles'

const formId = v4()

export const ContractorsListModal = ({ companySwiftFeePayRole, loading, onClose, onSubmit }) => {
  const { currencies, profile } = useApp()
  const usdCurrency = currencies.find((currency) => currency.short_code.toLowerCase() === 'usd')

  const [contractors, setContractors] = useState({})

  const isDetermine = Object.values(contractors).some(({ checked }) => checked)
  const allChecked = Object.values(contractors).length > 1 && Object.values(contractors).every(({ checked }) => checked)

  const { isLoading } = useQuery(fetchContractors.key, {
    enabled: !!profile,
    queryFn: () =>
      fetchContractors.fetch({
        contract_type: 'contractor',
        offset: 0,
        limit: 1000,
        receiving_currency_id: usdCurrency.id,
        swift_fee_pay_role_not_in: `${companySwiftFeePayRole},${SWIFT_FEE_PAY_ROLE.REMOFIRST}`,
      }),
    onSuccess: ({ results }) => {
      if (results.length)
        setContractors(
          results.reduce(
            (object, contractor) => ({
              ...object,
              [contractor.id]: { ...contractor, checked: false },
            }),
            {}
          )
        )
    },
  })

  const handleChange = (id, checked) => () => {
    setContractors((prev) => ({
      ...prev,
      [id]: { ...prev[id], checked },
    }))
  }

  const handleCheckAll = ({ target }) => {
    setContractors(
      Object.values(contractors).reduce(
        (object, contractor) => ({
          ...object,
          [contractor.id]: { ...contractor, checked: target.checked },
        }),
        {}
      )
    )
  }

  const handleApply = (event) => {
    event.preventDefault()

    const payload = {
      contractors: Object.values(contractors)
        .filter(({ checked }) => checked)
        .map(({ id }) => ({
          contractor: id,
          swift_fee_pay_role: companySwiftFeePayRole,
        })),
    }
    onSubmit(payload)
  }

  if (isLoading) {
    return (
      <Modal show centered animation={false} onHide={onClose}>
        <Modal.Body style={{ height: 300 }}>
          <div className="d-flex align-items-center h-100 justify-content-center">
            <DotWave />
          </div>
        </Modal.Body>
      </Modal>
    )
  }

  return (
    <Modal show scrollable centered animation={false} onHide={onClose} onSubmit={handleApply}>
      <Modal.Header>
        <Modal.Title className="h5">International wire transfer (SWIFT) Fee</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <form id={formId}>
          <div className="pb-2">
            New setting: <b>Deduct SWIFT Fees from my contractors payments.</b>
          </div>
          <div className="pb-4">This setting will be applied to all new contractors automatically.</div>

          <div>
            {` Please select from the list of your current contractors the ones to
            whom you'd like to apply the new rule.`}
          </div>

          <table>
            <Styled.Tr>
              <th>
                <div className="d-flex align-items-center gap-1">
                  <Checkbox
                    data-testid="contractors-list-modal-15F7E5"
                    checked={allChecked}
                    determine={isDetermine}
                    label={<b>Contractor</b>}
                    onChange={handleCheckAll}
                  />
                  <Tooltip
                    id={v4()}
                    content="Existing contractors with receiving currency in USD and different SWIFT fee configuration"
                  />
                </div>
              </th>
              <Styled.Th>
                <b>Current configuration</b>
              </Styled.Th>
            </Styled.Tr>
            {Object.entries(contractors).map(([id, contract]) => (
              <Styled.Tr key={id}>
                <td>
                  <Checkbox
                    data-testid="contractors-list-modal-6B0866"
                    id={id}
                    label={contract.full_name}
                    checked={contract.checked}
                    onChange={handleChange(id, !contract.checked)}
                  />
                </td>
                <td>
                  {mapEnum(
                    capitalizeFirstLetter(
                      contract.contractor_fee?.swift_fee_pay_role ||
                        contract.company?.invoice_settings?.swift_fee_pay_role
                    )
                  )}
                </td>
              </Styled.Tr>
            ))}
          </table>

          {isEmpty(contractors) && (
            <div className="text-center pt-4">
              {`
                You don't have any existing contractors to which this setting can
                be applied. Going forward, it will be applied to new contractors
              `}
            </div>
          )}
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="contractors-list-modal-EBF83C"
          size="small"
          color="primary_black"
          type="button"
          priority="secondary"
          className="mr-2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button data-testid="contractors-list-modal-A155AC" size="small" type="submit" form={formId} loading={loading}>
          Apply
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
