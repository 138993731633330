import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { useToast } from '@core/hooks/useNotification'
import { switchUserSession, unlinkUserAccount } from '@services/auth.service'
import { getLinkedAccounts } from '@services/linked-accounts.service'
import classNames from 'classnames'
import { React } from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import styles from '../sidebar.module.scss'

const userHasSession = (uuid, sessions) => !!sessions.find((session) => session.uid === uuid)

const isAccountActive = (uuid, sessions) => {
  const session = sessions.find((s) => s.uid === uuid)

  if (session) return session.active

  return false
}

export const UserAccounts = ({ onClickSignIn, linkedAccounts, sessions }) => {
  const navigate = useNavigate()
  const { successAlert } = useToast()
  const queryClient = useQueryClient()

  const switchSessionMutation = useMutation({
    mutationKey: 'switch_session',
    mutationFn: switchUserSession,
    onSuccess: () => window.location.reload(),
  })

  const unlinkUserAccountMutation = useMutation({
    mutationKey: 'unlink_account',
    mutationFn: unlinkUserAccount,
    onSuccess: () => {
      successAlert('Account removed successfully')
      queryClient.invalidateQueries(getLinkedAccounts.key)
      queryClient.invalidateQueries('sessions')
    },
  })

  const handleClickUserAccount = (account) => {
    if (!isAccountActive(account.uuid, sessions) && userHasSession(account.uuid, sessions))
      switchSessionMutation.mutate({ new_session_uid: account.uuid })
  }

  const handleRemoveUserAccount = (e, account) => {
    e.stopPropagation()
    unlinkUserAccountMutation.mutate({ uid: account.uuid })
  }

  return (
    <>
      {linkedAccounts.map((account) => (
        <div
          className={classNames([
            styles[
              isAccountActive(account.uuid, sessions)
                ? 'sidebar-menu__account_item_active'
                : 'sidebar-menu__account_item'
            ],
          ])}
          role="button"
          aria-hidden
          key={account.uuid}
          onClick={() => handleClickUserAccount(account)}
        >
          <Avatar src={account.avatar_url} height={56} width={56} />
          <div className={classNames([styles['sidebar-menu__account_item_content']])}>
            <div className={classNames([styles['sidebar-menu__account_item_content_text']])}>
              <div className={classNames([styles['sidebar-menu__account_item_content_text_primary']])}>
                <Typography className="text_medium__14">{account.full_name}</Typography>
              </div>

              <Typography className="text_light__14">{account.entity_name}</Typography>
              <Typography className="text_light__14">{account.email}</Typography>
            </div>
            <div className={classNames([styles['sidebar-menu__account_item_content_buttons']])}>
              {isAccountActive(account.uuid, sessions) ? (
                <Button
                  data-testid="user-accounts.component-57E087"
                  size="sm"
                  priority="secondary"
                  onClick={() => navigate('/pages/profile')}
                >
                  Account preferences
                </Button>
              ) : (
                <>
                  {!userHasSession(account.uuid, sessions) && (
                    <Button
                      data-testid="user-accounts.component-3F458E"
                      onClick={() => onClickSignIn(account.email)}
                      size="sm"
                    >
                      Sign in
                    </Button>
                  )}
                  <Button
                    data-testid="user-accounts.component-9983C7"
                    onClick={(e) => handleRemoveUserAccount(e, account)}
                    size="sm"
                    priority="secondary"
                  >
                    Remove
                  </Button>
                </>
              )}
            </div>
          </div>
        </div>
      ))}
    </>
  )
}
