import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { infoIcon } from '@core/icons/icons'
import React from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 3rem;
`

const Container = styled.div`
  display: flex;
  align-items: center;
  padding-right: 1rem;
  font-size: 14px;
  line-height: 150%;
  color: #121212;
`
const IconWrapper = styled.div`
  margin-right: 1rem;
`

export const HiringInfo = ({ country }) => (
  <StyledAlert>
    <Container>
      <IconWrapper>
        <Icon icon={infoIcon} fill="#878787" />
      </IconWrapper>
      <Typography>
        At the next step, you will be asked to sign the MSA Appendix for the country:{' '}
        <strong>{country?.label || 'Not selected'}</strong>. Please select <strong>Continue</strong> to review the
        document.
      </Typography>
    </Container>
  </StyledAlert>
)
