import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import { fieldNameMap } from '@core/constants'
import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  .modal-header {
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    position: relative;
  }
`

const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

export const PersonalInfoEditForm = ({ data, onSubmit, onClose, isLoading }) => {
  const formRef = useRef(null)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    reset(data)
  }, [data])

  const triggerFormSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Personal information</Typography>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Input
              data-testid="personal-info-edit-form.component-F07AD2"
              {...register('firstName', {
                required: `${fieldNameMap.firstName} is required`,
              })}
              type="text"
              label={fieldNameMap.firstName}
              isRequired
            />
            {errors?.firstName && (
              <Typography className="text_regular__14 color_red">{errors?.firstName.message}</Typography>
            )}
          </FormControl>
          <FormControl>
            <Input
              data-testid="personal-info-edit-form.component-EE0D97"
              {...register('lastName', {
                required: `${fieldNameMap.lastName} is required`,
              })}
              type="text"
              label={fieldNameMap.lastName}
              isRequired
            />
            {errors?.lastName && (
              <Typography className="text_regular__14 color_red">{errors?.lastName.message}</Typography>
            )}
          </FormControl>
          <FormControl>
            <Input
              data-testid="personal-info-edit-form.component-31CFB9"
              {...register('email', {
                required: `${fieldNameMap.email} is required`,
                validate: {
                  isEmail: (v) => {
                    return /.+@.+\..+/.test(v) || 'Please enter correct email'
                  },
                },
              })}
              type="text"
              label={fieldNameMap.email}
              disabled
            />
            {errors?.email && <Typography className="text_regular__14 color_red">{errors?.email.message}</Typography>}
          </FormControl>
          <FormControl>
            <Input
              data-testid="personal-info-edit-form.component-578A7B"
              {...register('jobTitle', {
                required: `${fieldNameMap.jobTitle} is required`,
              })}
              type="text"
              label={fieldNameMap.jobTitle}
              isRequired
            />
            {errors?.jobTitle && (
              <Typography className="text_regular__14 color_red">{errors?.jobTitle.message}</Typography>
            )}
          </FormControl>
          <FormControl>
            <Input
              data-testid="personal-info-edit-form.component-FECC42"
              {...register('phoneNumber', {
                required: `${fieldNameMap.phoneNumber} is required`,
              })}
              type="text"
              label={fieldNameMap.phoneNumber}
              isRequired
            />
            {errors?.phoneNumber && (
              <Typography className="text_regular__14 color_red">{errors?.phoneNumber.message}</Typography>
            )}
          </FormControl>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="personal-info-edit-form.component-0D203D"
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="personal-info-edit-form.component-6E81F5"
          priority="primary"
          size="small"
          type="button"
          onClick={triggerFormSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
