import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Typography from '@atoms/Typography/Typography'
import { downloadPayrollCsv } from '@services/payroll.service'
import moment from 'moment/moment'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation } from 'react-query'
import styled from 'styled-components'

const StyledForm = styled.form`
  > * {
    margin-bottom: 16px;
  }
`
export default ({ onClose }) => {
  const [startDate, setStartDate] = useState()
  const [endDate, setEndDate] = useState()

  const clear = () => {
    setEndDate(undefined)
    setStartDate(undefined)
  }

  const downloadCSVRequest = useMutation({
    mutationFn: downloadPayrollCsv,
    onSuccess: (response) => {
      const href = URL.createObjectURL(response)
      const link = document.createElement('a')
      link.href = href
      link.setAttribute('download', `Payroll_${moment(new Date()).format('DD_MM_YYYY')}.csv`)
      document.body.appendChild(link)
      link.click()

      document.body.removeChild(link)
      URL.revokeObjectURL(href)
      clear()
      onClose()
    },
  })

  const onSubmit = () => {
    downloadCSVRequest.mutate({
      end_date: endDate ? moment(endDate).format('YYYY-MM-DD') : undefined,
      start_date: startDate ? moment(startDate).format('YYYY-MM-DD') : undefined,
    })
  }

  return (
    <Modal show onHide={onClose} centered>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Payroll report</Typography>
      </Modal.Header>
      <Modal.Body>
        <StyledForm className="d-flex flex-column" onSubmit={onSubmit}>
          <div className="row">
            <div className="col">
              <div className="remo-form-input">
                <DateInput className="mb-3" onChange={setStartDate} label="Start date" value={startDate} />
              </div>
            </div>
            <div className="col">
              <div className="remo-form-input">
                <DateInput className="mb-3" onChange={setEndDate} label="End date" value={endDate} />
              </div>
            </div>
          </div>
        </StyledForm>
      </Modal.Body>
      <Modal.Footer>
        <Button data-testid="DownloadReport-D7E443" priority="secondary" size="small" type="button" onClick={onClose}>
          Cancel
        </Button>
        <Button
          data-testid="DownloadReport-CE616F"
          size="small"
          onClick={onSubmit}
          disabled={downloadCSVRequest.isLoading}
          loading={downloadCSVRequest.isLoading}
        >
          Download report
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
