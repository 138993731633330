export const getDocumentFields = (onDownload, onDelete) => [
  {
    title: 'Name',
    key: 'name',
    type: 'textMedium',
    icon: 'description',
    maxWidth: 680,
    minWidth: 680,
    width: 680,
  },
  {
    title: 'Uploaded',
    key: 'uploaded',
    type: 'string',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'actions',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
    actions: [
      {
        action: onDownload,
        icon: 'download',
      },
      {
        action: onDelete,
        icon: 'deleteIcon',
      },
    ],
  },
]
