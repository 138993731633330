import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import EditModal from '@atoms/EditModal/EditModal'
import Input from '@atoms/Input/Input'
import RadioButton from '@atoms/RadioButton/RadioButton'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { UpdateContractModal } from '@components/contract-update-modal'
import { CONTRACT_FIELDS, CONTRACT_TYPE, RESIDENCY_TYPES, SUB_STATE_CHANGE_REQUEST_TYPES } from '@core/constants'
import { AppContext } from '@core/context'
import { patchContractById } from '@store/contracts'
import moment from 'moment'
import { useCallback, useContext, useState } from 'react'
import { useMutation } from 'react-query'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

import { ContractUpdateNotification } from '../../../../components/contract-update-notification'
import {
  createSubStateChangeRequest,
  deactivateContract,
  mutationUploadAvatar,
  reactivateContract,
} from '../../../../services/contract.service'

export default function Personal({ employee, refetch, setEmployee, openAgreementUpdateConfirm }) {
  const { countries } = useContext(AppContext)
  const [visibleEdit, setVisibleEdit] = useState(false)
  const activateContractorModal = useBoolean(false)
  const [visibleCancelOnboarding, setVisibleCancelOnboarding] = useState(false)

  const initialState = {
    first_name: employee.profile.first_name,
    middle_name: employee.profile.middle_name,
    last_name: employee.profile.last_name,
    email: employee.profile.email,
    avatar: employee.profile.avatar,
    phone_number: employee.profile.phone_number,
    birth_date: employee.profile.birth_date ? employee.profile.birth_date : null,
    living_country: employee.profile.address?.living_country?.id || '',
    street_address: employee.profile.address?.street_address,
    state: employee.profile.address?.state,
    city: employee.profile.address?.city,
    postal_code: employee.profile.address?.postal_code,
    address_line: employee.profile?.address?.address_line,
    home_living_country: employee.profile.home_address?.living_country?.id || '',
    home_street_address: employee.profile.home_address?.street_address,
    home_state: employee.profile.home_address?.state,
    home_city: employee.profile.home_address?.city,
    home_postal_code: employee.profile.home_address?.postal_code,
    home_address_line: employee.profile?.home_address?.address_line,
    needs_work_permit: !!employee.profile?.needs_work_permit,
    residency_type: employee.residency_type,
  }

  const [editState, setEditState] = useState(initialState)

  const haveAgreementFieldsUpdated = () => {
    return CONTRACT_FIELDS.filter((field) => {
      return initialState[field] !== editState[field]
    })
  }

  const updatePersonalInfo = useMutation({
    mutationFn: (payload) => patchContractById(payload.id, payload.data),
    onSuccess: (res) => {
      setEmployee(res.data)
      setVisibleEdit(false)
      toast.success('Successfully updated')
      const agreementFieldsList = haveAgreementFieldsUpdated()
      if (agreementFieldsList.length) {
        openAgreementUpdateConfirm.setTrue()
      }
    },
  })

  const onChangeEdit = (evt) => {
    const { value } = evt.target
    setEditState((prevState) => ({
      ...prevState,
      [evt.target.name]: value,
    }))
  }
  const onChangeBirthDate = (value) => {
    setEditState((prevState) => ({
      ...prevState,
      birth_date: value,
    }))
  }
  const onChangeWorkPermit = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      needs_work_permit: e.target.value,
    }))
  }
  const onChangeResidencyType = (e) => {
    setEditState((prevState) => ({
      ...prevState,
      residency_type: e.target.value,
    }))
  }
  const onChangeLivingCountry = (option) => {
    setEditState((prevState) => ({
      ...prevState,
      living_country: option.value,
    }))
  }

  const activateContract = useMutation({
    mutationFn: ({ id, payload }) => reactivateContract(id, payload),
    onSuccess: () => {
      activateContractorModal.setFalse()
      refetch()
    },
  })

  const onClickActivateContractor = async () => {
    activateContract.mutateAsync({
      id: employee.id,
      payload: {
        state: 'active',
      },
    })
  }

  const createSubStateChangeRequestMutation = useMutation({
    mutationFn: ({ id, payload }) => createSubStateChangeRequest(id, payload),
    onSuccess: () => {
      toast.success('Thank you. Please wait for our team to contact you about the next steps.', {
        position: 'top-right',
        theme: 'colored',
        autoClose: false,
      })
      setVisibleCancelOnboarding(false)
      refetch()
    },
  })

  const uploadProfileAvatar = useMutation({
    mutationFn: (payload) => mutationUploadAvatar(payload.id, payload.file),
    onSuccess: ({ data }) => {
      setEmployee(data)
      setEditState((prevState) => ({
        ...prevState,
        avatar: data.profile.avatar,
      }))
      toast.success('Profile image has been updated successfully')
    },
  })

  const onSaveEdit = () => {
    const compensation =
      employee.contract_type === 'contractor'
        ? {
            contractor_rate: employee.compensation.contractor_rate,
            estimated_working_hours: employee.compensation.estimated_working_hours || undefined,
            contractor_wage_type: employee.compensation.contractor_wage_type,
            contractor_payment_frequency: employee.compensation.contractor_payment_frequency,
          }
        : {
            yearly_gross_salary: employee.compensation.yearly_gross_salary,
          }

    const residencyType =
      employee.contract_type !== 'contractor'
        ? {
            residency_type: editState.residency_type,
          }
        : {}
    const data = {
      profile: {
        first_name: editState.first_name,
        middle_name: editState.middle_name,
        last_name: editState.last_name,
        phone_number: editState.phone_number,
        birth_date: editState.birth_date ? moment(editState.birth_date).format('YYYY-MM-DD') : null,
        email: editState.email,
        address: {
          living_country: editState.living_country,
          street_address: editState.street_address,
          city: editState.city,
          postal_code: editState.postal_code,
          state: editState.state,
          address_line: editState.address_line,
        },
        home_address: {
          living_country: editState.home_living_country,
          street_address: editState.home_street_address,
          city: editState.home_city,
          postal_code: editState.home_postal_code,
          state: editState.home_state,
          address_line: editState.home_address_line,
        },
        ...(employee.contract_type === CONTRACT_TYPE.FULL_TIME_EMPLOYEE && {
          needs_work_permit: editState.needs_work_permit,
        }),
      },
      compensation,
      ...residencyType,
    }
    updatePersonalInfo.mutate({ id: employee.id, data })
  }
  const showUpdateContractModal = useBoolean(false)

  return (
    <div style={{ maxWidth: 540 }}>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-FFCC37"
          label="First name"
          placeholder="Name"
          name="first_name"
          value={editState?.first_name}
          onChange={onChangeEdit}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-713D04"
          onChange={onChangeEdit}
          label="Middle name"
          placeholder="Middle name"
          name="middle_name"
          value={editState?.middle_name}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-7F7405"
          onChange={onChangeEdit}
          label="Last name"
          placeholder="Surname"
          name="last_name"
          value={editState?.last_name}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-7F74035"
          label="Email"
          placeholder="Email"
          name="email"
          value={editState?.email}
          disabled
        />
      </div>
      <div className="w-100 remo-form-input">
        <DateInput
          className="mb-3"
          maxDate={new Date()}
          onChange={onChangeBirthDate}
          label="Date of birth"
          value={editState?.birth_date}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-8023E6"
          onChange={onChangeEdit}
          label="Phone number"
          placeholder="Phone number"
          name="phone_number"
          type="text"
          value={editState?.phone_number}
          disabled={!visibleEdit}
        />
      </div>
      {countries && (
        <div className="w-100 remo-form-input">
          <Select
            data-testid="Personal-9FB35D"
            options={countries.map((country) => ({
              value: country.id,
              label: country.name,
            }))}
            name="living_country"
            id="living_country"
            placeholder="e.g United Kingdom"
            onChange={onChangeLivingCountry}
            value={editState?.living_country}
            label="Country"
            isDisabled={!visibleEdit}
          />
        </div>
      )}

      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-83416E"
          onChange={onChangeEdit}
          label="State"
          placeholder="State"
          name="state"
          value={editState?.state}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-50015D"
          onChange={onChangeEdit}
          label="City"
          name="city"
          value={editState?.city}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-B9335D"
          onChange={onChangeEdit}
          label="Address"
          placeholder="Address"
          name="street_address"
          value={editState?.street_address}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-CC6D51"
          onChange={onChangeEdit}
          label="Postal code"
          placeholder="Postal code"
          name="postal_code"
          value={editState?.postal_code}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <Input
          data-testid="Personal-0BC568"
          onChange={onChangeEdit}
          label="Address line"
          placeholder="Address line"
          name="address_line"
          value={editState?.address_line}
          disabled={!visibleEdit}
        />
      </div>
      <div className="w-100 remo-form-input">
        <RadioButton
          label="Residency type"
          onChange={onChangeResidencyType}
          value={editState.residency_type}
          addText="Is this employee a Resident or Non-Resident?"
          options={[
            {
              id: 'resident',
              value: RESIDENCY_TYPES.RESIDENT,
              text: 'Resident',
            },
            {
              id: 'non-resident',
              value: RESIDENCY_TYPES.NON_RESIDENT,
              text: 'Non-Resident',
            },
          ]}
          disabled={!visibleEdit}
        />
      </div>
      {editState.residency_type === RESIDENCY_TYPES.NON_RESIDENT && (
        <div className="w-100 remo-form-input">
          <RadioButton
            label="Work eligibility"
            onChange={onChangeWorkPermit}
            value={editState.needs_work_permit}
            addText="Does this employee require a work eligibility document (work permit, visa, etc)?"
            options={[
              {
                id: 'needs_work_permit_true',
                value: 'true',
                text: 'Yes',
              },
              {
                id: 'needs_work_permit_false',
                value: 'false',
                text: 'No',
              },
            ]}
            disabled={!visibleEdit}
          />
        </div>
      )}
      <div className="d-flex align-items-center justify-content-between gap-2">
        {!visibleEdit ? (
          <Button data-testid="Personal-7A0795" priority="secondary" size="small" onClick={() => setVisibleEdit(true)}>
            Edit
          </Button>
        ) : (
          <Button
            data-testid="Personal-7A0795"
            priority="primary"
            size="small"
            onClick={onSaveEdit}
            disabled={updatePersonalInfo.isLoading}
          >
            Save
          </Button>
        )}
      </div>
      <UpdateContractModal
        employee={employee}
        showUpdateContractModal={showUpdateContractModal}
        withSelectCancelButton
      />
    </div>
  )
}
