import { client } from '@core/hellosign-client'
import { useToast } from '@core/hooks/useNotification'
import { getSignUrl } from '@services/sign.service'
import React, { useEffect, useState } from 'react'
import { useQuery } from 'react-query'

export const useDocumentSign = (signatureId, fileUrl, onSuccess, onError) => {
  const [signUrl, setSignUrl] = useState()
  const { successAlert } = useToast()

  const fetchSignUrlQuery = useQuery(['signUrl', signatureId], {
    queryFn: () => getSignUrl(signatureId),
    onSuccess: ({ sign_url }) => {
      setSignUrl(sign_url)
    },
    onError,
    enabled: !!signatureId,
  })

  useEffect(() => {
    if (signUrl) {
      client.on('finish', () => {
        successAlert('The document has been signed!')
        onSuccess()
      })
    }
    return () => {
      client.off('finish')
    }
  }, [signUrl, onSuccess])

  const share = () => console.log('Share')

  const download = () => {
    window.open(fileUrl, '_blank')
  }

  const sign = () => {
    client.open(signUrl, {
      skipDomainVerification: process.env.REACT_APP_HELLOSIGN_SKIP_DOMAIN === 'true',
    })
  }

  return {
    signUrl,
    fetchSignUrlQuery,
    sign,
    share,
    download,
  }
}
