import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'

import css from '../onboarding.module.scss'
import { SuccessSvg } from './success-svg'

export const SuccessOnboarding = () => {
  const navigate = useNavigate()
  const queryClient = useQueryClient()

  const handleSubmit = () => {
    queryClient.refetchQueries('fetchProfile')
    navigate('/pages/dashboard')
  }

  return (
    <div className="d-flex justify-content-center align-items-center h-100">
      <div className={css.success}>
        <SuccessSvg />
        <Typography className="heading_semibold__28">Congratulations, you have successfully registered</Typography>
        <Typography className="text_regular-normal__14">Please navigate to the Dashboard to continue</Typography>

        <Button
          data-testid="success-onboarding-index-5C4638"
          size="small"
          priority="primary_black"
          disabled={queryClient.isFetching({ queryKey: 'fetchProfile' })}
          onClick={handleSubmit}
        >
          Go to Dashboard
        </Button>
      </div>
    </div>
  )
}
