import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'

export const UpdateAgreementConfirmModal = ({ show, onHide, onConfirm, isLoading }) => {
  return (
    <Modal centered show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Update contract?</Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="heading_semi__16">
          These fields are included in the contract agreement, should a new contract be uploaded or generated?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button priority="secondary" size="small" className="mr-3" onClick={onHide}>
            {`Don't update`}
          </Button>
          <Button disabled={isLoading} priority="primary" size="small" onClick={onConfirm}>
            Upload or generate a new contract
          </Button>
        </div>
      </Modal.Body>
    </Modal>
  )
}
