import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 8px;
    width: 600px;
  }
`

export const DeleteMilestoneConfirmModal = ({ show, onHide, onConfirm }) => {
  return (
    <StyledModal centered show={show} onHide={onHide} animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Delete milestone</Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="heading_semi__16">Are you sure you want to delete this milestone?</Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button priority="secondary" size="small" className="mr-3" onClick={onHide}>
            Cancel
          </Button>
          <Button priority="primary_dangerous" size="small" onClick={onConfirm}>
            Delete
          </Button>
        </div>
      </Modal.Body>
    </StyledModal>
  )
}
