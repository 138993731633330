import ModalSide from '@atoms/ModalSide/ModalSide'
import CustomTable from '@atoms/Table/CustomTable'
import Typography from '@atoms/Typography/Typography'

import { PAYMENT_FIELDS } from './payContractorsMock'

const CreateInvoiceConfirmModal = ({
  onClose,
  onConfirm,
  loading,
  title,
  contractIds,
  paymentPluralLabel,
  paymentsListToConfirm,
}) => {
  return (
    <ModalSide
      onClose={onClose}
      onOk={onConfirm}
      title={title}
      okText="Create invoice"
      closeText="Cancel"
      okButtonProps={{ loading, disabled: loading }}
    >
      <div className="d-flex flex-column gap-4">
        <Typography className="text_regular__14 color_gray">
          {`You are about to create an invoice for the following ${contractIds.length} ${
            contractIds.length > 1 ? 'payments' : 'payment'
          }.`}
        </Typography>
        <Typography className="text_regular__14 color_gray">
          {`${paymentPluralLabel} will no longer be visible from the Payments section to view ${paymentPluralLabel.toLowerCase()} please go to the
          Invoices section.`}
        </Typography>
        <CustomTable fields={PAYMENT_FIELDS} data={paymentsListToConfirm || []} />
      </div>
    </ModalSide>
  )
}

export default CreateInvoiceConfirmModal
