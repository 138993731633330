import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import CustomTable from '@atoms/Table/CustomTable'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { SplitNumber } from '@core/utils'
import { payrollsField, payrollsToApproveField } from '@pages/payrollsDetailPage/mock'
import { parserPayrollsBasePayment } from '@pages/payrollsDetailPage/parse'
import SuccessApproveModal from '@pages/payrollsDetailPage/SuccessApproveModal'
import {
  changePayrollStatus,
  fetchDeletePayroll,
  getPayrollApprovalFLowList,
  getPayrollInvoiceTotal,
  partialPayrollApprove,
} from '@services/payroll.service'
import React, { useCallback, useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useBoolean } from 'usehooks-ts'

export const PayrollToApprove = ({ setCount }) => {
  const navigate = useNavigate()
  const [payrollsData, setPayrollsData] = useState([])
  const [invoiceId, setInvoiceId] = useState()
  const [comment, setComment] = useState()
  const [invoiceTotal, setInvoiceTotal] = useState()
  const [payrollCurrent, setPayrollCurrent] = useState()
  const [rejectVisible, setRejectVisible] = useState(false)
  const [currentPayroll, setCurrentPayroll] = useState()
  const [approvedPayroll, setApprovedPayroll] = useState(null)
  const [selectedPayrollDelete, setSelectedPayrollDelete] = useState(null)

  const { value: visibleDelete, toggle: toggleDelete } = useBoolean(false)
  const { data, isLoading, refetch } = useQuery(['getPayrollApprovalFLowListApprove'], {
    queryFn: () => getPayrollApprovalFLowList({ limit: 10000, page: 1, status: 'to_approve' }),
    keepPreviousData: true,
    onSuccess: (v) => {
      setCount((prev) => prev + v.count)
    },
  })

  const { isLoading: invoiceTotalLoading } = useQuery(['invoiceTotal', invoiceId], {
    queryFn: () => getPayrollInvoiceTotal(invoiceId),
    onSuccess: (res) => {
      setInvoiceTotal(res)
    },
    enabled: !!invoiceId,
  })

  const approvePayrollRequest = useMutation('payrollsChangeStatus', {
    mutationFn: partialPayrollApprove,
    onSuccess: (response) => {
      setApprovedPayroll(response)
      toast.success('Successfully updated')
      refetch()
      setPayrollCurrent(undefined)
      // asd
    },
  })

  const deleteMutate = useMutation({
    mutationFn: (payrollId) => fetchDeletePayroll(payrollId),
    onSuccess: () => {
      toggleDelete()
      refetch()
      toast.success('Payroll successfully deleted')
    },
  })
  const payrollsChangeStatus = useMutation('payrollsChangeStatus', {
    mutationFn: ({ id, body }) => changePayrollStatus(id, body),
    onSuccess: () => {
      setComment(undefined)
      refetch()
      setRejectVisible(false)
    },
  })

  const onCollapseClick = useCallback((row) => {
    if (!row.disabled) {
      setInvoiceId(row.id)
    }
  }, [])

  const onCreateInvoice = useCallback(() => {
    approvePayrollRequest.mutate({ payrolls: payrollsData.map((p) => p.id) })
  }, [payrollsData])

  const onClickEdit = useCallback((rowData) => {
    navigate(`/pages/payrolls/edit/${rowData.id}`)
  }, [])

  const onClickRequestChange = useCallback((payroll) => {
    setCurrentPayroll(payroll)
    setRejectVisible(true)
  }, [])

  const onSendComment = useCallback(() => {
    payrollsChangeStatus.mutate({
      id: currentPayroll.id,
      body: {
        state: 'need_changes_from_admin',
        comment,
      },
    })
  }, [currentPayroll, comment])

  const handleDelete = (row) => {
    toggleDelete()
    setSelectedPayrollDelete(row.id)
  }

  const onChangeComment = useCallback((e) => {
    setComment(e.target.value)
  }, [])

  useEffect(() => {
    if (data) {
      setPayrollCurrent({
        total: data?.total,
        currency: data?.currency,
      })
      setPayrollsData(data?.current)
      setInvoiceId(data?.current[0]?.id)
    }
  }, [data])
  return (
    <div className="customer-payroll-wrapper-approve mb-3">
      <div className="customer-payroll-wrapper-header-approve">TO APPROVE NOW</div>
      <div className="customer-payroll-wrapper-description-approve d-flex justify-content-between">
        {payrollsData.length > 0 ? (
          <>
            <span>
              Total to approve {SplitNumber(payrollCurrent?.total)}{' '}
              {payrollCurrent?.currency?.sign || payrollCurrent?.currency?.short_code}
            </span>
            <span>No. of employee to approve: {payrollsData.length}</span>
            <Button
              data-testid="PayrollDetailPage-D23DE4"
              className="px-5"
              priority="primary"
              size="medium"
              loading={approvePayrollRequest.isLoading || isLoading}
              onClick={onCreateInvoice}
            >
              Approve
            </Button>
          </>
        ) : (
          'None'
        )}
      </div>
      {payrollsData.length > 0 && (
        <div className="customer-payroll-table">
          <CustomTable
            collapseId={invoiceId}
            type="collapse"
            className="customer-payroll-table"
            fields={payrollsToApproveField(onClickEdit, handleDelete)}
            onCollapseClick={onCollapseClick}
            data={parserPayrollsBasePayment(
              payrollsData,
              invoiceTotal,
              invoiceTotalLoading,
              onClickRequestChange,
              data.month
            )}
          />
        </div>
      )}

      {approvedPayroll && <SuccessApproveModal payroll={approvedPayroll} onClose={setApprovedPayroll} />}
      <EditModal
        visible={visibleDelete}
        title="Delete payroll"
        onSave={() => {}}
        footer={false}
        closeModal={toggleDelete}
      >
        <Typography className="heading_semi__16">
          Are you sure you want to delete this payroll from your company?
        </Typography>
        <div className="d-flex justify-content-end mt-5">
          <Button
            data-testid="PayrollDetailPage-76352E"
            priority="secondary"
            size="small"
            className="mr-3"
            onClick={toggleDelete}
          >
            Cancel
          </Button>
          <Button
            data-testid="PayrollDetailPage-276C7F"
            priority="primary_dangerous"
            size="small"
            loading={deleteMutate.isLoading}
            disabled={deleteMutate.isLoading}
            onClick={() => deleteMutate.mutate(selectedPayrollDelete || '')}
          >
            Delete
          </Button>
        </div>
      </EditModal>
      <EditModal
        visible={rejectVisible}
        footer
        size="lg"
        loading={payrollsChangeStatus.isLoading}
        title="Reject Pending "
        closeModal={() => setRejectVisible(false)}
        onSave={onSendComment}
      >
        <TextArea onChange={onChangeComment} placeholder="Reject Cause" rows={5} />
      </EditModal>
    </div>
  )
}
