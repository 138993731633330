import { Api } from '@api/Api'

export const AGREEMENT_STATUS = {
  NOT_SIGNED: 'not_signed',
  SIGNED: 'signed',
  UPLOADED: 'uploaded',
}

export const createAuthorizedPerson = async (payload) => {
  const { data } = await Api.post(`company-authorized-person/create/`, payload)
  return data
}

export const notifyManagerToSignMsa = async (payload) => {
  const { data } = await Api.post(`notify-company-manager-to-sign-msa/`, payload)
  return data
}

export const checkAppendixAgreement = async (payload) => {
  const { data } = await Api.post(`appendix-agreement/check/`, payload)
  return data
}

export const getTemplateSignerRoles = async (id) => {
  const { data } = await Api.get(`agreement-templates/${id}/signer-roles/`)
  return data
}

export const getActiveTemplate = async (params) => {
  const { data } = await Api.get(`agreement-templates/`, { params })
  return data
}

export const createAppendix = async (payload) => {
  const { data } = await Api.post(`agreement-documents/appendix-create/`, payload)
  return data
}

export const getAgreement = async (id) => {
  const { data } = await Api.get(`agreement-documents/${id}/`)
  return data
}

export const getAgreements = async (params) => {
  const { data } = await Api.get(`agreement-documents/`, {
    params,
  })
  return data
}

export const getSignUrl = async (signatureId) => {
  const { data } = await Api.get(`/document-signatures/${signatureId}/sign-url/`)
  return data
}

export const notify = async ({ id, body }) => {
  const { data } = await Api.post(`agreement-documents/${id}/remind/`, body)
  return data
}

export const createAgreement = async (body) => {
  const { data } = await Api.post(`agreement-documents/create/`, body)
  return data
}
