/* eslint-disable jsx-a11y/no-static-element-interactions,jsx-a11y/click-events-have-key-events */
import './Chat.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { AbsoluteSpinner } from '@atoms/Spinner/AbsoluteSpinner'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { attachFile, chatDownloadFile, send } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import classNames from 'classnames'
import { first } from 'lodash'
import moment from 'moment'
import React, { useCallback, useContext, useRef, useState } from 'react'
import InfiniteScroll from 'react-infinite-scroll-component'
import { useMutation, useQuery } from 'react-query'
import { fetchPostMessage, fetchSendDocument, fetchThreadMessages } from 'src/services/chat.service'
import { useBoolean } from 'usehooks-ts'

import AddMemberModal from './AddMemberModal/AddMemberModal'
import ThreadEditModal from './ThreadEditModal/ThreadEditModal'

let offset = 0
const limit = 200

export default function ChatList({ currentThread, onClickResolve, onGetNewMessage, isOwner = false }) {
  const activeChat = currentThread?.state === 'ACTIVE'

  const { userProfile } = useContext(AppContext)
  const inputRef = useRef(null)
  const messagesRef = useRef(null)

  const addNewMemberVisible = useBoolean(false)
  const aboutVisible = useBoolean(false)

  const [value, setValue] = useState('')
  const [messages, setMessages] = useState([])
  const [isMoreMessages, setIsMoreMessages] = useState(false)

  React.useEffect(() => {
    return () => {
      setMessages([])
      offset = 0
    }
  }, [currentThread])

  const messagesQuery = useQuery(['messages', currentThread], {
    enabled: !!currentThread,
    keepPreviousData: true,
    queryFn: () => fetchThreadMessages(currentThread.id, limit, offset),
    onSuccess: (response) => {
      setMessages((prev) => [...prev, ...response.results])
      setIsMoreMessages(!!response.next)
    },
  })

  // REMOVE ME AND ASK BE devs to ADD AS RESPONSE for postMessage sendDocument, addMember
  // const getLastMsgQuery = useMutation({
  //   mutationFn: () => fetchThreadMessages(currentThread.id, 1, 0),
  //   onSuccess: (response) => {
  //     setMessages((prev) => [...response.results, ...prev])
  //     messagesRef.current.scrollTop = messagesRef.current.scrollHeight
  //     refetch()
  //   },
  // })

  const postMessage = useMutation({
    mutationFn: (body) => fetchPostMessage(currentThread.id, body),
    onSuccess: (result) => {
      onGetNewMessage(result)
      setValue('')
      setMessages((prev) => [result, ...prev])
      offset += 1
    },
  })

  const sendDocumentMutation = useMutation({
    mutationFn: (file) => fetchSendDocument(currentThread.id, file),
    onSuccess: (result) => {
      onGetNewMessage(result)
      setMessages((prev) => [result, ...prev])
      offset += 1
    },
  })

  const handleNext = () => {
    offset += limit
    messagesQuery.refetch()
  }

  const onChangeMessage = useCallback((e) => {
    setValue(e.target.value)
  }, [])

  const onSendMessage = (e) => {
    if (e.keyCode === 13 && e.shiftKey === false && value !== '') {
      e.preventDefault()
      postMessage.mutate({ message_type: 'TEXT', content: value.trim() })
    }
  }

  const onClickSendMessage = () => {
    postMessage.mutate({ message_type: 'TEXT', content: value.trim() })
  }

  const onChangeFile = (event) => {
    const file = event.target.files[0]
    if (file) sendDocumentMutation.mutate(file)
  }

  const onClickFile = useCallback(() => {
    inputRef.current?.click()
  }, [])

  const renderMessage = useCallback((msg) => {
    switch (msg.message_type) {
      case 'DOCUMENT':
        return (
          <div className="d-flex">
            <a
              data-testid="ChatList-A22DD8"
              target="_blank"
              className="mr-2"
              style={{ textDecoration: 'none' }}
              href={msg.thread_document}
              rel="noreferrer"
            >
              <Icon fill="white" icon={chatDownloadFile} style={{ cursor: 'pointer' }} />
            </a>
            <div className="d-flex flex-column pt-1 w-100">
              <Typography className="text_regular__14">{msg.content}</Typography>
              <Typography className="text_light__12 w-100 text-right">
                {moment(msg.created_at).format('HH:mm')}
              </Typography>
            </div>
          </div>
        )
      case 'NEW_MEMBER':
        return (
          <div className="d-flex">
            <div className="msg_line">
              <Typography
                as="span"
                className="text_medium__14 color_text_200"
                style={{ background: '#fff', padding: '0 10px' }}
              >
                {msg.content} <span className="text_regular__14 color_text_300">was added to the chat</span>
              </Typography>
            </div>
          </div>
        )
      default:
        return (
          <div className="d-flex flex-column">
            <Typography className="text_regular__14">{msg.content}</Typography>
            <Typography className="text_light__12 w-100 text-right">
              {moment(msg.created_at).format('HH:mm')}
            </Typography>
          </div>
        )
    }
  }, [])

  if (messagesQuery.isLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={40} speed={1} color="black" />
      </div>
    )
  }

  return (
    <>
      <div className="d-flex align-items-center justify-content-between">
        <Typography className="heading_semibold__20">{currentThread.topic}</Typography>
        <div className="d-flex align-items-center">
          <Button data-testid="ChatList-0DDD54" priority="secondary" size="small" onClick={addNewMemberVisible.setTrue}>
            <img src="/assets/icons/add-member.svg" alt="add-member" />
          </Button>
          <Button
            data-testid="ChatList-89FE61"
            className="ml-2"
            priority="secondary"
            size="small"
            onClick={aboutVisible.setTrue}
          >
            <img src="/assets/icons/info.svg" alt="info" />
          </Button>
          {isOwner && (
            <Button
              data-testid="ChatList-3A062F"
              className="ml-2"
              priority="secondary"
              size="small"
              title={activeChat ? 'close' : 'open'}
              onClick={onClickResolve}
            >
              {activeChat ? (
                <img src="/assets/icons/unresolve.svg" alt="unresolve" />
              ) : (
                <img src="/assets/icons/resolve.svg" alt="resolve" />
              )}
            </Button>
          )}
        </div>
      </div>

      <div className="d-flex flex-column">
        <div ref={messagesRef} id="message_content" className="message_content mt-3">
          <AbsoluteSpinner isFetching={postMessage.isLoading || sendDocumentMutation.isLoading}>
            <InfiniteScroll
              dataLength={messages.length}
              next={handleNext}
              style={{ display: 'flex', flexDirection: 'column-reverse' }}
              hasMore={isMoreMessages}
              inverse
              scrollableTarget="message_content"
              loader={
                <div className="d-flex w-100 loader align-items-center justify-content-center" style={{ height: 80 }}>
                  <DotWave size={32} speed={1} color="black" />
                </div>
              }
            >
              <>
                {messages.map((msg) => (
                  <div key={String(msg.id)}>
                    <div
                      className={classNames(
                        'mb-3 message',
                        msg.author.id !== userProfile.id ? 'chat_message' : 'chat_message__user',
                        msg.message_type === 'NEW_MEMBER' && 'chat_message__member'
                      )}
                    >
                      {msg.message_type !== 'NEW_MEMBER' && msg.author.id !== userProfile.id && (
                        <Typography className="text_medium__14 mb-2">{msg.author.full_name}</Typography>
                      )}
                      {renderMessage(msg)}
                    </div>
                    <div className="clearfix" />
                  </div>
                ))}
              </>
            </InfiniteScroll>
          </AbsoluteSpinner>
        </div>
        <div className="d-flex position-relative justify-content-center">
          {activeChat ? (
            <div className="chat_textarea w-100">
              <TextArea
                onKeyDown={onSendMessage}
                rows={3}
                value={value}
                placeholder="Write a message"
                style={{
                  resize: 'none',
                  border: 'none',
                }}
                onChange={onChangeMessage}
              />
              <div className="chat-file d-flex align-items-center justify-content-between">
                <Button
                  data-testid="ChatList-D2240F"
                  style={{ padding: '8px', minWidth: 40 }}
                  priority="secondary"
                  size="small"
                  onClick={onClickFile}
                >
                  <Icon fill="black" icon={attachFile} style={{ cursor: 'pointer' }} />
                </Button>
                <input
                  data-testid="ChatList-2E59B1"
                  type="file"
                  className="d-none"
                  ref={inputRef}
                  onChange={onChangeFile}
                  onClick={(e) => {
                    e.target.value = null
                  }}
                />
                <Button
                  data-testid="ChatList-E6E7CE"
                  priority="primary"
                  size="small"
                  disabled={!value.trim() || postMessage.isLoading}
                >
                  <Icon icon={send} onClick={onClickSendMessage} style={{ cursor: 'pointer' }} />
                </Button>
              </div>
            </div>
          ) : (
            <Typography className="text_medium__14 color_text_300 my-3 text-center">This chat is now closed</Typography>
          )}
        </div>
      </div>
      {addNewMemberVisible.value && currentThread && (
        <AddMemberModal
          threadId={currentThread.id}
          onClose={addNewMemberVisible.setFalse}
          onAdd={(results) => {
            onGetNewMessage(first(results))
            setMessages((prev) => [...results, ...prev])
          }}
        />
      )}
      {aboutVisible.value && currentThread && (
        <ThreadEditModal
          closeModal={aboutVisible.setFalse}
          onClickResolve={() => {
            aboutVisible.setFalse()
            onClickResolve()
          }}
          thread={currentThread}
          isOwner={currentThread?.is_owner}
        />
      )}
    </>
  )
}
