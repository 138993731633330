/* eslint-disable react/jsx-props-no-spreading */
import Icon from '@atoms/Icon/Icon'
import { emptyAvatar } from '@core/icons/icons'
import React from 'react'

export default function Avatar({ width, height, src, ...rest }) {
  return (
    <div {...rest}>
      {src ? (
        <img
          style={{
            width,
            height,
            borderRadius: '50%',
            objectFit: 'cover',
          }}
          src={src}
          alt="avatar"
        />
      ) : (
        <Icon width={width} height={height} icon={emptyAvatar} />
      )}
    </div>
  )
}
