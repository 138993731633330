import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const getIntegrationAppsList = generateService(async (category) => {
  const { data } = await Api.get('integration-app/list/', {
    params: { category, limit: 1000 },
  })

  return data
})

export const getLinkToken = async (body) => {
  const { data } = await Api.post('integration-app/get-link-token/', body)

  return data
}

export const createApp = async (body) => {
  const { data } = await Api.post('integration-app/create/', body)
  return data
}

export const updateAppPlugins = async (id, body) => {
  const { data } = await Api.put(`integration-app/${id}/update/`, body)
  return data
}

export const deleteApp = async (id) => {
  const { data } = await Api.delete(`integration-app/${id}/delete/`)
  return data
}

export const getGroups = generateService(async (id) => {
  const { data } = await Api.get(`integration-group/list/`, {
    params: { integration_app: id, limit: 1000 },
  })
  return data
})

export const updateGroups = async (body) => {
  const { data } = await Api.put(`integration-group/set-is-turn/`, body)
  return data
}

export const updateAppUsers = async (id, body) => {
  const { data } = await Api.post(`integration-app/${id}/insert-employees/`, body)
  return data
}

export const syncEmployees = async (id) => {
  const { data } = await Api.get(`integration-app/${id}/sync-employees/`)

  return data
}

export const getMergeInformation = generateService(async () => {
  const { data } = await Api.get(`merge-access/get-information/`)

  return data
})
