import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import { searchIcon } from '@core/icons/icons'
import React from 'react'

export default ({ onChange }) => {
  const [value, setValue] = React.useState('')

  const handleChange = (e) => {
    setValue(e.target.value)
    onChange(e.target.value)
  }

  return (
    <div className="mt-3 mb-3">
      <Input
        data-testid="ThreadSearch-0486C2"
        placeholder="Search"
        type="text"
        name="search"
        value={value}
        endIcon={<Icon icon={searchIcon} />}
        onChange={handleChange}
      />
    </div>
  )
}
