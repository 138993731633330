import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { emptyCircle, success } from '@core/icons/icons'
import { getContractsChecklist } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import moment from 'moment'
import React from 'react'
import { useQuery } from 'react-query'
import styled from 'styled-components'

const ContentItem = styled.div`
  height: 56px;
  background: #f9f9f9;
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 16px;
  margin-bottom: 16px;
`

export default function Compilance({ id }) {
  const { data: contractorCheckList, isLoading } = useQuery(getContractsChecklist.key, {
    queryFn: () => getContractsChecklist.fetch(id),
  })
  if (isLoading) {
    return (
      <div className="d-flex w-100 h-100 align-items-center justify-content-center">
        <DotWave size={48} speed={1} color="black" />
      </div>
    )
  }
  return (
    <div className="d-flex flex-column">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <Typography className="heading_semibold__20 color_black">Onboarding checklist</Typography>
      </div>

      <div className="d-flex justify-content-between mb-3">
        <Typography className="text_medium__14 color_grey">Document name</Typography>
        <Typography className="text_medium__14 color_grey">Passed</Typography>
      </div>
      <Item title="Upload contract" data={contractorCheckList?.check_list.uploaded_agreement} />
      <Item title="W-8BEN" data={contractorCheckList?.check_list.w8ben_agreement} />
      <Item title="Verify your identity" data={contractorCheckList?.check_list.sumsub_identity} />
      <Item title="Withdrawal method" data={contractorCheckList?.check_list.withdrawal_method} />
    </div>
  )
}

function Item({ title, data }) {
  return (
    <ContentItem>
      <div className="d-flex align-items-center">
        {data?.passed ? <Icon icon={success} /> : <Icon icon={emptyCircle} />}
        <Typography className="text_medium__14 ml-2">{title}</Typography>
      </div>
      <Typography className="text_light__14 d-flex">
        {data.datetime && moment(data.datetime).format('DD MMM YYYY')}
      </Typography>
    </ContentItem>
  )
}
