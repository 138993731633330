import TextArea from '@atoms/TextArea/TextArea'
import Toggle from '@atoms/Toggle'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { useFormContext } from 'react-hook-form'
import styled from 'styled-components'

const Card = styled.div`
  position: relative;
  width: 520px;
  min-height: 120px;
  background: #f5f5f5;
  border-radius: 8px;
  padding: 20px;
  margin-bottom: 8px;
`
const CardContent = styled.div`
  width: 390px;
`

const CardBody = styled.div`
  background: white;
  padding: 12px;
  border-radius: 8px;
  margin-top: 24px;
`

const TextDescription = styled.div`
  color: #484848;
`

export default ({ ...field }) => {
  const {
    control,
    register,
    watch,
    formState: { errors },
  } = useFormContext()

  const provides_work_equipment_watch = watch('provides_work_equipment')

  return (
    <Card>
      <div className="d-flex">
        <div className="mr-3">
          <Toggle {...field} id="provides_work_equipment" />
        </div>
        <CardContent>
          <Typography className="heading_semibold__18 mb-2">Remofirst to provide work equipment</Typography>
          <TextDescription className="text_regular-normal__14">
            We will purchase and ship the equipment to your international employee and issue the invoice afterwards.
          </TextDescription>
        </CardContent>
      </div>
      {provides_work_equipment_watch && (
        <CardBody>
          <div className="w-100 remo-form-input">
            <TextArea
              {...register('equipment_specification', {
                required: provides_work_equipment_watch && 'Equipment specifications are required',
              })}
              label="Equipment specifications"
              placeholder="Be as specific as possible with the required equipment specification"
            />
            <Typography className="text_light__12 color_text_300 mt-1">
              We’ll send an email to request additional information and provide further steps.
            </Typography>
            {errors.equipment_specification && (
              <Typography className="text_regular__14 color_red">{errors.equipment_specification.message}</Typography>
            )}
          </div>
        </CardBody>
      )}
    </Card>
  )
}
