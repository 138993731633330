import { createGlobalStyle } from 'styled-components'

const KompassifyNotifWidget = createGlobalStyle`
#kompassify-notif-widget {
  display:none !important;
}
`
export const KompassifyNotificationsWrapper = ({ children }) => (
  <>
    <KompassifyNotifWidget />
    <div id="notification-icon-client">{children}</div>
  </>
)
