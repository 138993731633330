import 'react-circular-progressbar/dist/styles.css'

import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import { buildStyles, CircularProgressbarWithChildren } from 'react-circular-progressbar'
import { MdCheck, MdChevronRight } from 'react-icons/md'
import styled from 'styled-components'
import { v4 } from 'uuid'

import styles from './status-stepper.module.scss'

const Title = styled(Typography)`
  color: ${(p) => !p.success && '#878787'};
`

const StyledIcon = styled(MdChevronRight)`
  transform: ${(p) => (p.open ? 'rotate(-90deg)' : 'rotate(90deg)')};
`

export default ({ steps, onStepClick }) => {
  const handleClickStep = (step) => () => {
    if (step.content && step.success && onStepClick) {
      onStepClick(step) // TODO: please check why we are not sending this prop
    }
  }

  return (
    <div className="d-flex flex-column">
      {steps.map((s, index) => (
        <div key={v4()} className={styles.container}>
          {s.success && (
            <div className={styles.success}>
              <MdCheck color="#04c53a" fontSize={22} />
            </div>
          )}
          {!s.success && (
            <div className={styles.step}>
              <CircularProgressbarWithChildren
                value={s.progress}
                strokeWidth={8}
                styles={buildStyles({
                  strokeLinecap: 'butt',
                  pathColor: '#CCCCCC',
                })}
              />
            </div>
          )}
          <div
            className={classNames(styles.content, {
              [styles.divider]: index !== steps.length - 1,
            })}
          >
            <Title
              success={s.success || s.active ? 1 : 0}
              className={classNames('text_medium__14 mt-1', {
                [styles.expandable]: s.content && s.success,
              })}
              role={s.content && s.success ? 'button' : ''}
              onClick={handleClickStep(s)}
            >
              {s.title}
              {s.content && s.success && <StyledIcon size="24" open={s.active} />}
            </Title>
            {s.active && s.content && <div className="mt-2">{s.content}</div>}
          </div>
        </div>
      ))}
    </div>
  )
}
