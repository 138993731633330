import './index.scss'

import { useAxiosCall } from '@api/Api'
import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Select from '@atoms/Select/Select'
import Status from '@atoms/Status/Status'
import Typography from '@atoms/Typography/Typography'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { useSearchParams } from '@core/hooks/useRouteQuery'
import { buildingBankRegularIcon, trash } from '@core/icons/icons'
import CreatePaymentModal from '@pages/stripe/CreatePaymentModal'
import { invoicePaymentMethods } from '@services/aggregated-invoices.service'
import {
  autoPaymentMethods,
  deletePaymentMethods,
  fetchPaymentMethods,
  postPaymentMethods,
  primaryPaymentMethods,
} from '@services/stripe.service'
import { Elements } from '@stripe/react-stripe-js'
import { loadStripe } from '@stripe/stripe-js'
import { DotWave } from '@uiball/loaders'
import moment from 'moment/moment'
import { useEffect, useState } from 'react'
import { useMutation, useQuery } from 'react-query'
import { useBoolean } from 'usehooks-ts'

import { PaymentMethodAddModal } from './payment-method-add/payment-method-add.modal'
import { PaymentMethodInfoNotRemove } from './payment-method-remove/payment-method-info-not-remove.component'
import { PaymentMethodRemoveModal } from './payment-method-remove/payment-method-remove.modal'
import { SuccessStripeModal } from './SuccessStripeModal'

const stripePromise = loadStripe(`${process.env.REACT_APP_STRIPE_PUBLIC_KEY}`)

const FilterStates = [
  {
    value: 'wire_transfer',
    label: 'Yes, I’ve made a wire payment',
    payment_method: 'Wire transfer',
  },
  {
    value: 'ach',
    label: 'No, I haven’t made a wire payment',
    payment_method: 'ACH',
  },
  { value: 'idk', label: 'I don’t know' },
]

const FilterByKey = {
  wire_transfer: 'Wait for wire payment',
  ach: 'Use direct debit ',
  idk: 'Please contact finance@remofirst.com',
}

export const PaymentInfo = () => {
  const { profile, refetchProfile } = useApp()
  const { params } = useSearchParams()
  const { successAlert } = useToast()
  const [payment, setPayment] = useState()
  const [nextPayment, setNextPayment] = useState()
  const newPaymentModalState = useBoolean(Boolean(false))
  const confirmDelete = useBoolean(false)
  const stripeSuccess = useBoolean(false)
  const confirmPrimary = useBoolean(false)
  const confirmInvoice = useBoolean(false)
  const modalAutoPayment = useBoolean(false)
  const successModalOpen = useBoolean(false)
  const visibleNotication = useBoolean(true)
  const infoNotRemoveModalOpen = useBoolean(false)
  const [newPayment, setNewPayment] = useState()
  const [selectedValues, setSelectedValues] = useState({})

  const [setupIntent, setSetupIntent] = useState(null)
  const {
    data: paymentMethods,
    isLoading,
    isFetching,
    refetch,
  } = useQuery('getPaymentMethods', {
    queryFn: fetchPaymentMethods,
  })
  const paymentMethodsList = paymentMethods?.filter((paymentMethod) => paymentMethod.payment_method?.created)

  const options = {
    // passing the client secret obtained from the server
    clientSecret: setupIntent?.client_secret,
  }

  const [{ data }] = useAxiosCall({
    url: `aggregated-invoices/?limit=99999&offset=0&company_id=${profile.id}&status=created&type=payments`,
  })

  const primaryPaymentRequest = useMutation(['primaryPaymentMethods', data], {
    mutationFn: (payload) => primaryPaymentMethods(payload),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated')
      setPayment(undefined)
      confirmPrimary.setFalse()
      if (data?.results?.length > 0 && profile.enabled_automatic_pay_for_fte) {
        confirmInvoice.setTrue()
      }
    },
  })

  const autoPaymentRequest = useMutation(['autoPaymentMethods', data], {
    mutationFn: (payload) => autoPaymentMethods(payload),
    onSuccess: () => {
      refetchProfile()
      modalAutoPayment.setFalse()
      if (data?.results?.length > 0) {
        confirmInvoice.setTrue()
      }
    },
  })

  const invoicePaymentMethodsMutate = useMutation('invoicePaymentMethods', {
    mutationFn: ({ id, body }) => invoicePaymentMethods(id, body),
    onSuccess: () => {
      confirmInvoice.setFalse()
      setSelectedValues({})
    },
  })
  const deletePaymentRequest = useMutation(['deletePaymentMethods', paymentMethodsList], {
    mutationFn: ({ id, body }) => deletePaymentMethods(id, body),
    onSuccess: () => {
      refetch()
      successAlert('Successfully deleted')
      confirmDelete.setFalse()
      if (nextPayment) {
        primaryPaymentRequest.mutate(nextPayment)
      }
      setPayment(undefined)
    },
    onError: () => {
      infoNotRemoveModalOpen.setTrue()
    },
  })

  const addPayment = useMutation('postPaymentMethods', {
    mutationFn: () => postPaymentMethods(),
    onSuccess: (response) => {
      setSetupIntent(response)
    },
  })

  const onAddMethod = () => {
    addPayment.mutate()
    newPaymentModalState.setTrue()
  }

  const onVerify = (link) => () => {
    window.open(link)
  }
  const onClickDelete = (paymentMethod) => () => {
    setPayment(paymentMethod)
    confirmDelete.setTrue()
    if (paymentMethodsList.length === 2) {
      setNextPayment(paymentMethodsList.find((f) => f.payment_method.id !== paymentMethod.id)?.payment_method.id)
    }
  }
  const onMakePrimary = (paymentMethod) => () => {
    setPayment(paymentMethod)
    confirmPrimary.setTrue()
  }
  const onApproveDelete = () => {
    deletePaymentRequest.mutate({
      id: payment.id,
      body: { is_default: payment.metadata.default },
    })
  }
  const onApprovePrimary = () => {
    primaryPaymentRequest.mutate(payment.id)
  }
  const onApproveInvoice = () => {
    Object.keys(selectedValues).forEach((selectedValue) => {
      if (selectedValues[selectedValue] && selectedValues[selectedValue] !== 'idk') {
        invoicePaymentMethodsMutate.mutate({
          id: selectedValue,
          body: {
            payment_method: selectedValues[selectedValue],
          },
        })
      }
    })
  }

  const onSelectStatus = (id, value) => {
    setSelectedValues((prevValues) => ({
      ...prevValues,
      [id]: value,
    }))
  }

  const getFiltersState = (invoice) => {
    return FilterStates.filter((filterState) => filterState.payment_method !== invoice.payment_method)
  }

  useEffect(() => {
    if (params.get('redirect_status')) {
      stripeSuccess.setTrue()
    }
  }, [params])

  useEffect(() => {
    if (!confirmDelete.value) {
      setNextPayment(undefined)
    }
  }, [confirmDelete.value])

  useEffect(() => {
    if (modalAutoPayment.value) {
      autoPaymentRequest.mutate({ enabled_automatic_pay: true })
    }
  }, [modalAutoPayment.value])

  if (isLoading || primaryPaymentRequest.isLoading || isFetching)
    return (
      <div className="d-flex w-100  align-items-center justify-content-center" style={{ height: '100vh' }}>
        <DotWave size={48} speed={1} color="black" />
      </div>
    )

  return (
    <div className="payment-card">
      <div className="d-flex justify-content-between align-items-center w-100">
        <div className="d-flex flex-column">
          <Typography className="heading_semibold__18">My payment methods</Typography>
        </div>
      </div>
      {visibleNotication.value && (
        <NotificationBlock
          styledClass="notification-info"
          onClose={visibleNotication.setFalse}
          render={
            <Typography className="text_light__12">
              Thanks for using ACH Direct Debit for your full-time employees payroll payments. Our team are working hard
              on contractor payment via Direct Debit and will notify you once it is ready;
              <br />
              meanwhile please use payment details on invoices to settle contractor payments.
            </Typography>
          }
        />
      )}
      {paymentMethods?.length > 0 && paymentMethodsList?.length > 0 ? (
        <div className="d-flex flex-wrap">
          {paymentMethodsList.map((paymentMethod) => (
            <div className="payment-methods mr-2 mb-2" key={paymentMethod.id}>
              <div>
                {paymentMethod?.next_action?.type === 'verify_with_microdeposits' && (
                  <div className="d-flex justify-content-between align-items-center mb-2">
                    <Status status="awaiting_verification" />
                    <Button
                      data-testid="PaymentInfo-28AA79"
                      style={{ height: 28 }}
                      type="button"
                      size="small"
                      priority="primary"
                      onClick={onVerify(paymentMethod?.next_action.verify_with_microdeposits.hosted_verification_url)}
                    >
                      Verify
                    </Button>
                  </div>
                )}
              </div>
              <div className="d-flex w-100">
                <Icon icon={buildingBankRegularIcon} />
                <div className="w-100 ml-2">
                  <div className="d-flex justify-content-between align-items-center w-100">
                    <Typography className="heading_semibold__16 d-flex gap-2">
                      ACH Direct Debit
                      {paymentMethod.payment_method.metadata.default && <Status status="default" />}
                    </Typography>
                    {!paymentMethod.payment_method.metadata.default && paymentMethod.status === 'succeeded' && (
                      <Typography
                        className="text_medium__14 color_red cursor-pointer"
                        onClick={onMakePrimary(paymentMethod.payment_method)}
                        style={{ cursor: 'pointer' }}
                      >
                        Make your default
                      </Typography>
                    )}
                  </div>
                  <div className="d-flex justify-content-between align-items-center mt-2">
                    <div>
                      <Typography className="text_light__12 color_grey">
                        Swift ending in {paymentMethod?.payment_method?.us_bank_account?.last4} | USD
                      </Typography>
                    </div>
                    {paymentMethod.status === 'succeeded' &&
                      (paymentMethodsList.length > 1 || !profile.enabled_automatic_pay_for_fte) && (
                        <Button
                          data-testid="PaymentInfo-EB5307"
                          priority="outlined"
                          size="small"
                          className="p-0 h-auto"
                          onClick={onClickDelete(paymentMethod.payment_method)}
                        >
                          <Icon icon={trash} fill="#ADADAD" />
                        </Button>
                      )}
                  </div>
                </div>
              </div>
            </div>
          ))}
          <Button
            data-testid="PaymentInfo-2F7CD4"
            type="button"
            size="small"
            priority="primary"
            className="border-0"
            loading={addPayment.isLoading}
            onClick={onAddMethod}
          >
            + Add payment method
          </Button>
        </div>
      ) : (
        <div className="payment-info">
          <div className="d-flex align-items-center justify-content-between w-100">
            <div className="d-flex align-items-center">
              <img width="79" src="/assets/img/card.png" alt="card" />
              <div>
                <Typography className="text_regular__14">Automatically pay off your invoices</Typography>
                <Typography className="text_light__13 color_text_300 mt-2">
                  Set up direct debit so that payments of eligible payroll invoices and contractor bills are
                  automatically settled.
                </Typography>
              </div>
            </div>
            <Button
              data-testid="PaymentInfo-8F979B"
              type="button"
              size="small"
              priority="primary"
              className="border-0"
              loading={addPayment.isLoading}
              onClick={onAddMethod}
            >
              + Add payment method
            </Button>
          </div>
        </div>
      )}

      {(setupIntent || stripeSuccess.value) && (
        <Elements stripe={stripePromise} options={options}>
          {newPaymentModalState.value && <CreatePaymentModal onClose={newPaymentModalState.setFalse} />}
          {stripeSuccess.value && (
            <SuccessStripeModal
              close={stripeSuccess.setFalse}
              refetch={refetch}
              isLoading={isLoading}
              setNewPayment={setNewPayment}
              successModalOpen={successModalOpen}
            />
          )}
        </Elements>
      )}

      {confirmDelete.value && (
        <PaymentMethodRemoveModal
          nextPayment={nextPayment}
          paymentMethodsList={paymentMethodsList}
          setNextPayment={setNextPayment}
          isLoading={deletePaymentRequest.isLoading}
          onCancel={confirmDelete.setFalse}
          payment={payment}
          disabled={payment?.metadata?.default && paymentMethodsList?.length > 2 && !nextPayment}
          onConfirm={onApproveDelete}
        />
      )}

      <PaymentMethodInfoNotRemove modalState={infoNotRemoveModalOpen} />

      {confirmPrimary.value && (
        <Confirm
          title="Set as default"
          isLoading={deletePaymentRequest.isLoading}
          onCancel={confirmPrimary.setFalse}
          onYes={onApprovePrimary}
          yesButtonProps={{
            text: 'Understood',
            priority: 'primary',
          }}
        >
          <Typography className="color_grey">
            Are you sure you want to set the payment method{' '}
            {`ACH Direct Debit, Swift ending in ${payment?.us_bank_account?.last4} in USD`} as your new default payment
            method?
            <br />
            <br />
            The current default payment method{' '}
            {`ACH Direct Debit, Swift ending in ${
              paymentMethodsList.find((f) => f.payment_method.metadata.default === 'True')?.payment_method
                .us_bank_account?.last4
            } in USD`}{' '}
            will no longer be your default payment method.
          </Typography>{' '}
        </Confirm>
      )}

      {confirmInvoice.value && (
        <Confirm
          title="Outstanding invoice"
          dialogClassName="modal-invoice"
          onCancel={confirmInvoice.setFalse}
          onYes={onApproveInvoice}
          yesButtonProps={{
            text: 'Confirm',
            priority: 'primary',
          }}
        >
          <Typography className="color_grey">Thanks for setting up ACH Direct Debit payment method.</Typography>
          <Typography className="color_grey">
            We have noticed that you have {3} outstanding invoices as listed below.
          </Typography>
          <Typography className="color_grey">
            We have noticed that you have {data?.results?.length} outstanding invoices as listed below.
          </Typography>
          <Typography className="color_grey">
            Please indicate if you have already made wire payment for any of the invoices by switching from No to Yes.
          </Typography>{' '}
          <div className="invoice-table mt-3">
            <div className="invoice-table-header">
              <div className="head-title w-15">Invoice No.</div>
              <div className="head-title w-15">Amount</div>
              <div className="head-title w-15">Month</div>
              <div className="head-title w-25">Wire payment made already</div>
              <div className="head-title w-25">How we process</div>
            </div>
            {data?.results?.map((result) => (
              <div className="invoice-table-header">
                <div className="body-title w-15">{result.id}</div>
                <div className="body-title w-15">
                  {result.total} {result.currency?.sign || result.currency?.short_code}
                </div>
                <div className="body-title w-15">{moment(result.date).format('MMMM')}</div>
                <div className="body-title w-25">
                  <Select
                    data-testid="PaymentInfo-460E2F"
                    placeholder="Select status"
                    isClearable
                    value={selectedValues[result.id] || undefined}
                    onChange={(e) => onSelectStatus(result.id, e.value)}
                    options={getFiltersState(result).map((state) => {
                      return {
                        value: state.value,
                        label: state.label,
                      }
                    })}
                  />
                </div>
                <div className="body-title w-25">
                  {selectedValues[result.id] && FilterByKey[selectedValues[result.id]]}
                </div>
              </div>
            ))}
          </div>
        </Confirm>
      )}

      <PaymentMethodAddModal
        newPaymentMethod={newPayment}
        paymentMethodsList={paymentMethodsList}
        modalState={successModalOpen}
        refetch={refetch}
        modalAutoPayment={modalAutoPayment}
        modalInvoiceState={confirmInvoice}
        outstandInvoice={data?.results.length > 0}
      />
    </div>
  )
}
