import './style.scss'

import Typography from '@atoms/Typography/Typography'
import { getContractDepositInvoice } from '@services/contract.service'
import { DotWave } from '@uiball/loaders'
import { useQuery } from 'react-query'

import NotGenerated from './NotGenerated'

export default function Deposit({ employee }) {
  const { isFetching, data } = useQuery([getContractDepositInvoice.key], {
    queryFn: () => getContractDepositInvoice(employee.id),
    enabled: employee.onboarding_stage.substage.deposit_invoice_issued_by_cs === 'completed',
  })

  return employee.onboarding_stage.substage.deposit_invoice_issued_by_cs !== 'completed' ? (
    <div className="not-generated-container">
      <div className="svg-container">
        <NotGenerated />
      </div>
      <Typography className="text-heading">We’re working hard on it</Typography>
      <Typography className="text">Deposit invoice will be available here once available</Typography>
    </div>
  ) : (
    <div>
      {!isFetching && data?.aggregated_invoice?.file ? (
        <embed
          src={`${data?.aggregated_invoice?.file}#toolbar=0`}
          type="application/pdf"
          height="1000px"
          width="750px"
        />
      ) : (
        <div className="d-flex w-100 h-100 align-items-center justify-content-center">
          <DotWave size={48} speed={1} color="black" />
        </div>
      )}
    </div>
  )
}
