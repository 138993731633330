import { getPaymentsTotal, SplitNumber } from '@core/utils'
import moment from 'moment'

export const ContractorWageType = {
  monthly: 'monthly',
  hourly: 'hr',
  daily: 'd',
  milestone: 'milestone',
}

const displayRate = (contract) => {
  if (contract.contractor_wage_type === 'milestone') {
    return 'Milestone'
  }
  return `${contract.contract_currency_sign || contract.contract_currency} ${SplitNumber(contract.rate)}/${
    ContractorWageType[contract.contractor_wage_type]
  }`
}

export const payContractorsTableParse = (contracts) =>
  contracts.map((contract) => {
    return {
      ...contract,
      id: contract.id,
      avatar: contract.avatar,
      full_name: `${contract.first_name} ${contract.last_name}`,
      worked_hours: `${contract.contract_currency_sign || contract.contract_currency} ${SplitNumber(
        contract.payment_amount
      )}`,
      worked_hours_time: `(${moment(contract.start_date).format('DD MMM')} - ${moment(contract.end_date).format(
        'DD MMM'
      )})`,
      rate: displayRate(contract),
      additional_payment: `${contract.contract_currency_sign || contract.contract_currency} ${SplitNumber(
        getPaymentsTotal(contract.additional_payments, true)
      )}`,
      total_number: contract.total,
      total: `${contract.contract_currency_sign || contract.contract_currency} ${SplitNumber(contract.total)}`,
      payment_provider_fee: contract.payment_provider_fee,
      worked_hours_num: contract.worked_hours,
      rate_num: contract.rate,
      created_by: contract.employee ? 'Created by contractor' : null,
      contractor_wage_type: contract.contractor_wage_type,
      currency: contract.contract_currency_sign || contract.contract_currency,
      currency_company: contract.company_currency_sign,
      add_payment: contract.additional_payment_detailed,
      isDisabled: contract?.contract_onboarding_status === 'onboarding',
      tooltipText: 'This contractor must complete their onboarding before a payment is made',
    }
  })
