import Button from '@atoms/Button/Button'
import ActionModal from '@components/action-modal/action-modal.component'
import { InfoMessage } from '@components/info-message/info-message.component'
import { useApp } from '@core/context'
import {
  TIME_OFF_POLICY_FORM_ID,
  TimeOffPolicyForm,
} from '@features/time-off/time-off-policy-form/time-off-policy-form.component'
import { parsePayload } from '@features/time-off/time-off-policy-form/time-off-policy-form.utils'
import { TimeOffPolicyModal } from '@features/time-off/time-off-policy-modal/time-off-policy-modal.component'
import ListHolidaysModal from '@pages/time-off/ListHolidaysModal'
import { getTimeOffPolicy, updateTimeOffPolicy } from '@services/company.service'
import { DotWave } from '@uiball/loaders'
import { useMutation, useQuery } from 'react-query'
import { Link } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

import { Styled } from './time-off-policy-tab.styles'

export const TimeOffPolicyTab = () => {
  const { profile } = useApp()
  const changeRequestModalState = useBoolean(false)
  const publicHolidaysModalState = useBoolean(false)

  const { isLoading, data, refetch } = useQuery([getTimeOffPolicy.key, profile.id], {
    queryFn: () => getTimeOffPolicy.fetch(profile.id),
  })

  const { mutate, isLoading: isUpdating } = useMutation({
    mutationFn: (payload) => updateTimeOffPolicy.fetch(profile.id, payload),
    onSuccess: () => {
      changeRequestModalState.setTrue()
      refetch()
    },
  })

  const handleSubmit = (values) => {
    mutate(parsePayload(values))
  }

  if (isLoading) {
    return (
      <div className="d-flex justify-content-center align-items-center p-5">
        <DotWave />
      </div>
    )
  }

  return (
    <Styled.Container>
      <InfoMessage message="Time off regulations can very from country to country. Our local teams will do their best to accommodate your desired Time Off policies, but when this is not possible, we will contact you to clarify what your options are. " />
      <TimeOffPolicyForm onSubmit={handleSubmit} data={data} />
      <InfoMessage
        message={
          <span>
            In addition to the paid time off and sick leave days specified above, the employee is also entitled to
            regional/national public holidays depending on their region, excluding weekends.
            <Styled.LinkButton type="button" onClick={publicHolidaysModalState.setTrue}>
              See country time off guide
            </Styled.LinkButton>
          </span>
        }
      />
      <Button
        type="submit"
        form={TIME_OFF_POLICY_FORM_ID}
        priority="primary"
        size="small"
        className="mt-4"
        loading={isUpdating}
        disabled={isUpdating}
      >
        Request change
      </Button>
      {changeRequestModalState.value && <TimeOffPolicyModal onClose={changeRequestModalState.setFalse} />}
      {publicHolidaysModalState.value && <ListHolidaysModal onClose={publicHolidaysModalState.setFalse} />}
    </Styled.Container>
  )
}
