import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Route } from 'react-router-dom'

export const ProtectedRoute = ({ permitted, children, path = '', ...rest }) => {
  if (!permitted)
    return (
      <Route
        path={path}
        element={
          <Typography className="text_regular-normal__14">You do not have permission to view this page</Typography>
        }
        {...rest}
      />
    )
  return children
}
