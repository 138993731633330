export const PAYMENT_FREQUENCY = [
  { value: 'weekly', label: 'Weekly' },
  { value: 'monthly', label: 'Monthly' },
  { value: 'semi_monthly', label: 'Semi-monthly' },
  { value: 'biweekly', label: 'Bi-weekly' },
  { value: 'one_time_payment', label: 'None' },
]

export const PaymentValue = {
  weekly: 'Weekly',
  monthly: 'Monthly',
  semi_monthly: 'Semi-monthly',
  one_time_payment: 'One time payment',
  biweekly: 'Bi weekly',
}

export const PaymentMethods = {
  nium: 'nium',
  ach: 'ach',
  wise: 'wise',
}
export const PaymentMethodsLabel = {
  [PaymentMethods.nium]: 'Nium',
  [PaymentMethods.ach]: 'ACH',
  [PaymentMethods.wise]: 'Wire transfer',
}

export const fieldNameMap = {
  name: 'Name',
  firstName: 'First name',
  lastName: 'Last name',
  middleName: 'Middle name',
  email: 'Email',
  jobTitle: 'Job title',
  jobDescription: 'Description of work',
  department: 'Department',
  phoneNumber: 'Phone number',
  companyName: 'Legal company name',
  country: 'Country',
  currency: 'Currency',
  receivingCurrency: 'Receiving currency',
  headquarters: 'Headquarters',
  state: 'State',
  homeState: 'Region/State/Province',
  streetAddress: 'Street address',
  workingCountry: 'Country of hire',
  addressLine: 'Address line',
  city: 'City',
  postalCode: 'Zip / Postal code',
  startDate: 'Start date',
  endDate: 'End date',
  rateType: 'Rate type',
  contractCurrency: 'Contract currency',
  contractorRate: 'Contractor rate',
  paymentFrequency: 'Payment frequency',
  contractTermName: 'Contract term',
  registeredAddress: 'Registered address',
  cycleStartDate: 'Payment cycle start date',
}

export const SEND_TYPES = {
  SELECT: 'SELECT',
  INVITE: 'INVITE',
}

export const TEMPLATE_TYPES = {
  JOB_CONTRACT: 'job_contract',
  SALARY_CHANGE: 'salary_change',
  CONTRACT_TERMINATION: 'contract_termination',
  MSA: 'msa',
  APPENDIX: 'appendix',
  CONTRACTOR_EMPLOYMENT: 'contractor_employment',
  AOR_CONTRACT: 'aor_contract',
  COMPLIANCE: 'compliance',
}

export const EOR_TYPES = {
  TRADITIONAL: 'traditional_eor',
  SAAS: 'saas_eor',
}
export const PARENTAL_LEAVE_TYPE = {
  MATERNITRY: 'maternity_days',
  PATERNITY: 'paternity_days',
}

export const COMPANY_STATES = {
  ONBOARDING: 'onboarding',
  SIGNING: 'signing',
  CREATED: 'created',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
  BEING_VERIFIED: 'being_verified',
  MSA_SIGNED: 'msa_signed',
}

export const EMPLOYEE_STATES = {
  ONBOARDING: 'onboarding',
  CREATED: 'created',
  SIGNING: 'signing',
  ACTIVE: 'active',
  INACTIVE: 'inactive',
  ARCHIVED: 'archived',
  BEING_VERIFIED: 'being_verified',
  COMPLETED: 'completed',
  DRAFT: 'draft',
  INVITED: 'invited',
}

export const CONTRACT_TYPE = {
  FULL_TIME_EMPLOYEE: 'full_time_employee',
  CONTRACTOR: 'contractor',
}

export const SIGNATURE_STATES = {
  AWAITING: 'awaiting_signature',
  SIGNED: 'signed',
}

export const REMO_HEALTH_PACKAGE = {
  CORE: 'core',
  STANDARD: 'standard',
  PREMIUM: 'premium',
  PLUS: 'plus',
}

export const BENEFIT_TYPES = {
  EQUIPMENT_DELIVERY: 'equipment_delivery',
  REMOHEALTH: 'remohealth',
}

export const REMOHEALTH_STATUSES = {
  CREATED: 'created',
  ACTIVE: 'active',
  CANCELED: 'canceled',
  EXPIRED: 'expired',
  SUBMITTED: 'app_submitted',
  ACCEPTED: 'app_accepted',
  MEDICAL_OUTSTANDING: 'medical_outstanding',
  INFO_OUTSTANDING: 'info_outstanding',
}

export const PAYROLL_STATUSES = {
  CREATED: 'CREATED',
  APPROVED_BY_COMPANY: 'APPROVED_BY_COMPANY',
  REJECTED: 'REJECTED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  DELETED: 'DELETED',
  MOVED_TO_NEXT_MONTH: 'MOVED_TO_NEXT_MONTH',
}

export const EXPENSE_STATUSES = {
  CREATED: 'CREATED',
  APPROVED_BY_COMPANY: 'APPROVED_BY_COMPANY',
  REJECTED: 'REJECTED',
  CANCELLED: 'CANCELLED',
  PENDING: 'PENDING',
  PAID: 'PAID',
  AWAITING_PAYMENT: 'AWAITING_PAYMENT',
  DELETED: 'DELETED',
  MOVED_TO_NEXT_MONTH: 'MOVED_TO_NEXT_MONTH',
}

export const USER_GROUPS = {
  FINANCE_SPECIALIST: 1,
  PTO_VIEWER: 2,
  PTO_MANAGER: 3,
  EXPENSE_VIEWER: 4,
  EXPENSE_MANAGER: 5,
  FINANCE_AP_SPECIALIST: 6,
  HR_SPECIALIST: 7,
  SUPERUSER: 8,
}

export const RESIDENCY_TYPES = {
  RESIDENT: 'resident',
  NON_RESIDENT: 'non_resident',
}

export const PRONOUNS = {
  SHE_HER: 'she/her',
  HE_HIM: 'he/him',
  THEY_THEM: 'they/them',
  PREFER_NOT_TO_SAY: 'Prefer not to say',
}

export const MASS_IMPORT_STATE = {
  UPLOAD_CSV: 'upload_csv',
  VALIDATE_DATA: 'validate_data',
  CONFIRM_IMPORT: 'confirm_import',
  UPLOAD_WORK_AGREEMENT: 'upload_work_agreement',
  COMPLETED: 'completed',
}

export const TIME_OFF_STATUSES = {
  APPROVED: 'APPROVED',
  CANCELED: 'CANCELED',
  PENDING: 'PENDING',
  REJECTED: 'REJECTED',
  TAKEN: 'TAKEN',
}

export const TIME_OFF_STATUSES_MAP = {
  PENDING: 'Pending',
  APPROVED: 'Approved',
  REJECTED: 'Rejected',
  CANCELED: 'Canceled',
  TAKEN: 'Taken',
}

export const TIME_OFF_LEAVE_TYPES = {
  VACATION: 'Vacation',
  SICK_LEAVE: 'Sick leave',
  PARENTAL_LEAVE: 'Parental leave',
  BEREAVEMENT: 'Bereavement',
  CAREGIVER_LEAVE: 'Caregiver leave',
  CIVIC_DUTIES: 'Civic duties',
  EDUCATIONAL_LEAVE: 'Educational leave',
  MARRIAGE_LEAVE: 'Marriage leave',
  MILITARY_LEAVE: 'Military leave',
  MILITARY_MEDICAL_EXAMINATION: 'Military medical examination',
  MILITARY_TRAINING_ASSEMBLY: 'Military training assembly',
  RELIGIOUS_OBSERVANCE: 'Religious observance',
  SABBATICAL: 'Sabbatical',
}

export const BALANCE_BREAKDOWN_TYPES = {
  VACATION: 'vacation',
  ADHOC: 'adhoc',
  ANNUAL_ENTITLEMENT: 'annual_entitlement',
  CARRYOVER: 'carryover',
}

export const BALANCE_BREAKDOWN_TYPES_MAP = {
  vacation: 'Vacation',
  adhoc: 'Ad-hoc',
  annual_entitlement: 'Annual entitlement',
  carryover: 'Carryover',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES = {
  USER_GENERATED: 'user_generated',
  AUTO_GENERATED: 'auto_generated',
}

export const BALANCE_BREAKDOWN_ACTION_TYPES_MAP = {
  user_generated: 'User Generated',
  auto_generated: 'Auto Generated',
}

export const USER_ROLES_MAP = {
  company_owner: `Company Admin`,
  'company owner': `Company Admin`,
  company_admin: `Manager`,
  partner_owner: `Partner Admin`,
  partner_manager: `Partner Manager`,
  admin: `System Admin`,
  dummy: `System Admin`,
  employee: `Employee`,
}

export const LeaveType = {
  PAID: 'PAID',
  UNPAID: 'UNPAID',
}

export const CANCEL_ONBOARDING_REASONS_MAP = {
  ee_declined_offer: 'EE declined offer',
  hiring_with_another_eor: 'Hiring with Another EOR',
  hiring_as_contractor: 'Hiring as a contractor',
  visa_issue: 'Visa issues',
  failed_background_check: 'Failed Background Check',
  non_responsive_ee: 'Non-Responsive EE',
  decided_not_to_hire_this_ee: 'Decided not to hire this EE',
  position_canceled: 'Position canceled',
  OTHER: 'Other',
}

export const SUB_STATE_CHANGE_REQUEST_TYPES = {
  OFFBOARDING: 'offboarding',
  CANCELLED: 'cancelled',
  ON_HOLD: 'on_hold',
}

export const AP_TYPE = {
  BONUS: 'BONUS',
  ALLOWANCE: 'ALLOWANCE',
  OTHER: 'OTHER',
}

export const RECURRENCE_FREQUENCY_TYPES = {
  ONE_TIME: 'ONE_TIME',
  MONTHLY: 'MONTHLY',
  EVERY_SECOND_MONTH: 'EVERY_SECOND_MONTH',
  QUARTERLY: 'QUARTERLY',
}

export const RECURRENCE_FREQUENCY_TYPES_MAP = {
  ONE_TIME: 'One-time',
  MONTHLY: 'Monthly',
  EVERY_SECOND_MONTH: 'Every 2 months',
  QUARTERLY: 'Every 3 months',
}

export const DEFAULT_CURRENCIES = {
  USD: 'USD',
  CAD: 'CAD',
  EUR: 'EUR',
  GBP: 'GBP',
  AUD: 'AUD',
  SGD: 'SGD',
  NZD: 'NZD',
}

export const CONTRACT_FIELDS = [
  'first_name',
  'middle_name',
  'last_name',
  'birth_date',
  'living_country',
  'street_address',
  'state',
  'city',
  'postal_code',
  'address_line',
  'home_living_country',
  'home_street_address',
  'home_state',
  'home_city',
  'home_postal_code',
  'home_address_line',
  'position',
  'position_description',
  'currency',
  'receiving_currency',
  'contractor_rate',
  'contractor_wage_type',
  'working_country',
  'contractor_payment_frequency',
  'requested_starts_at',
  'ends_at',
]
export const KOMPASSIFY_SURVEYS = {
  ContractorAdded: '037353bd-99e2-4814-a7a0-3f00f6295e8b',
  ContractCreated: 'e27870cd-d596-4fc6-8c9f-f1d8deb4320b',
  InvoiceCreated: '16301256-68a3-49c9-a527-9be33c3616c1',
}

export const EMPLOYEE_PAY_TYPE = {
  Salaried: 'salaried',
  Hourly: 'hourly',
}

export const EMPLOYEE_PAYMENT_FREQUENCY_MAP = {
  monthly: 'Monthly',
  semi_monthly: 'Semi-monthly',
  weekly: 'Weekly',
  every_two_weeks: 'Every 2 weeks',
}
