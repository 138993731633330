import styled from 'styled-components'

const OrderedList = styled.ol`
  padding-left: 20px;
`

const BoldListItem = styled.li`
  font-weight: bold;
`

export const Styled = {
  OrderedList,
  BoldListItem,
}
