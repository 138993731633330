import Button from '@atoms/Button/Button'
import DateInput from '@atoms/DateInput/DateInput'
import Typography from '@atoms/Typography/Typography'
import { AttachmentDropzone } from '@components/attachment/attachment-dropzone/attachment-dropzone'
import moment from 'moment'
import Papa from 'papaparse'
import { useCallback, useState } from 'react'
import { Controller, useForm } from 'react-hook-form'

export const UploadCSV = ({ onNext }) => {
  const [csvColumns, setCsvColumns] = useState([])
  const [csvData, setCsvData] = useState([])
  const [csvFile, setCsvFile] = useState(null)
  const [fileError, setFileError] = useState('')

  const {
    control,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm()

  const startDate = watch('startDate')
  const endDate = watch('endDate')

  const handleClickNext = (formValues) => {
    if (!csvFile) {
      setFileError('Please upload a CSV file.')
      return
    }
    setFileError('')
    onNext({
      startDate: moment(formValues.startDate).format('YYYY-MM-DD'),
      endDate: moment(formValues.endDate).format('YYYY-MM-DD'),
      csvColumns,
      csvData,
      csvFile,
    })
  }

  const handleFileDrop = useCallback(([file]) => {
    setCsvFile(file)
    setFileError('')
    Papa.parse(file, {
      header: true,
      skipEmptyLines: true,
      complete: (result) => {
        const columns = Object.keys(result.data[0])
        setCsvColumns(columns)
        setCsvData(result.data)
      },
      error: (error) => {
        console.error('Error parsing CSV file:', error.message)
      },
    })
  }, [])

  const handleRemoveFile = () => setCsvFile(null)

  return (
    <form className="d-flex flex-column h-100" onSubmit={handleSubmit(handleClickNext)}>
      <section className="flex-grow-1 d-flex gap-4 flex-column justify-content-center align-items-between">
        <div className="d-flex gap-3 justify-content-between">
          <div>
            <Controller
              control={control}
              name="startDate"
              rules={{
                required: 'Start date is required',
              }}
              render={({ field }) => {
                return (
                  <DateInput
                    label="Start date"
                    disabled={field.disabled}
                    value={field.value}
                    isRequired
                    onChange={(date) => {
                      field.onChange(date)
                    }}
                    maxDate={endDate ? moment(endDate).toDate() : undefined}
                  />
                )
              }}
            />
            {errors.startDate && (
              <Typography className="text_regular__14 color_red">{errors.startDate.message}</Typography>
            )}
          </div>
          <div>
            <Controller
              control={control}
              name="endDate"
              rules={{
                required: 'End date is required',
              }}
              render={({ field }) => {
                return (
                  <DateInput
                    label="End date"
                    disabled={field.disabled}
                    value={field.value}
                    isRequired
                    onChange={(date) => {
                      field.onChange(date)
                    }}
                    minDate={startDate ? moment(startDate).toDate() : undefined}
                  />
                )
              }}
            />
            {errors.endDate && <Typography className="text_regular__14 color_red">{errors.endDate.message}</Typography>}
          </div>
        </div>
        <div>
          <Typography>
            Upload your timesheet csv file <span style={{ color: 'red' }}>*</span>
          </Typography>
          <AttachmentDropzone
            multiple={false}
            accept={{ 'text/csv': ['.csv'] }}
            file={csvFile}
            onDrop={handleFileDrop}
            onDelete={handleRemoveFile}
          >
            Supported file formats: .csv; file size up to 50 MB
          </AttachmentDropzone>
          {fileError && <Typography className="text_regular__14 color_red">{fileError}</Typography>}
        </div>
        <div className="d-flex flex-column" style={{ maxWidth: 500 }}>
          <Typography className="light_regular_normal__16 mb-4">
            Please ensure you have these columns: email, name, no. of hours and able to identity correlating columns for
            input.
          </Typography>
          <Typography className="light_regular_normal__16 mb-4">
            You can identify columns once you have uploaded your CSV, provide the new values if required or correct the
            errors in your CSV and then re-upload your file.
          </Typography>
        </div>
        <div className="pb-5 align-self-center">
          <Button type="submit" className="align-self-end">
            Next
          </Button>
        </div>
      </section>
    </form>
  )
}
