import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

import { ReactComponent as SuccessIcon } from '../../../../assets/icons/success-plane.svg'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 845px;
  }
`

export const SuccessCreatedContractModal = ({ isAuthorized, contractorEmail, managerEmail, onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Contract created & sent successfully</Typography>
      </Modal.Header>
      <Modal.Body>
        <div className="d-flex justify-content-center mb-5">
          <SuccessIcon />
        </div>
        <Typography className="color_grey">
          You have successfully created and sent a contract to {isAuthorized ? 'the Contractor' : 'all signatories'}.
        </Typography>

        <Typography className="color_grey mt-4">
          {!isAuthorized && (
            <>
              This contract will be sent to <br />
            </>
          )}
          Contractor signee: {contractorEmail}
          <br />
          {!isAuthorized && `Client signee: ${managerEmail}`}
        </Typography>
        <Typography className="color_grey mt-4">
          {isAuthorized ? 'Contractor will receive' : 'They will each receive'} an email invitation to sign the
          contract. You can monitor their progress via the Agreements section.
        </Typography>
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="success-created-contract-modal-8C439B"
          type="submit"
          size="small"
          priority="primary"
          onClick={onSave}
        >
          Continue
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
