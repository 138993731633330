export const fields = ({ handleDelete, handleEdit, handleReset }) => [
  {
    title: 'Name',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 320,
    minWidth: 320,
    width: 320,
  },
  {
    title: 'Email',
    key: 'email',
    type: 'string',
    maxWidth: 220,
    minWidth: 220,
    width: 220,
  },
  {
    title: 'Groups',
    key: 'groups_joined',
    type: 'string',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
  {
    title: 'Assignments',
    key: 'custom',
    type: 'custom',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
    render: ({ is_billing_contact }) => (is_billing_contact ? 'Billing contact' : '-'),
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'actions',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
    actions: [
      {
        action: handleReset,
        icon: 'blockIcon',
        actionText: 'Reset password',
        afterDisabledActionText: 'Check your email',
      },
      {
        action: handleEdit,
        icon: 'edit',
      },
      {
        action: handleDelete,
        icon: 'deleteIcon',
      },
    ],
  },
]

const getText = (v, c) => {
  if (v && v.trim()) {
    return `${v}${c} `
  }
  return ''
}

export const getMainList = (company) => [
  {
    label: 'Company name',
    value: company.name,
  },
  {
    label: 'Headquarters',
    value: company.head_quarters,
  },
  {
    label: 'Address',
    value: `${getText(company?.legal_address?.living_country?.name, ',')}
    ${getText(company?.legal_address?.state, ',')}
    ${getText(company?.legal_address?.city, ',')}
    ${getText(company?.legal_address?.street_address, ',')}
    ${getText(company?.legal_address?.postal_code, '')}
    `,
  },
]

export const SWIFT_FEE_PAY_ROLE = {
  COMPANY_AND_CONTRACTOR: 'company_and_contractor',
  COMPANY: 'company',
  CONTRACTOR: 'contractor',
  REMOFIRST: 'remofirst',
}
export const SwiftFeePayrollLabel = {
  [SWIFT_FEE_PAY_ROLE.COMPANY]: 'I will cover the potential SWIFT fees as the employer',
  [SWIFT_FEE_PAY_ROLE.CONTRACTOR]: 'Deduct SWIFT fees from my contractor’s payments',
  [SWIFT_FEE_PAY_ROLE.COMPANY_AND_CONTRACTOR]: 'Split SWIFT fees between me and a contractor 50:50',
  [SWIFT_FEE_PAY_ROLE.REMOFIRST]: 'Remofirst covers SWIFT fees',
}
