import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { CloseIcon, notificationNone } from '@core/icons/icons'
import { fetchMfaCancel, fetchMfaRemind } from '@services/app.service'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Alert = styled.div`
  display: flex;
  align-items: center;
  background: #eff3f8;
  padding: 16px 12px 16px 48px;
  cursor: pointer;
  margin-bottom: 8px;
`

export default ({ onClose }) => {
  const queryClient = useQueryClient()
  const navigate = useNavigate()

  const mfaRemindMutation = useMutation(fetchMfaRemind, {
    onSuccess: () => queryClient.invalidateQueries('popupMFA'),
  })
  const mfaCancelMutation = useMutation(fetchMfaCancel, {
    onSuccess: () => queryClient.invalidateQueries('popupMFA'),
  })

  return (
    <Alert>
      <Icon icon={notificationNone} />
      <div className="d-flex align-items-start justify-content-between w-100">
        <div className="ml-3">
          <Typography className="text_regular-normal__14">
            Important: Enable two-factor authentication (2FA) today
          </Typography>
          <Typography className="text_light__12 color_text_300">
            For added security on your account, please proceed to set up 2FA 🛡️
          </Typography>
        </div>
        <div className="d-flex">
          <Button
            data-testid="AlertMFA-index-32776F"
            type="button"
            size="xsmall"
            priority="secondary"
            onClick={() => {
              navigate('/pages/profile?tab=5')
              onClose()
            }}
          >
            Enable 2FA
          </Button>
          <Button
            data-testid="AlertMFA-index-438E90"
            type="button"
            size="xsmall"
            priority="primary_black"
            className="ml-2"
            loading={mfaRemindMutation.isLoading}
            onClick={mfaRemindMutation.mutate}
          >
            Remind me later
          </Button>
        </div>
        <Icon icon={CloseIcon} onClick={onClose} />
      </div>
    </Alert>
  )
}
