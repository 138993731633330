import { Description } from '@pages/payContracts/Description'
import moment from 'moment'

export const getFields = ({ onClickEdit, handleOpenAdditionPayment, handleDelete }) => [
  {
    title: 'Name',
    key: 'full_name',
    add_key: 'rate',
    add_tag: 'created_by',
    type: 'avatar',
    maxWidth: 240,
    minWidth: 240,
    width: 240,
  },
  {
    title: 'Payment',
    key: 'worked_hours',
    add_text: 'worked_hours_time',
    type: 'textAction',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
    action: onClickEdit,
  },
  {
    title: 'Description',
    key: 'description',
    type: 'custom',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
    render: (item) => {
      return <Description description={item.description} />
    },
  },
  {
    title: 'Additional pay',
    key: 'additional_payment',
    type: 'textAction',
    maxWidth: 180,
    minWidth: 180,
    width: 180,
    action: handleOpenAdditionPayment,
  },
  {
    title: 'Total',
    key: 'total',
    type: 'textAction',
    maxWidth: 138,
    minWidth: 138,
    width: 138,
  },

  {
    title: 'Action',
    key: 'action',
    type: 'actions',
    maxWidth: 120,
    minWidth: 120,
    width: 120,
    actions: [
      {
        action: handleDelete,
        icon: 'deleteIcon',
      },
    ],
  },
]

export const PAYMENT_FIELDS = [
  {
    title: 'Name',
    key: 'full_name',
    width: 220,
  },
  {
    title: 'Date range',
    type: 'custom',
    render: (item) => {
      return (
        <div>
          {moment(item.start_date).format('D MMM YYYY')} - {moment(item.end_date).format('D MMM YYYY')}
        </div>
      )
    },
  },
  {
    title: 'Total',
    key: 'total',
  },
]
