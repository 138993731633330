import { Api } from '@api/Api'

export const attach = async ({ id, payload }) => {
  const { data } = await Api.post(`contracts/${id}/insurance-attach/`, payload)
  return data
}

/**
 * @param {Object} params
 * @param {number} params.age
 * @param {number} params.country_id
 * @param {string} params.insurance_type
 */
export const getList = async (params) => {
  const { data } = await Api.get(`health-insurance-prices/`, {
    params,
  })
  return data
}

export const getTypes = async () => {
  const { data } = await Api.get(`health-insurance-types/`)
  return data
}

/**
 * @param {Object} params
 * @param {number} params.company_id
 * @param {number} params.contract_id
 */
export const getContractInsurances = async (params) => {
  const { data } = await Api.get(`contract-insurances/`, {
    params,
  })
  return data
}

export const updateContractInsurance = async ({ id, body }) => {
  const { data } = await Api.put(`/contracts/${id}/insurance-update/`, body)
  return data
}

export const updateContractInsuranceStatus = async ({ id, body }) => {
  const { data } = await Api.put(`/contracts/${id}/insurance-status-update/`, body)
  return data
}
