import BsModal from '@atoms/Modal/Modal'
import styled from 'styled-components'

const Modal = styled(BsModal)`
  width: 70%;
`
const Title = styled.div`
  font-size: 22px;
`

const Tr = styled.tr`
  height: 35px;
`

const Th = styled.th`
  color: unset;
`

export const Styled = { Modal, Title, Tr, Th }
