import 'src/pages/employeesExpense/EmployeesExpense.scss'

import Button from '@atoms/Button/Button'
import Confirm from '@atoms/Confirm'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { USER_GROUPS } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { useRouteQuery } from '@core/hooks/useRouteQuery'
import { edit, fileIcon } from '@core/icons/icons'
import { formatDate, userHasOneOfGroupsOrSuper } from '@core/utils'
import { categoryTypes, UpdateExpenseModal } from '@pages/employeesExpense/UpdateExpenseModal'
import { EventSides } from '@pages/time-off/enums'
import classNames from 'classnames'
import moment from 'moment'
import React, { useMemo, useState } from 'react'
import { useMutation } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { cancelExpense, updateExpense } from '../../services/expense.service'

const Styled = styled.div`
  background: #f9f9f9;
  border-radius: 0px 0px 8px 8px;
  padding: 24px;
`

const StyledComment = styled.div`
  word-break: break-all;
  background: #f5f5f5;
  border-radius: 4px;
  padding: 8px;
`

const StyledTable = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`
export default ({ expense, refetch }) => {
  const { successAlert } = useToast()
  const { userGroups } = useApp()
  const navigate = useNavigate()
  const routeQuery = useRouteQuery()
  const editTimOffModalState = useBoolean(Boolean(routeQuery.get('openEditModal')))
  const confirmCancelExpense = useBoolean(false)
  const confirmApproveExpense = useBoolean(false)
  const confirmRejectExpense = useBoolean(false)
  const [rejectReason, setRejectReason] = useState('')
  const cancelExpenseRequest = useMutation({
    mutationFn: (payload) => cancelExpense(payload),
    onSuccess: () => {
      refetch()
      successAlert('Successfully updated!')
    },
  })

  const onApproveExpense = () => {
    cancelExpenseRequest.mutate({
      id: expense.id,
      data: {
        event_side: 'COMPANY',
        status: 'AWAITING_PAYMENT',
      },
    })
  }
  const onRejectExpense = () => {
    cancelExpenseRequest.mutate({
      id: expense.id,
      data: {
        event_side: 'COMPANY',
        status: 'REJECTED',
        reject_reason: rejectReason,
      },
    })
  }

  const handleModalOpen = () => {
    editTimOffModalState.setTrue()
  }
  const handleModalClose = () => {
    routeQuery.delete('openEditModal')
    navigate({
      search: routeQuery.toString(),
    })
    editTimOffModalState.setFalse()
  }
  const editExpenseRequest = useMutation({
    mutationFn: (payload) => updateExpense(payload),
    onSuccess: () => {
      handleModalClose()
      refetch()
      successAlert('Successfully updated!')
    },
  })
  const onCancelExpense = () => {
    cancelExpenseRequest.mutate({
      id: expense.id,
      data: {
        event_side: 'COMPANY',
        status: 'CANCELED',
      },
    })
  }
  const onEditExpense = (body) => {
    const data = {
      id: expense.id,
      data: {
        ...body,
      },
    }
    editExpenseRequest.mutate(data)
  }

  const onChangeReject = (e) => {
    setRejectReason(e.target.value)
  }

  const checkNegativeStatus = (status) => {
    return (
      status !== 'CANCELLED' &&
      status !== 'APPROVED_BY_COMPANY' &&
      status !== 'PAID' &&
      status !== 'AWAITING_PAYMENT' &&
      status !== 'REJECTED'
    )
  }
  const showActions = (expenseProps) => {
    if (!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST, USER_GROUPS.EXPENSE_MANAGER])) return false

    if (checkNegativeStatus(expenseProps.state)) {
      const findEvent = expenseProps.events.filter((evt) => evt.event_side === 'COMPANY')[0]
      if (findEvent) {
        return findEvent.status !== 'APPROVED_BY_COMPANY' && findEvent.status !== 'REJECTED'
      }
    }
    return false
  }
  const findRejectStatus = useMemo(() => expense.events.filter((evt) => evt.status === 'REJECTED')[0], [expense])
  return (
    <Styled className="expenseEvent">
      <div className="row justify-content-center">
        {(expense.state === 'REJECTED' || expense.state === 'PENDING') && (
          <div className="col-3 content-body mr-1">
            <Status status={expense.state} />
            <div className="d-flex justify-content-between mt-2">
              <Typography as="span" className="text_medium__14">
                {expense.state === 'PENDING' ? expense.created_by?.full_name : findRejectStatus.action_user?.full_name}
              </Typography>
              <Typography as="span" className="text_regular-normal__14 color_text_300">
                {expense.state === 'PENDING'
                  ? EventSides[expense.creator_role]
                  : EventSides[findRejectStatus.event_side]}
              </Typography>
            </div>
            {expense.state === 'REJECTED' ? (
              <Typography as="span" className="text_regular-normal__14 mt-2">
                {findRejectStatus.reject_reason}
              </Typography>
            ) : (
              <Typography as="span" className="text_regular-normal__14 mt-2">
                Please approve or reject the request
              </Typography>
            )}
          </div>
        )}
        <div className="col-5 content-body ml-1">
          <div className="d-flex justify-content-end mb-2">
            {checkNegativeStatus(expense.state) && expense.creator_role === 'COMPANY' && (
              <Button
                data-testid="ExpenseEvent-84AF65"
                onClick={handleModalOpen}
                priority="secondary"
                style={{ height: '32px' }}
                size="small"
                className="px-2 pl-0"
              >
                <Icon fill="none" icon={edit} />
                <Typography className={classNames('text_light__12', 'ml-2')}>Edit</Typography>
              </Button>
            )}
          </div>

          <StyledTable>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Expense name
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.5px' }}>
                  {expense.expense_name}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Company
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.5px' }}>
                  {expense.contract?.company_name}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Amount
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {expense.currency.short_code || expense.currency.sign} {expense.amount}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Expense date
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {moment(expense.expense_date).format('DD MMM YYYY')}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Category
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {categoryTypes.filter((type) => type.value === expense.category)[0]?.label}
                </Typography>
              </td>
            </tr>
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Receipt
                </Typography>
              </th>
              <td align="right">
                {expense.receipt ? (
                  <a
                    data-testid="ExpenseEvent-6601F1"
                    target="_blank"
                    className="text_regular-normal__14 color_black d-flex align-items-center justify-content-end"
                    style={{ textDecoration: 'none' }}
                    href={expense.receipt}
                    rel="noreferrer"
                  >
                    <Icon stroke="black" className="mr-2" fill="white" icon={fileIcon} style={{ cursor: 'pointer' }} />
                    {expense.filename}
                  </a>
                ) : (
                  <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                    -
                  </Typography>
                )}
              </td>
            </tr>

            {/* Approved date */}
            {expense.approved_date && (
              <tr>
                <th>
                  <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                    Approved date
                  </Typography>
                </th>
                <td align="right">
                  <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                    {formatDate(expense.approved_date)}
                  </Typography>
                </td>
              </tr>
            )}
            {expense.events.find((e) => e.status === 'AWAITING_PAYMENT') && (
              <tr>
                <th>
                  <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                    Approved by
                  </Typography>
                </th>
                <td align="right">
                  <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                    {expense.events.find((e) => e.status === 'AWAITING_PAYMENT')?.action_user?.full_name}
                  </Typography>
                </td>
              </tr>
            )}
            <tr>
              <th>
                <Typography className="text_regular-normal__14 ml-2" style={{ letterSpacing: '0.4px' }}>
                  Payout date
                </Typography>
              </th>
              <td align="right">
                <Typography className="text_regular-normal__14 mr-2" style={{ letterSpacing: '0.4px' }}>
                  {expense?.payout_date ? moment(expense.payout_date).format('MMMM') : '-'}
                </Typography>
              </td>
            </tr>
          </StyledTable>
          {expense.description && (
            <div className="mt-2">
              <Typography className="text_regular-normal__14 ml-2 mb-2" style={{ letterSpacing: '0.4px' }}>
                Description
              </Typography>
              <StyledComment>{expense.description}</StyledComment>
            </div>
          )}
          {showActions(expense) && (
            <div className="d-flex justify-content-between">
              {expense.creator_role === 'COMPANY' && (
                <Button
                  data-testid="ExpenseEvent-2AFD27"
                  className="mt-2 w-50"
                  size="small"
                  onClick={confirmCancelExpense.setTrue}
                  priority="danger_outlined"
                >
                  Cancel request
                </Button>
              )}
              {expense.creator_role !== 'COMPANY' && (
                <Button
                  data-testid="ExpenseEvent-5E8F1B"
                  className="mt-2 w-50 mr-1"
                  size="small"
                  onClick={confirmRejectExpense.setTrue}
                  priority="danger_outlined"
                >
                  Reject
                </Button>
              )}
              <Button
                data-testid="ExpenseEvent-F55E41"
                className="mt-2 w-50 ml-1"
                size="small"
                onClick={confirmApproveExpense.setTrue}
                priority="primary"
              >
                Approve
              </Button>
            </div>
          )}
        </div>
      </div>
      {editTimOffModalState.value && (
        <UpdateExpenseModal
          title="Submit expense"
          onClose={handleModalClose}
          expense={expense}
          isEdit
          loading={editExpenseRequest.isLoading}
          onSubmit={onEditExpense}
        />
      )}
      {confirmCancelExpense.value && (
        <Confirm
          title="Cancel expense request?"
          yesButtonProps={{
            text: 'Cancel Forever',
          }}
          cancelButtonProps={{
            text: 'Close',
          }}
          isLoading={cancelExpenseRequest.isLoading}
          onCancel={confirmCancelExpense.setFalse}
          onYes={onCancelExpense}
        >
          <Typography>
            This action will cancel the request forever and you will not be able to edit it anymore. Employee will be
            notified if cancelled. The request will be stored in History tab.
          </Typography>
        </Confirm>
      )}

      {confirmRejectExpense.value && (
        <Confirm
          title="Reject expense request"
          isLoading={cancelExpenseRequest.isLoading}
          onCancel={confirmRejectExpense.setFalse}
          onYes={onRejectExpense}
          yesButtonProps={{
            text: 'Send',
          }}
        >
          <TextArea placeholder="Explain why the expense is rejected" rows={4} onChange={onChangeReject} />
        </Confirm>
      )}
      {confirmApproveExpense.value && (
        <Confirm
          title="Approve expense request?"
          isLoading={cancelExpenseRequest.isLoading}
          onCancel={confirmApproveExpense.setFalse}
          onYes={onApproveExpense}
          yesButtonProps={{
            text: 'Approve',
            priority: 'primary',
          }}
        >
          <Typography>As soon as you approve - you will not be able to edit or cancel the request</Typography>
        </Confirm>
      )}
    </Styled>
  )
}
