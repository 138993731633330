import Button from '@atoms/Button/Button'

import { TimesheetStatus } from './timesheetStatus/TimesheetStatus'

export const TIMESHEETS = [
  {
    id: 1,
    full_name: 'Carla Botosh',
    job_position: 'Financial Controller',
    avatar: null,
    start_date: '1 Oct 2024',
    end_date: '31 Oct 2024',
    total_hours: '40h 0m',
    status: 'rejected',
  },
  {
    id: 2,
    full_name: 'Gregory Jones',
    job_position: 'Head of People',
    avatar: null,
    start_date: '1 Sep 2024',
    end_date: '30 Sep 2024',
    total_hours: '150h 0m',
    status: 'pending',
  },
  {
    id: 3,
    full_name: 'Hanna Donin',
    job_position: 'Lead Engineer',
    avatar: null,
    start_date: '1 Aug 2024',
    end_date: '31 Aug 2024',
    total_hours: '80h 0m',
    status: 'pending',
  },
  {
    id: 4,
    full_name: 'Zain Torff',
    job_position: 'Office Manager',
    avatar: null,
    start_date: '1 Jul 2024',
    end_date: '31 Jul 2024',
    total_hours: '40h 0m',
    status: 'approved',
  },
  {
    id: 5,
    full_name: 'Lindsey Calzoni',
    job_position: 'Marketing Manager',
    avatar: null,
    start_date: '1 Jun 2024',
    end_date: '30 Jun 2024',
    total_hours: '65h 0m',
    status: 'rejected',
  },
  {
    id: 6,
    full_name: 'David Hartman',
    job_position: 'Software Developer',
    avatar: null,
    start_date: '1 Oct 2024',
    end_date: '31 Oct 2024',
    total_hours: '120h 0m',
    status: 'approved',
  },
  {
    id: 7,
    full_name: 'Jessica Miller',
    job_position: 'Product Manager',
    avatar: null,
    start_date: '1 Sep 2024',
    end_date: '30 Sep 2024',
    total_hours: '90h 0m',
    status: 'pending',
  },
  {
    id: 8,
    full_name: 'Ravi Kapoor',
    job_position: 'Data Analyst',
    avatar: null,
    start_date: '1 Aug 2024',
    end_date: '31 Aug 2024',
    total_hours: '100h 30m',
    status: 'rejected',
  },
  {
    id: 9,
    full_name: 'Emily Chen',
    job_position: 'UX Designer',
    avatar: null,
    start_date: '1 Jul 2024',
    end_date: '31 Jul 2024',
    total_hours: '75h 0m',
    status: 'approved',
  },
  {
    id: 10,
    full_name: 'Michael Silver',
    job_position: 'Sales Executive',
    avatar: null,
    start_date: '1 Jun 2024',
    end_date: '30 Jun 2024',
    total_hours: '85h 45m',
    status: 'pending',
  },
  {
    id: 11,
    full_name: 'Michael Silver',
    job_position: 'Sales Executive',
    avatar: null,
    start_date: '1 Jun 2024',
    end_date: '30 Jun 2024',
    total_hours: '85h 45m',
    status: 'pending',
  },
]

export const getTimesheetFields = (handleView) => [
  {
    title: 'Employee',
    key: 'full_name',
    type: 'avatar',
    maxWidth: 400,
    add_key: 'job_position',
    minWidth: 300,
  },
  {
    title: 'Period',
    type: 'custom',
    maxWidth: 300,
    minWidth: 200,
    render: (item) => `${item.start_date} - ${item.end_date}`,
  },
  {
    title: 'Total no. of hours',
    key: 'total_hours',
    type: 'string',
    maxWidth: 300,
    minWidth: 200,
  },
  {
    title: 'Status',
    key: 'status',
    type: 'custom',
    maxWidth: 300,
    minWidth: 200,
    render: (item) => <TimesheetStatus status={item.status} />,
  },
  {
    title: 'Action',
    type: 'custom',
    minWidth: 150,
    render: (item) => {
      return (
        <div>
          {(item.status === 'rejected' || item.status === 'approved') && (
            <Button onClick={() => handleView(item.id)} size="xsmall" priority="secondary">
              View
            </Button>
          )}
          {item.status === 'pending' && (
            <Button onClick={() => handleView(item.id)} size="xsmall">
              Review
            </Button>
          )}
        </div>
      )
    },
  },
]
