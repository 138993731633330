import Stepper from '@atoms/Stepper/Stepper'
import { useApp } from '@core/context'
import { fetchProfile } from '@services/app.service'
import { createAuthorizedPerson } from '@services/sign.service'
import React, { useState } from 'react'
import { Modal } from 'react-bootstrap'
import { useMutation, useQuery } from 'react-query'
import styled from 'styled-components'

import { AuthorizedStep } from './authorized-step/authorized-step.component'
import { ReviewStep } from './review-step/review-step.component'
import { SignStep } from './sign-step/sign-step.component'

const authorizedStepTitle = 'Authorized person invite'
const reviewStepTitle = 'Review & confirm your data'
const signStepTitle = 'Sign agreement'

const StyledModal = styled(Modal)`
  .modal-header {
    align-items: center;
    justify-content: space-between;
  }
  .modal-content {
    padding: 0;
    border-radius: 0 !important;
  }
  .modal-body {
    padding: 0;
  }
  .dropzone {
    width: 100%;
  }
`

export const SignMsa = ({ onClose }) => {
  const { profile, refetchProfile } = useApp()
  const [activeStep, setStep] = useState(1)
  const [authorizedCreatePayload, setAuthorizedCreatePayload] = useState()

  const createAuthorizedMutation = useMutation({
    mutationFn: (payload) => createAuthorizedPerson(payload),
  })

  const handleNext = () => setStep((prev) => prev + 1)

  const handleBack = () => setStep((prev) => prev - 1)

  // Also sends MSA agreement on backend
  const handleAuthorizedCreate = async () => {
    if (profile.legal_registration_document) {
      setStep((prev) => prev + 1)
    } else {
      try {
        await createAuthorizedMutation.mutateAsync(authorizedCreatePayload)
        await refetchProfile()
        setStep((prev) => prev + 1)
      } catch (e) {
        console.log(e)
      }
    }
  }

  return (
    <StyledModal show fullscreen>
      <Modal.Body>
        <Stepper
          activeStep={activeStep}
          title="Sign the Master Service Agreement"
          description="If necessary, you can modify your original data. It is important to us that you provide the accurate information."
          sidebarStyle={{ width: '340px' }}
        >
          <Stepper.Step title={authorizedStepTitle} setStep={setStep}>
            <Stepper.StepContent title={authorizedStepTitle} onClose={onClose}>
              <AuthorizedStep onNext={handleNext} onSave={setAuthorizedCreatePayload} />
            </Stepper.StepContent>
          </Stepper.Step>

          <Stepper.Step title={reviewStepTitle} setStep={setStep}>
            <Stepper.StepContent title={reviewStepTitle} onClose={onClose} onBack={handleBack}>
              <ReviewStep onNext={handleAuthorizedCreate} />
            </Stepper.StepContent>
          </Stepper.Step>

          <Stepper.Step title={signStepTitle} setStep={setStep}>
            <Stepper.StepContent title={signStepTitle} displayHeader={false} onClose={onClose} onBack={handleBack}>
              {activeStep === 3 && <SignStep />}
            </Stepper.StepContent>
          </Stepper.Step>
        </Stepper>
      </Modal.Body>
    </StyledModal>
  )
}
