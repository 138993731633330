import Button from '@atoms/Button/Button'
import ContentPlaceholder from '@components/content-placeholder/content-placeholder.component'
import { useApp } from '@core/context'
import { checkAppendixAgreement } from '@services/sign.service'
import { DotWave } from '@uiball/loaders'
import React from 'react'
import { useQuery } from 'react-query'

import { SignAppendix } from '../sign-appendix/sign-appendix.component'

export const SignAppendixStep = ({ workingCountryId, onNext, onClose }) => {
  const { profile } = useApp()

  const { data: checkData, isFetching } = useQuery({
    queryKey: ['checkAppendixAgreement', workingCountryId, profile.id],
    queryFn: () =>
      checkAppendixAgreement({
        country: workingCountryId,
        company: profile.id,
      }),
    enabled: !!workingCountryId,
  })

  if (isFetching) {
    return (
      <div className="d-flex h-100 justify-content-center align-items-center">
        <DotWave />
      </div>
    )
  }

  return (
    <div className="d-flex flex-column h-100">
      <section className=" flex-grow-1 d-flex flex-column  justify-content-center align-items-center">
        {checkData?.signed && (
          <ContentPlaceholder
            imgSrc="/assets/img/sign-success.png"
            title={
              <>
                <div>Congratulations! </div>
                <div>You have signed the MSA appendix</div>
              </>
            }
          />
        )}
        {!checkData?.signed && checkData?.agreement_template && (
          <SignAppendix
            template={checkData?.agreement_template}
            agreement={checkData?.agreement_document}
            country={workingCountryId}
            onNext={onNext}
          />
        )}
        {!checkData?.signed && !checkData?.agreement_template && (
          <ContentPlaceholder
            imgSrc="/assets/img/success.png"
            title="Your request has been sent to the Remofirst team "
            description="Our team will contact you as soon as we process your request"
          />
        )}
      </section>
      {checkData?.signed && (
        <div className="align-self-center pb-5">
          <Button
            data-testid="sign-appendix-step.component-E4A0CE"
            type="button"
            className="align-self-end"
            onClick={onNext}
          >
            Continue
          </Button>
        </div>
      )}
      {!checkData?.signed && !checkData?.agreement_template && (
        <div className="align-self-center pb-5">
          <Button
            data-testid="sign-appendix-step.component-98294C"
            type="button"
            className="align-self-end"
            onClick={onClose}
          >
            Finish
          </Button>
        </div>
      )}
    </div>
  )
}
