import Typography from '@atoms/Typography/Typography'
import { SplitNumber } from '@core/utils'
import classNames from 'classnames'
import React from 'react'
import { v4 as uuid } from 'uuid'

import styles from './employer-burden.module.scss'

const employeeBurdenTitle = {
  estimated_taxes: 'Estimated taxes and contributions',
  gross_monthly: 'Gross monthly salary costs',
  remoteam_fee: 'Remofirst fee',
  remofirst_platform_fee: 'Remofirst Platform Fee',
  partner_fee: 'Partner fee',
  medical_insurance_amount: 'Medical insurance',
  health_insurance_amount: 'Health insurance',
  dental_insurance_amount: 'Dental insurance',
  total: 'Total monthly cost of employment',
}

const parseEmployeeBurden = (employeeBurdens, titleObject) => {
  return Object.keys(employeeBurdens).map((key) => ({
    title: titleObject[key],
    month: employeeBurdens[key].monthly,
    year: employeeBurdens[key].yearly,
    total: key === 'total',
  }))
}

export default function EmployerBurden({ currency, employeeBurden }) {
  const items = employeeBurden ? parseEmployeeBurden(employeeBurden, employeeBurdenTitle) : []
  const isEmpty = items.length === 0

  return (
    <section className={styles.burden}>
      <div className={classNames('d-flex justify-content-between', styles.burden_header)}>
        <Typography
          style={{
            minWidth: 270,
          }}
          className="text_medium__14 color_text_300"
        >
          Employer burden
        </Typography>
        {!isEmpty && (
          <div className="d-flex justify-content-between w-100">
            <Typography className="text_medium__14 color_text_300">{currency ? `${currency}/` : ''}month</Typography>
            <Typography className="text_medium__14 color_text_300">{currency ? `${currency}/` : ''}year</Typography>
          </div>
        )}
      </div>
      <div className={styles.burden_content}>
        {!isEmpty &&
          items.map((item) => (
            <div
              key={uuid()}
              className={classNames('d-flex justify-content-between', styles.burden_content__row, {
                [styles.burden_total]: item.total,
              })}
            >
              <Typography
                style={{
                  minWidth: 270,
                }}
                className={item.total ? 'text_medium__14' : 'text_regular-normal__14'}
              >
                {item.title}
              </Typography>
              <div className="d-flex justify-content-between w-100">
                <Typography className={item.total ? 'text_medium__14' : 'text_regular-normal__14'}>
                  {SplitNumber(item.month)}
                </Typography>
                <Typography className={item.total ? 'text_medium__14' : 'text_regular-normal__14'}>
                  {SplitNumber(item.year)}
                </Typography>
              </div>
            </div>
          ))}

        {isEmpty && (
          <div className={classNames('d-flex justify-content-center align-items-center', styles.burden_content)}>
            <Typography className="text_regular-normal__14 color_text_300 text-center">
              Fill in the data so that we can calculate
            </Typography>
          </div>
        )}
      </div>
    </section>
  )
}
