import Button from '@atoms/Button/Button'
import RadioButton from '@atoms/RadioButton/RadioButton'
import { SEND_TYPES } from '@core/constants'
import { useApp } from '@core/context'
import { AuthorizedNoForm } from '@features/sign-msa/authorized-no-form/authorized-no-form.component'
import { fetchAddCompanyManager } from '@services/manager.service'
import React, { useEffect, useRef, useState } from 'react'
import { useMutation } from 'react-query'

const COMPANY_ROLE = 'Client'

export const ConfirmSignatory = ({ onNext, signer, isModal }) => {
  const { profile, userProfile } = useApp()
  const [isAuthorized, setIsAuthorized] = useState('true')
  const authorizedNoFormRef = useRef(null)

  useEffect(() => {
    if (signer?.id) setIsAuthorized('false')
  }, [signer])
  const handleAuthorizedChange = (e) => setIsAuthorized(e.target.value)

  const checkAuthorized = () => isAuthorized === 'true'

  const submit = () => {
    if (checkAuthorized()) {
      onNext({
        isAuthorized: checkAuthorized(),
        role_name: COMPANY_ROLE,
        name: `${userProfile.profile.first_name} ${userProfile.profile.last_name}`,
        email_address: userProfile.profile.email,
      })
    } else if (authorizedNoFormRef.current) {
      authorizedNoFormRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  const addManagerMutation = useMutation({
    mutationFn: (payload) => fetchAddCompanyManager(payload),
    onSuccess: () => {
      console.log('success add')
    },
  })
  const handleAuthorizedNoSubmit = async (data) => {
    const { sendType, firstName, lastName, email, manager } = data
    try {
      let newManager
      if (sendType === SEND_TYPES.INVITE) {
        newManager = await addManagerMutation.mutateAsync({
          company: profile.id,
          manager: {
            email,
            profile: {
              first_name: firstName,
              last_name: lastName,
            },
            // TODO need checkbox for select is it billing contact or not
            is_billing_contact: false,
          },
        })
        onNext({
          isAuthorized: checkAuthorized(),
          id: newManager.id,
          name: `${firstName} ${lastName}`,
          role_name: COMPANY_ROLE,
          email_address: email,
        })
      } else {
        onNext({
          isAuthorized: checkAuthorized(),
          id: manager.value,
          name: manager.label,
          role_name: COMPANY_ROLE,
          email_address: manager.email,
        })
      }
    } catch (err) {
      console.log(err)
    }
  }
  return (
    <div
      className="d-flex flex-column h-100 employees-page__form"
      // onSubmit={handleSubmit(submit)}
    >
      <section className="flex-grow-1 d-flex flex-column justify-content-center align-items-center">
        <div className="w-100 remo-form-input">
          <RadioButton
            isRequired
            label="Are you the authorised signatory for this contract?"
            options={[
              {
                id: 1,
                text: 'Yes',
                value: 'true',
              },
              {
                id: 2,
                text: 'No',
                value: 'false',
              },
            ]}
            value={isAuthorized}
            onChange={handleAuthorizedChange}
          />
        </div>
        {!checkAuthorized() && (
          <AuthorizedNoForm
            type="contractor"
            managerId={signer?.id}
            formRef={authorizedNoFormRef}
            onSubmit={handleAuthorizedNoSubmit}
          />
        )}
      </section>
      {isModal ? (
        <div className="align-self-end gap-3 d-flex">
          <Button
            data-testid="confirm-signatory-E70804"
            type="button"
            priority="secondary"
            size="small"
            onClick={() => onNext()}
          >
            Close
          </Button>
          <Button
            data-testid="confirm-signatory-A9B4EB"
            priority="primary"
            size="small"
            type="submit"
            onClick={submit}
            loading={addManagerMutation.isLoading}
          >
            Save
          </Button>
        </div>
      ) : (
        <div className="pb-5 align-self-center">
          <Button
            data-testid="confirm-signatory-872373"
            type="submit"
            className="align-self-end"
            onClick={submit}
            loading={addManagerMutation.isLoading}
          >
            Confirm and continue
          </Button>
        </div>
      )}
    </div>
  )
}
