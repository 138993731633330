import React from 'react'
import { Modal } from 'react-bootstrap'
import { MdOutlineCheck } from 'react-icons/md'

import Button from './Button/Button'

const data = [
  'Hire with one click in 150+ countries',
  'Streamline Payroll, tax filings, pensions, and more',
  'Compliant and localized contract creation',
  'Onboarding & termination HR guidance',
  'On demand expertise on local rules and regulations',
  '24/7 support',
]

const ChooseMeetingTime = ({ onMeet, onClose }) => {
  return (
    <Modal show onHide={onClose} centered animation={false}>
      <Modal.Header closeButton className="mb-0" />
      <Modal.Body className="pt-0">
        <img src="/assets/img/MeetIcon.svg" alt="meetimg" className="mb-4" />
        <p className="heading_semibold__20 mb-2">Start hiring full time employees</p>
        <p className="text_light__14 color_text_300 mb-3">Book a meeting with Remofirst team to discuss Partnership.</p>
        {data.map((t) => {
          return (
            <div key={t} className="d-flex align-items-center mb-3">
              <MdOutlineCheck size={26} style={{ color: '#04C53A' }} />
              <p className="ml-1">{t}</p>
            </div>
          )
        })}
        <Button
          disabled={process.env.REACT_APP_ENV === 'dev' || process.env.REACT_APP_ENV === 'stage'}
          data-testid="ChooseMeetingTimeModal-27696F"
          size="medium"
          priority="primary_black"
          className="w-100 mt-4"
          onClick={onMeet}
        >
          Choose a meeting time
        </Button>
      </Modal.Body>
    </Modal>
  )
}

export default ChooseMeetingTime
