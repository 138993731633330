import Checkbox from '@atoms/Checkbox/Checkbox'
import React from 'react'
import { Alert } from 'react-bootstrap'
import styled from 'styled-components'

const StyledContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`
const StyledDescription = styled.div`
  font-size: 0.75rem;
  line-height: 150%;
  margin-bottom: 2rem;
`

const StyledAlert = styled(Alert)`
  display: flex;
  justify-content: space-between;
  margin-bottom: 1rem;
`
const StyledAlertText = styled.div`
  display: flex;
  padding-right: 1rem;
  font-size: 14px;
  color: #0f172a;
`
const StyledHint = styled.div`
  font-size: 12px;
  color: #0f172a;

  a {
    color: #04c53a;
  }
`

export default function SignConfirm({ countryName, checked, onCheck }) {
  return (
    <StyledContainer>
      <StyledDescription>
        <p>
          The MSA Appendix should be signed only by an authorized person of the company. If you are not, please close
          the window and ask this person to add a new employee and sign the agreement for the country:{' '}
          <strong>{countryName}</strong>.
        </p>
        <br />
        <p>
          Please note, that you need to sign the MSA appendix for this country only once. After that, both company owner
          and managers will be able to add employees in <strong>{countryName}</strong>.
        </p>
      </StyledDescription>
      <StyledAlert variant="secondary">
        <StyledAlertText>
          <div className="mr-3">
            <Checkbox data-testid="sign-confirm.component-C8AE88" value={checked} onChange={onCheck} />
          </div>
          <div>I confirm that I am an autorized representative and have legal rights to sign the MSA Appendix</div>
        </StyledAlertText>
      </StyledAlert>
    </StyledContainer>
  )
}
