import Typography from '@atoms/Typography/Typography'
import React from 'react'

export const LostRecoveryCode = () => {
  return (
    <Typography className="text_regular__15">
      Sorry for the trouble! Please contact{' '}
      <a
        data-testid="lost-recovery-code.component-3ACD76"
        href="mailto:access.recovery@remofirst.com"
        style={{ color: '#037b0f', textDecoration: 'none' }}
      >
        access.recovery@remofirst.com
      </a>{' '}
      and our team will help you out.
    </Typography>
  )
}
