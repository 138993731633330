import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Modal from '@atoms/Modal/Modal'
import Typography from '@atoms/Typography/Typography'
import * as icons from '@core/icons/icons'
import { clearMedium } from '@core/icons/icons'
import React from 'react'
import CopyToClipboard from 'react-copy-to-clipboard'
import { Link } from 'react-router-dom'
import { useBoolean } from 'usehooks-ts'

export default ({ payroll, onClose }) => {
  const { transaction_id } = payroll
  const { value, setTrue } = useBoolean(false)

  const handleClose = () => {
    onClose(null)
  }

  const handleCopy = () => {
    setTrue(true)
  }

  return (
    <Modal
      style={{
        transition: 'height 300ms linear',
        padding: '36px 40px',
        minHeight: '381px',
        borderRadius: 28,
      }}
      onClose={handleClose}
      className="d-flex align-items-center flex-column"
    >
      <div className="d-flex justify-content-end w-100 mb-4">
        <Icon
          // eslint-disable-next-line react/destructuring-assignment
          onClick={handleClose}
          icon={clearMedium}
          fill="#878787"
          style={{ cursor: 'pointer' }}
        />
      </div>
      <img src="/assets/img/payroll_approved.png" alt="payroll_approved_image" />
      <Typography className="heading_semibold__24 mb-3 text-center mt-4">Payroll Approved</Typography>
      <Typography className="text_regular__14 color_text_300 text-center">
        You can find your invoice in the
        <Link to="/pages/invoices" className="invoice-link">
          &nbsp;Invoices
        </Link>{' '}
        tab. Ensure timely payment to guarantee that your employees receive their pay on time.
      </Typography>
      {transaction_id && (
        <div className="copy_text d-flex align-content-center my-5">
          <div className="d-flex flex-column">
            <Typography className="text_regular__14 color_text_300">Reference number:</Typography>
            <Typography className="heading_semibold__16" id="copy_text">
              {transaction_id}
            </Typography>
          </div>
          <CopyToClipboard text={transaction_id} onCopy={handleCopy}>
            <Button data-testid="SuccessApproveModal-8050E5" className="p-0" priority="outline" size="medium">
              <div className="copy_text-icon">
                <Icon icon={value ? icons.check : icons.copyText} fill="none" />
              </div>
            </Button>
          </CopyToClipboard>
        </div>
      )}
    </Modal>
  )
}
