import Button from '@atoms/Button/Button'
import Select from '@atoms/Select/Select'
import Typography from '@atoms/Typography/Typography'
import { DEFAULT_CURRENCIES } from '@core/constants'
import { useApp } from '@core/context'
import { SWIFT_FEE_PAY_ROLE, SwiftFeePayrollLabel } from '@pages/profilePage/mock'
import { Modal } from 'react-bootstrap'
import { Controller, useForm } from 'react-hook-form'
import { v4 } from 'uuid'

const formId = v4()

const BANK_ACCOUNT = {
  Local: 'local',
  Global: 'global',
}

const BANK_OPTIONS = {
  [BANK_ACCOUNT.Global]: {
    id: BANK_ACCOUNT.Global,
    text: 'Global',
    value: BANK_ACCOUNT.Global,
    tooltipText: 'Payment to Remofirst will be made via international wire transfer.',
  },
  [BANK_ACCOUNT.Local]: {
    id: BANK_ACCOUNT.Local,
    text: 'Local',
    value: BANK_ACCOUNT.Local,
    tooltipText: 'Payment to Remofirst will be processed through local bank transfers.',
  },
}

const getBankAccountType = (currency) => {
  switch (currency) {
    case 'GBP':
    case 'USD':
    case 'EUR':
      return [BANK_OPTIONS[BANK_ACCOUNT.Local], BANK_OPTIONS[BANK_ACCOUNT.Global]]
    case 'INR':
    case 'SGD':
      return [BANK_OPTIONS[BANK_ACCOUNT.Local]]
    case 'HKD':
    case 'AUD':
    case 'NZD':
    case 'JPY':
      return [BANK_OPTIONS[BANK_ACCOUNT.Global]]
    default:
      return [BANK_OPTIONS[BANK_ACCOUNT.Global]]
  }
}

export const PaymentTermsEditForm = ({ onClose, onNext }) => {
  const { profile, currencies, countries } = useApp()
  const country = countries.find(({ id }) => profile?.legal_address.living_country?.id === id)
  const defaultBankAccountType = country ? country.default_bank_account_type : ''

  const defaultCurrenciesList = currencies.filter((currency) => DEFAULT_CURRENCIES[currency.short_code])
  const {
    handleSubmit,
    control,
    formState: { errors },
  } = useForm({
    defaultValues: {
      invoice_settings: {
        swift_fee_pay_role: {
          value: profile.invoice_settings.swift_fee_pay_role || SWIFT_FEE_PAY_ROLE.COMPANY,
          label: SwiftFeePayrollLabel[profile.invoice_settings.swift_fee_pay_role || SWIFT_FEE_PAY_ROLE.COMPANY],
        },
        bank_account_type: profile?.invoice_settings.bank_account_type || defaultBankAccountType || '',
      },
      currency: {
        value: profile?.default_currency?.id,
        label: profile?.default_currency?.short_code,
      },
    },
  })

  const onSubmit = ({ invoice_settings, currency }) => {
    const { bank_account_type, swift_fee_pay_role } = invoice_settings
    onNext({
      invoice_settings: {
        bank_account_type,
        swift_fee_pay_role: swift_fee_pay_role.value,
      },
      currency: currency.value,
    })
  }

  return (
    <Modal show centered onHide={onClose} animation={false}>
      <Modal.Body>
        <form id={formId} className="d-flex flex-column w-100" onSubmit={handleSubmit(onSubmit)}>
          <Typography className="heading_semibold__20 color_black mb-2">Company currency</Typography>
          <div className="mb-4">
            <Controller
              control={control}
              name="currency"
              rules={{ required: 'Currency is required' }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="payment-information-form.component-4F97DA"
                    {...field}
                    label="Default currency"
                    addText="Invoices from Remofirst to your company will be generated in the following currency"
                    options={
                      defaultCurrenciesList.map(({ id, name }) => ({
                        value: id,
                        label: name,
                      })) || []
                    }
                    isRequired
                  />
                )
              }}
            />
          </div>
          <Typography className="heading_semibold__20 color_black mb-2">Contractor payments</Typography>
          <div className="w-100 remo-form-input">
            <Controller
              control={control}
              name="invoice_settings.swift_fee_pay_role"
              rules={{
                required: 'International wire transfer (SWIFT) Fee is required',
              }}
              render={({ field }) => {
                return (
                  <Select
                    data-testid="PaymentTermsEditForm-DD1FC9"
                    {...field}
                    isRequired
                    label="International wire transfer (SWIFT) Fee"
                    options={Object.entries(SwiftFeePayrollLabel)
                      .slice(0, 3)
                      .map(([key, value]) => ({ value: key, label: value }))}
                  />
                )
              }}
            />
            <Typography className="text_light__12 color_text_300 mt-1" style={{ marginBottom: 12 }}>
              If contractor payment will be done via International wire transfer, the payment rails for contractor
              payments will utilize SWIFT wire transfers. SWIFT fee is fixed at 20 USD per transaction
            </Typography>
            {errors.invoice_settings?.swift_fee_pay_role && (
              <Typography className="text_regular__14 color_red">
                {errors.invoice_settings.swift_fee_pay_role.message}
              </Typography>
            )}
          </div>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="PaymentTermsEditForm-907170"
          size="small"
          color="primary_black"
          type="button"
          priority="secondary"
          className="mr-2"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button data-testid="PaymentTermsEditForm-38FBE1" size="small" type="submit" form={formId}>
          Next
        </Button>
      </Modal.Footer>
    </Modal>
  )
}
