import { Api } from '@api/Api'
import { generateService } from '@core/utils'

export const getTimeOffBalances = generateService(async (params) => {
  const { data } = await Api.get(`contracts/time-off/balances/`, {
    params,
  })

  return data
})

export const getTimeOffBalanceBreakdown = generateService(async (id, params) => {
  const { data } = await Api.get(`contracts/${id}/time-off/balances/`, {
    params,
  })

  return data
})

export const createAdHoc = generateService(async (id, body) => {
  const { contract_id, number_of_days, attached_document, note } = body
  const fd = new FormData()
  fd.append('contract_id', contract_id)
  fd.append('number_of_days', number_of_days)
  if (note) {
    fd.append('note', note)
  }
  if (attached_document) {
    fd.append('attached_document', attached_document)
  }

  const { data } = await Api.post(`contracts/${id}/ad-hoc/create/`, body, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return data
})
