import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Status from '@atoms/Status/Status'
import CustomTable from '@atoms/Table/CustomTable'
import ContentPlaceholder from '@components/content-placeholder/content-placeholder.component'
import { useToast } from '@core/hooks/useNotification'
import { coin, deleteIcon, view } from '@core/icons/icons'
import { deleteDraftContract, getDraftContractDetails, getDraftContractList } from '@services/draft-contract.service'
import { getAgreement } from '@services/sign.service'
import { DotWave } from '@uiball/loaders'
import React, { useState } from 'react'
import { useMutation, useQuery, useQueryClient } from 'react-query'

const DEFAULT_PAGE_LIMIT = 10

const DraftContractsTableActions = ({ item, onView }) => {
  const queryClient = useQueryClient()
  const { successAlert } = useToast()

  const deleteDraftContractMutation = useMutation({
    mutationFn: deleteDraftContract,
    onSuccess: () => {
      queryClient.refetchQueries('getDraftContractList')
      successAlert('Draft has been deleted!')
    },
  })

  const handleDelete = () => {
    deleteDraftContractMutation.mutate(item.id)
  }

  return (
    <div className="d-flex align-items-center">
      <Button
        data-testid="draft-contracts-table.component-0D9DD0"
        onClick={onView}
        className="text_light__12 px-2 mr-1"
        priority="secondary"
        size="small"
        style={{ height: '32px' }}
      >
        {/* <Icon fill="none" icon={view} className="mr-2" /> */}
        View
      </Button>
      <Button
        data-testid="draft-contracts-table.component-60876A"
        onClick={handleDelete}
        className="text_light__12 px-2"
        priority="danger_outlined"
        size="small"
        style={{ height: '32px' }}
      >
        {/* <Icon fill="none" icon={deleteIcon} /> */}
        Delete
      </Button>
    </div>
  )
}

export const DraftContractsTable = ({ onDraftOpen }) => {
  const [page, setPage] = useState(1)
  const [total, setTotal] = useState(0)

  const fields = [
    {
      title: 'Name',
      key: 'full_name',
      type: 'avatar',
      add_key: 'position',
      minWidth: 300,
      width: 300,
    },
    {
      title: 'Status',
      key: 'status',
      type: 'custom',
      render: () => <Status status="draft" />,
    },
    {
      title: 'Actions',
      key: 'actions',
      type: 'custom',
      render: (item) => <DraftContractsTableActions item={item} onView={() => onDraftOpen(item.id)} />,
      width: 100,
    },
  ]

  const { data, isFetching } = useQuery('getDraftContractList', () => getDraftContractList())

  return (
    <div>
      {isFetching ? (
        <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 200 }}>
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <div>
          {data?.results?.length > 0 ? (
            <CustomTable
              fields={fields}
              data={data.results}
              page={page}
              total={total}
              onPage={setPage}
              pageSize={DEFAULT_PAGE_LIMIT}
            />
          ) : (
            <div className="d-flex flex-column justify-content-center align-items-center" style={{ paddingTop: 200 }}>
              <ContentPlaceholder imgSrc="/assets/img/quiet.png" title={`It's quiet in here ...`} />
            </div>
          )}
        </div>
      )}
    </div>
  )
}
