import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 16px;
    border-radius: 16px;
  }
  .modal-header {
    border-bottom: none;
    align-items: flex-start;
    margin-bottom: 0;
  }
  .modal-footer {
    border-top: none;
  }
`

export const TimeOffPolicyDefaultModal = ({ onClose, onSubmit, loading }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Set as company default paid time off policy</Typography>
      </Modal.Header>
      <Modal.Body>
        Do you want to update your company time off policy to match this employee? If you click “Yes”, your current time
        off policy will be overwritten.
      </Modal.Body>

      <Modal.Footer>
        <Button type="button" size="small" priority="secondary" onClick={onClose}>
          No, don’t set as default
        </Button>
        <Button type="submit" size="small" priority="primary" onClick={onSubmit} loading={loading} disabled={loading}>
          Yes, set default PTO policy
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
