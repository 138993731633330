import 'react-datepicker/dist/react-datepicker.css'
import './style.scss'

import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import classNames from 'classnames'
import { forwardRef } from 'react'
import DatePicker from 'react-datepicker'

export default forwardRef((props, ref) => {
  const {
    value,
    onChange,
    label,
    disabled,
    onRemove,
    isRequired,
    className: classNameProps,
    minDate,
    addText,
    maxDate,
  } = props

  return (
    <div className={classNames(['w-100', classNameProps])}>
      <div className="d-flex justify-content-between w-100 align-items-center">
        <div className="d-flex flex-column">
          <Typography className={classNames(['text_medium__14', !addText && 'remo-form-label'])}>
            {label} {isRequired && <span className="text-danger ml-1">*</span>}
          </Typography>
          {addText && (
            <Typography className="text_light__12 color_text_300" style={{ marginBottom: 12 }}>
              {addText}
            </Typography>
          )}
        </div>
        {onRemove && (
          <Button
            data-testid="DateInput-FBD953"
            className="p-0 m-0"
            priority="outlined"
            size="small"
            onClick={onRemove}
          >
            <Typography className="color_red">Clear</Typography>
          </Button>
        )}
      </div>
      <DatePicker
        ref={ref}
        selected={value}
        disabled={disabled}
        onChange={onChange}
        minDate={minDate}
        maxDate={maxDate}
        dateFormat="dd MMM yyyy"
        showMonthDropdown
        showYearDropdown
        dropdownMode="select"
        placeholderText="DD Mon YYYY"
      />
    </div>
  )
})
