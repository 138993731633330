import ContentPlaceholder from '@components/content-placeholder/content-placeholder.component'
import { useApp } from '@core/context'
import React, { useState } from 'react'
import { useNavigate } from 'react-router-dom'

import { SignMsaDocument } from '../sign-msa-document/sign-msa-document.component'
import { Container } from './sign-step.styles'

export const SignStep = () => {
  const { profile } = useApp()
  const navigate = useNavigate()
  const [signed, setSigned] = useState(false)

  const title = profile?.msa_agreement_document?.agreement_template?.title || ''
  const file = profile?.msa_agreement_document?.agreement_template?.file || ''
  const signatureId = profile?.msa_agreement_document?.document_signatures?.[0].id || null

  const handleNavigate = () => {
    navigate(`/`, { replace: true })
  }

  return (
    <Container>
      {signed ? (
        <ContentPlaceholder
          imgSrc="/assets/img/sign-success.png"
          title={
            <>
              <div>Congratulations! </div>
              <div>You have signed the MSA</div>
            </>
          }
          description="Once we verify your company, you will be able to add Full-time employees"
          buttonText="Go to dashboard"
          onClick={handleNavigate}
        />
      ) : (
        <SignMsaDocument title={title} file={file} signatureId={signatureId} onSuccess={() => setSigned(true)} />
      )}
    </Container>
  )
}
