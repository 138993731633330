import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { addWhite, briefcaseMedical, gift } from '@core/icons/icons'
import React from 'react'
import styled from 'styled-components'

const StyledActiveCard = styled.div`
  background: #ebfcef;
  border-radius: 12px;
  width: 300px;
  height: 200px;
  padding: 30px 40px;
`

const CardsWrapper = styled.div`
  background: #f7f8fc;
  border-radius: 12px;
  padding: 30px 40px;
  width: 100%;
  margin-left: 8px;
  display: flex;
`

export default function EmptyBenefit({ onAddBenefit }) {
  return (
    <div className="mt-4">
      <div className="d-flex justify-content-center align-items-center flex-grow-1">
        <div className="text-center">
          <img src="/assets/img/document-search.png" alt="" style={{ width: 48 }} />
          <p className="heading_semibold__20 mb-3 mt-4">No added requests for benefits</p>
          <p className="text_light__14 mb-4">You can add one of the following benefits</p>
          <div className="d-flex align-items-center justify-content-center">
            <Button data-testid="EmptyBenefit-BA4764" size="small" onClick={onAddBenefit}>
              <Icon icon={addWhite} className="mr-2" />
              Add benefits
            </Button>
          </div>
        </div>
      </div>
      {/* //TODO: uncomment after appearence other benefits  */}
      {/* <div className="d-flex" style={{ marginTop: 144 }}> */}
      {/*  <StyledActiveCard> */}
      {/*    <Icon icon={briefcaseMedical} className="mr-2" /> */}
      {/*    <Typography className="heading_semibold__16 mt-3"> */}
      {/*      Healthcare plan{' '} */}
      {/*    </Typography> */}
      {/*    <Typography className="text_light__14 color_text_300 mt-2"> */}
      {/*      Check the available RemoHealth plans{' '} */}
      {/*      /!* eslint-disable-next-line jsx-a11y/anchor-is-valid *!/ */}
      {/*      <a  data-testid="EmptyBenefit-0933DC" href="#" className="color_primary"> */}
      {/*        here */}
      {/*      </a> */}
      {/*    </Typography> */}
      {/*  </StyledActiveCard> */}
      {/*  <CardsWrapper> */}
      {/*    <div style={{ marginRight: 60 }}> */}
      {/*      <Icon icon={gift} className="mr-2" /> */}
      {/*      <Typography className="heading_semibold__16 mt-3"> */}
      {/*        Other benefits */}
      {/*      </Typography> */}
      {/*      <Typography className="text_light__14 color_text_300 mt-2"> */}
      {/*        Would you like to offer other benefits to your employee? Add them */}
      {/*        here for review and we will provide feedback. */}
      {/*      </Typography> */}
      {/*    </div> */}
      {/*    <div style={{ marginRight: 60 }}> */}
      {/*      <Icon icon={gift} className="mr-2" /> */}
      {/*      <Typography className="heading_semibold__16 mt-3"> */}
      {/*        Allowance */}
      {/*      </Typography> */}
      {/*      <Typography className="text_light__14 color_text_300 mt-2"> */}
      {/*        Would you like to offer other benefits to your employee? Add them */}
      {/*        here for review and we will provide feedback. */}
      {/*      </Typography> */}
      {/*    </div> */}
      {/*    <div> */}
      {/*      <Icon icon={gift} className="mr-2" /> */}
      {/*      <Typography className="heading_semibold__16 mt-3"> */}
      {/*        Allowance */}
      {/*      </Typography> */}
      {/*      <Typography className="text_light__14 color_text_300 mt-2"> */}
      {/*        Would you like to offer other benefits to your employee? Add them */}
      {/*        here for review and we will provide feedback. */}
      {/*      </Typography> */}
      {/*    </div> */}
      {/*  </CardsWrapper> */}
      {/* </div> */}
    </div>
  )
}
