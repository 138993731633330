import styled from 'styled-components'

const Root = styled.div``
const File = styled.div`
  background-color: #f5f5f5;
  border: 1px dashed #f5f5f5;
  border-radius: 4px;
  padding: 8px 12px;
`

const FileContainer = styled.div`
  display: flex;
  gap: 8px;
  align-items: center;
`

const FileContent = styled.div`
  display: flex;
  flex: 1;
  gap: 8px;
  align-items: center;
`

const FileName = styled.div`
  color: #4c92c7;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
`

const Description = styled.div`
  color: #8f8f8f;
  font-size: 12px;
  line-height: 16px;
`

const Error = styled.div`
  color: #d6331f;
  font-size: 12px;
  font-weight: 400;
  margin-top: 2px;
`

const IconButton = styled.button`
  background: none;
  border: 0;
  padding: 0;
  margin: 0;
`

export const Styled = {
  Root,
  File,
  FileContainer,
  FileContent,
  FileName,
  Description,
  Error,
  IconButton,
}
