import { SplitNumber } from '@core/utils'
import moment from 'moment'

export const parseInvoice = (invoices) =>
  invoices.map((invoice) => ({
    ...invoice,
    type: invoice.invoice_type,
    amount:
      invoice.status === 'generating'
        ? 'calculating'
        : `${invoice.currency?.sign || invoice.currency?.short_code} ${SplitNumber(invoice.total)}`,
    date: moment(invoice.date).format('DD MMM YYYY'),
    invoiceCount: invoice.invoices.length,
  }))
