import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import { deleteIcon, edit, noteIcon, reviewIcon, viewEyeIcon } from '@core/icons/icons'
import { formatDate } from '@core/utils'
import { OverlayTrigger, Popover, Tooltip } from 'react-bootstrap'

import fileIconSvg from '../../../../assets/images/attachment.svg'
import { MilestoneStatus } from './MilestoneStatus'

export const getMilestoneFields = (handleEdit, handleDelete, currency, handleReview, handleInvoiceClick) => [
  {
    title: 'Milestone',
    key: 'milestone',
    type: 'string',
    maxWidth: 200,
    minWidth: 100,
  },
  {
    title: 'Description',
    key: 'description',
    type: 'custom',
    maxWidth: 100,
    minWidth: 50,
    render: ({ description }) => {
      return description ? (
        <OverlayTrigger
          placement="right"
          overlay={<Popover style={{ maxWidth: 400, width: 'auto', padding: 10 }}>{description}</Popover>}
        >
          <span>
            <Icon icon={noteIcon} />
          </span>
        </OverlayTrigger>
      ) : (
        '-'
      )
    },
  },
  {
    title: 'Deadline',
    type: 'custom',
    key: 'deadline',
    maxWidth: 100,
    minWidth: 80,
    render: ({ deadline }) => {
      return formatDate(deadline)
    },
  },
  {
    title: 'Payment amount',
    key: 'payment_amount',
    type: 'custom',
    maxWidth: 100,
    minWidth: 80,
    render: ({ payment_amount }) => {
      return `${payment_amount} ${currency}`
    },
  },
  {
    title: 'Attachment',
    key: 'attachment.filename',
    type: 'custom',
    maxWidth: 180,
    minWidth: 80,
    render: (row) => {
      return row.attachment?.filename ? (
        <div style={{ display: 'flex', gap: 8 }}>
          <img src={fileIconSvg} alt="" />
          <a
            href={row.attachment.file}
            target="_blank"
            style={{ color: '#4C92C7', fontWeight: 500, textDecoration: 'none' }}
            rel="noreferrer"
          >
            {row.attachment.filename}
          </a>
        </div>
      ) : (
        '-'
      )
    },
  },
  {
    title: 'Status',
    key: 'status',
    type: 'custom',
    maxWidth: 120,
    minWidth: 120,
    render: (row) => <MilestoneStatus state={row.state} deadline={row.deadline} />,
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'custom',
    maxWidth: 200,
    minWidth: 100,
    render: (row) => {
      return (
        <div className="d-flex align-items-center">
          {row.state !== 'completed' && row.state !== 'paid' && row.state !== 'pending_invoice' && (
            <div className="d-flex gap-2 align-items-center mr-2">
              <Button
                onClick={(event) => handleEdit(event, row)}
                className="text_light__12 px-2 pl-0"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon icon={edit} className="mr-2" />
                Edit
              </Button>
              <Button
                onClick={(event) => handleDelete(event, row.milestone_id)}
                className="text_light__12 px-2 pl-0"
                priority="secondary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon icon={deleteIcon} width={20} />
              </Button>
            </div>
          )}
          {row.state === 'completed' && (
            <div>
              <Button
                onClick={(event) => handleReview(event, row.milestone_id)}
                className="text_light__12 px-2 pl-0"
                priority="primary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon icon={reviewIcon} className="mr-2" />
                Review
              </Button>
            </div>
          )}
          {(row.state === 'paid' || row.state === 'pending_payment_approval') && row.payment?.invoice && (
            <div>
              <Button
                onClick={() => handleInvoiceClick(row.payment?.invoice)}
                className="text_light__12 px-2 pl-0"
                priority="primary"
                size="small"
                style={{ height: '32px' }}
              >
                <Icon icon={viewEyeIcon} className="mr-2" />
                View invoice
              </Button>
            </div>
          )}
        </div>
      )
    },
  },
]

export const displayNotFoundMilesontes = (title) => {
  return (
    <div style={{ display: 'flex', color: 'gray', height: 500, alignItems: 'center', justifyContent: 'center' }}>
      {title}
    </div>
  )
}
