import moment from 'moment'

export const monthOptions = (startDate) => {
  const date = new Date(moment(new Date(startDate)).format('YYYY-MM-DD'))
  const months = moment.months()
  date.setDate(1)
  const filterMonth = []
  months.forEach(() => {
    filterMonth.push({
      label: `${months[date.getMonth()]} ${date.getFullYear()}`,
      value: moment(date).format('YYYY-MM-DD'),
      month: date.getMonth(),
    })
    date.setMonth(date.getMonth() + 1)
  })
  return filterMonth
}
