import React from 'react'

export const Flag = ({ code }) => (
  <span
    className={`fi fi-${code}`}
    style={{
      width: 32,
      height: 24,
      marginRight: 16,
      verticalAlign: 'middle',
      border: '1px solid #eee',
    }}
  />
)
