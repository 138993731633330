import { toast } from 'react-toastify'

export const useToast = () => {
  const successAlert = (msg) => {
    toast.success(msg, {
      position: 'top-right',
      theme: 'colored',
    })
  }

  const failedAlert = (msg) => {
    toast.error(msg, {
      position: 'top-right',
      theme: 'colored',
    })
  }

  return { successAlert, failedAlert }
}
