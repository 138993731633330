import { Api } from '@api/Api'

export const fetchManagers = async (id) => {
  const { data } = await Api.get(`companies/managers/?company_id=${id}`)
  return data
}

export const fetchUpdateCompanyManager = async (managerId, body) => {
  const { data } = await Api.patch(`companies/managers/${managerId}/`, body)
  return data
}

export const fetchAddCompanyManager = async (body) => {
  const { data } = await Api.post(`companies/managers/`, body)
  return data
}

export const fetchDeleteCompanyManager = async (managerId) => {
  const { data } = await Api.delete(`companies/managers/${managerId}/`)
  return data
}

export const updateCompanyOwnerProfile = async (id, body) => {
  const { data } = await Api.put(`profile/${id}/`, body)

  return data
}
