/* eslint-disable no-shadow */
import './EmployeesExpense.scss'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Input from '@atoms/Input/Input'
import NotFound from '@atoms/NotFound/NotFound'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import PageTitle from '@atoms/PageTitle/PageTitle'
import Select from '@atoms/Select/Select'
import Tab from '@atoms/Tabs/libs/Tab/Tab'
import Tabs from '@atoms/Tabs/Tabs'
import Typography from '@atoms/Typography/Typography'
import { EXPENSE_STATUSES, USER_GROUPS } from '@core/constants'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import usePagination from '@core/hooks/usePagination'
import { useRouteQuery, useSearchParams } from '@core/hooks/useRouteQuery'
import { coin, searchIcon } from '@core/icons/icons'
import { capitalizeFirstLetter, mapEnum, userHasOneOfGroupsOrSuper } from '@core/utils'
import { bulkApprove, createExpense, getExpenseManagements } from '@services/expense.service'
import { DotWave } from '@uiball/loaders'
import { last } from 'lodash'
import React, { useEffect, useState } from 'react'
import { HiDownload } from 'react-icons/hi'
import { useMutation, useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import { useBoolean, useDebounceValue } from 'usehooks-ts'

import { CreateExpenseModal } from './create-expense-modal/create-expense-modal.component'
import DownloadReport from './DownloadReport'
import ExpenseAccordion from './ExpenseAccordion'

export default function EmployeesExpense() {
  const navigate = useNavigate()
  const { profile, userGroups } = useApp()
  const { successAlert } = useToast()
  const routeQuery = useRouteQuery()
  const { params, setParams } = useSearchParams()
  const isOpenDownloadCsv = useBoolean(false)
  const { page, limit, setPage } = usePagination({
    page: 1,
    limit: 10,
  })

  const [createdExpenseId, setCreatedExpenseId] = useState(null)
  const [tab, setTab] = useState(0)
  const [statuses, setStatuses] = useState([EXPENSE_STATUSES.PENDING, EXPENSE_STATUSES.AWAITING_PAYMENT])
  const initialSearch = params.get('search') || ''
  const initialStatus = params.get('status') || null
  const [search, setSearch] = useState(initialSearch)
  const [debouncedSearch, setDebouncedSearch] = useDebounceValue(initialSearch, 500)
  const [statusFilterValue, setStatusFilterValue] = useState(initialStatus)
  const [FilterStates, setFilterStates] = useState([EXPENSE_STATUSES.PENDING, EXPENSE_STATUSES.AWAITING_PAYMENT])

  useEffect(() => {
    setParams({ search: debouncedSearch, status: statusFilterValue })
    setPage(1)
  }, [debouncedSearch, statusFilterValue])

  const { data, isLoading, isFetching, refetch } = useQuery(
    ['employeeExpense', statuses, debouncedSearch, statusFilterValue, page, limit],
    {
      queryFn: () => {
        const params = {
          state_in: statusFilterValue || statuses.join(','),
          search: debouncedSearch,
          limit,
          page,
          ordering: '-expense_state_order',
        }

        return getExpenseManagements(params)
      },
    }
  )

  const onTabChange = (id) => {
    setTab(id)
    setPage(1)
    setSearch('')
    setDebouncedSearch('')
    setStatusFilterValue(null)
    setParams({ search: '', status: null })

    switch (id) {
      case 0:
        setStatuses([EXPENSE_STATUSES.PENDING, EXPENSE_STATUSES.AWAITING_PAYMENT])
        setFilterStates([EXPENSE_STATUSES.PENDING, EXPENSE_STATUSES.AWAITING_PAYMENT])
        break
      case 1:
        setStatuses([EXPENSE_STATUSES.CANCELLED, EXPENSE_STATUSES.PAID, EXPENSE_STATUSES.REJECTED])
        setFilterStates([EXPENSE_STATUSES.CANCELLED, EXPENSE_STATUSES.PAID, EXPENSE_STATUSES.REJECTED])
        break
      default:
        break
    }
  }

  const onChangeSearch = (evt) => {
    const val = evt.target.value
    setSearch(val)
    setDebouncedSearch(val)
    setPage(1)
  }

  const onSelectStatus = (selectedOption) => {
    const selectedStatus = selectedOption?.value || null
    setStatusFilterValue(selectedStatus)
    setPage(1)
  }

  const handleClearAll = () => {
    setSearch('')
    setDebouncedSearch('')
    setStatusFilterValue(null)
    setParams({ search: '', status: null })
    setPage(1)
  }

  const newExpenseModalState = useBoolean(Boolean(routeQuery.get('openAddModal')))

  const handleModalOpen = () => {
    newExpenseModalState.setTrue()
  }

  const handleModalClose = () => {
    routeQuery.delete('openAddModal')
    navigate({
      search: routeQuery.toString(),
    })
    newExpenseModalState.setFalse()
  }

  const createExpenseMutation = useMutation({
    mutationFn: createExpense,
    onSuccess: (response) => {
      if (response.data) {
        setCreatedExpenseId(last(response.data).id)
      }
      refetch()
      setPage(1)
      handleModalClose()
      successAlert('Successfully created!')
    },
  })

  const bulkApproveMutation = useMutation({
    mutationFn: bulkApprove,
    onSuccess: () => {
      refetch()
      setCreatedExpenseId(null)
    },
  })

  const renderTable = React.useMemo(() => {
    return data?.results.length > 0 ? (
      <div className="d-flex flex-column mb-4">
        <div className="">
          <ExpenseAccordion
            isBulk={
              tab === 0 &&
              userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST, USER_GROUPS.EXPENSE_MANAGER])
            }
            expenses={data.results}
            createdExpenseId={createdExpenseId}
            isLoading={bulkApproveMutation.isLoading || isFetching}
            pagination={{
              count: data.count,
              limit,
              page,
              setPage,
            }}
            refetch={refetch}
            bulkApprove={bulkApproveMutation.mutate}
          >
            <div className="d-flex px-3">
              <div className="row expense-table-header w-100">
                <div className="col-2">
                  <Typography className="text_regular-normal__14 color_text_300">Team member</Typography>
                </div>
                <div className="col-2">
                  <Typography className="text_regular-normal__14 color_text_300">Name</Typography>
                </div>
                <div className="col-2">
                  <Typography className="text_regular-normal__14 color_text_300">Submitted</Typography>
                </div>
                <div className="col-1">
                  <Typography className="text_regular-normal__14 color_text_300">Amount</Typography>
                </div>
                <div className="col-2 d-flex">
                  <Typography className="text_regular-normal__14 color_text_300">Created by</Typography>
                </div>
                <div className="col-1 d-flex">
                  <Typography className="text_regular-normal__14 color_text_300">Expected payout</Typography>
                </div>
                <div className="col-2 justify-content-end d-flex">
                  <Typography className="text_regular-normal__14 color_text_300">Status</Typography>
                </div>
              </div>
            </div>
          </ExpenseAccordion>
        </div>
      </div>
    ) : (
      <div>
        <NotFound
          title="It's quiet in here ..."
          action={
            userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.EXPENSE_MANAGER, USER_GROUPS.HR_SPECIALIST])
              ? '+ Submit expense'
              : undefined
          }
          onClickAction={handleModalOpen}
        />
      </div>
    )
  }, [data, page, limit, isFetching, bulkApproveMutation.isLoading])

  return (
    <div className="employees-time-page">
      <div className="d-flex justify-content-between align-items-center mb-4">
        <PageTitle>Expenses</PageTitle>
        <div className="d-flex gap-2">
          <Button
            data-testid="EmployeesExpense-A8EDC7"
            priority="secondary"
            size="small"
            onClick={isOpenDownloadCsv.setTrue}
            hidden={!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST, USER_GROUPS.EXPENSE_MANAGER])}
          >
            <HiDownload className="mr-1" />
            Download report
          </Button>
          <Button
            data-testid="EmployeesExpense-02F933"
            className="ml-2"
            priority="secondary"
            size="small"
            onClick={handleModalOpen}
            hidden={!userHasOneOfGroupsOrSuper(userGroups, [USER_GROUPS.HR_SPECIALIST, USER_GROUPS.EXPENSE_MANAGER])}
          >
            Submit expense
          </Button>
        </div>
      </div>
      <NotificationBlock
        render={<Typography className="text_regular__14 ml-2">Manage expense requests from your team.</Typography>}
        cards={[
          {
            title: 'Submit an expense request',
            description: `Click "Submit Expense" to create a new expense request. Please note that any expense approved after the 3rd working day of the current month will be automatically processed in the following month's payroll.`,
            icon: coin,
          },
          {
            title: 'Review expenses requests',
            description:
              'Review pending expense requests for your company. Ensure all pending requests are approved by the 3rd working day of the month.',
            icon: coin,
          },
        ]}
      />
      <div className="d-flex mb-4 align-items-center gap-3">
        <div style={{ width: 250 }}>
          <Input
            placeholder="Employee name"
            type="text"
            onChange={onChangeSearch}
            value={search}
            name="search"
            endIcon={<Icon icon={searchIcon} />}
            styleClass="employees-page-search"
          />
        </div>
        <div style={{ width: 190 }}>
          <Select
            placeholder="Select status"
            value={statusFilterValue}
            onChange={onSelectStatus}
            isClearable
            options={FilterStates.map((state) => ({
              value: state,
              label: mapEnum(capitalizeFirstLetter(state)),
            }))}
            type="text"
          />
        </div>
        <div>
          <Button onClick={handleClearAll} priority="secondary" size="small">
            Clear all
          </Button>
        </div>
      </div>
      <Tabs className="mt-4" onTabChange={onTabChange} selectedTab={tab}>
        <Tab tabId={0} title="Pending">
          {isLoading ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="expense-table-box">
              <div className="expense-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
        <Tab tabId={1} title="History">
          {isLoading || isFetching ? (
            <div className="d-flex w-100 h-100 align-items-center justify-content-center">
              <DotWave size={48} speed={1} color="black" />
            </div>
          ) : (
            <div className="expense-table-box">
              <div className="expense-table-wrap">{renderTable}</div>
            </div>
          )}
        </Tab>
      </Tabs>
      {newExpenseModalState.value && (
        <CreateExpenseModal
          loading={createExpenseMutation.isLoading}
          onClose={handleModalClose}
          onSubmit={(formData) => createExpenseMutation.mutate(formData)}
        />
      )}
      {isOpenDownloadCsv.value && <DownloadReport onClose={isOpenDownloadCsv.setFalse} />}
    </div>
  )
}
