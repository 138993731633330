import moment from 'moment'

export const parseMilestoneState = (state, deadline) => {
  const currentDate = moment()
  if (moment(deadline).isBefore(currentDate, 'day') && ['created', 'in_progress', 'to_revise'].includes(state)) {
    return {
      label: 'Overdue',
      color: '#D6331F',
    }
  }

  switch (state) {
    case 'in_progress':
      return {
        label: 'In progress',
        color: '#497CF6',
      }
    case 'completed':
      return {
        label: 'Complete',
        color: '#2DB77B',
      }
    case 'paid':
      return {
        label: 'Paid',
        color: '#2DB77B',
      }
    case 'pending_invoice':
      return {
        label: 'Pending invoice',
        color: '#ADADAD',
      }
    case 'pending_payment_approval':
      return {
        label: 'Pending payment',
        color: '#497CF6',
      }
    case 'to_revise':
      return {
        label: 'To revise',
        color: '#FFA530',
      }
    case 'created':
      return {
        label: 'Created',
        color: '#2DB77B',
      }
    default:
      return {
        label: state,
        color: '#ADADAD',
      }
  }
}

export const MilestoneStatus = ({ state, deadline }) => {
  const { label, color } = parseMilestoneState(state, deadline)
  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'center',
        backgroundColor: color,
        borderRadius: '50px',
        padding: '4px 12px',
        gap: 8,
        width: 'fit-content',
      }}
    >
      <span style={{ minWidth: '12px', minHeight: '12px', borderRadius: '50%', backgroundColor: '#fff' }} />
      <span style={{ color: '#fff', fontWeight: 600, fontSize: 12 }}>{label}</span>
    </div>
  )
}
