import { fetchUploadFile } from '@services/app.service'
import { isEmpty } from 'lodash'
import React from 'react'

const defaultInitState = {
  isLoading: false,
  progress: 0,
  file_uuid: null,
  success: false,
}

export const useUploadFile = (initState = defaultInitState) => {
  const [file, setFile] = React.useState(initState)

  const upload = (fileToUpload) => {
    const setProgress = (progress) => {
      setFile((prev) => ({
        ...prev,
        isLoading: true,
        progress,
      }))
    }

    fetchUploadFile(fileToUpload, setProgress)
      .then(({ file_uuid }) => {
        setFile((prev) => ({
          ...prev,
          file_uuid,
          isLoading: false,
          success: true,
          progress: 0,
        }))
      })
      .catch(() => {
        setFile((prev) => ({
          ...prev,
          isLoading: false,
          success: false,
          progress: 0,
        }))
      })
  }

  const reset = (resetState) => {
    setFile(isEmpty(resetState) || initState)
  }

  return { file, upload, reset }
}
