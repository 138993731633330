import NotFound from '@atoms/NotFound/NotFound'
import CustomTable from '@atoms/Table/CustomTable'
import { payrollsGenerationField } from '@pages/payrollsDetailPage/mock'
import { parserPayrollsBasePayment } from '@pages/payrollsDetailPage/parse'
import { getPayrollApprovalFLowList, getPayrollInvoiceTotal } from '@services/payroll.service'
import React, { useCallback, useEffect, useState } from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

export const PayrollToGenerationg = ({ setCount }) => {
  const navigate = useNavigate()
  const [payrollsData, setPayrollsData] = useState([])
  const [invoiceId, setInvoiceId] = useState()
  const [invoiceTotal, setInvoiceTotal] = useState()
  const { data, isLoading } = useQuery(['getPayrollApprovalFLowListGenerating'], {
    queryFn: () => getPayrollApprovalFLowList({ limit: 1000, page: 1, status: 'generating' }),
    keepPreviousData: true,
    onSuccess: (v) => {
      setCount((prev) => prev + v.count)
    },
  })

  const { isLoading: invoiceTotalLoading } = useQuery(['invoiceTotal', invoiceId], {
    queryFn: () => getPayrollInvoiceTotal(invoiceId),
    onSuccess: (res) => {
      setInvoiceTotal(res)
    },
    enabled: !!invoiceId,
  })

  const onCollapseClick = useCallback((row) => {
    if (!row.disabled) {
      setInvoiceId(row.id)
    }
  }, [])

  const onClickEdit = useCallback((rowData) => {
    navigate(`/pages/payrolls/edit/${rowData.id}`)
  }, [])

  useEffect(() => {
    if (data) {
      setPayrollsData(data?.current)
      setInvoiceId(data?.current[0]?.id)
    }
  }, [data])
  return (
    <div className="customer-payroll-wrapper">
      <div className="customer-payroll-wrapper-header">NOT READY FOR APPROVAL</div>
      <div className="customer-payroll-wrapper-description">
        Once generated, payroll status will change to ‘To approve’.
      </div>
      {payrollsData.length > 0 ? (
        <div className="customer-payroll-table">
          <CustomTable
            collapseId={invoiceId}
            type="collapse"
            className="customer-payroll-table"
            fields={payrollsGenerationField(onClickEdit)}
            onCollapseClick={onCollapseClick}
            data={parserPayrollsBasePayment(payrollsData, invoiceTotal, invoiceTotalLoading, data.month)}
          />
        </div>
      ) : (
        <NotFound
          illustration="/assets/img/payrollEmpty.png"
          title="No payrolls yet"
          description="They will appear here in the next billing period"
        />
      )}
    </div>
  )
}
