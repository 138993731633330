import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'

export const MsaInfoModal = ({ modalState }) => {
  return (
    <Modal show={modalState.value} onHide={modalState.setFalse} centered animation={false}>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>
          <Typography className="heading_semibold__24">Master service agreement</Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Typography className="text_regular__14 mt-3" style={{ color: '#484848' }}>
          In order to start hiring employees around the globe, you need to sign the Master Service Agreement (MSA) on
          the platform with Service providers. This MSA consists of 2 parts:
          <br />
          <br />
          <b>1. Main part</b> - This agreement has general clauses and allows you to work with the platform and hire
          remote team globally.
          <br />
          <br />
          <b>2. Appendix part</b> - to be signed by you & Local Service Provider in order to hire employees in
          particular country. This agreement is an annex of main part of MSA and has specific country clauses.
        </Typography>
      </Modal.Body>
    </Modal>
  )
}
