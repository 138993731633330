import { Api } from '@api/Api'

export const getPayrollList = async ({ limit, page }) => {
  const offset = (page - 1) * limit
  const { data } = await Api.get(`/payrolls/flow/list/?limit=${limit}&offset=${offset}`)
  return data
}

export const getPayrollApprovalFLowList = async ({ limit, page, status }) => {
  const offset = (page - 1) * limit
  const { data } = await Api.get(
    `/payrolls/approval-flow/client/list/?limit=${limit}&offset=${offset}&status=${status}`
  )
  return data
}

export const getPayrollListNeedChange = async () => {
  const { data } = await Api.get(`/payrolls/flow/list/need-changes/`)
  return data
}
export const getPayrollById = async (id) => {
  const { data } = await Api.get(`/payrolls/${id}/`)
  return data
}
export const approvePayroll = async () => {
  const { data } = await Api.post(`/payrolls/flow/approve/`)
  return data
}

export const partialPayrollApprove = async (body) => {
  const { data } = await Api.put(`/payrolls/approval-flow/client/partial-approve/`, body)
  return data
}
export const getPayrollByCountryId = async (id) => {
  const { data } = await Api.get(`/payrolls/flow/by-country/${id}/`)
  return data
}
export const getPayrollInvoiceTotal = async (id) => {
  const { data } = await Api.get(`/payrolls/flow/${id}/totals/`)
  return data
}

export const changePayrollStatus = async (id, body) => {
  const { data } = await Api.post(`/payrolls/${id}/change-status/`, body)
  return data
}

export const fetchDeletePayroll = async (id) => {
  const { data } = await Api.post(`/payrolls/${id}/change-state/`, {
    state: 'archived',
  })
  return data
}
export const fetchViewCompanyInvoices = async (body) => {
  const { data } = await Api.post(`/payrolls/flow/invoice-preview/`, body, {
    responseType: 'blob',
  })
  return data
}

export const postCalculateLastDay = async (body, type) => {
  const { data } = await Api.post(`/calculate-last-day/`, body)
  return data
}

export const downloadPayrollCsv = async (params) => {
  const { data } = await Api.get('/payrolls/reports/', {
    params,
    responseType: 'blob',
  })

  return data
}
