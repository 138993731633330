import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { deleteIconFilled } from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import { useDropzone } from 'react-dropzone'

import { Styled } from './attachment-dropzone.styles'

export const AttachmentDropzone = ({
  multiple,
  accept,
  onDrop,
  errorText = '',
  helperText = '',
  children,
  isLoading,
  file,
  onDelete,
}) => {
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    multiple,
    accept,
    onDrop,
  })

  return (
    <>
      <Styled.Root error={!!errorText}>
        {!file && (
          <Styled.Container {...getRootProps()}>
            <input data-testid="attachment-dropzone-E88DCE" {...getInputProps()} />

            <Styled.Content>
              {isDragActive ? (
                <Styled.Title>Drop the {multiple ? 'files' : 'file'} here ...</Styled.Title>
              ) : (
                <Styled.Title>Drag and drop your {multiple ? 'files' : 'file'} here</Styled.Title>
              )}
              <Styled.Description>{helperText || children}</Styled.Description>
            </Styled.Content>

            {isLoading ? (
              <DotWave size={35} />
            ) : (
              <Button data-testid="attachment-dropzone-450373" priority="secondary" size="small" type="button">
                Select file
              </Button>
            )}
          </Styled.Container>
        )}

        {file && (
          <div className="d-flex align-items-center justify-content-between">
            <div className="d-flex align-items-center gap-1">
              <div className="d-flex flex-column gap-1">
                <Typography href={URL.createObjectURL(file)} target="_blank" variant="body2" rel="noreferrer">
                  {file.name}
                </Typography>
                <Typography variant="body2" className="secondary-variant">
                  ({file.size / 1000}) Kb
                </Typography>
              </div>
            </div>
            <div role="button" aria-hidden style={{ cursor: 'pointer' }} onClick={onDelete}>
              <Icon icon={deleteIconFilled} />
            </div>
          </div>
        )}
      </Styled.Root>
      {errorText && <Styled.Error>{errorText}</Styled.Error>}
    </>
  )
}
