/* eslint-disable */
/* eslint-disable no-nested-ternary */
import './Table.scss'

import Icon from '@atoms/Icon/Icon'
import Pagination from '@atoms/Pagination/Pagination'
import TableCheckbox from '@atoms/Table/libs/TableCheckbox/TableCheckbox'
import { arrowDown } from '@core/icons/icons'
import * as icons from '@core/icons/icons'
import { DotWave } from '@uiball/loaders'
import React, { useEffect, useState } from 'react'
import OverlayTrigger from 'react-bootstrap/OverlayTrigger'
import Tooltip from 'react-bootstrap/Tooltip'
import { useRowSelect, useSortBy, useTable } from 'react-table'

export default function SelectTable({
  page,
  total,
  pageSize = 10,
  onPage,
  columns: columnsData,
  data,
  loading,
  customSelectedIds,
  onChangeSelect,
}) {
  // Use the state and functions returned from useTable to build your UI
  const [selectedRowIds, setSelectedRowIds] = useState(
    customSelectedIds?.reduce((obj, id) => {
      obj[id] = true
      return obj
    }, {}) || {}
  )
  const [checkedAll, setCheckedAll] = useState(false)
  const { getTableProps, getTableBodyProps, headerGroups, rows, prepareRow, selectedFlatRows, toggleAllRowsSelected } =
    useTable(
      {
        columns: columnsData,
        data,
      },
      useSortBy,
      useRowSelect,
      (hooks) => {
        hooks.visibleColumns.push((columns) => [
          // Let's make a column for selection
          {
            id: 'selection',
            // The header can use the table's getToggleAllRowsSelectedProps method
            // to render a checkbox
            width: 40,
            minWidth: 40,
            maxWidth: 40,
            // The cell can use the individual row's getToggleRowSelectedProps method
            // to the render a checkbox
            Cell: ({ row }) => {
              return row?.original?.isDisabled && row?.original?.tooltipText ? (
                <OverlayTrigger placement="bottom" overlay={<Tooltip>{row?.original?.tooltipText}</Tooltip>}>
                  <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
                    <Icon icon={icons.infoIcon} fill="orange" />
                  </span>
                </OverlayTrigger>
              ) : (
                <TableCheckbox
                  checked={selectedRowIds?.[row.original.id] || false} // Use your custom ID here
                  onChange={() => {
                    row.toggleRowSelected()
                    setSelectedRowIds((prevSelectedRowIds) => {
                      const customId = row.original.id
                      // Toggle the selection for the customId
                      return {
                        ...prevSelectedRowIds,
                        [customId]: !prevSelectedRowIds[customId],
                      }
                    })
                  }}
                  style={{ width: 18, height: 18, marginLeft: 4 }}
                />
              )
            },
          },
          ...columns,
        ])
      }
    )
  // Render the UI for your table
  useEffect(() => {
    if (Object.keys(selectedRowIds).length > 0)
      onChangeSelect(Object.keys(selectedRowIds).filter((k) => selectedRowIds[k]))
  }, [selectedRowIds])
  return (
    <>
      {loading ? (
        <div className="d-flex w-100 align-items-center justify-content-center" style={{ height: 300 }}>
          <DotWave size={40} speed={1} color="black" />
        </div>
      ) : (
        <table {...getTableProps()} cellSpacing="0" cellPadding="0">
          <thead>
            {headerGroups.map((headerGroup) => (
              <tr {...headerGroup.getHeaderGroupProps()}>
                {headerGroup.headers.map((column) => (
                  <th
                    {...column.getHeaderProps(column.getSortByToggleProps())}
                    style={{
                      minWidth: column.minWidth,
                      width: column.width,
                      maxWidth: column.maxWidth,
                    }}
                  >
                    <div className="d-flex align-items-center table_header">
                      {column.render('Header')}
                      {!column.isSorted && <Icon fill="#878787" className="sort_icon" icon={arrowDown} />}
                      {column.isSorted ? (
                        column.isSortedDesc ? (
                          <Icon fill="#878787" className="rotate" icon={arrowDown} />
                        ) : (
                          <Icon fill="#878787" icon={arrowDown} />
                        )
                      ) : (
                        <span style={{ width: 20, height: 20 }} />
                      )}
                    </div>
                  </th>
                ))}
              </tr>
            ))}
          </thead>
          <tbody {...getTableBodyProps()}>
            {rows.map((row, i) => {
              prepareRow(row)
              return (
                <tr
                  // eslint-disable-next-line react/no-array-index-key
                  key={i}
                  {...row.getRowProps()}
                >
                  {row.cells.map((cell) => (
                    <td
                      {...cell.getCellProps()}
                      style={{
                        minWidth: cell.column.minWidth,
                        width: cell.column.width,
                        maxWidth: cell.column.maxWidth,
                      }}
                    >
                      {cell.render('Cell')}
                    </td>
                  ))}
                </tr>
              )
            })}
          </tbody>
        </table>
      )}
      {page && Math.ceil(total / pageSize) > 1 && (
        <Pagination page={page} pageSize={pageSize} total={total} onGetPage={onPage} />
      )}
    </>
  )
}
