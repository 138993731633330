import { useKompassifyNotifications } from '@core/hooks/useKompassifyNotifications'

import styles from './sidebar.module.scss'

export const KompassifyNotificationBadge = () => {
  const notifMessages = useKompassifyNotifications()

  if (!notifMessages) {
    return null
  }
  return (
    <span className={styles['section__item-counter']} {...{ 'kompassify-toggle-notif-widget': 'true' }}>
      {notifMessages > 9 ? '9+' : notifMessages}
    </span>
  )
}
