import Button from '@atoms/Button/Button'
import { StepSequence } from '@components/step-sequence/step-sequence.component'
import React from 'react'
import { Modal, ModalBody } from 'react-bootstrap'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  .modal-content {
    background-color: #f5f5f5;
  }
`
const StyledBody = styled(ModalBody)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`
const Title = styled.div`
  margin: 32px 0 24px 0;
  font-family: Manrope;
  font-size: 24px;
  font-weight: 600;
  line-height: 28px;
  letter-spacing: 0em;
  text-align: center;
`
const Description = styled.div`
  width: 400px;
  margin-bottom: 32px;
  font-family: Inter;
  font-size: 14px;
  font-weight: 400;
  line-height: 23px;
  letter-spacing: 0px;
  text-align: center;
  color: #878787;
`
const Actions = styled.div`
  margin-top: 2rem;
`

const steps = [
  {
    title: 'You pay a deposit',
    description:
      'You will receive a deposit invoice in 1-3 days. We will activate your account, once we receive a payment',
  },
  {
    title: 'Service provider signs the MSA appendix',
    description: 'Remofirst local partner signs the MSA appendix',
  },
  {
    title: 'Employee uploads the documents',
    description: 'Employee will receive an email and will need to upload the required documents',
  },
  {
    title: 'Employee signs the employment agreement',
    description: 'You will receive a copy of the employment contract for a review',
  },
]

export const CreateSuccessModal = ({ employee }) => {
  const navigate = useNavigate()

  const handleGoToOnboarding = () => {
    navigate('/pages/team')
  }

  return (
    <StyledModal show fullscreen>
      <StyledBody>
        <img src="/assets/img/success.png" alt="Great job" />

        <Title>Great Job!</Title>
        <Description>
          {`${employee?.profile.first_name} ${employee?.profile.last_name} at ${employee?.profile.email} has been saved as draft. Once you pay a deposit and our local provider signs the appendix, this employee will be invited to complete their onboarding process.`}
        </Description>

        <div>
          <p className="text_medium__14 mb-2">Next steps:</p>
          <StepSequence steps={steps} />
        </div>

        <Actions>
          <Button
            data-testid="create-success-modal.component-BEFA48"
            size="small"
            type="button"
            onClick={handleGoToOnboarding}
          >
            Go to Onboarding
          </Button>
        </Actions>
      </StyledBody>
    </StyledModal>
  )
}
