import Button from '@atoms/Button/Button'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import { BillingContactForm } from '@components/billing-contact/billing-contact-form'
import { useApp } from '@core/context'
import React from 'react'
import { useFormContext } from 'react-hook-form'

const billingContactFormId = 'billingContactFormId'

export const BillingContacts = ({ loading, onSubmit }) => {
  const { profile } = useApp()
  const { setValue } = useFormContext()
  const [checkboxes, setCheckboxes] = React.useState({
    self: false,
    byEmail: false,
  })

  const handleSubmit = (contacts) => {
    setValue('billing_contacts', contacts)
    onSubmit()
  }

  const isEmpty = Object.values(checkboxes).filter((checked) => !!checked).length === 0

  const handleChange = (name, checked) => {
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }))
  }

  return (
    <div className="d-flex h-100 justify-content-center align-items-center">
      <div className="d-flex flex-column gap-3" style={{ width: 488 }}>
        <b>Who is the billing contact for your company?</b>
        <NotificationBlock text="Please, provide at least one billing contact. You will be able to change this information later in your profile." />
        <BillingContactForm
          formId={billingContactFormId}
          checkboxes={checkboxes}
          profile={profile}
          onChange={handleChange}
          onSubmit={handleSubmit}
        />

        <Button
          data-testid="billing-contacts-0C25A1"
          form={billingContactFormId}
          type="submit"
          size="small"
          disabled={loading || isEmpty}
        >
          Continue
        </Button>
      </div>
    </div>
  )
}
