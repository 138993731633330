import Button from '@atoms/Button/Button'
import TextArea from '@atoms/TextArea/TextArea'
import Typography from '@atoms/Typography/Typography'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'

export const RejectMilestoneConfirmModal = ({ show, onHide, onConfirm, isLoading }) => {
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  const onFormSubmit = (data) => {
    onConfirm(data)
    reset()
  }

  const handleClose = () => {
    onHide()
    reset()
  }

  return (
    <Modal size="lg" centered show={show} onHide={handleClose} animation={false}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Reject milestone completion </Typography>
      </Modal.Header>
      <form onSubmit={handleSubmit(onFormSubmit)}>
        <Modal.Body>
          <Typography className="heading_semi__16 color_gray mb-4">
            You are about to reject this milestone, this will change the status of the milestone from ‘Completed’ to To
            revise. The contractor will be notified, please provide a description of the work that needs to be
            completed.
          </Typography>
          <TextArea
            placeholder="Enter"
            rows={2}
            type="text"
            label="Reason"
            {...register('rejection_reason', { required: 'The field is required' })}
          />
          {errors.rejection_reason && (
            <Typography className="text_regular__14 color_red">{errors.rejection_reason.message}</Typography>
          )}
          <div className="d-flex justify-content-end mt-5">
            <Button disabled={isLoading} priority="secondary" size="small" className="mr-3" onClick={handleClose}>
              Cancel
            </Button>
            <Button type="submit" disabled={isLoading} priority="primary_dangerous" size="small">
              {isLoading ? 'Loading...' : 'Reject milestone'}
            </Button>
          </div>
        </Modal.Body>
      </form>
    </Modal>
  )
}
