import { Card } from '@components/card/card.component'
import { accessibleOnClick, filterKeyEnter } from '@core/utils'
import React from 'react'
import styled from 'styled-components'
import { useBoolean } from 'usehooks-ts'

import { ReactComponent as CalcIcon } from '../../assets/icons/calculator.svg'
import { CostCalculatorModal } from './cost-calculator-modal/cost-calculator-modal'

const Container = styled.div`
  display: flex;
`
const Content = styled.div`
  margin-right: 1.5rem;
  flex: 1;
`
const Title = styled.div`
  color: #101828;
  font-size: 1rem;
  font-weight: 600;
  font-family: 'Manrope', sans-serif;
  margin-bottom: 0.5rem;
  margin-top: 0.5rem;
`
const Description = styled.div`
  font-size: 0.875rem;
  color: #878787;
`

export const CostCalculatorCard = () => {
  const costCalculatorOpen = useBoolean(false)

  return (
    <>
      <Card color="light" {...accessibleOnClick(costCalculatorOpen.setTrue)(filterKeyEnter)}>
        <Container>
          <Content>
            <Title>Country price and Cost Calculator</Title>
            <Description>
              Use the Employee Cost Calculator to swiftly estimate the additional costs of hiring someone in any country
              worldwide.
            </Description>
          </Content>
          <CalcIcon />
        </Container>
      </Card>
      {costCalculatorOpen.value && <CostCalculatorModal onHide={costCalculatorOpen.setFalse} />}
    </>
  )
}
