/* eslint-disable no-shadow */
import { useState } from 'react'

const usePagination = ({ page, limit }) => {
  const [_page, setPage] = useState(page)
  const [_limit, setLimit] = useState(limit)

  const handleSetLimit = (limit) => {
    setPage(page)
    setLimit(limit)
  }

  return {
    page: _page,
    setPage,
    limit: _limit,
    setLimit: handleSetLimit,
  }
}

export default usePagination
