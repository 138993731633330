import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { InfoMessage } from '@components/info-message/info-message.component'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  .modal-dialog {
    justify-content: center;
  }
  .modal-content {
    padding: 0;
    width: 500px;
  }
  .modal-header {
    padding: 24px 24px 15.5px 24px;
    margin-bottom: 0;
  }
  .modal-body {
    padding: 16px 24px;
  }
  .modal-footer {
    padding-top: 0;
  }
`

export const CreateContractModal = ({ onClose, onSave }) => {
  return (
    <StyledModal show centered onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__20">Create a contract</Typography>
      </Modal.Header>
      <Modal.Body>
        <Typography className="color_grey">
          You will be taken to the Remofirst contract tool, you must confirm your company and signee information, and
          then review the details provided for the corresponding contractor.
        </Typography>

        <Typography className="color_grey mt-4">
          At the end of the process you will have a legally compliant contract ready to send and sign for both parties.
        </Typography>

        <InfoMessage
          message={
            <span style={{ color: '#6d6d6c' }}>
              <strong>Disclaimer:</strong>
              <br />
              <Typography style={{ fontWeight: 500 }}>
                The proposed Agreement represents a template that Remofirst has put into place for Clients. It is
                important to note that this document does not constitute legal advice. The parties involved should
                carefully consider their own business purposes and seek independent legal counsel when concluding a
                contract. It is essential to understand that this template is a general guideline meant to apply to
                various situations. However, it may not cover every possible scenario or circumstance. Therefore,
                parties are encouraged to review and modify this Agreement as necessary to suit their specific needs and
                requirements. Remofirst assumes no liability for any errors, omissions, or inaccuracies in this
                template. The use of this Agreement is at the discretion and risk of the parties involved. Remofirst
                shall not be held responsible for any consequences arising from the reliance on or use of this template.
                By utilizing this Agreement, the parties acknowledge that they have read, understood, and accepted the
                terms of this disclaimer.
              </Typography>
            </span>
          }
        />
      </Modal.Body>

      <Modal.Footer>
        <Button
          data-testid="create-contract-modal-8FC12B"
          type="button"
          size="small"
          priority="secondary"
          onClick={onClose}
        >
          Cancel
        </Button>
        <Button
          data-testid="create-contract-modal-02B336"
          type="submit"
          size="small"
          priority="primary"
          onClick={onSave}
        >
          Continue
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
