import Button from '@atoms/Button/Button'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Typography from '@atoms/Typography/Typography'
import { BillingContactForm } from '@components/billing-contact/billing-contact-form'
import { useApp } from '@core/context'
import React from 'react'

export const BillingInformationForm = ({ formId, onSubmit }) => {
  const { profile } = useApp()
  const [checkboxes, setCheckboxes] = React.useState({
    self: false,
    byEmail: false,
  })

  const handleChange = (name, checked) => {
    setCheckboxes((prev) => ({
      ...prev,
      [name]: checked,
    }))
  }

  return (
    <>
      <Typography className="heading_semibold__20 mb-3">Who is the billing contact for your company?</Typography>
      <NotificationBlock text="Please provide at least one billing contact. You will be able to change this information later in your profile." />
      <BillingContactForm
        formId={formId}
        checkboxes={checkboxes}
        profile={profile}
        onChange={handleChange}
        onSubmit={onSubmit}
      />
    </>
  )
}
