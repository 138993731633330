export const getDocumentFields = (onDownload, onDelete, isContractor) => [
  {
    title: 'Name',
    key: 'name',
    type: 'textMedium',
    icon: 'description',
    maxWidth: 680,
    minWidth: 680,
    width: 680,
  },
  {
    title: 'Uploaded',
    key: 'uploaded',
    type: 'string',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
  {
    title: 'Actions',
    key: 'actions',
    type: 'actions',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
    actions: isContractor
      ? [
          {
            action: onDownload,
            icon: 'download',
          },
        ]
      : [
          {
            action: onDownload,
            icon: 'download',
          },
          {
            action: onDelete,
            icon: 'deleteIcon',
          },
        ],
  },
]
