import 'bootstrap/dist/css/bootstrap.min.css'

import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { AppContext } from '@core/context'
import { emptyCircle, success } from '@core/icons/icons'
import * as icons from '@core/icons/icons'
import { getPayrollList } from '@services/payroll.service'
import moment from 'moment'
import React, { useContext, useEffect, useState } from 'react'
import { OverlayTrigger, Tooltip } from 'react-bootstrap'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'
import styled, { css } from 'styled-components'

const ProgressBar = styled('div')`
  width: ${(p) => `${p.procentage}`};
  background: ${(p) => (p.active ? '#04c53a' : '#EBEBEB')};
  height: 1px;
  display: block;
`

function addWorkingDays(startDate, days) {
  const currentDate = new Date(startDate)
  let addedDays = 0

  while (addedDays < days) {
    currentDate.setDate(currentDate.getDate() + 1)

    // Check if the current day is a weekday (Monday to Friday) and not a holiday
    if (currentDate.getDay() !== 0 && currentDate.getDay() !== 6) {
      // eslint-disable-next-line no-plusplus
      addedDays++
    }
  }

  return currentDate
}

function calculateElapsedPercentage(startDate, endDate) {
  const start = new Date(startDate)
  const end = new Date(endDate)
  const now = new Date()

  // Ensure that the current date is within the range
  if (now < start) return 0
  if (now > end) return 100

  const totalDuration = end - start
  const elapsedDuration = now - start

  const percentage = (elapsedDuration / totalDuration) * 100

  return percentage.toFixed(2) // Return percentage with 2 decimal places
}

const CalendarStepper = () => {
  const navigate = useNavigate()
  const [isEmpty, setIsEmpty] = useState(null)
  const [payrollsData, setPayrollsData] = useState([])

  const { profile } = useContext(AppContext)
  const today = new Date()
  const currentMonthFirstDate = new Date(today.getFullYear(), today.getMonth(), 1)
  const thirdWorkDays = addWorkingDays(currentMonthFirstDate, 3)
  const endDueDate = new Date(thirdWorkDays.getFullYear(), thirdWorkDays.getMonth(), 8)
  const weekDate = new Date(endDueDate.getFullYear(), endDueDate.getMonth(), 16)
  const cutOffDate = new Date(weekDate.getFullYear(), weekDate.getMonth(), weekDate.getDate() + 3)
  const { data } = useQuery(['payrolls'], {
    queryFn: () => getPayrollList({ limit: 100000, page: 1 }),
    keepPreviousData: true,
    onSuccess: (v) => {
      setIsEmpty(v.current.length === 0)
    },
  })

  useEffect(() => {
    if (data) {
      setPayrollsData(data?.current)
    }
  }, [data])

  return (
    <div className="d-flex overview-page-stats mb-3 flex-column" style={{ border: '1px solid green' }}>
      <div className="d-flex align-items-center mb-3">
        <Typography className="semibold__16 mr-2">Payroll Timeline</Typography>
        <Typography className="semibold__16 color_primary mr-2">{moment(today).format('MMMM YYYY')}</Typography>
        <OverlayTrigger
          placement="bottom"
          overlay={
            <Tooltip>
              The dates below are to guarantee your team get paid on time; if you encounter any special difficulty
              please reach out to your customer success manager. Please note if you have new team member joining later
              this month, payroll dates might be impacted but stay assured that our team will guide you through.
            </Tooltip>
          }
        >
          <span style={{ marginLeft: 4, verticalAlign: 'top' }}>
            <Icon icon={icons.infoIcon} style={{ fill: '#878787' }} />
          </span>
        </OverlayTrigger>
      </div>
      <div className="d-flex">
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={calculateElapsedPercentage(currentMonthFirstDate, thirdWorkDays) === 100 ? success : emptyCircle}
            />
            <ProgressBar procentage={`${calculateElapsedPercentage(currentMonthFirstDate, thirdWorkDays)}%`} active />
            <ProgressBar procentage={`${100 - calculateElapsedPercentage(currentMonthFirstDate, thirdWorkDays)}%`} />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">Additional payments & expenses deadline</Typography>
            <Typography className="text_medium__14 mt-2">
              {currentMonthFirstDate.getDate()} {moment(currentMonthFirstDate).format('dddd')}
            </Typography>
            <Typography className="text_light__12 mt-2 color_grey">
              Entry after deadline can only go into {moment(currentMonthFirstDate).format('MMMM')} payroll
            </Typography>
          </div>
          {calculateElapsedPercentage(currentMonthFirstDate, thirdWorkDays) < 100 && (
            <>
              <Button
                data-testid="CalendarStepper-3C4BB9"
                type="button"
                size="xsmall"
                style={{ height: '24px', width: 'fit-content' }}
                priority="primary"
                className="mt-3"
                onClick={() => navigate('/pages/additional-payments')}
              >
                Add additional payment
              </Button>
              <Button
                data-testid="CalendarStepper-6B660D"
                type="button"
                size="xsmall"
                priority="primary"
                style={{ height: '24px', width: 'fit-content' }}
                className="mt-2"
                onClick={() => navigate('/pages/expense')}
              >
                Review expense
              </Button>
            </>
          )}
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon icon={calculateElapsedPercentage(endDueDate, weekDate) === 100 ? success : emptyCircle} />
            <ProgressBar procentage={`${calculateElapsedPercentage(endDueDate, weekDate)}%`} active />
            <ProgressBar procentage={`${100 - calculateElapsedPercentage(endDueDate, weekDate)}%`} />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">Payroll ready for approval</Typography>
            <Typography className="text_medium__14 mt-2">
              Between {thirdWorkDays.getDate()} {moment(thirdWorkDays).format('dddd')} and {weekDate.getDate()}{' '}
              {moment(weekDate).format('dddd')}
            </Typography>
            <Typography className="text_light__12 color_grey mt-2">
              You will get an email notification once payroll is ready for review
            </Typography>
            {calculateElapsedPercentage(endDueDate, weekDate) < 100 &&
              calculateElapsedPercentage(endDueDate, weekDate) > 0 && (
                <Button
                  data-testid="CalendarStepper-279B50"
                  type="button"
                  size="xsmall"
                  style={{ height: '24px', width: 'fit-content' }}
                  priority="primary"
                  className="mt-3"
                  onClick={() => navigate('/pages/payrolls')}
                >
                  Review & Approve
                </Button>
              )}
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon icon={calculateElapsedPercentage(weekDate, cutOffDate) === 100 ? success : emptyCircle} />
            <ProgressBar procentage={`${calculateElapsedPercentage(weekDate, cutOffDate)}%`} active />
            <ProgressBar procentage={`${100 - calculateElapsedPercentage(weekDate, cutOffDate)}%`} />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">Payroll approval cut-off</Typography>
            <Typography className="text_medium__14 mt-2">3 days after payroll ready</Typography>
            <Typography className="text_light__12 color_grey mt-2">
              Pending payroll will be approved automatically and invoice generated
            </Typography>
          </div>
        </div>
        <div className="d-flex flex-column">
          <div className="d-flex align-items-center">
            <Icon
              icon={
                calculateElapsedPercentage(weekDate, cutOffDate) === 100 &&
                payrollsData.filter((pd) => pd.state !== 'approved_by_partner').length === 0
                  ? success
                  : emptyCircle
              }
            />
          </div>
          <div className="mt-2 pr-4">
            <Typography className="semibold__16">Your payment deadline</Typography>
            <Typography className="text_medium__14 mt-2">
              {profile.invoice_settings.due_date_days} days after invoice generated
            </Typography>
            <Typography className="text_light__12 color_grey mt-2">
              Pay your invoice as quickly as you can so your team get paid on time
            </Typography>
            {calculateElapsedPercentage(weekDate, cutOffDate) === 100 && (
              <Button
                data-testid="CalendarStepper-BA75B5"
                type="button"
                size="xsmall"
                style={{ height: '24px', width: 'fit-content' }}
                priority="primary"
                className="mt-3"
                onClick={() => navigate('/pages/invoices')}
              >
                View invoice
              </Button>
            )}
          </div>
        </div>
      </div>
    </div>
  )
}

export default CalendarStepper
