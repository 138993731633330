import './style.scss'

import Icon from '@atoms/Icon/Icon'
import { ArrowLeft, ArrowRight } from '@core/icons/icons'
import classNames from 'classnames'
import React from 'react'

export default function Arrow({ direction, disabled, onClick }) {
  return direction === 'left' ? (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classNames(['arrow-wrap', { 'arrow-wrap--disabled': disabled }])}
      onClick={!disabled ? onClick : undefined}
    >
      <Icon fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon={ArrowLeft} />
      <p className="arrow-wrap__text-left">Previous</p>
    </div>
  ) : (
    // eslint-disable-next-line jsx-a11y/click-events-have-key-events,jsx-a11y/no-static-element-interactions
    <div
      className={classNames(['arrow-wrap', { 'arrow-wrap--disabled': disabled }])}
      onClick={!disabled ? onClick : undefined}
    >
      <p className="arrow-wrap__text-right">Next</p>
      <Icon fill="none" strokeWidth="2" strokeLinecap="round" strokeLinejoin="round" icon={ArrowRight} />
    </div>
  )
}
