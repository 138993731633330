import { useAxiosCall } from '@api/Api'
import Button from '@atoms/Button/Button'
import EditModal from '@atoms/EditModal/EditModal'
import Select from '@atoms/Select/Select'
import { DotWave } from '@uiball/loaders'
import React, { useEffect, useState } from 'react'
import { toast } from 'react-toastify'

export default function AddMemberModal({ onClose, threadId, onAdd }) {
  const [memberList, setMemberList] = useState([])

  const [, postThreadMembersAdd] = useAxiosCall(
    {
      method: 'post',
      url: `threads/${threadId}/members/create/`,
    },
    {
      manual: true,
    }
  )
  const [{ data: membersList, loading: membersListLoading }, getThreadsMembers] = useAxiosCall(
    {
      url: `threads/users/?thread_id=${threadId}`,
    },
    {
      manual: true,
    }
  )
  const onChangeMember = (selectMember) => {
    setMemberList(selectMember)
  }

  const onClickSaveMember = () => {
    const memberIds = memberList.map((user) => user.value)
    postThreadMembersAdd({
      data: {
        members: memberIds,
      },
    }).then(({ data }) => {
      getThreadsMembers()
      toast.success('New members added')
      onClose(false)
      onAdd(data)
    })
  }

  useEffect(() => {
    if (threadId) {
      getThreadsMembers()
    }
  }, [threadId])

  return (
    <EditModal
      // scrollable should be eq to {false} because of select dropdown menu scroll
      scrollable={false}
      visible
      footer={false}
      title="Add members"
      closeModal={onClose}
    >
      <div style={{ minHeight: 300 }}>
        {membersListLoading ? (
          <div className="d-flex w-100 loader align-items-center justify-content-center" style={{ height: 300 }}>
            <DotWave size={32} speed={1} color="black" />
          </div>
        ) : (
          <Select
            data-testid="AddMemberModal-1B0BCA"
            options={membersList?.map((user) => ({
              value: user.id,
              label: user.full_name,
            }))}
            isMulti
            name="thread_member"
            id="thread_member"
            placeholder="Select members"
            onChange={onChangeMember}
            value={memberList}
            label="Add members"
            className="w-100 mb-3"
          />
        )}
        {memberList.length > 0 && (
          <Button
            data-testid="AddMemberModal-B9CCA4"
            className="mb-4"
            priority="primary"
            size="small"
            onClick={onClickSaveMember}
          >
            Add
          </Button>
        )}
      </div>
    </EditModal>
  )
}
