import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React, { useState } from 'react'
import OtpInput from 'react-otp-input'
import styled from 'styled-components'

import { Form } from './auth-modal.styles'

export const MFAForm = ({ isLoading, onSubmit, onClickCantEnterCode }) => {
  const [authCode, setAuthCode] = useState('')
  const handleSubmit = (e) => {
    e.preventDefault()
    onSubmit(authCode)
  }

  return (
    <Form onSubmit={handleSubmit}>
      <div className="d-flex flex-column gap-3">
        <OtpInput
          value={authCode}
          onChange={(value) => {
            setAuthCode(value)
          }}
          numInputs={6}
          renderInput={(props) => (
            <StyledInput
              {...props}
              onKeyDown={(e) => {
                if (!(e.ctrlKey && e.key === 'v')) {
                  props.onKeyDown(e)
                }
                if (e.key === 'Enter' && authCode.length === 6) {
                  onSubmit(authCode)
                }
              }}
            />
          )}
          inputType="number"
          containerStyle={{
            display: 'flex',
            columnGap: '16px',
            alignSelf: 'center',
          }}
          shouldAutoFocus
        />
      </div>

      <Button
        data-testid="mfa-form.component-C93C07"
        loading={isLoading}
        disabled={authCode.length < 6}
        type="submit"
        size="medium"
        style={{ marginTop: '32px' }}
      >
        Continue
      </Button>

      <Typography
        role="button"
        aria-hidden
        style={{
          color: '#04c53a',
          alignSelf: 'center',
        }}
        onClick={() => onClickCantEnterCode()}
      >
        Can&apos;t enter the code?
      </Typography>
    </Form>
  )
}

export const StyledInput = styled.input`
  border-radius: 8px;
  width: 3rem !important;
  height: 4rem;
  font-size: 2rem;
  border: 1px solid rgba(0, 0, 0, 0.3);

  :focus {
    outline: none;
    border-color: #04c53a;
  }

  ::-webkit-outer-spin-button,
  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  -moz-appearance: textfield;
`
