import { Api, ApiNoResInterceptor } from '@api/Api'

export const addMFA = async () => {
  const { data } = await Api.post('/mfa/add/')
  return data
}

export const verifyMFA = async ({ otp, refresh_token }) => {
  const { data } = await ApiNoResInterceptor.post('/mfa/verify/', {
    otp,
    refresh_token,
  })
  return data
}

export const disableMFA = async ({ otp, refresh_token }) => {
  const { data } = await ApiNoResInterceptor.post('/mfa/disable/', {
    otp,
    refresh_token,
  })
  return data
}

export const recoveryDisableMFA = async ({ recovery_code, refresh_token }) => {
  const { data } = await ApiNoResInterceptor.post('/mfa/recovery-code-disable/', {
    recovery_code,
    refresh_token,
  })
  return data
}
