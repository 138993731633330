import Button from '@atoms/Button/Button'
import ModalSide from '@atoms/ModalSide/ModalSide'
import { CardStepper } from '@components/card-stepper/card-stepper.component'
import { CardStepperStep } from '@components/card-stepper/card-stepper-step.component'
import { useApp } from '@core/context'
import { useToast } from '@core/hooks/useNotification'
import { addOnboardingBillingContact, addOnboardingPaymentInfo } from '@services/company.service'
import React from 'react'
import { useMutation, useQueryClient } from 'react-query'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { ReactComponent as ChecklistBillingIcon } from '../../assets/icons/checklist-billing.svg'
import { ReactComponent as ChecklistPaymentIcon } from '../../assets/icons/checklist-payment.svg'
import { BillingInformationForm } from './billing-information-form/billing-information-form.component'
import { PaymentInformationForm } from './payment-information-form/payment-information-form.component'

const paymentInformationFormId = v4()
const billingInformationFormId = v4()

export const OnboardingChecklist = ({ checklist }) => {
  const { profile, countries } = useApp()
  const { successAlert } = useToast()
  const queryClient = useQueryClient()

  const paymentInformationFormOpen = useBoolean(false)
  const billingInformationFormOpen = useBoolean(false)

  const addPaymentInfoMutation = useMutation({
    mutationFn: (payload) => addOnboardingPaymentInfo(payload),
    onSuccess: () => {
      paymentInformationFormOpen.setFalse()
      queryClient.refetchQueries('getOnboardingChecklist')
      successAlert('Payment information has been successfully added!')
    },
  })

  const addBillingContactMutation = useMutation({
    mutationFn: (payload) => addOnboardingBillingContact(payload),
    onSuccess: () => {
      billingInformationFormOpen.setFalse()
      queryClient.refetchQueries('getOnboardingChecklist')
      successAlert('Billing contact has been successfully added!')
    },
  })

  const handlePaymentInfoSubmit = (formValues) => {
    const country = countries.find(({ id }) => profile?.legal_address.living_country?.id === id)
    const defaultBankAccountType = country ? country.default_bank_account_type : ''
    const payload = {
      currency: formValues.currency.value,
      bank_account_type: defaultBankAccountType,
      swift_fee_pay_role: formValues.swiftFeePayRole.value,
    }
    addPaymentInfoMutation.mutate(payload)
  }

  const handleBillingContactSubmit = (values) => {
    const payload = {
      billing_contacts: values,
    }
    addBillingContactMutation.mutate(payload)
  }

  return (
    <>
      <CardStepper>
        <CardStepperStep
          title="Payment information"
          icon={<ChecklistPaymentIcon />}
          completed={checklist.payment_details.passed}
          actionsPlaceholderText={checklist.payment_details.passed && 'Done'}
          actions={[
            <Button
              data-testid="onboarding-checklist.component-6C60C1"
              key={1}
              type="button"
              size="small"
              priority="secondary"
              onClick={paymentInformationFormOpen.setTrue}
            >
              Proceed
            </Button>,
          ]}
        />
        <CardStepperStep
          title="Billing information"
          icon={<ChecklistBillingIcon />}
          disabled={!checklist.payment_details.passed}
          completed={checklist.billing_contacts.passed}
          actionsPlaceholderText={checklist.billing_contacts.passed && 'Done'}
          actions={[
            <Button
              data-testid="onboarding-checklist.component-9A863D"
              key={1}
              type="button"
              size="small"
              priority="secondary"
              onClick={billingInformationFormOpen.setTrue}
            >
              Proceed
            </Button>,
          ]}
        />
      </CardStepper>

      {paymentInformationFormOpen.value && (
        <ModalSide
          title="Payment information"
          okText="Done"
          onClose={paymentInformationFormOpen.setFalse}
          okButtonProps={{
            disabled: false,
            loading: false,
            form: paymentInformationFormId,
            type: 'submit',
          }}
        >
          <PaymentInformationForm formId={paymentInformationFormId} onSubmit={handlePaymentInfoSubmit} />
        </ModalSide>
      )}

      {billingInformationFormOpen.value && (
        <ModalSide
          title="Billing information"
          okText="Done"
          onClose={billingInformationFormOpen.setFalse}
          okButtonProps={{
            disabled: false,
            loading: false,
            form: billingInformationFormId,
            type: 'submit',
          }}
        >
          <BillingInformationForm formId={billingInformationFormId} onSubmit={handleBillingContactSubmit} />
        </ModalSide>
      )}
    </>
  )
}
