import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'
import styled from 'styled-components'

const StyledHeader = styled(Modal.Header)`
  border-bottom: none;
`
const StyledFooter = styled(Modal.Footer)`
  border-top: none;
`

export default ({
  title,
  children,
  isLoading,
  onCancel,
  onYes,
  disabledButton,
  cancelButtonProps = {
    text: 'Cancel',
  },
  yesButtonProps = {
    priority: 'danger',
    text: 'Yes',
    size: 'small',
    type: 'submit',
  },
  ...props
}) => {
  return (
    <Modal {...props} centered show onHide={onCancel}>
      <StyledHeader className="mb-0">
        <Typography className="heading_semibold__24">{title}</Typography>
      </StyledHeader>
      <Modal.Body>{children}</Modal.Body>
      <StyledFooter>
        <Button
          data-testid="Confirm-index-3ADB87"
          type="button"
          size="small"
          priority="secondary"
          onClick={onCancel}
          {...cancelButtonProps}
        >
          {cancelButtonProps.text}
        </Button>
        {onYes && (
          <Button
            data-testid="Confirm-index-5478A0"
            type={yesButtonProps.type || 'submit'}
            size={yesButtonProps.size || 'small'}
            priority={yesButtonProps.priority || 'danger'}
            disabled={isLoading || disabledButton}
            loading={isLoading}
            onClick={onYes}
          >
            {yesButtonProps.text || 'Yes'}
          </Button>
        )}
      </StyledFooter>
    </Modal>
  )
}
