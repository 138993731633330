import Typography from '@atoms/Typography/Typography'
import React from 'react'
import { Modal } from 'react-bootstrap'

export const AboutDepositModal = (props) => {
  return (
    <Modal {...props} show centered animation={false}>
      <Modal.Header closeButton className="mb-0">
        <Modal.Title>
          <Typography className="heading_semibold__24">About a Deposit</Typography>
        </Modal.Title>
      </Modal.Header>
      <Modal.Body className="pt-0">
        <Typography className="text_regular__14 mt-3" style={{ color: '#484848' }}>
          Any new hire will be requested to have a deposit in place. Deposit invoices will be issued at the beginning of
          a supported EOR employee’s onboard with us and will be refunded once the final invoice relating to their
          employment is processed.
          <br />
          <p className="mt-3">
            Unless a very specific situation arises, deposits will be equivalent to <b>1 month’s compensation</b>{' '}
            (salary + allowances/benefits) + employer social costs + our monthly fee.
          </p>
          <br />
          The deposit funds can be used in case of unforeseen circumstances or if there is an urgent need to pay the
          employee before you are able to process the fund to us.
        </Typography>
      </Modal.Body>
    </Modal>
  )
}
