import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import Typography from '@atoms/Typography/Typography'
import { fieldNameMap } from '@core/constants'
import React, { useEffect, useRef } from 'react'
import { Modal } from 'react-bootstrap'
import { useForm } from 'react-hook-form'
import styled from 'styled-components'

const StyledModal = styled(Modal)`
  background: rgba(0, 0, 0, 0.5);
  z-index: 2000;
  .modal-header {
    margin-bottom: 0;
  }
  .modal-content {
    padding: 0;
  }
  .modal-body {
    position: relative;
  }
`

const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }
`

export const LegalAddressEditForm = ({ data, onSubmit, onClose, isLoading }) => {
  const formRef = useRef(null)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm()

  useEffect(() => {
    reset(data)
  }, [data])

  const triggerFormSubmit = () => {
    if (formRef.current) {
      formRef.current.dispatchEvent(new Event('submit', { cancelable: true, bubbles: true }))
    }
  }

  return (
    <StyledModal show onHide={onClose}>
      <Modal.Header closeButton>
        <Typography className="heading_semibold__24">Legal address</Typography>
      </Modal.Header>
      <Modal.Body>
        <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
          <FormControl>
            <Input
              data-testid="legal-address-edit-form.component-6EB7EB"
              {...register('state', {
                required: `${fieldNameMap.state} is required`,
              })}
              type="text"
              label={fieldNameMap.state}
              isRequired
            />
            {errors?.state && <Typography className="text_regular__14 color_red">{errors?.state.message}</Typography>}
          </FormControl>
          <FormControl>
            <Input
              data-testid="legal-address-edit-form.component-567C64"
              {...register('city', {
                required: `${fieldNameMap.city} is required`,
              })}
              type="text"
              label={fieldNameMap.city}
              isRequired
            />
            {errors?.city && <Typography className="text_regular__14 color_red">{errors?.city.message}</Typography>}
          </FormControl>
          <FormControl>
            <Input
              data-testid="legal-address-edit-form.component-6071C8"
              {...register('streetAddress', {
                required: `${fieldNameMap.streetAddress} is required`,
              })}
              type="text"
              label={fieldNameMap.streetAddress}
              isRequired
            />
            {errors?.streetAddress && (
              <Typography className="text_regular__14 color_red">{errors?.streetAddress.message}</Typography>
            )}
          </FormControl>
          <FormControl>
            <Input
              data-testid="legal-address-edit-form.component-A66B26"
              {...register('addressLine', {
                required: `${fieldNameMap.addressLine} is required`,
              })}
              type="text"
              label={fieldNameMap.addressLine}
              isRequired
            />
            {errors?.addressLine && (
              <Typography className="text_regular__14 color_red">{errors?.addressLine.message}</Typography>
            )}
          </FormControl>
          <FormControl>
            <Input
              data-testid="legal-address-edit-form.component-06E7F1"
              {...register('postalCode', {
                required: `${fieldNameMap.postalCode} is required`,
              })}
              type="text"
              label={fieldNameMap.postalCode}
              isRequired
            />
            {errors?.postalCode && (
              <Typography className="text_regular__14 color_red">{errors?.postalCode.message}</Typography>
            )}
          </FormControl>
        </form>
      </Modal.Body>
      <Modal.Footer>
        <Button
          data-testid="legal-address-edit-form.component-8D4F4B"
          type="button"
          priority="secondary"
          size="small"
          onClick={onClose}
        >
          Close
        </Button>
        <Button
          data-testid="legal-address-edit-form.component-307627"
          priority="primary"
          size="small"
          type="button"
          onClick={triggerFormSubmit}
          loading={isLoading}
          disabled={isLoading}
        >
          Save
        </Button>
      </Modal.Footer>
    </StyledModal>
  )
}
