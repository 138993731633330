import styled from 'styled-components'

const Table = styled.table`
  tr,
  th {
    height: 32px;
    color: unset;
    border-radius: 4px;
  }
  tr:nth-child(even) {
    background-color: #f9f9f9;
  }
`

const InputPairs = styled.div`
  display: flex;
  align-items: flex-end;
  margin-bottom: 16px;
  gap: 16px;

  .react-datepicker-wrapper {
    width: 100%;
  }
`
const InputPairsLeft = styled.div`
  width: 40%;
`
const Toggle = styled.div`
  height: 40px;
  display: flex;
  gap: 8px;
  align-items: center;
  flex: 1;
`

const Request = styled.div`
  background-color: #f6f6f6;
  border-radius: 8px;
  padding: 16px;
  margin-bottom: 16px;
`
const Review = styled.div`
  background-color: #ecf1fe;
  border-radius: 8px;
  padding: 16px;
`

export const Styled = {
  Table,
  InputPairs,
  InputPairsLeft,
  Toggle,
  Request,
  Review,
}
