import { Api } from '@api/Api'

export const fetchDeleteInvoice = async (invoiceId) => {
  const { data } = await Api.delete(`aggregated-invoices/${invoiceId}/delete/`)
  return data
}
export const getAggregatedInvoice = async (params) => {
  const v = await Api.get('aggregated-invoices/', { params })
  return v.data
}
export const getAggregatedInvoiceReport = async (params) => {
  const v = await Api.get('aggregated-invoices/reports', { params, responseType: 'blob' })
  return v.data
}

export const fetchGetInvoiceById = async (id) => {
  const { data } = await Api.get(`aggregated-invoices/${id}/`)

  return data
}
export const updateAggregatedInvoice = async (invoice) => {
  const { id, data } = invoice
  const v = await Api.post(`/aggregated-invoices/${id}/change-status/`, data)
  return v.data
}

export const invoicePaymentMethods = async (id, body) => {
  const v = await Api.post(`/aggregated-invoices/${id}/change-payment-method/`, body)
  return v.data
}

export const getSubInvoices = async (id) => {
  const { data } = await Api.get(`/aggregated-invoices/${id}/payment-details/`)
  return data
}
