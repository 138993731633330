import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { androidIcon, iosIcon } from '@core/icons/icons'
import React from 'react'
import styled from 'styled-components'

export const DownLoadAuthenticator = () => (
  <HorizontalInfoWrapper>
    <Typography className="heading_semibold__16">How to set up 2FA:</Typography>
    <br />
    <Typography className="text_light__14">1 Download Google Authenticator</Typography>
    <br />
    <Typography className="text_light__14">2 Scan the QR code OR enter a code</Typography>
    <br />
    <Typography className="text_light__14">3 Download and save recovery keys</Typography>
    <br />
    <Typography className="text_light__14">Download Google Authenticator:</Typography>
    <div className="d-flex mt-3">
      <Button
        data-testid="download-authenticator-F3328F"
        size="small"
        priority="secondary"
        style={{ width: 164, marginRight: 12 }}
        onClick={() => {
          window.open('https://play.google.com/store/apps/details?id=com.google.android.apps.authenticator2', '_blank')
        }}
      >
        <Icon icon={androidIcon} className="mr-2" />
        Android
      </Button>
      <Button
        data-testid="download-authenticator-EB1476"
        size="small"
        priority="secondary"
        style={{ width: 164 }}
        onClick={() => {
          window.open('https://apps.apple.com/us/app/google-authenticator/id388497605', '_blank')
        }}
      >
        <Icon icon={iosIcon} className="mr-2" />
        IOS
      </Button>
    </div>
  </HorizontalInfoWrapper>
)

const HorizontalInfoWrapper = styled.div`
  background-color: #f5f5f5;
  padding: 12px;
  border-radius: 8px;
  margin-top: 24px;
  width: 560px;
  .heading_semibold__16 {
    line-height: 32px;
  }
`
