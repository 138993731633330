import React from 'react'
import styled, { css } from 'styled-components'

const Root = styled.div`
  background: #f9f9f9;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 1rem;

  ${(props) => {
    switch (props.color) {
      case 'light':
        return css`
          background: #fff;
          border-radius: 8px;
          box-shadow:
            0px 2px 4px rgba(15, 15, 16, 0.04),
            0px 2px 4px rgba(15, 15, 16, 0.04);
        `
      default:
        return ''
    }
  }}
`

export const Card = ({ color, children, ...rest }) => (
  <Root color={color} {...rest}>
    {children}
  </Root>
)
