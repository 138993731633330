import Button from '@atoms/Button/Button'
import Icon from '@atoms/Icon/Icon'
import Typography from '@atoms/Typography/Typography'
import { Card } from '@components/card/card.component'
import { CardContent } from '@components/card/card-content.component'
import { CardHeader } from '@components/card/card-header.component'
import { useApp } from '@core/context'
import { paymentMethods } from '@core/icons/icons'
import { fetchPaymentMethods } from '@services/stripe.service'
import React from 'react'
import { useQuery } from 'react-query'
import { useNavigate } from 'react-router-dom'

export default function PaymentCard() {
  const navigate = useNavigate()
  const { profile } = useApp()

  const { data } = useQuery('getPaymentMethodsOverview', {
    queryFn: fetchPaymentMethods,
  })

  const handleGoToPayment = () => {
    navigate('/pages/stripe')
  }
  return (
    <div>
      {profile?.is_allowed_ach_payment && data?.length <= 0 && (
        <Card>
          <CardHeader
            title="Recommendation"
            description={<span>You can make payments directly from your bank by setting up an ACH payment</span>}
          />
          <CardContent>
            <Card color="light">
              <div className="d-flex flex-grow-1 align-items-end align-items-end justify-content-between">
                <div className="d-flex align-items-center">
                  <div className="d-flex flex-column">
                    <Typography className="text_medium__14">Set up your payment methods</Typography>
                    <Typography className="text_light__13 mt-1">
                      Establish automatic payment so that eligible payroll invoices and contractor subscription bills
                      are automatically withdrawn from your account.
                    </Typography>
                  </div>
                </div>
                <Button
                  data-testid="PaymentCard-BFB5E0"
                  className="align-self-end"
                  size="small"
                  priority="secondary"
                  onClick={handleGoToPayment}
                >
                  <div className="d-flex align-content-center">
                    <Icon icon={paymentMethods} fill="#037b0f" className="mr-2" />
                    Payment methods
                  </div>
                </Button>
              </div>
            </Card>
          </CardContent>
        </Card>
      )}
    </div>
  )
}
