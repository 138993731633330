import Avatar from '@atoms/Avatar/Avatar'
import Button from '@atoms/Button/Button'
import Typography from '@atoms/Typography/Typography'
import { SplitNumber } from '@core/utils'
import React from 'react'
import { MdAdd, MdChevronLeft } from 'react-icons/md'
import { useNavigate } from 'react-router-dom'
import styled from 'styled-components'

const Banner = styled.div`
  margin: 30px 0 25px 0;
  padding: 16px;
  background: #f9f9f9;
  border-radius: 8px;
`

const StyledBtn = styled(Button)`
  border: 1px solid #cccccc;
  border-radius: 8px;
  padding: 8px;
`

export default ({ onAdd, total, fullName, companyCurrency }) => {
  const navigate = useNavigate()

  return (
    <>
      <div className="d-flex justify-content-between">
        <div className="d-flex align-items-center">
          <StyledBtn size="small" priority="secondary" onClick={() => navigate(-1)}>
            <MdChevronLeft />
          </StyledBtn>
          <Typography className="heading_semibold__24 ml-3">Additional payments</Typography>
        </div>

        <Button data-testid="Header-1986AB" size="small" priority="secondary" onClick={onAdd}>
          <MdAdd style={{ marginRight: 10 }} />
          <Typography>Additional pay</Typography>
        </Button>
      </div>

      <Banner className="d-flex justify-content-between align-items-center">
        <div className="d-flex align-items-center">
          <Avatar height={48} width={48} />
          <Typography>{fullName}</Typography>
        </div>

        <Typography>
          {companyCurrency} {SplitNumber(total)}
        </Typography>
      </Banner>
    </>
  )
}
