import styled, { css } from 'styled-components'

const Form = styled.form`
  width: 100%;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0.5rem;
  }
`

const Label = styled.label`
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 0.875rem;
`
const LabelDescription = styled.div`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 0.75rem;
  color: #878787;
  line-height: 1rem;
  margin-top: 4px;
  margin-bottom: 12px;
`
const RadioBox = styled.div`
  display: flex;
  border: 2px solid #f3f3f3;
  border-radius: 8px;
  padding: 1rem;
  margin-bottom: 0.5rem;

  ${(props) =>
    props.active
      ? css`
          border-color: #04c53a;
        `
      : ''}
`
const RadioBoxBody = styled.div`
  flex: 1;
`
const RadioInput = styled.input`
  margin-right: 1rem;
`

export { Form, FormControl, FormGroup, Label, LabelDescription, RadioBox, RadioBoxBody, RadioInput }
