/* eslint-disable consistent-return */
/* eslint-disable default-case */
import Button from '@atoms/Button/Button'
import Input from '@atoms/Input/Input'
import NotificationBlock from '@atoms/NotificationBlock/NotificationBlock'
import Toggle from '@atoms/Toggle'
import { Tooltip } from '@atoms/Tooltip/tooltip.component'
import Typography from '@atoms/Typography/Typography'
import { SwiftFeePayRoleTooltip } from '@components/swift-fee-pay-role-tooltip/swift-fee-pay-role-tooltip.component'
import { PaymentMethods } from '@core/constants'
import { useApp } from '@core/context'
import { PaymentTermsEditForm } from '@pages/profilePage/payment-terms/PaymentTermsEditForm'
import { updateSwiftFeePayRoles } from '@services/contract.service'
import { patchCompany } from '@store/companies'
import classNames from 'classnames'
import { useState } from 'react'
import { useMutation } from 'react-query'
import { useBoolean } from 'usehooks-ts'
import { v4 } from 'uuid'

import { SWIFT_FEE_PAY_ROLE, SwiftFeePayrollLabel } from '../mock'
import { ContractorsListModal } from './contractors-list-modal/contractors-list-modal'

export const PaymentTerms = () => {
  const { refetchProfile, profile } = useApp()
  const applyForCompanyOwnerModal = useBoolean(false)
  const [companyOwnerPayload, setCompanyOwnerPayload] = useState(null)
  const isWisePaymentMethod = profile?.contractors_automatic_payment_type === PaymentMethods.wise

  const patchCompanyMutation = useMutation({
    mutationFn: ({ id, payload }) => patchCompany(id, payload),
  })

  const patchContractorsFee = useMutation({
    mutationFn: updateSwiftFeePayRoles,
    onSuccess: () => {
      refetchProfile()
      setCompanyOwnerPayload(null)
    },
  })

  const handleSubmit = (payload) => {
    patchCompanyMutation.mutate({
      id: profile.id,
      payload: companyOwnerPayload,
    })
    patchContractorsFee.mutate(payload)
  }

  if (!profile?.invoice_settings) return 'loading'

  return (
    <div style={{ maxWidth: 540, paddingBottom: 48 }}>
      <div className="d-flex flex-column">
        <div className="d-flex align-items-top justify-content-between mb-4">
          <Typography className="heading_semibold__20 color_black mb-2">Company currency</Typography>
          <Button
            data-testid="payment-terms-276137"
            priority="secondary"
            size="small"
            hidden={isWisePaymentMethod}
            onClick={applyForCompanyOwnerModal.setTrue}
          >
            Edit
          </Button>
        </div>
        <div className="mb-4">
          <Input
            data-testid="payment-terms-3573F0"
            value={profile.default_currency.short_code}
            label="Default currency"
            disabled
          />
        </div>

        {/* <Typography className="text_medium__14 mt-4">
          Bank account type
        </Typography>
        <Typography className="text_medium__14 color_text_300">
          {capitalizeFirstLetter(
            profile.invoice_settings.bank_account_type || '-'
        </Typography> */}
        <Typography className="heading_semibold__20 color_black">Contractor payments</Typography>
        <div
          className={classNames('d-flex align-items-center gap-2 mt-4', {
            'd-none': isWisePaymentMethod,
          })}
        >
          <Typography className="text_medium__14">International wire transfer (SWIFT) Fee</Typography>
          <Tooltip id={v4()} className="w-50" clickable content={<SwiftFeePayRoleTooltip />} />
        </div>
        <Typography
          className={classNames('text_medium__14 color_text_300', {
            'd-none': isWisePaymentMethod,
          })}
        >
          {SwiftFeePayrollLabel[profile.invoice_settings.swift_fee_pay_role || SWIFT_FEE_PAY_ROLE.COMPANY]}
        </Typography>

        <Typography className="heading_semibold__20 color_black mt-5">Full-time employee payments</Typography>
        <Input
          data-testid="payment-terms-141AFC"
          value={`${profile.invoice_settings.due_date_days} days`}
          label="Invoice Payment Terms"
          styleClass="mt-3"
          disabled
        />
        <Typography className="text_light__12 color_text_300 my-1">
          The number of days from when the invoice is generated until the payment is expected
        </Typography>
        <div className="d-flex align-items-center mt-3">
          <Toggle checked={profile.invoice_settings.allow_due_date_exceed_next_month} />
          <Typography className="text_medium__14 ml-2">Allow invoice due date to extend to the next month?</Typography>
        </div>
        <NotificationBlock
          styledClass={{ marginTop: 8 }}
          text="The system will extend the due date to the following month if necessary.
           For example, if the invoice payment terms are set to 7 days and the invoice is
           issued on July 26th, the invoice due date will be August 2nd"
        />
        <Input
          data-testid="payment-terms-CC33F9"
          value={`${profile.invoice_settings.late_payment_fee_percentage}%`}
          label="Late payment fee"
          disabled
        />
        <Typography className="text_light__12 color_text_300 my-1">
          If an invoice payment is made after the due date, a{' '}
          {`${profile.invoice_settings.late_payment_fee_percentage}%`} late fee will be incurred
        </Typography>
      </div>

      {applyForCompanyOwnerModal.value && (
        <PaymentTermsEditForm
          onClose={applyForCompanyOwnerModal.setFalse}
          onNext={(payload) => {
            setCompanyOwnerPayload(payload)
            applyForCompanyOwnerModal.setFalse()
          }}
        />
      )}

      {companyOwnerPayload && (
        <ContractorsListModal
          companySwiftFeePayRole={companyOwnerPayload.invoice_settings.swift_fee_pay_role}
          loading={patchCompanyMutation.isLoading || patchContractorsFee.isLoading}
          onClose={() => setCompanyOwnerPayload(null)}
          onSubmit={handleSubmit}
        />
      )}
    </div>
  )
}
