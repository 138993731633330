import styled from 'styled-components'

const Form = styled.form`
  width: 100%;
`

const FormGroup = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`
const FormControl = styled.div`
  margin-bottom: 1.5rem;
  width: 100%;

  &:last-child {
    margin-bottom: 0;
  }

  label {
    margin-bottom: 0.5rem;
  }
`

export { Form, FormControl, FormGroup }
