import Typography from '@atoms/Typography/Typography'
import React from 'react'

export const getHistoryFields = () => [
  {
    title: 'Title',
    key: 'card',
    type: 'custom',
    maxWidth: 680,
    minWidth: 680,
    width: 680,
    render: (item) => (
      <div>
        <Typography className="text_medium__14">{item.description}</Typography>
        <Typography className="text_light__12 color_text_300">
          •• {item.payment_method_details.us_bank_account.last4}
        </Typography>
      </div>
    ),
  },
  {
    title: 'Status',
    key: 'status',
    type: 'status',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
  {
    title: 'Total sum',
    key: 'amount',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
  {
    title: 'Date',
    key: 'created',
    maxWidth: 170,
    minWidth: 170,
    width: 170,
  },
]
